 //添加数字下载商品
 import React, {useEffect, useRef, useState, useImperativeHandle, forwardRef,useMemo} from "react";
 import {
     Col,
     Form,
     Input,
     Row,
     Select,
     Spin,
     Button,
     Dropdown,
     Divider,
     Layout,
     PageHeader,
     Switch
 } from "antd";
 import styles from './newProductDownload.module.less'
 import {useParams,useHistory} from 'react-router-dom'
 import { DownloadImgSize } from 'constants/Options'
 import { getSelectOptions } from 'utils/Help'
 import { PriceValidateRule,pricePattern } from 'utils/ValidateRulesAndMsg'
 import myMessage from 'components/Message'
 import {
   addDigital as _addDigital,
   editDigital as _editDigital,
 } from 'reduxModules/store'
 const { Option } = Select
 const { TextArea } = Input
 const {  Content } = Layout


 const NewProductDownload = ({name}) => {
     let history = useHistory()
     const [form] = Form.useForm()
     let { pricesheetId } = useParams()
     const [loading, setLoading] = useState(false)
     const [downloadType, setSupplierList] = useState([
         {code:'all',value:'全部图片'},
         {code:'single',value:'单个图片'},
     ])
     // const [downloadSize, setDownloadSize] = useState([
     //     {code:'1',value:'源尺寸'},
     //     {code:'2',value:'从下载尺寸数据库中读取'},
     // ])
   const [enterpriseId, setEnterpriseId] = useState('');
   const [pricesheet, setPricesheet] = useState({});
   useEffect(()=> {
     setPricesheet(JSON.parse(sessionStorage.getItem('pricesheet')))
     setEnterpriseId(sessionStorage.getItem('user')
       ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
       : null)
     //修改时赋值
     if(sessionStorage.getItem('product')) {
       console.log(JSON.parse(sessionStorage.getItem('product')))
       let product=JSON.parse(sessionStorage.getItem('product'))
       form.setFieldsValue({
         ...product
       })
     }
   },[])
     // 返回
     const goBack = () => {
         history.goBack()
     }

   const save =  () => {
     form
       .validateFields()
       .then( async (val) => {
         if(val.price==0){
           myMessage.warning('价格不能为空且不能为0!')
           return
         }
         val.priceListId=pricesheetId
         val.storeId=enterpriseId
         val.typeId=4
         let res
         if(sessionStorage.getItem('product')) {
           val.id= JSON.parse(sessionStorage.getItem('product')).id
           val.productOptionEditFroms= val.productOptionFroms
           val.productSpecificationsEditForm= val.productSpecificationsForms
           res = await _editDigital(val)
         }else{
           res = await _addDigital(val)
         }
         if ( res.status=='200'&&res.data.code === '000000') {
           myMessage.success(sessionStorage.getItem('product')?'更新成功':'创建成功')
           goBack()
         } else {
           myMessage.error(res.data.msg)
         }
       })
       .catch((info) => {
         console.log('Validate Failed:', info)
       })
   }
   //表单值变化
   const valuesChange =  ( size ) => {
      if(!!size.downloadType&&!sessionStorage.getItem('product')){
        console.log(size.downloadType)
        downloadType.forEach(v=>{
          if(form.getFieldValue('downloadType')==v.code){
            form.setFieldsValue({
              name:v.value+'下载'
            })
          }
        })
      }
   }
     return (
         <Layout>
             <PageHeader
                 ghost={false}
                 className={styles.titleBar}
                 title={
                     <div id="productDownload">
                         <Button
                             icon={
                                 <span
                                     className={`iconfont iconbtn_back`}
                                 ></span>
                             }
                             onClick={goBack}
                             className={styles.backBtn}
                         />
                       {pricesheet.name} · {sessionStorage.getItem('product')?'编辑':'添加'}数字下载
                     </div>
                 }
                 extra={[
                     <Button onClick={goBack} className={styles.cancelBtn}>取消</Button>,
                     <Button onClick={save} type="primary">保存</Button>
                 ]}
             ></PageHeader>
             <Content className={styles.newProductsPage}>
                 <Spin tip="数据加载中" spinning={loading}>
                     <Form
                         form={form}
                         layout="vertical"
                         name="productDownload"
                         className={styles.productForm}
                         onValuesChange={valuesChange}
                         initialValues={{
                             // users: [
                             //     {
                             //         isShow: false,
                             //     },
                             // ],
                         }}
                     >
                         <Row gutter={24} className={styles.productContent}>
                             <Col span={12}>
                                 <Form.Item
                                     name="downloadType"
                                     label="下载类型"
                                     rules={[
                                         {
                                             required: true,
                                             message: '请选择',
                                         },
                                     ]}
                                 >
                                     <Select
                                         placeholder="请选择"
                                         suffixIcon={
                                             <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                                         }
                                     >
                                         {downloadType.map(v=>(
                                             <Option value={v.code}>{v.value}</Option>
                                         ))}
                                     </Select>
                                 </Form.Item>
                             </Col>
                             <Col span={12}>
                                 <Form.Item
                                     name="downloadSize"
                                     label="下载尺寸"
                                     rules={[
                                        {
                                            required: true,
                                            message: '请选择',
                                        },
                                    ]}
                                 >
                                   <Select
                                     placeholder="请选择"
                                     suffixIcon={
                                       <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                                     }
                                   >
                                     {getSelectOptions(DownloadImgSize)}
                                   </Select>
                                     {/*<Select
                                         placeholder="请选择"
                                         suffixIcon={
                                             <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                                         }
                                     >
                                         {downloadSize.map(v=>(
                                             <Option value={v.code}>{v.value}</Option>
                                         ))}
                                     </Select>*/}
                                 </Form.Item>
                             </Col>
                             <Col span={24} >
                                 <Form.Item label="商品名称"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请输入商品名称',
                                                },
                                            ]}>
                                     <Input placeholder="请输入"/>
                                 </Form.Item>
                             </Col>
                             <Col span={24}>
                                 <Form.Item label="价格"
                                            name="price"
                                            rules={[PriceValidateRule]}>
                                     <Input placeholder="请输入"/>
                                 </Form.Item>
                             </Col>
                             <Col span={24}>
                                 <Form.Item label="商品描述" name="describe">
                                     <TextArea rows={3}  placeholder="选填，请输入该商品的详细信息，限500字内。"/>
                                 </Form.Item>
                             </Col>
                         </Row>
                     </Form>
                 </Spin>
             </Content>
         </Layout>
     )
 }
 export default NewProductDownload
