/*
 * @Author: melon
 * @Date: 2020-07-06 12:31:51
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-19 20:12:02
 */
import { isEmpty, map } from 'lodash/fp'

const mapWithIndex = map.convert({ cap: false })

// 获取
export const ShowcaseCategoryListSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    list: data,
    formattedData: {
      list: isEmpty(data)
        ? []
        : map((item) => ({ ...item, key: item.id + '' }))(data),
    },
  }
  return newData
}

// 获取
export const ShowcaseCategoryMoveOrCopyCategorySelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    list: data,
    formattedData: {
      list: isEmpty(data) ? [] : map((item) => ({ ...item }))(data),
    },
  }
  return newData
}

// 修改
export const ShowcaseCategoryAddSelector = (data) => {
  return {
    ...data,
  }
}

// 批量修改
export const ShowcaseCategoryBatchEditSelector = (data) => {
  return {
    showcaseType: data.showcaseType,
    categoryList: isEmpty(data.categoryList) ? [] : mapWithIndex(
      (item, index) => ({
        ...item,
        sort: index,
      })
    )(data.categoryList)
  }
}
