// Servers
import { _getWeb, _saveWeb,_clearRecycle,_getRecycleList,_clearRecycleAll,_recycleReduction } from 'servers/settings'
// Selectors

import {
  WebSettingSelector,
  WebSettingAddSelector,
  getRecycleListSelecter,
} from 'selectors/settings/SettingsSelectors'
// Actions

// Action Creators

// init data

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action
export const getWeb = async () => {
  const res = await _getWeb()
  return new Promise(function (resolve, reject) {
    resolve(WebSettingSelector(res))
    reject({})
  })
}

export const saveWeb = (data) => _saveWeb(WebSettingAddSelector(data))
export const getRecycleList = async (data) => {
  const res = await _getRecycleList(data)
  return new Promise(function (resolve, reject) {
    resolve(getRecycleListSelecter(res))
    reject({})
  })
}
export const clearRecycle = (data) => _clearRecycle(data)
export const recycleReduction = (data) => _recycleReduction(data)
export const clearRecycleAll = (data) => _clearRecycleAll(data)
