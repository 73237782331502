import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Switch, Divider} from 'antd'
import React, {useRef, useState,useEffect} from 'react'
import styles from './permissionsForm.module.less'
import myMessage from 'components/Message'
import {
    getInfoClient as _getInfoClient,
} from 'reduxModules/client'
import {color} from "echarts/src/export";
const { Option } = Select;
const PermissionsForm  = ({
                          visible,
                              onCancel,
                              onCreate,
                              projectDetail,
                              editClient,
                              removeClient
                        }) => {
    const formRef = useRef()
    const [form] = Form.useForm()
    const [isVisitor, setisVisitor] = useState(projectDetail.isVisitor)
    const [isSetPhotoHidden, setisSetPhotoHidden] = useState(projectDetail.isSetPhotoHidden)
    const [isMessage, setisMessage] = useState(projectDetail.isMessage)
    // constructor(props){
    //     super(props);
    //     this.state = { set:[this.props.states.set[0]]};
    //     this.handleBtnStatusChange = this.handleBtnStatusChange.bind(this);
    // }
    const changeVisitor = (checked) => {
        setisVisitor(checked)
    }
    const changePhotoHidden = (checked) => {
        setisSetPhotoHidden(checked)
    }
    const changeMessage = (checked) => {
        setisMessage(checked)
    }
    useEffect(() => {
        setisVisitor(projectDetail.isVisitor)
        setisSetPhotoHidden(projectDetail.isSetPhotoHidden)
        setisMessage(projectDetail.isMessage)
        formRef.current.setFieldsValue({
            isMessage:projectDetail.isMessage,
            isSetPhotoHidden:projectDetail.isSetPhotoHidden,
            isVisitor:projectDetail.isVisitor,
        });
        // console.log(formRef)
    }, [projectDetail])
    return (
        <Modal
            visible={visible}
            title="设置客户权限"
            okText="保存"
            cancelText="取消"
            centered
            cancelButtonProps={{className: 'footerCanceltext'}}
            onCancel={onCancel}
            maskClosable={false}
            width={600}
            forceRender={true}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields()
                        onCreate(values)
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info)
                    })
            }}
        >
            <Form
                ref={formRef}
                form={form}
                layout="vertical"
                name="add_project_form"
                requiredMark={false}
                className={styles.createProjectDialog}
                initialValues={{
                    isVisitor:isVisitor,
                    isSetPhotoHidden:isSetPhotoHidden,
                    isMessage:isMessage,
                }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                       <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',fontWeight:'bold'}}>
                           <span>
                               {projectDetail.customerResultVO?projectDetail.customerResultVO.customerName+'，'+(projectDetail.customerResultVO.customerPhone?projectDetail.customerResultVO.customerPhone:projectDetail.customerResultVO.customerEmail):''}
                               <Divider type="vertical" />
                                <Button type="link" className="link-btn"
                                    onClick={editClient}
                                    >编辑
                                </Button>
                            </span>
                           <span style={{color:'#E02020', cursor:'pointer'}} onClick={removeClient}>移除客户</span>
                       </div>
                    </Col>
                    <Col span={24}>
                        <div style={{marginBottom:'20px',paddingBottom:'20px',borderBottom:'1px solid rgba(225,225,225,.5)'}}></div>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="仅客户访问">
                            <Form.Item name="isVisitor" noStyle valuePropName="checked">
                                <Switch onChange={changeVisitor} />
                            </Form.Item>
                            <span className={styles.switchLabel}>
              {isVisitor ? '是' : '否'}
            </span>

                            <div className={styles.desc}>
                                说明：开启后该项目将关闭访客与浏览模式，仅客户账号可访问。
                            </div>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="允许客户标记图片隐藏">
                            <Form.Item name="isSetPhotoHidden" noStyle valuePropName="checked">
                                <Switch onChange={changePhotoHidden} />
                            </Form.Item>
                            <span className={styles.switchLabel}>
              {isSetPhotoHidden ? '是' : '否'}
            </span>

                            <div className={styles.desc}>
                                说明：选择是则客户可设置图片隐藏，设置后将对其他浏览者不显示，仅客户账号可见。
                            </div>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="允许客户对图片留言">
                            <Form.Item name="isMessage" noStyle valuePropName="checked">
                                <Switch onChange={changeMessage} />
                            </Form.Item>
                            <span className={styles.switchLabel}>
              {isMessage ? '是' : '否'}
            </span>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default PermissionsForm
