import {Card, Checkbox, Col, DatePicker, Divider, Form, Input, List, Modal, Row, Select, Spin} from 'antd'
import React, {useEffect, useState} from 'react'
import styles from './assignToProject.module.less'
import classNames from "classnames";

import {
  getPricename as _getPricename,
} from 'reduxModules/store'
import {
  getProjectsByCategory as _getProjectsByCategory,
} from 'reduxModules/customerPhoto'
import myMessage from 'components/Message'

const AssignToProject = ({
                           visible,
                           onCreate,
                           onCancel,
                           checkAssign,
                           pricesheetList,
                         }) => {
  const [form] = Form.useForm()
  const [isAll, setIsAll] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [checkedIds, setCheckedIds] = useState([])
  const [priductList, setPriductList] = useState([
    {id: '1', projectName: '框画', pricesheetName: ''},
    {id: '2', projectName: '项目', pricesheetName: '通用'}
  ])
  const [loading, setLoading] = useState(false)
  //项目
  const [projectList, setProjectList] = useState([])
  //绑定的价格表
  const [priceList, setPriceList] = useState([])
  useEffect(() => {
    form.setFieldsValue({
      // name:checkOption?.obj?.optionName,
    })
    // getListByCategory()
    console.log(pricesheetList)
  }, [])
  useEffect(() => {
    if(!isSearch){
      form.setFieldsValue({
        projectName:'',
      })
      getListByCategory()
    }
  }, [isSearch])
  // 获取项目列表
  const getListByCategory = async (data) => {
    setLoading(true)
    const res = await _getProjectsByCategory({
      //分类id
      categoryId: '',
      //项目名称
      name: form.getFieldValue('projectName'),
      // 所属图片项目分类id
      projectCategoryId: '',
      // 排序类型 SHOOTING_DAY_DESC, CREATE_TIME_DESC, PROJECT_NAME_DESC
      sortEnum: 'SHOOTING_DAY_DESC',
      //  项目状态 HIDDEN, ONLINE
      status: '',
      pageNo:1,
      pageSize:1000,
    })
    if (res.code === 0) {
      setProjectList(res.data)
      let priceIds=[]
      res.data.map(v=>{
        if(!priceIds.includes(v.priceId)) {
          priceIds.push(v.priceId)
        }
      })
      const re = await _getPricename(priceIds)
      if (re.status=='200'&&re.data.code == '000000') {
        setLoading(false)
        setPriceList(re.data.data)
      }else{
        setLoading(false)
        myMessage.error(res.mesg)
      }
    } else {
      setLoading(false)
      myMessage.error(res.msg)
    }
  }
  const priceName = (id) => {
    let name=''
    priceList.map(v=>{
      if(v.id==id){
        name=v.name
      }
    })
    return name||'未应用价格表'
  }
  const onChangeCheckBox = (e, item) => {
    let arr = checkedIds
    if (e.target.checked) {
      arr.push(item.id)
    } else {
      arr.splice(arr.indexOf(item.id), 1)
    }
    setCheckedIds([...arr])
  }
  const hasAll = (val) => {
    setIsAll(val)
    if (val) {
      let arr = projectList.map((v) => {
        return v.id
      })
      setCheckedIds([...arr])
    } else {
      setCheckedIds([])
    }
  }
  const valuesChange = (size) => {
    if (!!size.projectName||size.projectName=='') {
      getListByCategory()
    }
  }
  return (
    <Modal
      visible={visible}
      title={`应用${checkAssign?.name}到项目`}
      okText="应用到项目"
      cancelText="取消"
      centered
      cancelButtonProps={{className: 'footerCanceltext'}}
      onCancel={onCancel}
      maskClosable={false}
      width={600}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            onCreate(checkedIds)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_project_form"
        requiredMark={false}
        className={styles.createProjectDialog}
        onValuesChange={valuesChange}
        initialValues={{
          presetId: '',
        }}
      >
        <Spin tip="数据加载中" spinning={loading}>
        <div className={styles.titleBar}>
          <span>项目</span>
          <p>
            <span onClick={() => setIsSearch(!isSearch)}>{isSearch ? '关闭搜索' : '搜索'}</span>
            <Divider type="vertical"/>
            <span onClick={() => hasAll(true)}>全选</span>
            <Divider type="vertical"/>
            <span onClick={() => hasAll(false)}>取消选择</span>
          </p>
        </div>
        {isSearch &&
        <Form.Item label=""
                   name="projectName"
        >
          <Input placeholder="输入项目名称搜索" className={styles.searchInput}/>
        </Form.Item>
        }
        <List
          bordered
          itemLayout="vertical"
          dataSource={projectList}
          style={{marginTop: '10px', overflow: 'auto', maxHeight: '400px'}}
          renderItem={(item) => (
            <List.Item
              extra={
                <Checkbox
                  checked={checkedIds.includes(item.id)}
                  onChange={(e) => onChangeCheckBox(e, item)}
                ></Checkbox>
              }
            >
              <div className={styles.options} key={item.id}>
                <span className={styles.names}>{item.name}</span>
                <span className={styles.assignedPrice}>{item.priceId ? priceName(item.priceId) : '未应用价格表'}</span>
              </div>
            </List.Item>
          )}
        />
        </Spin>
      </Form>
    </Modal>
  )
}

export default AssignToProject
