import {
  Button,
  Col,
  Divider,
  Layout,
  Modal,
  PageHeader,
  Row,
  Spin,
  Typography,
} from 'antd'
import commonComponent from 'components/index'
import download from 'downloadjs'
import { indexOf, uniq, isEmpty } from 'lodash'
import React from 'react'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import InfiniteScroll from 'react-infinite-scroller'
import { getAllMoveOrCopyCategory as _getMoveOrCopyCategory } from 'reduxModules/showcaseCategory'
import {
  addDescription as _addDescription,
  getShowcasePhotoDownloadUrl as _getDownloadUrl,
  moveOrCopy as _moveOrCopy,
  remove as _remove,
} from 'reduxModules/showcasePhoto'
import styles from './photoPage.module.less'

const {
  MoveOrCopyModal,
  MyMessage,
  AddDescriptionModal,
  PreviewCarouselModal,
  InfoEmptyData,
} = commonComponent

const { Header, Sider, Content } = Layout
const { Paragraph } = Typography
export default class PhotoPage extends React.Component {
  state = {
    checkedList: [], // 被选中的 ids
    showName: false,
    moveOrCopyModalVisible: false, // 移动/复制弹窗
    categoryList: [], // 移动/复制弹窗需要展示的分类
    addDescriptionModalVisible: false, // 添加描述的弹窗
    checkedItems: [], // 被选中的 items
    deleteModalVisible: false, // 删除弹窗
    deleting: false, // 删除中
    moving: false, // 移动中
    coping: false, // 粘贴中
    previewCarouselModalVisible: false, // 查看图片弹窗
    previewList: [], // 查看弹窗图片list
    previewCurrent: 0, // 查看弹窗当前siderNumber
  }
  doSelect = (item) => {
    const { id } = item
    const { checkedList, checkedItems } = this.state
    const index = indexOf(checkedList, id)
    if (index < 0) {
      checkedList.push(id)
      uniq(checkedList)
      checkedItems.push(item)
      uniq(checkedItems)
    } else {
      checkedList.splice(index, 1)
      checkedItems.splice(index, 1)
    }
    this.setState({
      checkedList,
      checkedItems,
    })
  }
  onChange = (page, pageSize) => {
    this.props.changePage()
  }
  selectAll = () => {
    const {
      photosData: { list = [] },
    } = this.props
    const allList = list.map((img) => img.id)
    this.setState({
      checkedList: allList,
      checkedItems: list,
    })
  }
  removeAll = () => {
    this.setState({ checkedList: [], checkedItems: [] })
  }
  showFileName = () => {
    const { showName } = this.state
    this.setState({ showName: !showName })
  }
  // 显示移动/复制弹窗
  showMoveOrCopyModal = async () => {
    let { categoryId = '-1' } = this.props
    try {
      const res = await _getMoveOrCopyCategory()
      if (res.code === 0) {
        const {
          formattedData: { list },
        } = res.data

        const categoryList = list.filter(
          (category) => category.id !== categoryId
        )
        this.setState({
          categoryList: categoryList,
          moveOrCopyModalVisible: true,
        })
      } else {
        MyMessage.error(res.msg)
      }
    } catch (error) {}
  }
  // 隐藏移动/复制弹窗
  hideMoveOrCopyModal = () => {
    this.setState({
      categoryList: [],
      moveOrCopyModalVisible: false,
    })
  }
  // 移动
  onMove = async ({ targetCategoryId, categoryType }) => {
    const { checkedList } = this.state

    const ids = checkedList
    const body = {
      ids,
      targetCategoryId,
      categoryType,
      moveType: 'MOVE',
    }
    let res = {}
    // 派发修改数据请求
    try {
      await this.setState({
        moving: true,
      })
      const resData = await _moveOrCopy(body)
      await this.setState({
        moving: false,
      })
      res = resData
      if (resData.data && [0].includes(resData.data.code)) {
        MyMessage.success('移动成功')
        // 清空选择的数据
        this.setState({
          checkedList: [],
          checkedItems: [],
        })
        // 派发获取列表数据
        this.props.getPhotos({})
        // 关闭弹窗
        this.hideMoveOrCopyModal()
      } else {
        MyMessage.error('移动失败')
      }
    } catch (error) {
      res = {}
    }
    return res
  }

  // 复制
  onCopy = async ({ targetCategoryId, categoryType }) => {
    const { checkedList } = this.state
    const ids = checkedList

    const body = {
      ids,
      targetCategoryId,
      categoryType,
      moveType: 'COPY',
    }
    let res = {}
    // 派发修改数据请求
    try {
      await this.setState({
        coping: true,
      })
      const resData = await _moveOrCopy(body)
      await this.setState({
        coping: false,
      })
      res = resData
      if (resData.data && [0].includes(resData.data.code)) {
        MyMessage.success('复制成功')
        // 清空选择的数据
        this.setState({
          checkedList: [],
          checkedItems: [],
        })
        // 派发获取列表数据
        this.props.getPhotos({})
        // 关闭弹窗
        this.hideMoveOrCopyModal()
      } else {
        MyMessage.error('复制失败')
      }
    } catch (error) {
      res = {}
    }
    return res
  }
  // 显示添加描述弹窗
  showAddDescriptionModal = async () => {
    try {
      this.setState({
        addDescriptionModalVisible: true,
      })
    } catch (error) {}
  }
  // 隐藏添加描述弹窗
  hideAddDescriptionModalVisible = () => {
    this.setState({
      addDescriptionModalVisible: false,
    })
  }
  doAddDescription = async (values) => {
    const { checkedList } = this.state
    // 派发添加描述请求
    const body = {
      description: values.description,
      ids: [checkedList[0]],
    }
    let res = {}
    // 派发修改数据请求
    try {
      const resData = await _addDescription(body)
      res = resData
      if (resData.data && [0].includes(resData.data.code)) {
        MyMessage.success('添加描述成功')
        // 清空选择的数据
        this.setState({
          checkedList: [],
          checkedItems: [],
        })
        // 派发获取列表数据
        this.props.getPhotos({})
        // 关闭弹窗
        this.hideAddDescriptionModalVisible()
      } else {
        MyMessage.error('添加描述失败')
      }
    } catch (error) {
      res = {}
    }
    return res
  }
  // 显示删除弹窗
  showDeleteModal = async () => {
    try {
      this.setState({
        deleteModalVisible: true,
      })
    } catch (error) {}
  }
  // 隐藏删除弹窗
  hideDeleteModal = () => {
    this.setState({
      deleteModalVisible: false,
    })
  }
  // 删除
  onRemove = async () => {
    const { checkedList } = this.state
    // 派发删除请求
    const body = {
      ids: checkedList,
    }
    // 派发修改数据请求
    try {
      await this.setState({
        deleting: true,
      })
      const resData = await _remove(body)
      await this.setState({
        deleting: false,
      })
      if (resData.data && [0].includes(resData.data.code)) {
        MyMessage.success('删除成功')
        // 清空选择的数据
        this.setState({
          checkedList: [],
          checkedItems: [],
        })
        // 派发获取列表数据
        this.props.getPhotos({})
        // 关闭弹窗
        this.hideDeleteModal()
      } else {
        MyMessage.error('删除失败')
      }
    } catch (error) {}
  }

  // 显示查看弹窗
  showPreviewCarouselModal = async () => {
    const { checkedItems, checkedList } = this.state
    const {
      photosData: { pagination = {}, list = [] },
    } = this.props
    try {
      this.setState({
        previewCarouselModalVisible: true,
        previewList: checkedItems.length > 1 ? checkedItems : list,
        previewCurrent:
          checkedItems.length > 1
            ? 0
            : list.findIndex((item) => checkedList[0] === item.id),
      })
    } catch (error) {}
  }
  // 隐藏查看弹窗
  hidePreviewCarouselModal = () => {
    this.setState({
      previewCarouselModalVisible: false,
      previewList: [],
      previewCurrent: 0,
    })
  }
  // 下载图片
  downloadImgs = async () => {
    const { checkedList, checkedItems } = this.state
    const resData = await _getDownloadUrl({
      ids: checkedList,
    })

    if (resData && [0].includes(resData.code)) {
      const {
        data: {
          formattedData: { packageDownloadUrl },
        },
      } = resData
      download(packageDownloadUrl)
      MyMessage.success('下载成功')
    }
  }

  // 添加找类别照片
  showAddPhotos = () => {
    this.props.onShow()
  }

  //图片拖拉排序
  onSortEnd = async ({ oldIndex, newIndex }) => {
    const {
      photosData: { pagination = {}, list = [] },
      setPhotosData,
      _changeSort,
    } = this.props
    const newList = arrayMove(list, oldIndex, newIndex)
    const newSortIds = newList.map((img) => img.id)

    const res = await _changeSort(newSortIds)
    if (res.data.code == 0) {
      setPhotosData({
        pagination: pagination,
        list: newList,
      })
    } else {
      MyMessage.error('系统问题！请联系管理员！')
    }
  }

  render() {
    const {
      photosData: { pagination = {}, list = [] },
      loadData = () => {},
      loading,
      hasMore,
      headerTitle,
    } = this.props
    const {
      checkedList,
      showName,
      moveOrCopyModalVisible,
      categoryList,
      checkedItems,
      addDescriptionModalVisible,
      deleteModalVisible,
      deleting,
      moving,
      coping,
      previewCarouselModalVisible,
      previewList,
      previewCurrent,
    } = this.state
    const beNotSelected = checkedList.length < 1

    const SortableContainer = sortableContainer(({ children }) => {
      return <>{children}</>
    })

    const SortableItem = sortableElement(({ img }) => {
      return (
        <Col key={img.id} className={styles.photoItem}>
          <div key={img.id} onClick={this.doSelect.bind(this, img)}>
            <div
              className={`${styles.photo} ${
                checkedList.includes(img.id) ? styles.selected : ''
              }`}
              style={{
                width: 130,
                height: 130,
              }}
            >
              <img
                draggable="false"
                src={img.thumbInfo['thumb'].photoUrl}
                className={styles.image}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            </div>
            <div
              className={styles.photoName}
              style={{ visibility: showName ? '' : 'hidden' }}
              title={img.photoName}
            >
              <Paragraph ellipsis={{ rows: 2 }}>{img.photoName}</Paragraph>
            </div>
          </div>
        </Col>
      )
    })

    return (
      <>
        <Header className="sub-header">
          <Spin spinning={loading}>
            <PageHeader
              ghost={false}
              title={<div style={{ fontWeight: 'bold' }}>{headerTitle}</div>}
              style={{
                padding: '24px 50px 23px',
              }}
              extra={[
                <Button
                  type="primary"
                  key="addPhoto"
                  onClick={this.showAddPhotos}
                >
                  添加照片
                </Button>,
              ]}
            ></PageHeader>
          </Spin>
        </Header>
        <Spin spinning={loading}>
          {!isEmpty(pagination) &&
            (list.length > 0 ? (
              <Content
                className={`sub-content ${styles.content}`}
                style={{
                  overflowX: 'hidden',
                  height: '100vh',
                }}
              >
                <div className={styles.topArea}>
                  <div>
                    <span className={styles.imgCount}>
                      {pagination.total}张照片
                      {beNotSelected ? null : `/${checkedList.length}个选择`}
                    </span>
                    <span style={{ display: beNotSelected ? 'none' : '' }}>
                      <Divider type="vertical" />
                      <Button
                        type="link"
                        className="link-btn"
                        onClick={this.selectAll}
                      >
                        全选
                      </Button>
                      <Divider type="vertical" />
                      <Button
                        type="link"
                        className="link-btn"
                        onClick={this.removeAll}
                      >
                        取消选择
                      </Button>
                    </span>
                  </div>
                  <div>
                    <span style={{ display: beNotSelected ? 'none' : '' }}>
                      <Button
                        type="link"
                        className="link-btn"
                        disabled={beNotSelected}
                        onClick={this.showPreviewCarouselModal}
                      >
                        查看
                      </Button>
                      <Divider type="vertical" />

                      <Button
                        type="link"
                        className="link-btn"
                        disabled={beNotSelected}
                        onClick={this.showMoveOrCopyModal}
                      >
                        移动/复制
                      </Button>
                      {/* <Divider type="vertical" />
                      <Button
                        type="link"
                        className="link-btn"
                        onClick={this.downloadImgs}
                        disabled={beNotSelected}
                      >
                        下载
                      </Button> */}
                      <Divider type="vertical" />
                      <Button
                        type="link"
                        className="link-btn"
                        disabled={beNotSelected || checkedItems.length > 1}
                        onClick={this.showAddDescriptionModal}
                      >
                        添加描述
                      </Button>
                      <Divider type="vertical" />
                      <Button
                        type="link"
                        className="link-btn"
                        disabled={beNotSelected}
                        onClick={this.showDeleteModal}
                      >
                        删除
                      </Button>
                      </span>
                  </div>
                </div>
                {/* <div style={{ height: 200, overflow: 'auto' }}> */}
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={1}
                  loadMore={loadData}
                  hasMore={!loading && hasMore}
                  // hasMore={true}
                  useWindow={false}
                  style={{ marginTop: 40 }}
                >
                  <SortableContainer
                    onSortEnd={this.onSortEnd}
                    axis="xy"
                    distance={5}
                  >
                    <Row gutter={24}>
                      {list.map((img, index) => (
                        <SortableItem
                          key={`item-${index}`}
                          index={index}
                          img={img}
                        />
                      ))}
                    </Row>
                  </SortableContainer>
                  <Row justify={'center'}>
                    {' '}
                    {loading && hasMore && <Spin />}
                  </Row>
                  {/* <div style={{ height: '60vh' }}></div> */}
                </InfiniteScroll>
                {/* </div> */}
                {/* <div className={styles.pagination}>
              <Pagination
                current={pagination.current}
                total={pagination.total}
                onChange={this.onChange}
                showSizeChanger={false}
              />
            </div> */}
                {/* 移动/复制弹窗 */}
                <MoveOrCopyModal
                  visible={moveOrCopyModalVisible}
                  onCancel={this.hideMoveOrCopyModal}
                  data={categoryList}
                  onMove={this.onMove}
                  moveLoading={moving}
                  onCopy={this.onCopy}
                  copyLoading={coping}
                  isPage={false}
                />
                {/* 添加描述 弹窗 */}
                <AddDescriptionModal
                  visible={addDescriptionModalVisible}
                  onCancel={this.hideAddDescriptionModalVisible}
                  data={checkedItems.length ? checkedItems[0] : {}}
                  onCreate={this.doAddDescription}
                />
                {/* 删除弹窗 */}
                <Modal
                  visible={deleteModalVisible}
                  title="删除照片"
                  okText="删除"
                  cancelText="取消"
                  centered
                  onCancel={this.hideDeleteModal}
                  onOk={this.onRemove}
                  confirmLoading={deleting}
                  maskClosable={false}
                  width={600}
                  closable={false}
                  cancelButtonProps={{className: 'footerCanceltext'}}
                >
                  <div className="Inf_page_title">
                    所选内容将会被永久移除且不可恢复，确定要删除？
                  </div>
                </Modal>
                {/* 查看弹窗 */}
                <PreviewCarouselModal
                  visible={previewCarouselModalVisible}
                  onCancel={this.hidePreviewCarouselModal}
                  list={previewList}
                  key={previewList}
                  previewCurrent={previewCurrent}
                  showKey={'photoName'}
                />
              </Content>
            ) : (
              <div
                style={{
                  marginTop: 200,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <InfoEmptyData description="该分类暂无数据" />
                  <span className={styles.remarkTip}>添加作品即刻向朋友或客户展示您的才华。</span>
                </div>
              </div>
            ))}
        </Spin>
      </>
    )
  }
}
