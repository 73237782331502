import request from 'utils/Request'

import Api from 'apis/Api.js'

// 保存网站设置

export const _saveWeb = async (data) => {
  return await request({
    method: 'post',
    url: Api.settings.webEdit,
    data: data,
  })
}

export const _getWeb = async () => {
  return await request({
    method: 'get',
    url: Api.settings.webInof,
  })
}
export const _getRecycleList = async (params) => {
  return await request({
    method: 'get',
    url: Api.recycle.getRecycleList,
    params: params,
  })
}

export const _clearRecycle = async (data) => {
  return await request({
    method: 'post',
    url: Api.recycle.clearRecycle,
    data: data,
  })
}
export const _recycleReduction = async (data) => {
  return await request({
    method: 'post',
    url: Api.recycle.recycleReduction,
    data: data,
  })
}
export const _clearRecycleAll = async (data) => {
  return await request({
    method: 'post',
    url: Api.recycle.clearRecycleAll,
    data: data,
  })
}
