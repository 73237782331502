// Servers
import {
  _addOrderAdd,
  _deliverGoods,
  _eiditDeliverGoods,
  _getOrderAll,
  _getOrderDetails,
  _getOrderAddress,
  _addPricesheet,
  _copyPricesheet,
  _deletePricesheet,
  _editPricesheet,
  _getPricesheet,
  _getPricesheetId,
  _getEnterprise,
  _pricesheetInfo,
  _productEditStatus,
  _addProduct,
  _editProduct,
  _deleteProduct,
  _getSingle,
  _getPricename,
  _setDefault,
  _editPrice,
  _addDigital,
  _editDigital,
  _addPackage,
  _editPackage,
  _addCoupon,
  _deleteCoupon,
  _endCoupon,
  _editCoupon,
  _getCoupon,
  _productType,
  _uploadImg,
  _addPolicy,
  _editPolicy,
  _getPolicy,
  _getPolicyDetail,
  _deletePolicy,
  _addFreight,
  _deleteFreight,
  _editFreight,
  _getFreight,
  _addProductType,
  _deleteProductType,
  _editProductType,
  _getProductType,
  _getLogistEnu,
} from 'servers/store'
// Selectors

import {
  newDatas,
  pricesheetInfoSelect,
  getPricesheetSelect,
  getOrderSelect,
  getOrderAddressSelect,
  getOrderDetailsSelect,
} from 'selectors/store/StoreSelectors'
// Actions

// Action Creators

// init data

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

// //异步action
// export const getWeb = async () => {
//   const res = await _getWeb()
//   return new Promise(function (resolve, reject) {
//     resolve(WebSettingSelector(res))
//     reject({})
//   })
// }
//订单
export const addOrderAdd =  (data) => _addOrderAdd(data)
export const deliverGoods =  (data) => _deliverGoods(data)
export const eiditDeliverGoods =  (data) => _eiditDeliverGoods(data)
export const getOrderAll =  async (data) => {
  const res = await _getOrderAll({...data })
  return new Promise(function (resolve, reject) {
    resolve(getOrderSelect(res))
    reject(res)
  })
}
export const getOrderAddress =  async (data) => {
  const res = await _getOrderAddress({...data })
  return new Promise(function (resolve, reject) {
    resolve(getOrderAddressSelect(res))
    reject(res)
  })
}
export const getOrderDetails =   async (data) => {
  const res = await _getOrderDetails({...data })
  return new Promise(function (resolve, reject) {
    resolve(getOrderDetailsSelect(res))
    reject(res)
  })
}
//价格表
export const addPricesheet =  (data) => _addPricesheet(data)
export const copyPricesheet =  (data) => _copyPricesheet(data)
export const deletePricesheet =  (data) => _deletePricesheet(data)
export const editPricesheet = (data) =>  _editPricesheet(data)
export const getPricesheetId =  (data) => _getPricesheetId(data)
export const getPricesheet =  async (data) => {
  const res = await _getPricesheet({...data })
  return new Promise(function (resolve, reject) {
    resolve(getPricesheetSelect(res))
    reject(res)
  })
}
export const getEnterprise =  (data) => _getEnterprise({...data })
export const pricesheetInfo =  async (data) => {
  const res = await _pricesheetInfo({...data })
  return new Promise(function (resolve, reject) {
    resolve(pricesheetInfoSelect(res))
    reject(res)
  })
}
export const productEditStatus =  (data) => _productEditStatus(data)
export const addProduct =  (data) => _addProduct(data)
export const editProduct =  (data) => _editProduct(data)
export const editPrice =  (data) => _editPrice(data)
export const addDigital =  (data) => _addDigital(data)
export const editDigital =  (data) => _editDigital(data)
export const addPackage =  (data) => _addPackage(data)
export const editPackage =  (data) => _editPackage(data)
export const deleteProduct =  (data) => _deleteProduct(data)
export const getSingle =  (data) => _getSingle(data)
export const getPricename =  (data) => _getPricename(data)
export const setDefault =  (data) => _setDefault(data)
//优惠券
export const addCoupon =  (data) => _addCoupon(data)
export const deleteCoupon =  (data) => _deleteCoupon(data)
export const endCoupon =  (data) => _endCoupon(data)
export const editCoupon =  (data) => _editCoupon(data)
export const getCoupon = async (data) => {
  const res = await _getCoupon({...data })
  return new Promise(function (resolve, reject) {
    resolve(newDatas(res))
    reject(res)
  })
}
export const productType =  (data) => _productType(data)
export const uploadImg =  (data) => _uploadImg(data)
export const addPolicy =  (data) => _addPolicy(data)
export const editPolicy =  (data) => _editPolicy(data)
export const getPolicy =  (data) => _getPolicy(data)
export const getPolicyDetail =  (data) =>_getPolicyDetail(data)
export const deletePolicy =  (data) =>_deletePolicy(data)
export const addFreight =  (data) =>_addFreight(data)
export const deleteFreight =  (data) =>_deleteFreight(data)
export const editFreight =  (data) =>_editFreight(data)
export const getFreight =  (data) =>_getFreight(data)
export const addProductType =  (data) =>_addProductType(data)
export const deleteProductType =  (data) =>_deleteProductType(data)
export const editProductType =  (data) =>_editProductType(data)
export const getProductType =  (data) =>_getProductType(data)
export const getLogistEnu =  (data) =>_getLogistEnu(data)

