import { Row, Select } from 'antd'
import { DashboardDataType } from 'constants/Options'
// 按需加载
import 'echarts/lib/chart/line'
import 'echarts/lib/component/dataset'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip'
// 引入 ECharts 主模块
import echarts from 'echarts/lib/echarts'
import Img from 'imgs'
import { isEmpty } from 'lodash'

import React from 'react'
//message需要用到Table，参考MsgList.js
import { Table } from 'antd'
import {
  getCustomerInfo as _getCustomerInfo,
  getProjectInfo as _getProjectInfo,
} from 'reduxModules/dashboard'
import { getSelectOptions } from 'utils/Help'
import styles from './dashboardPage.module.less'

const { Option } = Select
export default class DashboardPage extends React.Component {
  async componentDidMount() {
    document.title = `Dashboard - Infotos`
    const user = sessionStorage.getItem('user')
      ? JSON.parse(sessionStorage.getItem('user'))
      : {}
    if (!isEmpty(user)) {
      // var projectChart = echarts.init(
      //   document.getElementById('project'),
      //   'light'
      // )
      // var customerChart = echarts.init(
      //   document.getElementById('customer'),
      //   'light'
      // )
      // projectChart.showLoading()
      // const projectRes = await _getProjectInfo({
      //   type: DashboardDataType[0].key,
      // })
      // projectChart.hideLoading()
      // projectChart.setOption({
      //   title: {
      //     text: '项目',
      //     subtext: projectRes.data.total,
      //     subtextStyle: {
      //       color: '#000000',
      //       fontSize: 24,
      //     },
      //     textStyle: {
      //       color: '#000000',
      //       fontSize: 14,
      //     },
      //   },
      //   xAxis: {
      //     type: 'category',
      //     boundaryGap: false,
      //     data: projectRes.data.date,
      //   },
      //   yAxis: {
      //     type: 'value',
      //     splitLine: {
      //       show: false,
      //     },
      //     axisTick: { show: false },
      //     axisLabel: {
      //       interval: (index, value) => {
      //         return true
      //       },
      //       showMaxLabel: true,
      //     },
      //   },
      //   series: [
      //     {
      //       data: projectRes.data.counts,
      //       type: 'line',
      //       areaStyle: {
      //         color: {
      //           type: 'linear',
      //           x: 0,
      //           y: 0,
      //           x2: 1,
      //           y2: 1,
      //           colorStops: [
      //             {
      //               offset: 0,
      //               color: '#d7e9fa', // 0% 处的颜色
      //             },
      //             {
      //               offset: 1,
      //               color: '#6ebae2', // 100% 处的颜色
      //             },
      //           ],
      //           global: false, // 缺省为 false
      //         },
      //       },
      //     },
      //   ],
      //   grid: { top: 80, height: 300 },
      //   tooltip: {},
      // })
      // customerChart.showLoading()
      // const customerRes = await _getCustomerInfo({
      //   type: DashboardDataType[0].key,
      // })
      // customerChart.hideLoading()
      // customerChart.setOption({
      //   title: {
      //     text: '客户',
      //     subtext: customerRes.data.total,
      //     subtextStyle: {
      //       color: '#000000',
      //       fontSize: 24,
      //     },
      //     tooltip: {},
      //     textStyle: {
      //       color: '#000000',
      //       fontSize: 14,
      //     },
      //   },
      //   xAxis: {
      //     type: 'category',
      //     boundaryGap: false,
      //     data: customerRes.data.date,
      //   },
      //   yAxis: {
      //     type: 'value',
      //     splitLine: {
      //       show: false,
      //     },
      //   },
      //   series: [
      //     {
      //       data: customerRes.data.counts,
      //       type: 'line',
      //       areaStyle: {
      //         color: {
      //           type: 'linear',
      //           x: 0,
      //           y: 0,
      //           x2: 1,
      //           y2: 1,
      //           colorStops: [
      //             {
      //               offset: 0,
      //               color: '#d8f0e8', // 0% 处的颜色
      //             },
      //             {
      //               offset: 1,
      //               color: '#7fcdb6', // 100% 处的颜色
      //             },
      //           ],
      //           global: false, // 缺省为 false
      //         },
      //       },
      //     },
      //   ],
      //   grid: { top: 80, height: 300 },
      // })
    }
  }
  changeDate = async (type) => {
    // 基于准备好的dom，初始化echarts实例
    var projectChart = echarts.getInstanceByDom(
      document.getElementById('project')
    )
    projectChart.showLoading()
    const projectRes = await _getProjectInfo({
      type: type,
    })
    projectChart.hideLoading()
    // 绘制图表
    projectChart.setOption({
      title: {
        subtext: projectRes.data.total,
      },
      xAxis: {
        data: projectRes.data.date,
      },
      series: [
        {
          data: projectRes.data.counts,
        },
      ],
    })
    var customerChart = echarts.getInstanceByDom(
      document.getElementById('customer')
    )
    customerChart.showLoading()
    const customerRes = await _getCustomerInfo({
      type: type,
    })
    customerChart.hideLoading()
    // 绘制图表
    customerChart.setOption({
      title: {
        subtext: customerRes.data.total,
      },
      xAxis: {
        data: customerRes.data.date,
      },
      series: [
        {
          data: customerRes.data.counts,
        },
      ],
    })
  }

  render() {
    const user = sessionStorage.getItem('user')
      ? JSON.parse(sessionStorage.getItem('user'))
      : {}
    const welcomeTitle =
      user.isFirstLogin === 'N'
        ? `Hi, ${user.nickname}`
        : '欢迎，在Infotos可以轻松快速建立你的摄影品牌展示与交付流程。'

    return (
      <div className={styles.dashboardPage}>
        <div className={styles.body}>
          <div className={styles.welcomeTitle}>{welcomeTitle}</div>
          {/*
          <Select
            suffixIcon={
              <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
            }
            defaultValue={DashboardDataType[0].key}
            style={{ width: 120 }}
            onChange={this.changeDate}
          >
            {getSelectOptions(DashboardDataType)}
          </Select>
          
          <Row style={{ margin: '20px 0' }}>
            <div id="project" style={{ width: '100%', height: 450 }}></div>
          </Row>
          <Row style={{ margin: '20px 0' }}>
            <div id="customer" style={{ width: '100%', height: 450 }}></div>
          </Row>
          */}
          <div className={styles.dashboardPorject}>
            <div style={{position:'absolute',width:'100%',height:'100%', zIndex:2, background:'rgba(255,255,255, 0.5)'}}><p style={{position:'relative',textAlign:'center', color:'#ccc', lineHeight:'4', top:'20%', fontSize: '60px', fontWeight: 'bold'}}>SAMPLE</p></div>
            <div className={styles.projectLeft}>
                <Select
                suffixIcon={
                  <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                }
                defaultValue={DashboardDataType[0].key}
                style={{ width: 120, marginBottom:20 }}
                // onChange={this.changeDate}
              >
                {getSelectOptions(DashboardDataType)}
              </Select>
              <div className={styles.projectItem}>
                <div className={styles.itemTit}><span className="icon iconfont iconicon_project"></span> 项目</div>
                <div className={styles.itemNum}>128</div>
              </div>
              <div className={styles.projectItem}>
                <div className={styles.itemTit}><span className="icon iconfont iconicon_customer"></span> 客户</div>
                <div className={styles.itemNum}>98</div>
              </div>
            </div>
            <div className={styles.projectRight}>
                <h4>消息通知</h4>
                <div className={styles.projectMsg}>
                  <div className={styles.projectMsgItem}>
                    <p className={styles.projectMsgItemIcon}><span class="icon iconfont iconicon_msg_normal"></span></p>
                    <div className={styles.projectMsgItemInfo}>
                      <p><strong>客户王杰18602819355</strong> 提交了预约或需求</p>
                      <p>留言内容。意向服务：儿童摄影。有确定时间：2021年10月8日。通过朋友介绍找到。</p>
                      <span className={styles.projectMsgItemInfoDate}>12分钟前</span>
                    </div>
                    <a href className={styles.projectMsgItemDel}><span class="icon iconfont iconbtn_dialog_close"></span></a>
                  </div>
                  <div className={styles.projectMsgItem}>
                    <p className={styles.projectMsgItemIcon}><span class="icon iconfont iconicon_msg_remind"></span></p>
                    <div className={styles.projectMsgItemInfo}>
                      <p><strong>客户王杰18602819355</strong> 提交了预约或需求</p>
                      <span className={styles.projectMsgItemInfoDate}>18小时前</span>
                    </div>
                    <a href className={styles.projectMsgItemDel}><span class="icon iconfont iconbtn_dialog_close"></span></a>
                  </div>
                  <div className={styles.projectMsgItem}>
                    <p className={styles.projectMsgItemIcon}><span class="icon iconfont iconicon_msg_remind"></span></p>
                    <div className={styles.projectMsgItemInfo}>
                      <p><strong>客户王杰18602819355</strong> 提交了预约或需求</p>
                      <span className={styles.projectMsgItemInfoDate}>2021/05/28 10:28</span>
                    </div>
                    <a href className={styles.projectMsgItemDel}><span class="icon iconfont iconbtn_dialog_close"></span></a>
                  </div>
                </div>
                <p className={styles.showAll}><a hre>查看全部</a></p>
            </div>
          </div>
          <div className={styles.banner}>
            <img src={Img.Banner} className={styles.welcomeImg} />
          </div>
        </div>
      </div>
    )
  }
}
