/*
 * @Author: melon
 * @Date: 2020-07-10 10:30:26
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-11 19:24:51
 */

import { isEmpty, map } from 'lodash/fp'
import moment from 'moment'

//  未读消息列表
export const MessageNoteReadListSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    ...data,
    formattedData: {
      formattedList: isEmpty(data.list)
        ? []
        : map((item) => ({
            ...item,
            timeText: moment().isAfter(moment(item.createTime), 'day')
              ? moment(item.createTime).format('YYYY/MM/DD HH:mm')
              : moment(item.createTime).fromNow(),
          }))(data.list),
      notReadTotal: data.totalCount,
      formattedPagination: {
        current: Number(data.pageNo),
        pageSize: Number(data.pageSize),
        total: Number(data.totalCount),
      },
    },
  }
  return newData
}

//  消息列表
export const MessageListSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    ...data,
    formattedData: {
      formattedList: isEmpty(data.list)
        ? []
        : map((item) => ({
            ...item,
            timeText: moment().isAfter(moment(item.createTime), 'day')
              ? moment(item.createTime).format('YYYY/MM/DD HH:mm')
              : moment(item.createTime).fromNow(),
          }))(data.list),
      formattedPagination: {
        current: Number(data.pageNo),
        pageSize: Number(data.pageSize),
        total: Number(data.totalCount),
      },
    },
  }
  return newData
}
