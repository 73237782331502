// Servers
// Selectors
import {
  ShowcaseSettingAboutPreviewUrlSelector,
  ShowcaseSettingAddSelector,
  ShowcaseSettingContactPreviewUrlSelector,
  ShowcaseSettingInfoSelector,
} from 'selectors/showcaseSetting/ShowcaseSettingSelectors'
import {
  _editShowcaseSetting,
  _getAboutUrl,
  _getContactUrl,
  _getShowcaseSetting,
} from 'servers/showcaseSetting'
// Actions

// Action Creators

// init data

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action
export const getShowcaseSetting = async () => {
  const res = await _getShowcaseSetting()
  return new Promise(function (resolve, reject) {
    resolve(ShowcaseSettingInfoSelector(res))
    reject({})
  })
}

export const editShowcaseSetting = (data) =>
  _editShowcaseSetting(ShowcaseSettingAddSelector(data))

//  获取关于预览地址
export const getAboutUrl = async () => {
  const res = await _getAboutUrl()
  return new Promise(function (resolve, reject) {
    resolve(ShowcaseSettingAboutPreviewUrlSelector(res))
    reject({})
  })
}

//  获取联系预览地址
export const getContactUrl = async () => {
  const res = await _getContactUrl()
  return new Promise(function (resolve, reject) {
    resolve(ShowcaseSettingContactPreviewUrlSelector(res))
    reject({})
  })
}
