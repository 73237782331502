import React from 'react';
import { Typography, Button, Menu, Dropdown } from 'antd';
import Imgs from 'imgs'
import styles from './showQRCode.module.less';
import { base64Encode } from 'qiniu-js/esm/base64';
import Api from '../../../Apis/Api'

const { Text, Paragraph } = Typography
const { QRcode } = Imgs

export const ShowQRCode = ({project}) => {
  console.log(Api.common.getQrcode)
  const prarme = 'https://static.infotos.co/wxShare/index.html?project_id='+project.id;
  const QRcodeImg = Api.common.getQrcode + '?url=' + base64Encode(prarme);
  const menu = (
    <Menu>
      <Menu.Item key="0" className={styles.code_item}>
        {/* 插入二维码 */}
        <img src={QRcodeImg} className={styles.code_img}></img>
        <br/>
        <p className={styles.code_text}>用微信扫一扫并分享</p>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
      <Button type="primary" size="small" className="black-btn" onClick={e => e.preventDefault()}>
        显示二维码
      </Button>
      {/* <a className="ant-dropdown-link" >
        Click me <DownOutlined />
      </a> */}
    </Dropdown>
  )
}

// export default ShowQRCode
