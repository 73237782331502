/*
 * @Author: melon
 * @Date: 2020-07-04 23:16:44
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-05 00:06:01
 */
// 登录数据
export const LoginSelector = (data) => {
  return {
    account: data.account, // 账号
    password: data.password, // 密码
  }
}
