/**
 * Help是提供特殊模块公用的方法的类
 */
import React from 'react'
import { Select } from 'antd'
import { isArray } from 'lodash'
import addressData from 'components/ProvinceSelectCascader/data.json'
const { Option } = Select

/**
 * 根据传入的选项常量数组 输出 可用于Select组件的Option
 *
 * @param {*} options  选项数组
 * 形如[
  {
    key: 1,
    label: '男'
  },
  {
    key: 2,
    label: '女'
  }
]
返回
<Option key={1}>男</Option>
<Option key={2}>女</Option>
 */
export const getSelectOptions = (options = []) => {
  const Options = isArray(options)
    ? options.map((item) => {
        return (
          <Option key={item.key} value={item.value || item.key} disabled={item.disabled}>
            {item.label}
          </Option>
        )
      })
    : ''
  return Options
}

export const getAddressString = (arrayAddr = []) => {
  let string = ''
  const matchP = addressData.filter(
    (province) => province.value === arrayAddr[0]
  )[0]
  if (matchP) {
    string = string + matchP.label
  }
  const matchC = matchP.children.filter(
    (city) => city.value === arrayAddr[1]
  )[0]
  if (matchC) {
    string = string + matchC.label
  }
  const matchZ = matchC.children.filter(
    (zone) => zone.value === arrayAddr[2]
  )[0]
  if (matchZ) {
    string = string + matchZ.label
  }
  return string
}

export const getName = (item,type) => {
  if(type=='download'&&item.downloadEmail){
    return item.downloadEmail
  }
  if(item.nickName){
    return item.nickName
  }
    if(item.signinInfo&&item.signinInfo.customerPhone){
        return item.signinInfo.customerPhone
    }
  if(item.signinInfo&&item.signinInfo.customerEmail){
    return item.signinInfo.customerEmail
  }
  if(item.signinInfoResultVO&&item.signinInfoResultVO.customerPhone){
      return item.signinInfoResultVO.customerPhone
  }
  if(item.signinInfoResultVO&&item.signinInfoResultVO.customerEmail){
    return item.signinInfoResultVO.customerEmail
  }
  if(item.visitorResultVO&&item.visitorResultVO.email){
    return item.visitorResultVO.email
  }
  if(item.visitorResultVO&&item.visitorResultVO.nickName){
    return item.visitorResultVO.nickName
  }
}
