/*
 * @Author: melon
 * @Date: 2020-07-04 23:17:14
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-12 00:17:43
 */
// Servers
// Selectors
import {
  EnterpriseServiceAddSelector,
  EnterpriseServiceListSelector,
  EnterpriseServiceInfoSelector,
  EnterpriseServiceCategoryInfoSelector,
} from 'selectors/enterpriseService/EnterpriseServiceSelectors'
import {
  _editEnterpriseInfo,
  _getCategorySelector,
  _getEnterpriseInfo,
  _getEnterpriseList,
  _removeEnterpriseInfo,
} from 'servers/enterpriseService'

// Actions

// Action Creators

// init data

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action

export const getEnterpriseList = async (data) => {
  const res = await _getEnterpriseList(data)
  return new Promise(function (resolve, reject) {
    resolve(EnterpriseServiceListSelector(res))
    reject({})
  })
}

export const getEnterpriseInfo = async (data) => {
  const res = await _getEnterpriseInfo(data)
  return new Promise(function (resolve, reject) {
    resolve(EnterpriseServiceInfoSelector(res))
    reject({})
  })
}

export const getCategorySelector = async (data) => {
  const res = await _getCategorySelector(data)
  return new Promise(function (resolve, reject) {
    resolve(EnterpriseServiceCategoryInfoSelector(res))
    reject({})
  })
}

export const editEnterpriseInfo = (data) =>
  _editEnterpriseInfo(EnterpriseServiceAddSelector(data))

export const removeEnterpriseInfo = (data) => _removeEnterpriseInfo(data)
