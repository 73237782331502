/*
 * @Author: melon
 * @Date: 2020-07-10 11:07:54
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-11 19:25:10
 */
/*
 * @Author: melon
 * @Date: 2020-07-04 23:17:14
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-06 12:33:59
 */
// Servers

import { _getMessageList, _getNotReadList } from 'servers/message'

// Selectors
import {
  MessageNoteReadListSelector,
  MessageListSelector,
} from 'selectors/message/MessageSelectors'

// Actions

// Action Creators

// init data

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action
export const getNotReadList = async (data) => {
  const res = await _getNotReadList(data)
  return new Promise(function (resolve, reject) {
    resolve(MessageNoteReadListSelector(res))
    reject({})
  })
}

export const getMessageList = async (data) => {
  const res = await _getMessageList(data)
  return new Promise(function (resolve, reject) {
    resolve(MessageListSelector(res))
    reject({})
  })
}
