import request from 'utils/Request'

import Api from 'apis/Api.js'

export const _getProjectInfo = async (params) => {
  return await request({
    method: 'get',
    url: Api.dashboard.projectInfo,
    params: params,
  })
}

export const _getCustomerInfo = async (params) => {
  return await request({
    method: 'get',
    url: Api.dashboard.customerInfo,
    params: params,
  })
}
