//优惠券
import {
    Button,
    Layout,
    Menu,
    Divider,
    Dropdown,
    PageHeader,
    Spin,
    Select,
    Col,
    Row,
    Input,
    Tooltip,
    Card,
    Table,
    Pagination,
    Modal,
    Form, DatePicker
} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {
  getOptionBykeyName,
} from 'utils/Tools'
import { PAGE_PATH } from 'apis/Router'
import {
    addCoupon as _addCoupon,
    deleteCoupon as _deleteCoupon,
    endCoupon as _endCoupon,
    editCoupon as _editCoupon,
    getCoupon as _getCoupon,
    productType as _productType,
} from 'reduxModules/store'
import CommonComponent from 'components/index'
import myMessage from 'components/Message'
import styles from './coupon.module.less'
import NewCoupon from "./NewCoupon";
import { scopeTypes,discountType,couponStatus } from 'constants/Options'

const {Header, Footer, Sider, Content} = Layout
const { MyMessage, InfoEmptyData } = CommonComponent

const Coupon = ({name}) => {
    let history = useHistory()
    const [loading, setLoading] = useState(false)
    // 选中的
    const [selectedRows, setSelectedRows] = useState({})
    // 总数
    const [total, setTotal] = useState(0)
    const [deleteVisible, setdeleteVisible] = useState(false)
    const [selectedCoupon, setSelectedCoupon] = useState({
        pageNo:1,
        pageSize:20,
        current:10,
        totalCount:20
    })
    const [CouponList, setCouponList] = useState([])
    const [addVisible, setAddVisible] = useState(false)

    const [CouponInfo, setCouponInfo] = useState({})
    const [productTypes, setProductTypes] = useState([])

    // 来源
    const onClick = ({key}) => {
        // switch (key) {
        //     case "1":
        setSelectedCoupon({
            pageNo:selectedCoupon.pageNo,
            pageSize:selectedCoupon.pageSize,
        })

    };

    const onBaseClick=(current,pageSize)=>{
        setSelectedCoupon({
            pageNo:current,
            pageSize:selectedCoupon.pageSize,
            totalCount:20
        })
    }
    // 获取列表
    const getListByCoupon = async (data) => {
        setLoading(true)
        const res = await _getCoupon({
            storeId:sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).enterpriseId :null,
            pageNum:selectedCoupon.pageNo,
            pageSize:selectedCoupon.pageSize,
        })
        setLoading(false)
        if (res.code === '000000') {
            setCouponList(res.data.list)
            setTotal(res.data.pagination.total)
        } else {
            myMessage.error(res.data.mesg)
        }
    }
    // 获取列表
    const getListProductType = async () => {
      const res = await _productType({
        storeId:sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).enterpriseId :null,
        pageNum:1,
        pageSize:1000,
      })
      if (res.status=='200'&&res.data.code === '000000') {
        setProductTypes(res.data.data.records)
      } else {
        myMessage.error(res.data.mesg)
      }
    }

    useEffect(() => {
        getListByCoupon(selectedCoupon)
        getListProductType()
    }, [selectedCoupon])
    const handleItem = async (type, item) => {
      setSelectedRows(item)
        if(type === 'edit'){
          setAddVisible(true)
        }else if(type === 'delete'){
          setdeleteVisible(true)
        }
    }

  const deleteCoupon = async () => {
    let res
    if(selectedRows.status=='using'){
      res = await _endCoupon({id:selectedRows.id})
    }else{
      res = await _deleteCoupon({id:selectedRows.id})
    }
    if (res.status=='200'&&res.data.code === '000000') {
      getListByCoupon(selectedCoupon)
      setdeleteVisible(false)
    } else {
      myMessage.error(res.data.msg)
    }
  }
    const columns = [
        {
            title: '名称',
            dataIndex: 'discountName',
            key: 'discountName',
            render: (_, item) =>  {return (
                <div
                    // onClick={() => clickRow(item)}
                    className={styles.nameCell}
                >
                    {item.discountName}
                </div>
            )},
        },
        {
            title: '优惠券编码',
            dataIndex: 'redemptionCode',
            key: 'redemptionCode',
        },
        {
            title: '优惠形式',
            dataIndex: 'discountType',
            key: 'discountType',
            render: (_, item) =>  {return (
              <div>
                {item.minPrice!=null?'订单满':'全部订单'}{item.minPrice}
                {item.discountType!='freeMail'?'优惠':'免邮'}
                {item.discountTypeContent}
                {item.discountType=='freeMail'?'':item.discountType=='sunMoney'?'元':'%'}
              </div>
            )},
        },
        {
            title: '截止日期',
            dataIndex: 'endTime',
            key: 'endTime',
            render: (_, item) =>  {return (
              <div>
                {item.endTime?item.endTime:'永久'}
              </div>
            )},
        },
        {
            title: '创建时间',
            dataIndex: 'createdTime',
            key: 'createdTime',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (_, item) =>  {return (
              <div>
                {getOptionBykeyName(
                  'value',
                  item.status,
                  'label',
                  couponStatus
                )}
              </div>
            )},
        },
        {
            title: '',
            dataIndex: 'opt',
            key: 'opt',
            render: (_, item) => {
                // if (+item.rolesId === 1) return ''
                return (
                    <div className={styles.menuBtn}>
                        <Dropdown
                            overlay={
                                <div className={styles.settingsMenu}>
                                    <Menu>
                                        {/*<Menu.Item><a key='1' onClick={() => handleItem('runorstop', item)}>停止/启用</a></Menu.Item>*/}
                                        {/*<Menu.Divider />*/}
                                        <Menu.Item><a key='2' onClick={() => handleItem('edit', item)}>编辑</a></Menu.Item>
                                        <Menu.Divider />
                                        <Menu.Item><a key='3' onClick={() => handleItem('delete', item)}>{item.status=='using'?'终止':'删除'}</a></Menu.Item>
                                    </Menu>
                                </div>
                            }
                            placement="bottomRight"
                            trigger={['click']}>
                            <span className="iconfont iconbtn_list_set"></span>
                        </Dropdown>
                    </div>
                )
            }
        }
    ]
    useEffect(() => {
    }, [])
    //添加
    const onAddCoupon= async (val)=>{
      let res
      val.storeId=sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).enterpriseId :null
      if(!!selectedRows.id) {
        let data={
          id:selectedRows.id,
          discountName:val.discountName
        }
        res = await _editCoupon(data)
      }else{
        val.endTime=val.endTime?val.endTime.format('YYYY-MM-DD'):''
        res = await _addCoupon(val)
      }
      if (res.status=='200'&&res.data.code === '000000') {
        myMessage.success(!!selectedRows.id?'更新成功':'创建成功')
        setAddVisible(false)
        getListByCoupon(selectedCoupon)
      } else {
        myMessage.error(res.data.msg)
      }
    }
    return (
        <Layout>
            <PageHeader
                ghost={false}
                className={styles.titleBar}
                title={
                    <div id="couponList">
                        优惠券
                    </div>
                }
                extra={[
                    <Button type="primary" onClick={()=>{
                      setSelectedRows({})
                      setAddVisible(true)
                    }}>添加优惠券</Button>
                ]}
            ></PageHeader>
            <Card bordered={false} className={styles.couponList}>
                <Spin tip="数据加载中" spinning={loading}>
                    {/*{CouponList.length > 0||selectedCoupon.sourceEnum!=''||selectedCoupon.searchValue!=''?(*/}
                    {CouponList.length > 0 && !loading?(
                        <Row className={styles.controlBar}>
                            <Col span={24} className={styles.listTopText}>
                                <span className={styles.ptCount}>{total}张优惠券</span>
                                {/*<Divider type="vertical"/>*/}
                                {/* <span style={{color:'#4a4a4a',fontSize:'12px',marginLeft:'10px'}}>说明：定价单是用来管理设置输出商品的选项，您可将不同的价格应用在不同的销售场景中。</span> */}
                            </Col>
                        </Row>):null
                    }
                    {CouponList.length > 0 && (
                        <Table
                            // className={styles.tablesbody}
                            style={{marginBottom: '30px'}}
                            dataSource={CouponList}
                            columns={columns}
                            pagination={{
                                simple:false,
                                current:selectedCoupon.pageNo,
                                total:total,
                                hideOnSinglePage: true,
                                howSizeChanger: false,
                                position:["bottomCenter"],
                                pageSize:20,
                                onChange:(current,pageSize)=>{
                                    onBaseClick(current,pageSize)
                                }
                            }}
                        />
                    )}
                    {CouponList.length <= 0 && !loading && (
                        <div>
                            <InfoEmptyData description="暂未创建优惠券" />
                            <span className={styles.remarkTip}>创建优惠券并发放给客户，提升产品销量。</span>
                        </div>
                    )}
                </Spin>
            </Card>
          {addVisible &&
          <NewCoupon
            visible={addVisible}
            onCreate={onAddCoupon}
            onCancel={() => {
              setAddVisible(false)
            }}
            couponType={discountType}
            couponAssign={scopeTypes}
            productTypes={productTypes}
            selectedRows={selectedRows}
          />
          }
          <Modal
            title={(selectedRows.status=='using'?'终止':'删除') + '优惠卷'}
            visible={deleteVisible}
            onOk={deleteCoupon}
            onCancel={()=>setdeleteVisible(false)}
            okText="确定"
            cancelText="取消"
            width={600}
            closable={false}
            centered
            maskClosable={false}
            cancelButtonProps={{className: 'footerCanceltext'}}
          >
            <div className="Inf_page_title">{selectedRows.status=='using'?'终止后将不可恢复，可能会影响已有相关商品，':'删除将不可恢复，'}确定继续？</div>
          </Modal>
        </Layout>
    )
}

export default Coupon
