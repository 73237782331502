import Api from 'apis/Api.js'
import request from 'utils/Request'

// ------------------------客片项目
// 客片项目分类
export const _getProjectCategory = async () => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.category,
  })
}

export const _addProjectCategory = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.addCategory,
    data: data,
  })
}

export const _saveProjectCategory = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.saveProjectCategory,
    data: data,
  })
}

export const _getProjectsByCategory = async (data) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.projects,
    params: data,
  })
}

export const _changeLanguage = async (params) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.changeLang,
    params: params,
  })
}

// 新增客片项目
export const _addProject = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.addProject,
    data: data,
  })
}

//  修改客片项目状态
export const _changeProjectStatus = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.changeProjectStatus,
    data: data,
  })
}

//  删除客片项目
export const _removeProject = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.removeProject,
    data: data,
  })
}

// 获取客片项目
export const _getProjectById = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.project,
    params: params,
  })
}

// 项目照片分类
export const _getPhotoCategory = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.photoCategory,
    params: params,
  })
}

// 获取移动或复制项目照片分类
export const _getPhotoCategoryForMove = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.photoCategoryForMove,
    params: params,
  })
}

// 修改照片分类
export const _savePhotoCategory = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.savePhotoCategory,
    data: data,
  })
}

// 项目照片
export const _getPhotosByCategory = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.photos,
    params: params,
  })
}

// 新增照片
export const _addPhotos = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.addPhotos,
    data: data,
  })
}
// 打包客片照片
export const _packagePhotos = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.packagePhotos,
    data: data,
  })
}

// 检查照片存在
export const _checkPhotos = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.checkImage,
    data: data,
  })
}

// 移动照片
export const _moveOrCopyPhotos = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.moveOrCopyPhotos,
    data: data,
  })
}

// 设置照片为封面

export const _setCover = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.setCover,
    data: data,
  })
}

// 获取客片预览地址
export const _getPreviewUrlByProject = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.previewUrl,
    params: params,
  })
}

// 删除照片
export const _removePhotos = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.removePhotos,
    data: data,
  })
}
// 调整客片照片顺序(将当前已加载的所有图片顺序全部提交)
export const _changeSort = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.changeSort,
    data: data,
  })
}
// 收藏夹
export const _getBookmarks = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.bookmark,
    params: params,
  })
}

// 获取移动或复制文件夹
export const _getBookmarksForMove = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.bookmarkCategoryForMove,
    params: params,
  })
}

export const _saveBookmark = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.saveBookmark,
    data: data,
  })
}
//下载Excel
export const _downloadBookmarkExcel = async (data) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.downloadBookmarkExcel,
    params: data,
    responseType: 'blob'
  })
}

//下载Excel
export const _downloadPrivateExcel = async (data) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.downloadPrivateExcel,
    params: data,
    responseType: 'blob'
  })
}

export const _removeBookmark = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.removeBookmark,
    data: data,
  })
}

// 收藏夹照片
export const _getPhotosByBookmark = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.bookmarkPhoto,
    params: params,
  })
}

// 移动文件夹照片
export const _moveOrCopyBookmarkPhotos = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.moveOrCopyBookmarkPhotos,
    data: data,
  })
}
// 删除文件夹照片
export const _removeBookmarkPhotos = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.removeBookmarkPhotos,
    data: data,
  })
}
// 打包收藏夹客片照片
export const _packageBookmarkPhotos = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.packageBookmarkPhotos,
    data: data,
  })
}
// 设置
export const _editProject = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.editProject,
    data: data,
  })
}
// b端显示c端超量提示
export const _overFavorite = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.overFavorite,
    params: params,
  })
}

// 忽略超量提示
export const _ignoreExceedTip = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.ignoreExceedTip,
    data: data,
  })
}
// 获取收藏夹文件名
export const _fileNameList = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.fileNameList,
    params: params,
  })
}
// 获取收藏夹选中文件名
export const _fileNameLists = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.fileNameLists,
    data: data,
  })
}
// 获取私有/留言选中文件名
export const _customerFileNameLists = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.customerFileNameLists,
    data: data,
  })
}
// 添加意见反馈
export const _feedback = async (data) => {
  return await request({
    method: 'post',
    url: Api.feedback.feedback,
    data: data,
  })
}
// 邮件分享项目链接
export const _shareEmail = async (data) => {
  return await request({
    method: 'post',
    url: Api.share.email,
    data: data,
  })
}
//邮件已分享人员
export const _emailRecords = async (params) => {
  return await request({
    method: 'get',
    url: Api.share.emailRecords,
    params: params,
  })
}
//获取客片私有图片
export const _getHiddenListForPage = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.getHiddenListForPage,
    params: params,
  })
}
//获取客片留言图片
export const _getMessageListForPage = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.getMessageListForPage,
    params: params,
  })
}
//根据项目ID获取收藏图片数量，隐藏图片数量，留言数量
export const _getProjectStatistics = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.getProjectStatistics,
    params: params,
  })
}
//查询项目浏览记录列表
export const _projectRecord = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.projectRecord,
    params: params,
  })
}
//移除浏览记录
export const _removeRecord = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.removeRecord,
    data: data,
  })
}
//移除留言
export const _removeMessage = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.removeMessage,
    data: data,
  })
}
//查询项目分享记录列表
export const _shareRecord = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.shareRecord,
    params: params,
  })
}
//移除分享
export const _removeShare = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.removeShare,
    data: data,
  })
}
//查询项目分享记录列表
export const _projectBookmarkRecord = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.projectBookmarkRecord,
    params: params,
  })
}
//移除分享
export const _removeBookmarkRecord = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.removeBookmarkRecord,
    data: data,
  })
}
//查询项目分享记录列表
export const _projectDownloadRecord = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.projectDownloadRecord,
    params: params,
  })
}
//移除分享
export const _removeDownloadRecord = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.removeDownloadRecord,
    data: data,
  })
}
//移除私有影藏
export const _removeHidden = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.removeHidden,
    data: data,
  })
}
//添加项目预设
export const _addPreset = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.addPreset,
    data: data,
  })
}
//修改项目预设
export const _editPreset = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.editPreset,
    data: data,
  })
}
//查询项目预设
export const _listPreset = async (params) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.listPreset,
    params: params,
  })
}
//移除项目预设
export const _removePreset = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.removePreset,
    data: data,
  })
}
//项目批量绑定销售价格ID
export const _editProjectsPriceId = async (data) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.editProjectsPriceId,
    data: data,
  })
}
