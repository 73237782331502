// Servers
import { _getInfoClient, _editClient,_addClient, _removeClient,_clientInfos,_customerRecord} from 'servers/client'
// Selectors

import {
  getInfoClientSelector,
  editClientSelector,
  addClientSelector,
  removeClientSelector,
  clientInfosSelector,
  customerRecordSelector
} from 'selectors/client/ClientSelectors'
// Actions

// Action Creators

// init data

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

// //异步action
// export const getWeb = async () => {
//   const res = await _getWeb()
//   return new Promise(function (resolve, reject) {
//     resolve(WebSettingSelector(res))
//     reject({})
//   })
// }

export const getInfoClient = (data) => _getInfoClient(getInfoClientSelector(data))
export const editClient = (data) => _editClient(editClientSelector(data))
export const addClient = (data) => _addClient(addClientSelector(data))
export const removeClient = (data) => _removeClient(removeClientSelector(data))
export const clientInfos = (data) => _clientInfos(clientInfosSelector(data))
export const customerRecord = async (data) => {
  const res = await _customerRecord(data)
  return new Promise(function (resolve, reject) {
    resolve(customerRecordSelector(res))
    reject({})
  })
}
