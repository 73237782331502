/*
 * @Author: melon
 * @Date: 2020-07-05 22:49:07
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-12 00:17:54
 */
import request from 'utils/Request'

import Api from 'apis/Api.js'

// 获取服务信息列表

export const _getEnterpriseList = async () => {
  return await request({
    method: 'get',
    url: Api.enterpriseService.enterpriseServiceList,
    params: {},
  })
}

// 获取服务信息列表

export const _getEnterpriseInfo = async (data) => {
  return await request({
    method: 'get',
    url: Api.enterpriseService.getById,
    params: {
      ...data,
    },
  })
}

// 查询分类选择列表

export const _getCategorySelector = async () => {
  return await request({
    method: 'get',
    url: Api.enterpriseService.getCategorySelector,
    params: {},
  })
}

// 新建/修改服务信息
export const _editEnterpriseInfo = async (data) => {
  return await request({
    method: 'post',
    url: Api.enterpriseService.enterpriseServiceEdit,
    data: data,
  })
}

// 删除
export const _removeEnterpriseInfo = async (data) => {
  return await request({
    method: 'post',
    url: Api.enterpriseService.remove,
    data: data,
  })
}
