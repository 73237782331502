import axios from 'axios'

import { message } from 'antd'
import myMessage from 'components/Message/index'
import { PAGE_PATH } from 'apis/Router'

let token = undefined
let accessToken = undefined

export const setAccessToken = (userToken) => {
  accessToken = userToken
}
export const getAccessToken = () => {
  return accessToken
}
/**
 * 请求拦截器
 * 处理http请求request，添加token等
 */
axios.interceptors.request.use(
  (config) => {
    // 自动设置 token
    config.headers.accessToken = accessToken
    config.headers['content-type'] = 'application/json'
    return config
  },
  (error) => {
    return Promise.error(error)
  }
)
/**
 * 响应拦截器
 * 处理http响应response，判断异常或错误等
 */
axios.interceptors.response.use(
  (response) => {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'mock'
    ) {
      // console.log('---response--->', response)
    }
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    // console.log('process.env.NODE_ENV', process.env.NODE_ENV)
    if (response.status === 200 && process.env.NODE_ENV !== 'mock') {
      if ([9200,'020001'].includes(response.data.code)) {
        // myMessage.error('登录过期，将会重新进入')
        // 清除token
        setAccessToken(undefined)
        // 清除登录用户信息
        sessionStorage.removeItem('user')

        setTimeout(() => {
          window.location.href = PAGE_PATH.login
        }, 1000)
      }
      return Promise.resolve(response)
    } else {
      return Promise.reject(response.data)
    }
  },
  // 服务器状态码不是2开头的的情况
  // 下面列举几个常见的操作，其他需求可自行扩展
  (error) => {
    if (error.response && process.env.NODE_ENV !== 'mock') {
      console.log('response mock', process.env.NODE_ENV)
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          message.error('身份验证失败，请重新进入')
          break

        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          message.error('登录过期，将会重新进入')
          // 清除token
          setAccessToken(undefined)
          // 清除登录用户信息
          sessionStorage.removeItem('user')
          setTimeout(() => {
            window.location.href = PAGE_PATH.login
          }, 1000)
          break

        // 404请求不存在
        case 404:
          message.error('您访问的网页不存在。')
          break
        // 其他错误，直接抛出错误提示
        default:
          message.error(error.response.data.message)
      }
      return Promise.reject(error.response)
    }
  }
)

/**
 *
 * @param {*} options
 *
 * 用法
 *
  request({
      method: 'post',
      url: 'www.baidu.com',
      headers: { 'Content-Type': 'blob' },
      data: {
        firstName: 'Fred',
        lastName: 'Flintstone',
      },
    })
 */

export default function request(options) {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'mock'
  ) {
    // console.log('---request--->', options, JSON.stringify(options))
  }
  return axios(options)
}
