/**
 * tabs颜色
 *
 * #607AFB  - 紫蓝
 * #FFD001  - 蛋黄
 * #15C8AE  - 深绿
 * #F73B42  - 蜜桃红
 * #29A7F6  - 蓝色
 * #677CAB  - 墨灰
 * #FD8D02  - 橙黄
 *
 */

import {first, flow, last, map, split} from 'lodash/fp'

const mapWithIndex = map.convert({cap: false})

//  流程状态
const FlowStatusOptions = [
  {
    key: 0,
    label: '未提交',
    color: '#677CAB',
  },
  {
    key: 1,
    label: '已提交',
    color: '#15C8AE',
  },
  {
    key: 2,
    label: '未通过',
    color: '#F73B42',
  },
]

/* --------------------------------*/

const WebLayout = [
  {
    key: 1,
    label: '左右排列显示',
    value: 'LEFT_RIGHT',
  },
  {
    key: 2,
    label: '上下排列显示',
    value: 'TOP_DOWN',
  },
]
const getOptionsByEnums = (Enums) =>
  mapWithIndex((i, index) => ({
    key: index + 1,
    value: flow(split(':'), first)(i),
    label: flow(split(':'), last)(i),
  }))(Enums)
/* ---------------- 侧边栏外观 start----------------*/
const SidebarAppearance = [
  {
    key: 1,
    label: '深色',
    value: 'DEEP_COLOUR',
  },
  {
    key: 2,
    label: '浅色',
    value: 'LIGHT_COLOUR',
  },
]
/* ---------------- 侧边栏外观 end----------------*/

/* ---------------- 服务分类 start----------------*/

export const CategoryCodeEnums = [
  'CHILDREN:儿童摄影',
  'WEDDING:婚礼摄影',
  'DRESS:婚纱摄影',
  'FAMILY:家庭摄影',
  'PORTRAIT:人像摄影',
  'FASHION:时尚摄影',
  'BUSINESS:商业摄影',
  'FOOD:美食摄影',
  'NEWS:新闻纪实摄影',
  'SCENERY:建筑景观摄影',
  'SPORTS:体育活动摄影',
  'OTHER:其他',
]

const CategoryCodes = getOptionsByEnums(CategoryCodeEnums)

/* ---------------- 服务分类 end----------------*/

/* ---------------- 订阅版本 start----------------*/

export const EditionTypeEnums = [
  'FREE:免费版',
  'BASIC:基础版',
  'NORMAL:标准版',
  'PRO:专业版',
  'BUSINESS:商业版',
  'PREMIUM:高级版',
]

const EditionTypes = getOptionsByEnums(EditionTypeEnums)

/* ---------------- 订阅版本 end----------------*/

/* ---------------- 是否 start----------------*/
const YesOrNo = ['Y:是', 'N:否']
const YesOrNoOptions = getOptionsByEnums(YesOrNo)
const YesOrNoTypes = [
  {
    key: true,
    label: '是',
    value: 'Y',
  },
  {
    key: false,
    label: '否',
    value: 'N',
  },
]
/* ---------------- 是否 end----------------*/

/* ------- 消息类型 start------- */
const MessageTypeEnums = [
  'BOOKMARK:选片收藏',
  'DOWNLOAD:下载',
  'REGISTER:注册',
  'ORDER:订单',
  'OTHER:其它',
]
const MessageTypes = getOptionsByEnums(MessageTypeEnums)
/* ------- 消息类型 end------- */

// 项目状态
const ProjectStatus = [
  {
    key: 'HIDDEN',
    label: '隐藏',
  },
  {
    key: 'ONLINE',
    label: '上线',
  },
]
/* ------- 对齐方式 start------- */

const AlignTypeEnums = ['LEFT:居左', 'CENTER:居中', 'RIGHT:居右']
const AlignTypes = getOptionsByEnums(AlignTypeEnums)

/* ------- 对齐方式 end------- */
/* ------- 默认首页类型 start------- */
const DefaultIndexTypeEnums = [
  'INDEX:首页',
  'WORKS:作品',
  'CUSTOMER_PHOTO:项目',
  'ABOUT:关于',
  'CONTACT:联系',
]
const DefaultIndexTypes = getOptionsByEnums(DefaultIndexTypeEnums)
/* ------- 默认首页类型 end------- */

/* ------- 展示类型 start------- */
const ShowcaseTypeEnums = ['INDEX:首页', 'WORKS:作品']
const ShowcaseTypes = getOptionsByEnums(ShowcaseTypeEnums)
/* ------- 展示类型 end------- */
/* ------- 图片分类 start------- */
const ImgCategoryEnums = [
  'INDEX:首页',
  'SHOWCASE:网站',
  'CUSTOMER:项目',
  'BOOKMARK:收藏夹',
]
const ImgCategory = getOptionsByEnums(ImgCategoryEnums)
/* ------- 图片分类 end------- */
/* ------- 目标分类类型 start------- */
const categoryTypeEnums = [
  'INDEX:首页',
  'SHOWCASE:网站',
  'CUSTOMER:项目',
  'BOOKMARK:收藏夹',
]
const categoryTypes = getOptionsByEnums(categoryTypeEnums)
/* ------- 目标分类类型 end------- */
/* ---------------- 暂存类型 start----------------*/
const StorageTypeEnums = ['ALBUM:相册', 'OTHER:其他']
const StorageTypes = getOptionsByEnums(StorageTypeEnums)

/* ---------------- 暂存类型 end----------------*/
// 访问类型
const VisitTypes = [
  {
    key: 'OPEN',
    label: '公开访问',
  },
  {
    key: 'PASSWORD',
    label: '密码访问',
  },
]

// 下载图片类型
const DownloadImgSize = [
  {
    key: 'LOW',
    label: '通用（最长边1200）',
  },
  {
    key: 'MEDIUM',
    label: '大图（最长边2000）',
  },
  // {
  //   key: 'HIGH',
  //   label: '高分辨率（最长边3000）',
  // },
  {
    key: 'ORIGINAL',
    label: '原文件',
  },
]
//source来源
const sourceTypes = [
  {
    key: 'WEB',
    label: '网页',
  }, {
    key: 'MINI',
    label: '小程序',
  },
]
// Dashboard类型
const DashboardDataType = [
  {
    key: 'PAST_YEAR',
    label: '过去一年',
  },
  {
    key: 'PAST_HALF_YEAR',
    label: '过去半年',
  },
  {
    key: 'PAST_MONTH',
    label: '30天',
  },
  {
    key: 'PAST_WEEK',
    label: '7天',
  },
]
/* ---------------- 客片项目排序方式 start----------------*/
const ArrangementEnums = [
  'CREATE_TIME_DESC:创建日期',
  'SHOOTING_DAY_DESC:拍摄日期',
  'PROJECT_NAME_DESC:项目名称',
]
const ArrangementTypes = getOptionsByEnums(ArrangementEnums)
/* ---------------- 客片项目排序方式 end----------------*/
/* ---------------- 客片项目排序方式 start----------------*/
const versionsEnums = [
  '0:项目与客户交付',
  '1:存储',
  '2:项目数',
  '3:无限上传下载带宽流量',
  '4:项目管理与自定义',
  '5:水印应用',
  '6:销售管理设置',
  '7:下载设置',
  '8:自动生成小程序画廊',
  '9:历史记录与分析',
  '10:多账号',
  '11:账号数',
  '12:客户管理',
  '13:品牌',
  '14:移除Infotos品牌信息',
  '15:自定义Logo与品牌',
  '16:链接自有域名',
  '17:自定义水印设置',
  '18:SEO设置',
  '19:网站',
  '20:页面展示与设置',
  '21:销售',
  '22:印坊与商品展示',
  '23:销售佣金',
  '24:分析与记录',
  '25:订单管理',
  '26:商品与价格管理',
  '27:自定义商品',
  '28:优惠券',
  '29:销售自定义与设置',
]
const VersionsTypes = getOptionsByEnums(versionsEnums)
/* ---------------- 客片项目排序方式 end----------------*/
// 客户来源
const customerSource = [
  'PROJECT:项目', 'SALE:订单', 'OTHER:其他'
  // 'TARGET:目标客户',
  // 'POTENTIAL:潜在客户',
  // 'WORTH:价值客户',
  // 'KEYNOTE:重点客户'
]
const CustomerTypes = getOptionsByEnums(customerSource)
const scopeEnums = [
  "all:所有订单",
  // "fullMinus:订单金额高于",
  // "theProduct:单个商品",
  "typeProduct:某类商品"
]
const scopeTypes = getOptionsByEnums(scopeEnums)
const discountEnums = [
  "percentage:按百分比",
  "sunMoney:按金额",
  "freeMail:免邮"
]
const discountType = getOptionsByEnums(discountEnums)
const couponEnums = [
  "waitRelease:待生效",
  "using:运行中",
  "expired:已过期",
  "invalid:已失效"
]
const couponStatus = getOptionsByEnums(couponEnums)
const productEnums = [
  "online:上线",
  "offline:下线",
  "ban:已下架",
]
const productStatus = getOptionsByEnums(productEnums)
// 订单来源类型
const orderSourceEnums = [
  "0:系统订阅",
  "1:B端",
  "2:小程序",
  "3:网页",
  "4:PrintStore",
  "5:拾集APP",
  "6:自订单",
  "7:其他",
]
const orderSource = getOptionsByEnums(orderSourceEnums)
//订单状态
const orderTypeEnums = [
  "waitPay:待支付",
  "refunded:已退款",
  "cancel:订单已取消",
  "invalid:无效订单",
  "paid:已支付",
  "shipped:已发货",
  "received:已收货",
  "complete:订单已完成",
  "partShipped:部分已发货"
]
const orderType = getOptionsByEnums(orderTypeEnums)
//显示收益状态
const earningsTypeEnums = [
  "1:近一个月",
  "3:近三个月",
  "6:近半年",
]
const earningsType = getOptionsByEnums(earningsTypeEnums)
//支付方式
const payTypeEnums=[
  "WechatPay:微信支付",
  "AliPay:支付宝支付",
]
const payType = getOptionsByEnums(payTypeEnums)
export {
  AlignTypes,
  CategoryCodes,
  categoryTypes,
  DefaultIndexTypes,
  DownloadImgSize,
  DashboardDataType,
  EditionTypes,
  ImgCategory,
  MessageTypes,
  ProjectStatus,
  SidebarAppearance,
  ShowcaseTypes,
  StorageTypes,
  VisitTypes,
  WebLayout,
  YesOrNoOptions,
  YesOrNoTypes,
  ArrangementTypes,
  VersionsTypes,
  CustomerTypes,
  sourceTypes,
  scopeTypes,
  discountType,
  couponStatus,
  productStatus,
  orderSource,
  orderType,
  payType,
  earningsType,
}
