import {Col, DatePicker, Form, Input, Modal, Row, Select} from 'antd'
import AddSelect from 'components/AddSelect'
import React, {useState,useEffect} from 'react'
import styles from './addClientForm.module.less'
import { EmailValidateRule,  PhoneRule } from 'utils/ValidateRulesAndMsg'
import { TrimInput, TrimTextArea } from 'components/WithTrim/index'
import commonComponent from 'components/index'
import { CustomerTypes } from 'constants/Options'
import myMessage from 'components/Message'
const { ProvinceSelectCascader } = commonComponent
const { Option } = Select

const AddClientForm = ({
  visible,
  onCreate,
  onCancel,
  clientInfo,
  isAdd,
}) => {
  const [form] = Form.useForm()
  const [customerLabels,setCustomerLabels] = useState([])
  const { TextArea } = Input;
  // console.log(clientInfo)
  // console.log(form)
  const client=JSON.parse(JSON.stringify(clientInfo))
  useEffect(() => {
    newClient(clientInfo)
  },[])
  useEffect(() => {
    form.setFieldsValue({
      customerName: client.customerName?client.customerName:'',
      customerPhone: client.customerPhone?client.customerPhone:'',
      customerWechat: client.customerWechat?client.customerWechat:'',
      customerEmail: client.customerEmail?client.customerEmail:'',
      areaArray: client.customerProvinceGeoId?[client.customerProvinceGeoId,client.customerCityGeoId,client.customerAreaGeoId]:'',
      customerAddress: client.customerAddress?client.customerAddress:'',
      customerRemark: client.customerRemark?client.customerRemark:'',
      customerSource: client.customerSource?client.customerSource:'',
      customerCompany: client.customerCompany?client.customerCompany:'',
    })
    newClient(clientInfo)
  }, [clientInfo])
  const newClient=(client)=>{
    let arr=[]
    if(client.customerLabels) {
      client.customerLabels.map(v => {
        arr.push(v.customerLabel)
      })
    }
    setCustomerLabels(arr)
  }
  return (
    <Modal
      visible={visible}
      title={isAdd?"添加客户":'编辑客户'}
      okText={isAdd?"创建":'保存'}
      cancelText="取消"
      centered
      cancelButtonProps={{className: 'footerCanceltext'}}
      onCancel={onCancel}
      maskClosable={false}
      width={700}
      forceRender={true}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            if(!values.customerPhone&&!values.customerEmail){
              myMessage.warning('联系电话或Email邮箱必须选填一个')
              return
            }
            form.resetFields()
            onCreate({...values,customerLabels:customerLabels})
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_project_form"
        requiredMark={false}
        className={styles.createProjectDialog}

      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
                name="customerName"
                label="客户名称"
                rules={[
                  {
                    required: true,
                    message: '请输入',
                  },
                ]}
            >
              <Input placeholder="请输入" maxLength={30}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="customerPhone"
              label="电话"
              rules={[PhoneRule]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="customerWechat"
              label="微信"
            >
              <Input placeholder="客户绑定微信后自动添加" disabled/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="customerEmail"
              label="Email"
              rules={[EmailValidateRule]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="customerSource"
              label="客户来源"
            >
              <Select
                  // onChange={value => selectChange(value, item)}
                  // defaultValue="客户来源"
                  // bordered={false}
                  // style={{width: 140}}
                  suffixIcon={
                    <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                  }
              >
                {CustomerTypes.map(item => (
                    <Option value={item.value} key={item.key}>{item.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="customerCompany"
              label="公司名称"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name=""
              label="地址"
            >
              <Form.Item
                  label=""
                  name="areaArray"
                  className={styles.Inf_ant_form_item}
              >
                <ProvinceSelectCascader />
              </Form.Item>
              <Form.Item
                  style={{display:'inline-block'}}
                  label=""
                  name="customerAddress"
                  // rules={[RequiredInputRule]}
              >
                <TrimInput style={{width:'309px'}}  placeholder="详细地址"/>
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="备注" name='customerRemark'>
              <TextArea rows={3} placeholder=""/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default AddClientForm
