import React, { useState } from 'react'
import styles from './index.module.less'

const HoverOpt = ({
  // visible,
  children,
  title,
  content,
  width,
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false)
  return (
    <div
      className={styles.Inf_hover_opt_wrapper}
      onMouseEnter={() => setPopoverVisible(true)}
      onMouseLeave={() => setPopoverVisible(false)}
    >
      <div
        className={styles.Inf_hover_opt_popover}
        style={{
          display: popoverVisible ? 'block' : 'none',
          minWidth: width || '190px',
        }}
      >
        <div className={styles.Inf_hover_opt_popover_title}>{title}</div>
        <div className={styles.Inf_hover_opt_popover_content}>{content}</div>
      </div>

      <div
        className={styles.Inf_hover_opt_wrapper_content}
        style={{
          border: popoverVisible
            ? '1px solid #E1E1E1'
            : '1px solid transparent',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default HoverOpt
