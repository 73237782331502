//订单列表
import {
    Button,
    Layout,
    Menu,
    Divider,
    Dropdown,
    PageHeader,
    Spin,
    Select,
    Col,
    Row,
    Input,
    Tooltip,
    Card,
    Table,
    Pagination,
    Modal,
    Form, DatePicker
} from 'antd'
import {InfoCircleOutlined, UserOutlined, SearchOutlined,CloseCircleFilled} from '@ant-design/icons';

import components from 'components'
import {ProjectStatus, ArrangementTypes} from 'constants/Options'
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import { PAGE_PATH } from 'apis/Router'
import {
    getInfoClient as _getInfoClient,
    editClient as _editClient,
    addClient as _addClient,
    removeClient as _removeClient,
} from 'reduxModules/client'
import {
    addOrderAdd as _addOrderAdd,
    deliverGoods as _deliverGoods,
    getOrderAll as _getOrderAll,
    getOrderDetails as _getOrderDetails,
} from 'reduxModules/store'
import CommonComponent from 'components/index'
import classNames from "classnames";
import myMessage from 'components/Message'
import styles from './order.module.less'
import { orderType } from 'constants/Options'
import {
    getOptionBykeyName,
} from 'utils/Tools'
import Imgs from 'imgs'
import moment from "moment";
import {map} from "lodash/fp";
const {Header, Footer, Sider, Content} = Layout
const { MyMessage, InfoEmptyData } = CommonComponent
// const {ClientProfoto} = Imgs

const Order = ({name,ref}) => {
    let history = useHistory()
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    // 选中的行keys
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // 选中的行
    const [selectedRows, setSelectedRows] = useState([])
    // 总数
    const [total, setTotal] = useState(0)
    const [bg, setBg] = useState(false)
    const [deleteVisible, setdeleteVisible] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState({
        sourceEnum: '', //来源
        statusText: '全部', //来源
        status: '', //分组
        research:'',//邮件/名称/电话
        pageNo:1,
        pageSize:20,
        current:10,
        totalCount:20
    })
    const [orderList, setOrderList] = useState([])
    const [addVisible, setAddVisible] = useState(false)
    const [isAdd, setIsAdd] = useState(false)
    const [clientInfo, setClientInfo] = useState({})
    const [keys, setKeys] = useState(0)
    // 添加
    // const showAdd = (item,key) => {
    //     setClientInfo({});
    //     setAddVisible(true)
    //     setIsAdd(true)
    // }

    useImperativeHandle(ref, () => ({
        // onSaveForm 就是暴露给父组件的方法
        onSaveForm: () => {
            onClick()
        },
    }))
    // 来源
    const onClick = ({key}) => {
        // switch (key) {
        //     case "1":
        setSelectedOrder({
            status: selectedOrder.status, //状态
            statusText:selectedOrder.statusText, //状态
            research:selectedOrder.research,//邮件/名称/电话
            pageNo:selectedOrder.pageNo,
            pageSize:selectedOrder.pageSize,
        })
    };
    // 分组
    const onClicks = ({key}) => {
        let text=key=='item_1'?'全部':getOptionBykeyName(
          'value',
          key,
          'label', orderType)
        setSelectedOrder({
            status: key=='item_1'?'':key, //状态
            statusText:key=='item_1'?'全部':getOptionBykeyName(
              'value',
              key,
              'label', orderType), //状态
            research:selectedOrder.research,//邮件/名称/电话
            pageNo:selectedOrder.pageNo,
            pageSize:selectedOrder.pageSize,
        })

    };
    // 搜索名称
    const changeName = (event) => {
        // e.persist()
        setSelectedOrder({
            status: selectedOrder.status, //状态
            statusText:selectedOrder.statusText, //状态
            research:event.target.value,//邮件/名称/电话
            pageNo:1,
            pageSize:selectedOrder.pageSize,
            totalCount:20
        })
        // getListByClient()
    }
    const searchInput = useRef()
    const clearAll = () => {
        searchInput.current.state.value = '';
        setSelectedOrder({
            statusText:'全部', //状态
            status: '', //分组
            research:'',//邮件/名称/电话
            pageNo:1,
            pageSize:selectedOrder.pageSize,
            totalCount:20
        })
    }
    const menu = (
        <div className={styles.sortMenu}>
            <Menu onClick={onClick}>
                {/*SHOOTING_DAY_DESC, CREATE_TIME_DESC, PROJECT_NAME_DESC*/}
                <Menu.Item key="0" disabled className={styles.titleName}>订单类型</Menu.Item>
                <Menu.Item key="1">全部</Menu.Item>
                <Menu.Item key="2">销售商品</Menu.Item>
                <Menu.Item key="3">服务</Menu.Item>
            </Menu>
        </div>
    );
    const menus = (
        <div className={styles.sortMenu}>
            <Menu onClick={onClicks}>
                <Menu.Item key="" disabled className={styles.titleName}>订单状态</Menu.Item>
                <Menu.Item key="">全部</Menu.Item>
                {orderType.map((item) =>
                  <Menu.Item key={item.value}>{item.label}</Menu.Item>
                )}
            </Menu>
        </div>
    );
    const onBaseClick=(current,pageSize)=>{
        console.log(current,pageSize)
        setSelectedOrder({
            sourceEnum: selectedOrder.sourceEnum, //来源
            sourceName: selectedOrder.sourceName, //来源
            status: selectedOrder.sourceName, //分组
            research:selectedOrder.research,//邮件/名称/电话
            pageNo:current,
            pageSize:pageSize,
            totalCount:selectedOrder.totalCount
        })
    }
    // 获取列表
    const getListByClient = async () => {
        setLoading(true)
        // const res = await _getInfoClient({})
        let data={
            pageNum: selectedOrder.pageNo,
            pageSize: selectedOrder.pageSize,
            storeId: sessionStorage.getItem('user')
              ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
              :null
        }
        if(selectedOrder.research){
            data.research=selectedOrder.research
        }
        if(selectedOrder.status){
            data.status=selectedOrder.status
        }
        const res = await _getOrderAll(data)
        setLoading(false)
        if (res.code === '000000') {
            console.log(res.data)
            setOrderList(res.data.records)
            // setSelectedOrder({
            //     sourceEnum: '', //来源
            //     sourceName: '全部', //来源
            //     status: '全部', //分组
            //     research:selectedOrder.research, //邮件/名称/电话
            //     pageNo:res.data.current,
            //     pageSize:res.data.size,
            //     totalCount:res.data.total
            // })
            setTotal(res.data.total)
        } else {
            myMessage.error(res.mesg)
        }
    }
    useEffect(() => {
        getListByClient()
    }, [selectedOrder])
    // const accountCell = ({customerPortrait, customerName ,item}) => (<div onClick={clickRow(item)} className={styles.account_cell}>
    //     <img
    //         src={customerPortrait || ClientProfoto}/>
    //     <div>
    //         <p className={styles.nickname}>{customerName}</p>
    //     </div>
    // </div>)
    const columns = [
        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId',
            render: (_, item) =>  {return (
                <div onClick={() => clickRow(item)} className={styles.nameCell}>
                    {item.orderNum}
                </div>
            )},
        },
        {
            title: '下单账号',
            dataIndex: 'customerName',
            key: 'customerName',
        },
        {
            title: '订单来源',
            dataIndex: 'orderSource',
            key: 'orderSource',
        },
        {
            title: '订单金额',
            dataIndex: 'totalMoney',
            key: 'totalMoney',
            render: (_, item) =>  {return (
                <div>
                    ￥{item.totalMoney}
                </div>
            )},
        },
        {
            title: '创建时间',
            dataIndex: 'orderDate',
            key: 'orderDate',
            render: (_, item) =>  {return (
                <div>
                    {`${item.orderDate.replace(/-/g,'/').split(':')[0]}:${item.orderDate.replace(/-/g,'/').split(':')[1]}`}
                </div>
            )},
        },
        {
            title: '状态',
            dataIndex: 'statusText',
            key: 'statusText',
            /*render: (_, item) => {
                // if (+item.rolesId === 1) return ''
                return (
                    <div>
                        <Dropdown
                            overlay={
                                <div className={styles.settingsMenu}>
                                    <Menu>
                                        {/!* <Menu.Item><a key='0' onClick={() => handleItem('停用', item)}>停用</a></Menu.Item> *!/}
                                        {/!* <Menu.Item><a key='1' onClick={() => handleItem('添加客片', item)}>添加客片项目</a></Menu.Item>
                                        <Menu.Item><a key='2' onClick={() => handleItem('添加消费', item)}>添加消费记录</a></Menu.Item>
                                        <Menu.Item><a key='3' onClick={() => handleItem('添加标签', item)}>添加标签</a></Menu.Item>
                                        <Menu.Divider /> *!/}
                                        <Menu.Item><a key='4' onClick={() => handleItem('编辑', item)}>编辑</a></Menu.Item>
                                        <Menu.Divider />
                                        <Menu.Item><a key='5' onClick={() => handleItem('删除', item)}>删除</a></Menu.Item>
                                    </Menu>
                                </div>
                            }
                            placement="bottomRight"
                            trigger={['click']}>
                            <span className="iconfont iconbtn_list_set"></span>
                        </Dropdown>
                    </div>
                )
            }*/
        }
    ]
    // const handleItem = async (type, item) => {
    //     if(type === '编辑'){
    //         setKeys(keys+1)
    //         setClientInfo({...item});
    //         setAddVisible(true)
    //         setIsAdd(false)
    //     }else if(type === '删除'){
    //         // myMessage.success('修改成功')
    //         // getListByClient(selectedOrder)
    //         setClientInfo(item);
    //         setdeleteVisible(true)
    //     }
    // }

    useEffect(() => {
        // console.log(clientInfo,'参数')
    }, [clientInfo])
    //添加
    // const onAddClient = async (values) => {
    //     values.customerProvinceGeoId=values.areaArray[0]
    //     values.customerCityGeoId=values.areaArray[1]
    //     values.customerAreaGeoId=values.areaArray[2]
    //     if(clientInfo.id) {
    //         values.id=clientInfo.id
    //         const res = await _editClient(values)
    //         if (res.data.code === 0) {
    //             myMessage.success('修改成功')
    //             setAddVisible(false)
    //             getListByClient(selectedOrder)
    //             setClientInfo({});
    //             // history.push(`/collection/${res.data.data.id}/photos`)
    //         }
    //     }else{
    //         const res = await _addClient(values)
    //         if (res.data.code === 0) {
    //             myMessage.success('添加成功')
    //             setAddVisible(false)
    //             getListByClient(selectedOrder)
    //             setClientInfo({});
    //             // history.push(`/collection/${res.data.data.id}/photos`)
    //         }
    //     }
    // }
    const clickRow=(row)=>{
        console.log(row)
        sessionStorage.setItem('order',JSON.stringify(row))
        history.push(`/store/orderDetails/${row.id}`)
    }
    // const deleteModal=async ()=>{
    //     let data={id:clientInfo.id}
    //     const res = await _removeClient(data)
    //     if (res.data.code === 0) {
    //         getListByClient(selectedOrder)
    //         setClientInfo({});
    //         setdeleteVisible(false)
    //         myMessage.success('删除成功')
    //         // history.push(`/collection/${res.data.data.id}/photos`)
    //     }
    // }
    return (
        <Layout>
            <PageHeader
                ghost={false}
                className={styles.titleBar}
                title={
                    <div id="orderList">
                        订单
                    </div>
                }
            ></PageHeader>
            <Card bordered={false} className={styles.orderList}>
                <Spin tip="数据加载中" spinning={loading}>
                    {orderList.length > 0||selectedOrder.sourceEnum!=''||selectedOrder.research!=''?(
                        <Row className={styles.controlBar}>
                            <Col span={12}>
                                <span className={styles.ptCount}>{total}条</span>
                                {/*<Divider type="vertical"/>
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <Button type="link" className="link-btn">
                                        {selectedOrder.sourceName}订单
                                        <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                                    </Button>
                                </Dropdown>*/}
                                <Divider type="vertical"/>
                                <Dropdown overlay={menus} trigger={['click']}>
                                <Button type="link" className="link-btn">
                                    {selectedOrder.statusText}状态
                                    <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                                </Button>
                            </Dropdown>
                            </Col>
                            <Col span={12} style={{justifyContent: 'flex-end'}}>
                                <Input className={bg || selectedOrder.research ? styles.searchs : styles.search}
                                       placeholder="输入客户、电话或订单号搜索" onKeyUp={changeName} defaultValue={selectedOrder.research} ref={searchInput}
                                       onBlur={() => setBg(false)} onFocus={() => setBg(true)}
                                    //    prefix={<Tooltip><SearchOutlined style={{color: 'rgba(0,0,0,.45)'}}/></Tooltip>}
                                       prefix={<iconicon_search className="iconfont iconicon_search" SearchOutlined style={{color: 'rgba(0,0,0,.45)'}}/>}
                                       suffix={
                                           selectedOrder.research ?
                                               <span className={classNames('iconfont iconbtn_flclose', styles.clearInput)}
                                                     onClick={clearAll}></span>
                                               : ''
                                       }
                                />
                            </Col>
                        </Row>):null
                    }
                    {orderList.length > 0 && (
                        <Table
                            // className={styles.tablesbody}
                            style={{marginBottom: '30px'}}
                            dataSource={orderList}
                            columns={columns}
                            pagination={{
                                current:selectedOrder.pageNo,
                                total:total,
                                hideOnSinglePage: true,
                                showSizeChanger: false,
                                position:["bottomCenter"],
                                pageSize:20,
                                onChange:(current,pageSize)=>{
                                    onBaseClick(current,pageSize)
                                }
                            }}
                        />
                    )}
                    {orderList.length <= 0 && !loading && (
                        <div>
                            <InfoEmptyData description="暂无订单数据" />
                            <span className={styles.remarkTip}>您的客户若购买了产品将会显示订单在这里。</span>
                        </div>
                    )}
                </Spin>
            </Card>

        </Layout>
    )
}

export default Order
