import { Button, Layout, Menu, PageHeader } from 'antd'
import { PAGE_PATH } from 'apis/Router'
import classNames from 'classnames'
import React, { useRef } from 'react'
import { Link, Route, Switch, useLocation } from 'react-router-dom'
const { Header, Footer, Sider, Content } = Layout

const Accounts = ({ routes, name }) => {
  document.title = `${name} - Infotos`
  let location = useLocation()
  const childRef = useRef()

  const subSiderMenu = routes.map((menu) => (
    <Menu.Item key={menu.path}>
      <Link to={menu.path}>{menu.name}</Link>
    </Menu.Item> 
  ))

  const onSave = () => {
    childRef.current.onSaveForm()
  }

  function RouteWithSubRoutes(route) {
    return (
      <Route
        path={route.path}
        render={(props) => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} ref={childRef} />
        )}
      />
    )
  }
  return (
    <Layout>
      <Sider
        style={{
          overflowX: 'hidden',
          height: '100vh',
          position: 'fixed',
        }}
        theme="light"
        className="sub-sidebar"
      >
        <div className="sub-title">{name}</div>
        <Menu
          mode="inline"
          theme="light"
          defaultSelectedKeys={[location.pathname]}
          style={{ border: 'none', maxHeight: '100%', overflowY: 'hidden' }}
          className="sub-sider-menu"
          inlineIndent={30}
        >
          {subSiderMenu}
        </Menu>
        {/* <div style={{ height: 60 }}></div> */}
      </Sider>
      <Layout style={{ marginLeft: 200 }}>
        <Header className="sub-header">
          <Switch>
            {routes.map((route, i) => (
              <Route
                key={route.path}
                path={route.path}
                render={() => {
                  const name =
                    route.path === PAGE_PATH.settings.children.seo
                      ? '网站' + route.name
                      : route.name

                  return (
                    <PageHeader
                      ghost={false}
                      title={<div style={{ fontWeight: 'bold' }}>{name}</div>}
                      style={{
                        padding: '24px 50px 23px',
                      }}
                      extra={
                        !['subscribe', 'service'].includes(route.page)
                          ? [
                              <Button
                                key={'onSave' + i}
                                type="primary"
                                onClick={onSave.bind(this, route.page)}
                              >
                                保存
                              </Button>,
                            ]
                          : []
                      }
                    ></PageHeader>
                  )
                }}
              />
            ))}
          </Switch>
        </Header>
        <Content className="sub-content">
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </Content>
      </Layout>
    </Layout>
  )
}

export default Accounts
