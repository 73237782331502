import {Button, Select, Table, Menu, Dropdown, Spin, Modal, Layout,PageHeader} from 'antd'
import {SettingOutlined} from '@ant-design/icons'
import MyMessage from 'components/Message/index'
import React, {forwardRef, useEffect, useState} from 'react'
import styles from './recycle.module.less'
import Imgs from 'imgs'
import {
  getRecycleList as _getRecycleList,
  clearRecycle as _clearRecycle,
  recycleReduction as _recycleReduction,
  clearRecycleAll as _clearRecycleAll,
} from 'reduxModules/settings'
import {isEmpty} from "lodash";
import CommonComponent from 'components/index'
import ProjectMenu from './ProjectMenu'
const { message,InfoEmptyData,CategoryModal } = CommonComponent
// const {Option} = Select
const {imgFolderCatagory,imgFolderProject} = Imgs
const { Header, Footer, Sider, Content } = Layout

const RecyclePage = (props) => {
  document.title = `项目 - Infotos`
  const [recycleData, setRecycleList] = useState({list: [], pagination: []})
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false);
  const [deleteText, setDeleteText] = useState('彻底删除文件');
  const [contentText, setContentText] = useState('删除文件后将不可找回，确定彻底删除？');
  const [currentChange, setCurrentChange] = useState(null)
  const getList = async (pageNo) => {
    console.log(pageNo)
    setLoading(true)
    try {
      const res = await _getRecycleList({
        pageNo: pageNo,
        pageSize: 20,
      })
      if (res.code === 0) {
        let data = res.data
        setRecycleList({
          list: data.list,
          pagination: {
            total: data.pagination.total,
            pageNo: data.pagination.current
          }
        })
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const handleItem = async (type, item) => {
    if(type === 'recover'){
      setVisible(true)
      setDeleteText('恢复')
      setContentText('确定恢复该文件？')
      setCurrentChange(item);
    }else if(type=='delete'){
      setVisible(true)
      setDeleteText('彻底删除')
      setContentText('文件删除后将不可找回，确定继续？')
      setCurrentChange(item);
    }
  }
  const removeRecycle = async () => {
    setLoading(true)
    let res
    if(currentChange){
      if(deleteText=='恢复'){
        res = await _recycleReduction({id: currentChange.id})
      }else {
        res = await _clearRecycle({id: currentChange.id})
      }
    }else{
      res = await _clearRecycleAll({})
    }
    if(res.status === 200 && res.data.code === 0) {
      setVisible(false)
      MyMessage.success(deleteText=='恢复'?'恢复成功':'移除成功')
      getList(recycleData.pagination.pageNo)
    }else{
      setVisible(false)
    }
  }
  useEffect(() => {
    // getRoles()
    getList('1')
    // isshow()
  }, [])
  const recycleCell = ({photoBaseInfo,name, projectName, categoryName,type,createdTime }) => (<div className={styles.files_cell}>
    {/*project：项目，category：项目分类，PHOTO：图片*/}
    {type=='PHOTO'?(<img src={photoBaseInfo.photoUrl}/>):
      type=='CATEGORY'?(<img src={imgFolderCatagory}/>):
        (<img src={imgFolderProject}/>)
    }
    {type=='PHOTO'?(<div>
        <p className={styles.name}>{name}</p>
        <p className={styles.info}>{projectName} · {createdTime}</p>
      </div>):
      type=='CATEGORY'?(<div>
          <p className={styles.name}>{name}</p>
          <p className={styles.info}>{projectName} · {createdTime}</p>
        </div>):
        (<div>
          <p className={styles.name}>{projectName}</p>
          <p className={styles.info}>{createdTime}</p>
        </div>)
    }
  </div>)
  const columns = [
    {
      title: '',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (_, item) => recycleCell(item),
    },
    {
      title: '',
      dataIndex: 'opt',
      key: 'opt',
      width: 100,
      render: (_, item) => {
        return (
          <div className={styles.menuBtn}>
            <Dropdown
              overlay={
                <div className={styles.settingsMenu}>
                  <Menu>
                    <Menu.Item key='0' onClick={() => handleItem('recover', item)}>恢复</Menu.Item>
                    <Menu.Divider />
                    <Menu.Item className={styles.menuBtn_red} key='1' onClick={() => handleItem('delete', item)}>彻底删除</Menu.Item>
                  </Menu>
                </div>
              }
              trigger={['click']}>
              <span className="iconfont iconbtn_list_set"></span>
            </Dropdown>
          </div>
        )
      }
    }
  ]


  return (

    <Layout>
      <ProjectMenu
        name={'项目'}
      />
      <Layout style={{marginLeft: 200}}>
      <Header className="sub-header">
        <PageHeader
          ghost={false}
          title={<div style={{ fontWeight: 'bold' }} id="forBack">回收站</div>}
          style={{
            padding: '24px 50px 23px',
          }}
        ></PageHeader>
      </Header>
      <Content className="sub-content">
        <div>
          {recycleData.list.length <= 0 && !loading&& (
            <div>
              <InfoEmptyData description="回收站是空的" />
            </div>
          )}
          <div className={styles.recycle_wrapper}>
            {recycleData.list.length!=0&&(
            <div>
              <Button
                className={styles.deleteBtn}
                onClick={() => {
                  setVisible(true)
                  setDeleteText('清空回收站');
                  setContentText('确定彻底清空回收站？')
                  setCurrentChange(null);
                }}
              >
                彻底清空
              </Button>
              <div className={styles.desc}>说明：回收站文件将于删除日起20天后自动删除。</div>
            </div>
            )}
            {recycleData.list.length!=0&&(
            <Spin tip="加载中" spinning={loading}>
              <Table
                style={{marginTop: '20px'}}
                columns={columns}
                dataSource={recycleData.list}
                pagination={{
                  // simple:false,
                  // current:recycleData.pagination.pageNo,
                  total:recycleData.pagination.total,
                  hideOnSinglePage: true,
                  showSizeChanger: false,
                  position:["bottomCenter"],
                  pageSize:20,
                  onChange:(current,pageSize)=>{
                    getList(current,pageSize)
                  }
                }}
              />
              <div style={{height: '30px'}}></div>
            </Spin>
            )}
            <Modal
              visible={visible}
              title={deleteText}
              okText="确定"
              cancelText="取消"
              centered
              width={600}
              cancelButtonProps={{className: 'footerCanceltext'}}
              confirmLoading={loading}
              onCancel={() => setVisible(false)}
              maskClosable={false}
              closable={false}
              onOk={removeRecycle}
            >
              <div className={styles.desc}>
                {contentText}
              </div>
            </Modal>
          </div>
        </div>
      </Content>
      </Layout>
    </Layout>
  )
}

export default forwardRef(RecyclePage)
