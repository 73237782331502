/*
 * @Author: melon
 * @Date: 2020-07-05 22:49:20
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-13 21:26:20
 */

import request from 'utils/Request'

import Api from 'apis/Api.js'

// 获取订阅

export const _getSubscribeInfo = async () => {
  return await request({
    method: 'get',
    url: Api.subscribe.subscribe,
    params: {},
  })
}

// 获取订阅详情
/**
 * 
 * @param {*} param0 
 * editionType 订阅类型

FREE:免费版, BASIC:基础版
 */
export const _getSubscribeDetail = async ({ editionType = 'FREE' }) => {
  return await request({
    method: 'get',
    url: Api.subscribe.getSubscribeDetail,
    params: { editionType },
  })
}
