import { Col, Form, Input, InputNumber, Modal, Row } from 'antd'
import React, { useState } from 'react'

const { TextArea } = Input

const BookmarkForm = ({ visible, onCreate, onCancel, editData }) => {
  const [form] = Form.useForm()
  const [priceDisable, setPriceDisable] = useState(true)
  
  const changeMax = (values) => {
    if (values) {
      setPriceDisable(false)
    } else {
      setPriceDisable(true)
    }
  }

  return (
    <Modal
      visible={visible}
      title="添加收藏夹"
      okText="创建"
      cancelText="取消"
      centered
      cancelButtonProps={{className: 'footerCanceltext'}}
      onCancel={onCancel}
      width={600}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            onCreate(values)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        initialValues={editData}
        layout="vertical"
        name="add_project_form"
      >
        <Form.Item
          name="name"
          label="收藏夹名称"
          rules={[
            {
              required: true,
              message: '请输入',
            },
          ]}
        >
          <Input placeholder="请输入收藏夹名称" />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="maxCount" label="最大图片数">
              <InputNumber
                min={0}
                precision={0}
                style={{ width: '100%' }}
                onChange={changeMax}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="plusFee" label="加片费用（每张）">
              <InputNumber
                precision={2}
                min={0}
                style={{ width: '100%' }}
                disabled={editData.maxCount?false:priceDisable}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="remark" label="备注">
              <TextArea row={3} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default BookmarkForm
