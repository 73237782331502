/*
 * @Author: melon
 * @Date: 2020-07-05 22:12:47
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-14 15:19:50
 */
import { EditionTypes } from 'constants/Options'
import { map } from 'lodash/fp'
import Mock from 'mockjs'

const EditionTypeValues = map(({ value }) => value)(EditionTypes)

const { Random } = Mock
export const SubscribeData = {
  id: Random.id(), // id
  editionType: EditionTypeValues[Random.natural(0, 5)], // 订阅版本 [ FREE:免费版, BASIC:基础版, NORMAL:标准版, PRO:专业版, BUSINESS:商业版, PREMIUM:高级版 ]
  editionTypeDesc: Random.ctitle() + '订阅版本描述', // 订阅版本描述
  editionPrice: Random.natural(10, 50000),
  expireTime: Random.datetime(), // 过期时间
  useDb: Random.natural(1, 50000), // 使用存储空间数(kb)
  totalDb: Random.natural(50000, 500000), // 总共存储空间数(kb)
}

export const SubscribeDetailData = {
  editionType: EditionTypeValues[Random.natural(0, 5)], // 订阅版本 [ FREE:免费版, BASIC:基础版, NORMAL:标准版, PRO:专业版, BUSINESS:商业版, PREMIUM:高级版 ]
  projectCount: Random.natural(10, 200), // 客片项目数
  editionPrice: Random.natural(10, 50000), // 订阅价格
  funcList: [
    '品牌自定义',
    '客片项目管理',
    '客户资料',
    '收藏夹管理',
    '过程加密',
    '极速相册排版与输出',
  ], // 客片项目数
  dbDesc: Random.natural(1, 50000), // 存储描述
  totalDb: Random.natural(50000, 500000), // 总共存储空间数(kb)
}
