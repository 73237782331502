import { Carousel, Empty, Modal } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.less'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return <div className={className} style={{ ...style }} onClick={onClick} />
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return <div className={className} style={{ ...style }} onClick={onClick} />
}

const PreviewCarouselModal = ({
  visible,
  onCancel,
  list = [],
  previewCurrent,
  showKey = 'description',
}) => {
  const [currentImg, setCurrentImg] = useState({})
  const [current, setCurrent] = useState({})

  const ref = useRef()

  const afterChangeSlider = (current) => {
    setCurrentImg(list[current])
    setCurrent(current)
  }
  // 默认设置为第一个
  useEffect(() => {
    setCurrentImg(list[previewCurrent])
    setCurrent(previewCurrent)
  }, [list, previewCurrent])

  useEffect(() => {
    ref.current && ref.current.goTo(current)
  }, [current])

  const getWindowSize = () => ({
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  })
  const [windowSize, setWindowSize] = useState(getWindowSize())
  const handleResize = () => {
    setWindowSize(getWindowSize())
  }
  useEffect(() => {
    // 监听
    window.addEventListener('resize', handleResize)
    // 销毁
    return () => window.removeEventListener('resize', handleResize)
  })
  // console.log('========>windowSize=====>')
  // console.log(windowSize)

  // 参考react-slick接口
  const SliderConfig = {
    lazyLoad: true,
  }
  return (
    <Modal
      visible={visible}
      title=""
      onCancel={onCancel}
      maskClosable={false}
      footer={[]}
      style={{
        top: 0,
      }}
      width="100vw"
      height="100vh"
      centered
      wrapClassName={styles.previewWrapper}
      cancelButtonProps={{className: 'footerCanceltext'}}
      mask={false}
      // transitionName={'none'}
      // maskTransitionName={'none'}
    >
      {list.length ? (
        <>
          <div className={styles.optWrapper}></div>
          <SamplePrevArrow
            onClick={() => ref.current.prev()}
            className={styles.Inf_prev}
            style={{ display: [0].includes(current) ? 'none' : 'block' }}
          />
          <Carousel
            className={styles.Slider}
            afterChange={afterChangeSlider}
            dots={false}
            ref={ref}
            // style={{
            //   height: windowSize.innerHeight - 160,
            //   width: windowSize.innerWidth - 165,
            // }}
            {...SliderConfig}
          >
            {list.map((img) => (
              <div className={styles.image_wrapper} key={img.id}>
                <img
                  src={img.thumbInfo['general'].photoUrl}
                  className={styles.image}
                  // style={{
                  //   height: windowSize.innerHeight - 200,
                  //   width: windowSize.innerWidth - 165,
                  // }}
                />
              </div>
            ))}
          </Carousel>
          <SampleNextArrow
            onClick={() => ref.current.next()}
            className={styles.Inf_next}
            style={{
              display: [list.length - 1].includes(current) ? 'none' : 'block',
            }}
          />
          {/* </div> */}
          <div className={styles.slider_footer}>
            <div className={classNames('Inf_page_text')}>
              {/* <span className={styles.slider_footer_prev}>上一页</span> */}
              <span className={styles.slider_footer_text}>
                {current + 1}/{list.length}
              </span>
              {/* <span className={styles.slider_footer_next}>下一页</span> */}
            </div>
            <div className={classNames('Inf_page_text')}>
              {currentImg[showKey]}
            </div>
          </div>
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ paddingTop: 70 }}
        />
      )}
    </Modal>
  )
}

export default PreviewCarouselModal
