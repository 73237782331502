//添加产品包
import React, {useEffect, useRef, useState, useImperativeHandle, forwardRef, useMemo} from "react";
import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Button,
  Menu,
  Dropdown,
  Divider,
  Layout,
  PageHeader,
  Switch, InputNumber
} from "antd";
import styles from './newProductPackage.module.less'
import moment from "moment";
import myMessage from 'components/Message'
import {useParams, useHistory} from 'react-router-dom'
import SelectProduct from "./SelectProduct";
import {
  pricesheetInfo as _pricesheetInfo,
  addPackage as _addPackage,
  editPackage as _editPackage,
  getSingle as _getSingle,
  uploadImg as _uploadImg,
} from 'reduxModules/store'
import Imgs from 'imgs'
import { PriceValidateRule,pricePattern,extraPostageValidateRule } from 'utils/ValidateRulesAndMsg'
const {downloadCover} = Imgs
const {Option} = Select
const {TextArea} = Input
const {Content} = Layout
const NewProductPackage = ({name}) => {
  let history = useHistory()
  const [form] = Form.useForm()
  let {pricesheetId} = useParams()
  const [loading, setLoading] = useState(false)
  const [selectedClient, setSelectedClient] = useState({
    // sourceEnum: '', //来源
    sourceName: '全部', //来源
    // grouping: '全部', //分组
    // searchValue:'',//邮件/名称/电话
    pageNo: 1,
    pageSize: 20,
    current: 10,
    totalCount: 20
  })
  const [packageList, setPackageList] = useState([])
  const [visible, setVisible] = useState(false)
  const [list, setList] = useState([])
  const [curDragItem, setCurDragItem] = useState({});
  const [addVisible, setAddVisible] = useState(false);
  // 上传的弹窗显示与否
  const [uploadModalVisible, setUploadModalVisible] = useState(false)
  // 上传弹窗ref
  const [uploadModalRef, setUploadModalRef] = useState()
  const [productList, setProductList] = useState([])
  const [enterpriseId, setEnterpriseId] = useState('');
  const [pricesheet, setPricesheet] = useState({});
  const [isPrice, setIsPrice] = useState(false);
  const [eiditPrice, setEiditPrice] = useState({});
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [graph, setGraph] = useState({});
  const [graphVisible, setGraphVisible] = useState(false);
  const [optiondel, setOptiondel] = useState([]);
  let animationFlag = true;
  useEffect(() => {
    setPricesheet(JSON.parse(sessionStorage.getItem('pricesheet')))
    setEnterpriseId(sessionStorage.getItem('user')
      ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
      : null)
    //修改时赋值
    if (sessionStorage.getItem('product')) {
      let product = JSON.parse(sessionStorage.getItem('product'))
      form.setFieldsValue({
        ...product
      })
      let arr = []
      if (!!product.imageId) {
        product.imageId.split(',').map((v, i) => {
          arr.push({
            id: v,
            imgs: product.imageUrls[i]
          })
        })
        setList(arr)
      }
      setPackageList(product.productSetVos)
    }
  }, [])
  const dragStart = (e) => {
    setCurDragItem(JSON.parse(e.target.getAttribute('drag-data')));
  }

  const dragEnd = (e) => {
    setCurDragItem({});
  }

  const onDragOver = (e) => {
    e.preventDefault();
    let targetItem = JSON.parse(e?.target?.getAttribute('drag-data'));
    if (animationFlag && curDragItem.id && targetItem?.id && curDragItem.id !== targetItem?.id) {
      animationFlag = false;
      // 获取当前拖拽节点最新的位置
      let curNewestDragItemDom = getDomItem(curDragItem.id);
      let curNewestDragItemDomRect =
        curNewestDragItemDom?.getBoundingClientRect() || {};
      const targetRect = e.target.getBoundingClientRect();
      // 动画
      e.target.style.transition = 'all 200ms';
      curNewestDragItemDom.style.transition = 'all 200ms';

      e.target.style.transform = `translate3d(${curNewestDragItemDomRect.left - targetRect.left
      }px,${curNewestDragItemDomRect.top - targetRect.top}px,0)`;
      curNewestDragItemDom.style.transform = `translate3d(${targetRect.left - curNewestDragItemDomRect.left
      }px,${targetRect.top - curNewestDragItemDomRect.top}px,0)`;

      setTimeout(() => {
        //排序
        sortPosition(curDragItem.id, targetItem.id);
      }, 200);
    }
  }

  // 获取指定id的组件元素
  const getDomItem = (id) => {
    return document.getElementsByName('item_' + id)?.[0] || {};
  };

  // 元素换位置
  const sortPosition = (sourceId, targetId) => {
    if (sourceId && targetId && targetId !== sourceId) {
      // 真正的节点交换顺序
      let tmpList = JSON.parse(JSON.stringify(list))
      let sourceItem = tmpList.find((val) => val.id == sourceId);
      let sourceItemIndex = tmpList.findIndex((val) => val.id == sourceId);
      let targetItem = tmpList.find((val) => val.id == targetId);
      let targetItemIndex = tmpList.findIndex((val) => val.id == targetId);
      tmpList.splice(sourceItemIndex, 1, targetItem);
      tmpList.splice(targetItemIndex, 1, sourceItem);
      setList(tmpList);

      // 清除动画及位移
      let sourceDom = getDomItem(sourceId);
      let targetDom = getDomItem(targetId);
      sourceDom.style.transition = '';
      sourceDom.style.transform = '';
      targetDom.style.transition = '';
      targetDom.style.transform = '';

      // 允许下次交换
      animationFlag = true;
    }
  }
  useEffect(() => {
    getInfo()
  }, [pricesheetId])
  const getInfo = async () => {
    const res = await _pricesheetInfo({
      id: pricesheetId,
      storeId: sessionStorage.getItem('user')
        ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
        : null
    })
    if (res.code === '000000') {
      setProductList(res.data)
      // console.log(obj)
    } else {
      myMessage.error(res.mesg)
    }
  }
  // 返回
  const goBack = () => {
    history.goBack()
  }
  const setInputValue = (val, e, i, t) => {
    let arr = JSON.parse(JSON.stringify(packageList))
    let reg = /^([1-9]\d*)$/
    arr[i][t] = reg.test(e) ? e : 1
    setPackageList(arr)
  }
  useEffect(() => {
    redPrice()
  }, [packageList])
  const redPrice = () => {
    let price = 0
    packageList.map(v => {
      price += v?.costPrice * v?.num
    })
    form.setFieldsValue({
      costPrice: price,
    })
    setIsPrice(price > Number(form.getFieldValue('price')))
  }
  //选择商品
  const onSelectProduct = async (values) => {
    let childProductName = ''
    console.log(values)
    let pro; //保存当前选择的商品
    productList.forEach(va => {
      va.productBaseInfoList.forEach(v => {
        if (values.childProductId == v.id) {
          childProductName = v.name
          pro = v
        }
      })
    })
    let names = []
    for (let key in values) {
      if (!!values[key] && key != 'childProductId') {
        names.push(values[key])
      }
    }
    let data = {
      productId: values.childProductId,
      optionName: names.join(',')
    }
    let arr = JSON.parse(JSON.stringify(packageList))
    if (names.length == 0) { //当选择的商品没有选项时
      if (eiditPrice.ind == -1) {
        arr.push({
          imageId: pro.imageId.split(',')[0]?pro.imageId.split(',')[0]:'',
          imageUrl: pro.imageUrls[0]?pro.imageUrls[0]:'',
          costPrice: pro.price,
          childProductId: pro.id,
          childProductName: pro.name,
          num: 1,
          productSpecificationsName: ''
        })
      } else {
        if (!!eiditPrice.obj.id) {
          let arr = JSON.parse(JSON.stringify(optiondel))
          arr.push(eiditPrice.obj.id)
          setOptiondel(arr)
        }
        arr[eiditPrice.ind] = {
          imageId: pro.imageId.split(',')[0]?pro.imageId.split(',')[0]:'',
          imageUrl: pro.imageUrls[0]?pro.imageUrls[0]:'',
          costPrice: pro.price,
          childProductId: pro.id,
          childProductName: pro.name,
          num: 1,
          productSpecificationsName: ''
        }
      }
      setPackageList(arr)
      setAddVisible(false)
    } else {
      setLoading(true)
      let res = await _getSingle(data)
      if (res.status == '200' && res.data.code === '000000') {
        if (eiditPrice.ind == -1) {
          arr.push({
            imageId: res.data.data.imageId,
            imageUrl: res.data.data.imageUrl,
            costPrice: res.data.data.costPrice,
            childProductId: res.data.data.productId,
            childProductName: childProductName,
            num: 1,
            productSpecificationsName: names.join(',')
          })
        } else {
          if (!!eiditPrice.obj.id) {
            let arr = JSON.parse(JSON.stringify(optiondel))
            arr.push(eiditPrice.obj.id)
            setOptiondel(arr)
          }
          arr[eiditPrice.ind] = {
            imageId: res.data.data.imageId,
            imageUrl: res.data.data.imageUrl,
            costPrice: res.data.data.costPrice,
            childProductId: res.data.data.productId,
            childProductName: childProductName,
            num: 1,
            productSpecificationsName: names.join(',')
          }
        }
        setPackageList(arr)
        setAddVisible(false)
        setLoading(false)
      } else {
        myMessage.error(res.data.msg)
      }
    }
  }

  // 图片上传
  const upload = async (e, obj) => {
    if (e.target.files[0].size > (1024 * 1024)) {
      myMessage.warning('图片不能大于2M！')
      return;
    }
    let formData = new FormData();
    formData.append('file', e.target.files[0])
    const res = await _uploadImg(formData)
    if (res.status == '200' && res.data.code === '000000') {
      if (obj >= 0) { //选项图片
        let arr = JSON.parse(JSON.stringify(packageList))
        arr[obj].imageId = res.data.data.id
        arr[obj].imageUrl = res.data.data.url
        setPackageList(arr)
      } else { //示例图
        let arr = JSON.parse(JSON.stringify(list))
        arr.push({
          id: res.data.data.id,
          imgs: res.data.data.url
        })
        setList(arr)
      }
    } else {
      myMessage.error(res.data.msg)
    }
  }
  const save = () => {
    form
      .validateFields()
      .then(async (val) => {
        if (list.length == 0) {
          myMessage.warning('商品示例图不能为空！')
          return
        }
        if(val.price==0){
          myMessage.warning('价格不能为空且不能为0！')
          return
        }
        if(packageList.length==0){
          myMessage.warning('组合中至少需要选择一个商品！')
          return
        }
        val.priceListId = pricesheetId
        val.storeId = enterpriseId
        val.typeId = 5
        let imgs = []
        list.map(v => {
          imgs.push(v.id)
        })
        val.imageId = imgs.join(',')
        val.productSetForms = packageList
        let res
        if (sessionStorage.getItem('product')) {
          val.productSetForms = []
          val.addForms = []
          val.id = JSON.parse(sessionStorage.getItem('product')).id
          // 删除的ids
          val.deleteIds = optiondel
          //存在id就是修改否则添加
          packageList.map(v => {
            if (!v.id) {
              val.addForms.push(v)
            } else {
              val.productSetForms.push(v)
            }
          })
          res = await _editPackage(val)
        } else {
          res = await _addPackage(val)
        }
        if (res.status == '200' && res.data.code === '000000') {
          myMessage.success(sessionStorage.getItem('product') ? '更新成功' : '创建成功')
          goBack()
        } else {
          myMessage.error(res.data.msg)
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }
  //表单值变化
  const valuesChange = (size) => {
    redPrice()
  }
  const handleItem = async (type, item, i) => {
    setEiditPrice({obj: item, ind: i})
    console.log(eiditPrice)
    if (type === 'edit') {
      setAddVisible(true)
    } else if (type === 'delete') {
      setDeleteVisible(true)
    }
  }
  const deleteOption = async () => {
    packageList.splice(eiditPrice.ind, 1)
    //添加删除的组合商品id
    if (!!eiditPrice.obj.id) {
      let arr = JSON.parse(JSON.stringify(optiondel))
      arr.push(eiditPrice.obj.id)
      setOptiondel(arr)
    }
    setDeleteVisible(false)
    redPrice()
    setEiditPrice({})
  }
  //删除示例图
  const deleteSampleGraph = async (val, i) => {
    setGraph({obj: val, ind: i})
    setGraphVisible(true)
  }
  const deleteGraph = async () => {
    list.splice(graph.ind, 1)
    setGraphVisible(false)
    setGraph({})
    myMessage.success('删除成功')
  }
  return (
    <Layout>
      <PageHeader
        ghost={false}
        className={styles.titleBar}
        title={
          <div id="productPackage">
            <Button
              icon={
                <span
                  className={`iconfont iconbtn_back`}
                ></span>
              }
              onClick={goBack}
              className={styles.backBtn}
            />
            {pricesheet.name} · {sessionStorage.getItem('product') ? '编辑' : '添加'}商品组合
          </div>
        }
        extra={[
          <Button onClick={goBack} className={styles.cancelBtn}>取消</Button>,
          <Button onClick={save} type="primary">保存</Button>
        ]}
      ></PageHeader>
      <Content className={styles.newProductsPage}>
        <Spin tip="数据加载中" spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            name="productForm"
            onValuesChange={valuesChange}
            className={styles.productForm}
          >
            <Row gutter={24} className={styles.productContent}>
              <Col span={24}>
                <Form.Item label="商品组合名称"
                           name="name"
                           rules={[
                             {
                               required: true,
                               message: '添加名称',
                             },
                           ]}>
                  <Input/>
                </Form.Item>
                <Form.Item label="组合描述" name="describe">
                  <TextArea rows={3} placeholder="选填，请输入该商品组合的详细信息"/>
                </Form.Item>
              </Col>
              <Col span={12} className={isPrice ? styles.isPrice : ''}>
                <Form.Item label="价格"
                           name="price"
                           rules={[PriceValidateRule]}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="额外运费"
                           name="extraPostage"
                           rules={[extraPostageValidateRule]}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="成本价"
                           name="costPrice"
                >
                  <Input disabled/>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="示例图 *"
                           name="websiteFooter"
                >
                  <div className={styles.wrap}>
                    <ul
                      className={styles.list}
                      onDragOver={onDragOver}
                    >
                      {
                        list.map((val, i) => {
                          return (
                            <li
                              key={val.id}
                              name={'item_' + val.id}
                              className={styles.item}
                              draggable={true}
                              onDragStart={dragStart}
                              onDragEnd={dragEnd}
                              drag-data={JSON.stringify(val)}
                              style={{background: `url("${val.imgs}") center center/100% no-repeat`,backgroundPosition:'center'}}
                            >
                              {/*<img src={val.imgs}/>*/}
                              <span className="iconfont iconproduct_delete" onClick={() => {
                                deleteSampleGraph(val, i)
                              }}></span>
                            </li>
                          )
                        })
                      }
                      {list.length < 6 && (
                        <li className={styles.additem}>
                          <span className="iconfont iconproduct_add"></span>
                          <input type="file" onChange={(e) => upload(e)}/>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className={styles.hint}>必填，最多只能上传6张商品组合图片，可拖拉排序，首张默认为封面。</div>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="组合商品 *"
                           name="websiteFooter"
                >
                  <div className={styles.hint}>添加多个商品组合共同打包销售。
                    <span className={styles.addButton} onClick={() => {
                      setEiditPrice({obj: {}, ind: -1})
                      setAddVisible(true)
                    }}>
                                            {/* <span className="iconfont iconproduct_add"></span> */}
                      + 选择商品
                                        </span>
                  </div>
                </Form.Item>
              </Col>
              {packageList?.length > 0 &&
              <Col span={24}>
                <Form.Item name="websiteFooter" style={{marginTop: '-10px'}}>
                  <div className={styles.Ptitle}>
                    <div className={styles.opt}>商品</div>
                    <div className={styles.price}>成本价</div>
                    <div className={styles.count}>数量</div>
                    <div className={styles.setButton}>操作</div>
                  </div>
                  {packageList.map((val, i) => (
                    <div className={styles.content}>
                      <div className={styles.opt}>
                                     <span className={styles.img}>
                                       {val.imageUrl ? (
                                         <span className={styles.img}>
                                           <img src={val.imageUrl} alt=""/>
                                         </span>
                                       ) : (
                                         <span className="iconfont"></span>
                                       )}
                                     </span>
                        {val.childProductName}（{val.productSpecificationsName}）
                      </div>
                      <div className={styles.price}>
                        {val.costPrice == 0 ? '-' : val.costPrice}
                      </div>
                      <div className={styles.count}>
                        <Input
                          type="text"
                          value={val.num}
                          onChange={e => {
                            setInputValue(val, e.target.value, i, 'num');
                          }}
                        />
                      </div>
                      <div className={styles.setButton}>
                        <Dropdown
                          overlay={
                            <div className={styles.settingsMenu}>
                              <Menu>
                                <Menu.Item><a key='1' onClick={() => handleItem('edit', val, i)}>编辑</a></Menu.Item>
                                <Menu.Divider/>
                                <Menu.Item><a key='2' onClick={() => handleItem('delete', val, i)}>删除</a></Menu.Item>
                              </Menu>
                            </div>
                          }
                          placement="bottomRight"
                          trigger={['click']}>
                          <span className="iconfont iconbtn_list_set"></span>
                        </Dropdown>
                      </div>
                    </div>
                  ))}
                </Form.Item>
              </Col>
              }
            </Row>
          </Form>
        </Spin>
      </Content>
      {addVisible &&
      <SelectProduct
        visible={addVisible}
        onCreate={onSelectProduct}
        onCancel={() => {
          setAddVisible(false)
        }}
        eiditPrice={eiditPrice.obj}
        productList={productList}
      />
      }
      <Modal
        title="移除商品"
        visible={deleteVisible}
        onOk={deleteOption}
        onCancel={() => setDeleteVisible(false)}
        okText="确认"
        cancelText="取消"
        width={600}
        closable={false}
        centered
        maskClosable={false}
        cancelButtonProps={{className: 'footerCanceltext'}}
      >
        <div className="Inf_page_title">删除将不可恢复，可能会影响已有相关商品，确定移除？</div>
      </Modal>
      <Modal
        title="删除商品示例图"
        visible={graphVisible}
        onOk={deleteGraph}
        onCancel={() => setGraphVisible(false)}
        okText="确认"
        cancelText="取消"
        width={600}
        closable={false}
        centered
        maskClosable={false}
        cancelButtonProps={{className: 'footerCanceltext'}}
      >
        <div className="Inf_page_title">删除选项数据将不可恢复，可能会影响已有相关商品，确定删除？</div>
      </Modal>
    </Layout>
  )
}
export default NewProductPackage
