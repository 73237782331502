/*
 * @Author: melon
 * @Date: 2020-07-04 23:17:14
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-11 20:17:36
 */
// Servers
import {
  _getAccountInfo,
  _changePassword,
  _changeAvatar,
  _changeAccountInfo,
} from 'servers/account'

// Selectors
import {
  ChangePasswordSelector,
  ChangeAvatarSelector,
  ChangeAccountInfoSelector,
  AccountInfoSelector,
} from 'selectors/accounts/AccountSelectors'
// Actions
const GET_ACCOUNT_INFO = '/infotos/GET_ACCOUNT_INFO'
const GET_ACCOUNT_INFO_SUCCESS = '/infotos/GET_ACCOUNT_INFO_SUCCESS'
const GET_ACCOUNT_INFO_FAIL = '/infotos/GET_ACCOUNT_INFO_FAIL'

// Action Creators

export const getAccountInfoAction = () => ({
  type: GET_ACCOUNT_INFO,
})

export const getAccountInfoSuccessAction = (data) => ({
  type: GET_ACCOUNT_INFO_SUCCESS,
  data: data,
})
export const getAccountInfoFailAction = () => ({
  type: GET_ACCOUNT_INFO_FAIL,
})

// init data

const initialState = {
  data: {},
  errorMessage: null,
  fetching: false,
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ACCOUNT_INFO:
      return {
        ...state,
        fetching: true,
      }
    case GET_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        data: action.data,
        fetching: false,
      }
    case GET_ACCOUNT_INFO_FAIL:
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}

//异步action

export const getAccountInfo = async () => {
  const res = await _getAccountInfo()
  return new Promise(function (resolve, reject) {
    resolve(AccountInfoSelector(res))
    reject({})
  })
}

export const changePassword = (data) =>
  _changePassword(ChangePasswordSelector(data))

export const changeAvatar = (data) => _changeAvatar(ChangeAvatarSelector(data))

export const changeAccountInfo = (data) =>
  _changeAccountInfo(ChangeAccountInfoSelector(data))
