import { YesOrNoTypes } from 'constants/Options'
import { getOption, getOptionBykeyName } from 'utils/Tools'
import { map } from 'lodash'
import moment from 'moment'

export const ProjectInfoSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  if (data) {
    newData.data = {
      total: data.totalCount ? data.totalCount.toString() : 0,
      date: map(data.list, 'time').map((datatime) =>
        moment(datatime).format('YYYY-MM-DD')
      ),
      counts: map(data.list, 'count'),
    }
  }
  return newData
}

export const CustomerInfoSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  if (data) {
    newData.data = {
      total: data.totalCount ? data.totalCount.toString() : 0,
      date: map(data.list, 'time').map((datatime) =>
        moment(datatime).format('YYYY-MM-DD')
      ),
      counts: map(data.list, 'count'),
    }
  }
  return newData
}
