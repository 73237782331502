import {
  Button, Checkbox,
  Col,
  Divider,
  Dropdown,
  Layout, List,
  Menu,
  Modal,
  PageHeader,
  Popover,
  Row,
  Spin,
  Typography,
  Input, Tooltip,
} from 'antd'
import {
  Link,
} from 'react-router-dom'
import {
  getFullRoute,
  getQueryVariableObj
} from 'utils/Tools'
import { PAGE_PATH } from 'apis/Router'
import components from 'components'
import { indexOf, isEmpty, uniq } from 'lodash'
import React from 'react'
// import { Masonry } from 'react-virtualized'
import InfiniteScroll from 'react-infinite-scroller'
import download from 'downloadjs'
import styles from './bookmarkPage.module.less'
import BottomNotice from './BottomNotice'
import {CopyToClipboard} from "react-copy-to-clipboard";
import commonComponent from 'components/index'
const { PreviewCarouselModal, message, InfoEmptyData } = components
const {
  MyMessage
} = commonComponent
const { Header, Content } = Layout
const { Paragraph } = Typography
const activeStyle = { fontWeight: 'bolder', color: '#000' }
export default class BookmarkPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeKey: props.bookmarkData&&props.bookmarkData.length>0?props.bookmarkData[0].id:'',
      // checkedList:props.bookmarkData&&props.bookmarkData.length,
      checkedList: [],
      showName: false,
      moveOrCopyModalVisible: false,
      deleteModalVisible: false,
      previewCarouselModalVisible: false, // 查看图片弹窗
      previewList: [], // 查看弹窗图片list
      previewCurrent: 0, // 查看弹窗当前siderNumber
      isShowBottom: false,
      LightroomVisible:false,
      LightroomText:'',
      activeId:getQueryVariableObj(props.search).favId
    }
  }
  // state = {
  //   checkedList: [],
  //   showName: false,
  //   moveOrCopyModalVisible: false,
  //   deleteModalVisible: false,
  //   previewCarouselModalVisible: false, // 查看图片弹窗
  //   previewList: [], // 查看弹窗图片list
  //   previewCurrent: 0, // 查看弹窗当前siderNumber
  //   isShowBottom: false,
  //   LightroomVisible:false,
  //   LightroomText:'',
  // }
  // 选择
  doSelect = (item) => {
    const { checkedList } = this.state
    const index = indexOf(checkedList, item)
    if (index < 0) {
      checkedList.push(item)
      uniq(checkedList)
    } else {
      checkedList.splice(index, 1)
    }
    this.setState(checkedList)
  }
  // 选择全部
  selectAll = () => {
    const {
      photosData: { list = [] },
    } = this.props
    const allList = list.concat()
    this.setState({ checkedList: allList })
  }
  // 取消全选
  removeAll = () => {
    this.setState({ checkedList: [] })
  }
  // 显示文件名称
  showFileName = () => {
    const { showName } = this.state
    this.setState({ showName: !showName })
  }

  // 显示删除框
  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    })
  }
  // 隐藏删除框
  hideDeleteModal = () => {
    this.setState({
      deleteModalVisible: false,
    })
  }
  // 删除
  onRemove = async () => {
    const { checkedList } = this.state
    const { _removePhotos, reloadData } = this.props
    const ids = checkedList.map((item) => item.id)
    // 派发删除请求
    const body = {
      ids,
    }
    reloadData()
    // 派发修改数据请求
    try {
      const resData = await _removePhotos(body)

      if (resData.data && [0].includes(resData.data.code)) {
        message.success('移除成功')
        // 清空选择的数据
        this.setState({
          checkedList: [],
          checkedItems: [],
        })
        // 派发获取列表数据
        reloadData()
        // 关闭弹窗
        this.hideDeleteModal()
      } else {
        message.error('移除失败')
      }
    } catch (error) {}
  }

  // 显示查看弹窗
  showPreviewCarouselModal = async () => {
    const { checkedList } = this.state
    const { list = [] } = this.props
    this.setState({
      previewCarouselModalVisible: true,
      previewList: checkedList.length > 0 ? checkedList : list,
      previewCurrent:
        checkedList.length > 0
          ? 0
          : list.findIndex((item) => checkedList[0].id === item.id),
    })
  }
  // 隐藏查看弹窗
  hidePreviewCarouselModal = () => {
    this.setState({
      previewCarouselModalVisible: false,
      previewList: [],
      previewCurrent: 0,
    })
  }

  // 下载图片
  downloadImags = async () => {
    const { checkedList } = this.state
    const { _packageBookmarkPhotos } = this.props
    const ids = checkedList.map((item) => item.id)
    const res = await _packageBookmarkPhotos({ ids })
    if (res.data.code == 0) {
      // const { data } = res.data
      // download(data.packageDownloadUrl)
    }
  }
  toLightroom = async()=>{
    this.setState({ LightroomVisible: true })
    const { photosData: {list = []},selectedBookmark,_fileNameList,_fileNameLists } = this.props
    let all=[],name=[]
    // customPhotoName
    /*if(this.state.checkedList.length>0) {
      this.state.checkedList.map(v => {
        all.push(v.id)
        name.push(v.customPhotoName.slice(0, v.customPhotoName.lastIndexOf('.')))
      })
    }else{
      list.map(v=>{
        all.push(v.id)
      })
    }
    let customerPhotoSelectDTO={
      ids:all
    }*/
    this.state.checkedList.map(v=>{
      all.push(v.id)
      name.push(v.customPhotoName.slice(0,v.customPhotoName.lastIndexOf('.')))
    })
    let res =all.length>0?await _fileNameLists({bookmarkId :selectedBookmark.id,ids:all}): await _fileNameList({bookmarkId :selectedBookmark.id})
    // let res =await _fileNameLists({customerPhotoSelectDTO:customerPhotoSelectDTO})
    // let res = await _fileNameList({bookmarkId :selectedBookmark.id})
    if (res.data.code == 0) {
      let arr=[]
      res.data.data.map((item)=>{
        // if(name.indexOf(item)!=-1||name.length==0) {
          arr.push(item)
        // }
      })
      this.setState({ LightroomText: arr.join(', ') })
    }
  }
  onCopy=async()=>{
    let LrFilename=document.getElementById("LrFilename");
    LrFilename.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    message.success('已成功复制到剪切板')
  }
  onCopyClose=async()=>{
    this.setState({ LightroomVisible: false })
  }
  // 忽略提示

  // ignoreExceedTip = async () => {
  //   const { checkedList } = this.state
  //   const { selectedBookmark, _ignoreExceedTip } = this.props
  //   const res = await _ignoreExceedTip({ id: selectedBookmark.id })
  //   if (res.data.code == 0) {

  //   }
  // }
  //下载Excel
  forExcel=async()=>{
    const {photosData: {list = []}, selectedBookmark ,_downloadBookmarkExcel,projectDetail} = this.props
    let ids=[]
    list.map(v=>{
      ids.push(v.id)
    })
    let customerPhotoSelectDTO={
      ids:ids,
      sheetName:projectDetail.name
    }
    let res = await _downloadBookmarkExcel({bookmarkId :selectedBookmark.id})
    // console.log(list,selectedBookmark ,_downloadBookmarkExcel,projectDetail)
    // return
    // let res = await _downloadBookmarkExcel({customerPhotoSelectDTO:customerPhotoSelectDTO})
    const { data, headers } = res
        // .replace(/\w+;filename=(.*)/, '$1')
    // console.log(headers['content-disposition'].split('=')[1])
    if (res && res.status=='200') {
      const {
        data: { formattedData },
      } = res
      download(res.data, projectDetail.name+'.xls', headers['content-disposition'].split('=')[1], "application/vnd.ms-excel")
      message.success('已成功导出Excel文件')
    }
  }
  dataURLtoBlob=async(base64Str)=>{
    var bstr = atob(base64Str),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    // 下载的是excel格式的文件
    return new Blob([u8arr], { type: "application/vnd.ms-excel" });
  }
//切换展示收藏夹
  changeAcitive = (key) => {
    const { clickNav } = this.props
    // this.setState({ activeKey: key })
    clickNav(key)
  }
  render() {
    const {
      selectedBookmark,
      // photosData: { pagination = {}, list = [] },
      photosData: { pagination = {}, list = []},
      loadData = () => {},
      loading,
      hasMore,
      bookmarkData,
      _ignoreExceedTip,
      _overFavorite,
      projectId
    } = this.props
    const {
      checkedList,
      showName,
      deleteModalVisible,
      previewCarouselModalVisible,
      previewList,
      previewCurrent,
      isShowBottom,
      LightroomVisible,
      LightroomText,
      activeKey,
      activeId
    } = this.state
    const beNotSelected = checkedList.length < 1
    const menu = (
      <div className={styles.moreMenu}>
        <Menu>
          {/* <Menu.Item key="0">
            <a target="_blank" rel="noopener noreferrer">
              导出视频
            </a>
          </Menu.Item>
          <Menu.Item key="1">
            <a target="_blank" rel="noopener noreferrer">
              导出PDF
            </a>
          </Menu.Item>
          <Menu.Divider /> */}
          <Menu.Item key="3">
            <a onClick={this.forExcel} target="_blank" rel="noopener noreferrer">
              导出Excel
            </a>
          </Menu.Item>
        </Menu>
      </div>
    )
    return (
      <div>
        <Header className={`sub-header ${bookmarkData.length>1?styles.header:''}`}>
          <Spin spinning={loading}>
            <PageHeader
              ghost={false}
              title={
                <div style={{ fontWeight: 'bold' }}>收藏夹</div>
                // <div style={{ fontWeight: 'bold' }}>{selectedBookmark.name}</div>
              }
              style={{
                padding: '24px 50px 23px',
              }}
            >
              <div>
                {bookmarkData.length>1?bookmarkData.map((mark,ind)=>(
                    <span>
                      <Link
                          replace
                          to={{
                            pathname: getFullRoute(PAGE_PATH.customerPhoto.details, {
                              projectId: projectId,
                              showKey: 'fav',
                            }),
                            search: `?favId=${mark.id}&text=${mark.name}`,
                          }}
                      >
                        <Button
                            type="link"
                            className="tab-btn"
                            style={activeId === mark.id ? activeStyle : null}
                            onClick={this.changeAcitive.bind(this, mark.id)}
                        >
                          {mark.name}
                        </Button>
                      </Link>
                      {bookmarkData.length-1>ind?<Divider type="vertical" />:''}
                    </span>
                )):''}
              </div>
            </PageHeader>
          </Spin>
        </Header>
        {list.length <= 0 && !loading && !isEmpty(pagination) && (
          <div style={{ marginTop: bookmarkData.length>1?152:120 }}>
            <InfoEmptyData description="客户暂未收藏内容" />
          </div>
        )}
        {list.length > 0 && (
        <Content
          className={`sub-content ${styles.content}`}
          style={{
            overflowX: 'hidden',
            height: '100vh',
            paddingTop:bookmarkData.length>1?'158px':'128px'
          }}
        >
          <Spin spinning={loading}>
            <div className={styles.topArea} style={{
              top:bookmarkData.length>1?'120px':'88px'
            }}>
              <div>
                <span className={styles.imgCount}>
                  {pagination.total}张照片
                  {beNotSelected ? null : `/${checkedList.length}个选择`}
                </span>
                <span style={{ display: beNotSelected ? 'none' : '' }}>
                  <Divider type="vertical" />
                  <Button
                    type="link"
                    className="link-btn"
                    onClick={this.selectAll}
                  >
                    全选
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    type="link"
                    className="link-btn"
                    onClick={this.removeAll}
                  >
                    取消选择
                  </Button>
                </span>
                <Divider type="vertical" />
                <Button
                    type="link"
                    className="link-btn"
                    onClick={this.showFileName}
                    //disabled={beNotSelected}
                  >
                    {showName ? '隐藏' : '显示'}文件名
                </Button>
                {/* <Divider type="vertical" />
                <Button type="link" className="link-btn">
                  购买相册
                </Button> */}
              </div>
              <div>
                <span style={{ display: beNotSelected ? 'none' : '' }}>
                  <Button
                    type="link"
                    className="link-btn"
                    disabled={beNotSelected}
                    onClick={this.showPreviewCarouselModal}
                  >
                    查看
                  </Button>
                  {/* <Divider type="vertical" />
                  <Button
                    type="link"
                    className="link-btn"
                    disabled={beNotSelected}
                    onClick={this.downloadImags}
                  >
                    下载
                  </Button> */}
                  <Divider type="vertical" />
                  <Button
                    type="link"
                    className="link-btn"
                    disabled={beNotSelected}
                    onClick={this.showDeleteModal}
                  >
                    移除收藏
                  </Button>
                  <Divider type="vertical" />
                </span>

                  <Button
                    type="link"
                    className="link-btn"
                    onClick={this.toLightroom}
                    //disabled={beNotSelected}
                  >
                    导出Lightroom文件名
                  </Button>
                  <Divider type="vertical" />
                  <Dropdown
                    overlay={menu}
                    trigger={['click']}
                    //disabled={beNotSelected}
                  >
                    <Button type="link" className="link-btn">
                      更多
                    </Button>
                  </Dropdown>
              </div>
            </div>
            <InfiniteScroll
              initialLoad={false}
              pageStart={1}
              loadMore={loadData}
              hasMore={!loading && hasMore}
              useWindow={false}
              style={{ marginTop: 40 }}
            >
              <Row gutter={24}>
                {list.map((img) => (
                  <Col key={img.id} className={styles.photoItem}>
                    <div key={img.id} onClick={this.doSelect.bind(this, img)}>
                      <div
                        className={`${styles.photo} ${
                          checkedList.includes(img) ? styles.selected : ''
                        }`}
                      >
                        <img
                          src={img.thumbInfo['thumb'].photoUrl}
                          className={styles.image}
                        />
                        <div className={styles.imgMessage}>
                          {img.likeMarkCount>0?(
                              <span className={styles.commented}>
                                <span className="iconfont iconico_favmarked_s"></span>
                                <span className={styles.count}>{img.likeMarkCount}</span>
                              </span>
                          ):''}
                          {/* {isEmpty(img.message) ? null : (
                          // <Popover
                          //   placement="bottomLeft"
                          //   content={
                          //     <div>
                          //       <div
                          //         style={{ fontSize: 13, color: '#4A4A4A', lineHeight: '15px' }}
                          //       >
                          //         {img.message}
                          //       </div>
                          //       <div
                          //         style={{ fontSize: 12, color: '#9B9B9B' }}
                          //       >
                          //         {img.messageTime}
                          //       </div>
                          //     </div>
                          //   }
                          //   trigger="hover"
                          //   overlayClassName="noArrow"
                          //   overlayStyle={{ width: 260 }}
                          // >
                            <span className={styles.commented}>
                              <span className="iconfont iconico_comtmarked_s"></span>
                            </span>
                          // </Popover>
                          )} */}
                        </div>
                      </div>
                      <div
                        className={styles.photoName}
                        style={{ visibility: showName ? '' : 'hidden' }}
                        title={img.customPhotoName}
                      >
                        <Paragraph ellipsis={{ rows: 2 }}>
                          {img.customPhotoName}
                        </Paragraph>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              <Row justify={'center'}> {loading && hasMore && <Spin />}</Row>
              <div style={{ height: '20vh' }}></div>
            </InfiniteScroll>
          </Spin>
          <BottomNotice
            bookMarkDetail={selectedBookmark}
            _ignoreExceedTip={_ignoreExceedTip}
            _overFavorite={_overFavorite}
            key={selectedBookmark.id}
          />
        </Content>
        )}
        <Modal
          visible={deleteModalVisible}
          title="移除收藏"
          okText="确定"
          cancelText="取消"
          centered
          onCancel={this.hideDeleteModal}
          onOk={this.onRemove}
          maskClosable={false}
          width={600}
          cancelButtonProps={{className: 'footerCanceltext'}}
        >
          <div className="Inf_page_title">确定移除所选内容的收藏标记？</div>
        </Modal>
        <Modal
            maskClosable={false}
            title='导出Lightroom文件名'
            cancelText="关闭"
            okText="复制"
            visible={LightroomVisible}
            centered
            width={600}
            onOk={this.onCopy}
            onCancel={this.onCopyClose}
            cancelButtonProps={{className: 'footerCanceltext'}}
        >
          <div className="lightboxL2title">复制后在Lightroom图库模块使用过滤搜索功能时粘贴进去，可快速找到用户收藏的文件</div>
          <Input.TextArea id="LrFilename" className={styles.exportTextarea} value={LightroomText}/>
        </Modal>
        {/* 查看弹窗 */}
        <PreviewCarouselModal
          visible={previewCarouselModalVisible}
          onCancel={this.hidePreviewCarouselModal}
          list={previewList}
          key={previewList}
          previewCurrent={previewCurrent}
          showKey={'customPhotoName'}
        />
      </div>
    )
  }
}
