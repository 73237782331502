/*
 * @Author: melon
 * @Date: 2020-07-01 21:40:43
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-14 20:58:07
 */

import { Col, Form, Input, Row, Switch, Select } from 'antd'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { map } from 'lodash/fp'
import styles from './WebSettingForm.module.less'

const { TextArea } = Input
const { Option } = Select
const layout = {
  labelCol: { span: 16 },
  wrapperCol: { span: 16 },
}
const WebSettingForm = (props) => {
  const { formData, defaultIndexType, setDefaultIndexType } = props
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({
      ...formData,
    })
  }, [form, formData])
  const onChangSwitch = (checked, indexType) => {
    // 如果当前项选择设置为隐藏且是默认首页的话，设置默认首页为null
    if (!checked && [defaultIndexType].includes(indexType)) {
      setDefaultIndexType(null)
    }
  }
  const SwitchItem = ({ label, name, onChange, indexType }) => (
    <Form.Item
      label={label}
      className={classNames(styles.setting_switch_wrapper)}
    >
      <Form.Item
        name={name}
        rules={[{ required: true, message: '请选择' }]}
        className={classNames(styles.setting_switch)}
        valuePropName="checked"
        noStyle
      >
        <Switch
          style={{ width: '45px' }}
          onChange={(checked) => onChange(checked, indexType)}
        />
      </Form.Item>
      <Form.Item shouldUpdate className={styles.setting_text_form_item} noStyle>
        {() => {
          return (
            <span className={styles.setting_text}>
              {form.getFieldValue(name) ? '显示' : '隐藏'}
            </span>
          )
        }}
      </Form.Item>
    </Form.Item>
  )
  const TitleInput = ({ name, dependenciesName, indexType }) => (
    <Form.Item shouldUpdate noStyle>
      {() => {
        return (
          <Form.Item
            name={name}
            rules={[{ required: true, message: '请输入' }]}
            label={
              <div className={styles.show_title_label}>
                <div>显示标题</div>
                <div className={styles.index_type_opt}>
                  {[indexType].includes(defaultIndexType) ? (
                    <span className={styles.default_index}>
                      该页面为默认首页
                    </span>
                  ) : form.getFieldValue(dependenciesName) ? (
                    <span
                      onClick={() => setDefaultIndexType(indexType)}
                      className={styles.can_opt_index}
                    >
                      设为默认首页
                    </span>
                  ) : (
                    <span className={styles.default_index}>设为默认首页</span>
                  )}
                </div>
              </div>
            }
            // className={classNames(styles.Inf_ant_form_item)}
            className='hiddenStar'
          >
            <Input
              className="Inf_normal_input_350"
              placeholder="请输入"
              disabled={!form.getFieldValue(dependenciesName)}
            />
          </Form.Item>
        )
      }}
    </Form.Item>
  )
  const langOption = [
    {
      label: 'English',
      value: 'EN',
    },
    {
      label: '日本語',
      value: 'JP',
    },
    {
      label: '한국어',
      value: 'KR',
    },
    {
      label: '简体中文',
      value: 'CN',
    },
    {
      label: '繁體中文（中国香港）',
      value: 'HK',
    },
    {
      label: '繁體中文（中国台湾）',
      value: 'TW',
    }
  ]
  return (
    <div className={styles.Web_Setting_Form_Wrapper}>
      
      <Form
        form={form}
        ref={props.formRef}
        layout="vertical"
        name="Web_Setting_Form"
        className={styles.Web_Setting_Form}
        initialValues={{
          users: [
            {
              isShow: false,
            },
          ],
        }}
      >
        <div className={styles.web_setting_form_title}>
          <div className="Inf_page_title">网站显示设置</div>
          <div className="Inf_page_text" style={{marginBottom: '30px',fontSize:'13px'}}>
            说明：此处可管理设置对外公开网站的页面显示及显示名称。
          </div>
        </div>
        <Form.Item
          // label={<div className="Inf_page_title">界面语言</div>}
          label="界面语言"
          name="language"
        >
          <Select
            placeholder="请选择"
            suffixIcon={
              <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
            }
            style={{ width: 550 }}
            className={styles.Inf_normal_select}
          >
            {map(({ value, label }) => (
              <Option value={value} key={value}>
                {label}
              </Option>
            ))(langOption)}
          </Select>
        </Form.Item>
        <Row gutter={24}>
          {/* 首页 */}
          <Col span={24} className={styles.form_Item_col}>
            <SwitchItem
              label="首页"
              name="isShowIndex"
              onChange={onChangSwitch}
              indexType="INDEX"
            />
            <TitleInput
              name="indexName"
              dependenciesName="isShowIndex"
              indexType="INDEX"
            />
          </Col>

          {/* 作品 */}
          <Col span={24} className={styles.form_Item_col}>
            <SwitchItem
              label="作品"
              name="isShowWorks"
              onChange={onChangSwitch}
              indexType="WORKS"
            />
            <TitleInput
              name="worksName"
              dependenciesName="isShowWorks"
              indexType="WORKS"
            />
          </Col>

          {/* 项目客片 */}
          <Col span={24} className={styles.form_Item_col}>
            <SwitchItem
              label="项目"
              name="isShowCustomerPhoto"
              onChange={onChangSwitch}
              indexType="CUSTOMER_PHOTO"
            />
            <TitleInput
              name="customerPhotoName"
              dependenciesName="isShowCustomerPhoto"
              indexType="CUSTOMER_PHOTO"
            />
          </Col>

          {/* 关于 */}
          <Col span={24} className={styles.form_Item_col}>
            <SwitchItem
              label="关于"
              name="isShowAbout"
              onChange={onChangSwitch}
              indexType="ABOUT"
            />
            <TitleInput
              name="aboutName"
              dependenciesName="isShowAbout"
              indexType="ABOUT"
            />
          </Col>
          {/* 联系 */}
          <Col span={24} className={styles.form_Item_col}>
            <SwitchItem
              label="联系"
              name="isShowContact"
              onChange={onChangSwitch}
              indexType="CONTACT"
            />
            <TitleInput
              name="contactName"
              dependenciesName="isShowContact"
              indexType="CONTACT"
            />
          </Col>
        </Row>
      </Form>
    </div>
  )
}
export default WebSettingForm
