// Servers
import { _getProjectInfo, _getCustomerInfo } from 'servers/dashboard'
// Selectors

import {
  ProjectInfoSelector,
  CustomerInfoSelector,
} from 'selectors/dashboard/DashboardSelectors'
// Actions

// Action Creators

// init data

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action
export const getProjectInfo = async (params) => {
  const res = await _getProjectInfo(params)
  return new Promise(function (resolve, reject) {
    resolve(ProjectInfoSelector(res))
    reject({})
  })
}
export const getCustomerInfo = async (params) => {
  const res = await _getCustomerInfo(params)
  return new Promise(function (resolve, reject) {
    resolve(CustomerInfoSelector(res))
    reject({})
  })
}
