import { PAGE_PATH } from 'apis/Router'
import { isEmpty } from 'lodash/fp'
import Mock from 'mock/index'
import Dashboard from 'pages/Dashboard/Containers/Index'
/* ------- 注册 end------- */
/* ------- 忘记密码 start------- */
import ForgetPsw from 'pages/ForgetPsw/Containers/Index'
// 引入page
import Login from 'pages/Login/Containers/Index'
/* ------- 注册 start------- */
import Register from 'pages/Register/Containers/Index'
import React from 'react'
import { Provider } from 'react-redux'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import promiseMiddleware from 'redux-promise'
import thunk from 'redux-thunk'
// 引入 reducer
import RootReducer from 'reduxs/rootReducer'
import { setAccessToken } from 'utils/Request'
import './App.less'

/* ------- 忘记密码 end------- */

// console.log(process.env.NODE_ENV + ':' + process.env.REACT_APP_ENV)
// 引入mock模块
if (process.env.REACT_APP_ENV === 'mock') {
  Mock.start() //并且执行初始化函数
}
// 引入mock模块end
const store = createStore(
  RootReducer,
  composeWithDevTools(applyMiddleware(thunk, promiseMiddleware))
)

const App = () => {
  // 设置 token
  const user = sessionStorage.getItem('user')
    ? JSON.parse(sessionStorage.getItem('user'))
    : {}
  const accessToken = isEmpty(user) ? undefined : user.accessToken
  setAccessToken(accessToken)

  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path={PAGE_PATH.login} component={Login} />
            <Route path={PAGE_PATH.register} component={Register} />
            <Route path={PAGE_PATH.forgetPsw} component={ForgetPsw} />
            <Route path={PAGE_PATH.home} component={Dashboard} />
            <Redirect to={PAGE_PATH.home} />
          </Switch>
        </Router>
      </Provider>
    </div>
  )
}

export default App
