import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react'
import BrandForm from '../Components/BrandForm'

import { saveWeb as _saveWeb, getWeb as _getWeb } from 'reduxModules/settings'
import message from 'components/Message'
const Brand = (props, ref) => {
    const {getWebData, setWebData} = props
  const formRef = useRef()
  const onSave = async (values) => {
    // 如果只有域名不存在则不传
    if(!values.domain){
      delete values.domain
    }
    const res = await _saveWeb(values)
    if (res.data.code === 0) {
      message.success('保存成功')
    } else {
      message.error(res.data.msg)
    }
  }

  useImperativeHandle(ref, () => ({
    // onSaveForm 就是暴露给父组件的方法
    onSaveForm: () => {
      formRef.current
        .validateFields()
        .then((values) => {
          // formRef.current.resetFields()
          onSave({ ...props.webData, ...values }).then(() => {
            getWebData()
          })
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
        })
    },
  }))

  return <BrandForm formRef={formRef} webData={props.webData} infoDetails={props.infoDetails} setWebData={setWebData}/>
}

export default forwardRef(Brand)
