import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Switch,
    Divider,
    Dropdown,
    Menu,
    Card,
    PageHeader, Spin, Table, Layout
} from 'antd'
import React, {useRef, useState, useEffect} from 'react'
import myMessage from 'components/Message'
import {
    getInfoClient as _getInfoClient,
} from 'reduxModules/client'
import {getSelectOptions} from 'utils/Help'
import {DownloadImgSize} from 'constants/Options'
import Mock from 'mockjs'
import styles from "./presetList.module.less";
import CommonComponent from 'components/index'
import {
    addPreset as _addPreset,
    editPreset as _editPreset,
    listPreset as _listPreset,
    removePreset as _removePreset,
    getProjectCategory as _getProjectCategory,
    saveProjectCategory as _saveProjectCategory,
} from 'reduxModules/customerPhoto'
import {
    getPricesheet as _getPricesheet,
    getPricesheetId as _getPricesheetId,
} from 'reduxModules/store'
import {
    editEnterpriseLevel as _editEnterpriseLevel,
} from 'reduxModules/subscribe'
import style from "./presetList.module.less";
import ValidateRulesAndMsg from "../../../Utils/ValidateRulesAndMsg";
import PresetForm from '../Components/PresetForm'
import ProjectMenu from '../Components/ProjectMenu'
import AddProjectForm from "./AddProjectForm";

const {Header, Footer, Sider, Content} = Layout
const {message, InfoEmptyData,CategoryModal} = CommonComponent
const {Option} = Select;
const {Random} = Mock
const PresetList = () => {
    document.title = `项目 - Infotos`
    // 项目预设
    const formRef = useRef()
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [accountData, setAccountList] = useState({list: [], pagination: []})
    const [visible, setVisible] = useState(false);
    const [currentChange, setCurrentChange] = useState()
    const [inputValue, setInputValue] = useState('')
    const [isadd, setIsadd] = useState(false)
    const [isModifyName, setIsModifyName] = useState(false)
    const [presetDetail, setpresetDetail] = useState({
        name: "string",
        visitPassword: '1',
        isVisitorMobile: false,
        isAllowShare: true,
        isGuestPieceShow: true,
        canDownload: false,
        downloadSize: "LOW",
        isDownloadCode: false,
        isVisitorDownload: false,
        language: "CN",
        websiteColor: "light",
        isShowBookmark: true,
        printingWorkshop: true,
        salesPriceId: 0,
        visitType: "OPEN",
        isAllowVisitorMeetingConditions: true,
    })
    const [editPreset, setEditPreset] = useState({})
    //获取商品价格表
    const [pricesheetList, setPricesheetList] = useState([])
    const [pricesheetId, setPricesheetId] = useState('')
    const [infoDetails, setInfoDetails] = useState({})
    useEffect(() => {
        getWebDetails()
        getList()
        getListByPricesheet()
        getListByPricesheetId()
    }, [])
    const getWebDetails = async () => {
        const res = await _editEnterpriseLevel()
        if ([0].includes(res.code)) {
            setInfoDetails(res.data)
        }
    }
    // 获取价格表
    const getListByPricesheet = async (data) => {
        // setLoading(true)
        const res = await _getPricesheet({
            storeId:sessionStorage.getItem('user')
              ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
              :null,
            pageNum:1,
            pageSize:10000,
        })
        // setLoading(false)
        if ( res.data.code === '000000') {
            setPricesheetList(res.data.list)
        } else {
            if(res.data.code!= "020002") {
                message.error(res.data.mesg)
            }
        }
    }
    // 获取默认价格表id
    const getListByPricesheetId = async () => {
        // setLoading(true)
        const res = await _getPricesheetId({
            storeId:sessionStorage.getItem('user')
              ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
              :null,
        })
        // setLoading(false)
        if ( res.data.code === '000000') {
            setPricesheetId(res.data.data)
        } else {
            message.error(res.data.msg)
        }
    }
    // 项目预设
    const handleModal = (value) => {
        setShowModal(value)
    }
    // 获取预设列表
    const getList = async () => {
        setLoading(true)
        try {
            const res = await _listPreset({})
            setLoading(false)
            if (res.code === 0) {
                let data = res.data.list
                setAccountList({
                    list: data,
                    // list: reverse(data.records),
                    // pagination: {
                    //     total: data.total,
                    //     pageNo: data.current
                    // }
                })
              //添加名称后立即添加其他参数
              if (isadd) {
                // handleItem('update', data[0])
              }
            }
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }
    // 操作
    const handleItem = async (type, item) => {
        if (type === 'delete') {
            setVisible(true)
            setIsModifyName(false)
            setIsadd(false)
            setCurrentChange(item);
        } else if (type === 'update') {
            setInputValue(item.name)
            item.isPassword = !!item.visitPassword ? true : false
            setEditPreset(item)
            setShowDetails(true)
            setShowModal(false)
            setIsadd(false)
            setIsModifyName(false)
        } else if (type === 'modifyName') {
            setInputValue(item.name)
            item.isPassword = !!item.visitPassword ? true : false
            setEditPreset(item)
            setShowModal(true)
            setIsModifyName(true)
            setShowDetails(false)
            setIsadd(false)
            setTimeout(() => {
                formRef.current.setFieldsValue({
                    name: item.name,
                })
            }, 200)
        }
    }
    // 删除预设
    const removePreset = async () => {
        setLoading(true)
        let idDTO = {
            id: currentChange.id
        }
        const res = await _removePreset(idDTO)
        setVisible(false)
        if (res.status === 200 && res.data.code === 0) {
            message.success('删除成功')
            getList()
        }
    }
    //添加预设名称
    const addPresetName = async () => {
        // if(!inputValue){
        //     message.error('请添加名称!' )
        //     return
        // }
        let isn = false
        accountData.list.map(v => {
            if (v.name == inputValue) {
                isn = true
            }
        })
        if (isn) {
            message.error('预设名称已存在')
            return
        }
        // return
        if(isModifyName) {
            setShowModal(false)
            setIsadd(false)
            addPreset(editPreset)
        }else{
            setShowDetails(true)
            setShowModal(false)
            setIsadd(true)
            setEditPreset(presetDetail)
            console.log(inputValue)
        }
    }
    const addPreset = async (values) => {
        values.name = inputValue
        let res = ''
        if (isadd) {
            res = await _addPreset(values)
        } else {
            values.id = editPreset.id
            res = await _editPreset(values)
        }
        if (res.status === 200 && res.data.code === 0) {
            setShowDetails(false)
            setEditPreset({})
            setInputValue('')
            form.setFieldsValue({
                name: '',
            })
            getList()
        }
    }
    const lastStep = (v) => {
        console.log(v)
        // if(v=='up') {
            setShowDetails(false)
        //     console.log('111')
        //     setShowModal(true)
        //     setTimeout(() => {
        //         form.setFieldsValue({
        //             name: inputValue,
        //         })
        //     }, 500)
        // }else{
        //     console.log('222')
        // }
    }
    const columns = [
        {
            title: '预设名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '',
            dataIndex: 'opt',
            key: 'opt',
            width: 10,
            render: (_, item) => {
                if (+item.rolesId === 1) return ''
                return (
                    <div>
                        <Dropdown
                            overlay={
                                <div className={styles.settingsMenu}>
                                    <Menu>
                                        <Menu.Item><a key='0' onClick={() => handleItem('update', item)}>更新设置</a></Menu.Item>
                                        <Menu.Item><a key='1' onClick={() => handleItem('modifyName', item)}>编辑名称</a></Menu.Item>
                                        <Menu.Divider/>
                                        <Menu.Item><a key='2' onClick={() => handleItem('delete', item)}>删除</a></Menu.Item>
                                    </Menu>
                                </div>
                            }
                            placement="bottomRight"
                            trigger={['click']}>
                            <span className="iconfont iconbtn_list_set"></span>
                        </Dropdown>
                    </div>
                )
            }
        }
    ]
    return (
      <Layout>
          <ProjectMenu
            name={'项目'}
          />
        <Layout style={{marginLeft: 200}}>
            <Header className="sub-header">
                <PageHeader
                    ghost={false}
                    title={
                        <div style={{fontWeight: 'bold'}}>项目预设</div>
                    }
                    style={{
                        padding: '24px 50px 23px',
                    }}
                    extra={[
                        <Button type="primary" onClick={() => {
                            setEditPreset({})
                            setIsModifyName(false)
                            setIsadd(true)
                            handleModal(true)
                        }} key={'add'}>
                            添加预设
                        </Button>
                    ]}
                ></PageHeader>
            </Header>
            <Card bordered={false} className={styles.tables}>
                <Spin tip="数据加载中" spinning={loading} style={{marginTop: '80px'}}>
                    <Content>
                        {accountData.list.length > 0 ? (
                            <div>
                                <Row className={styles.controlBar}>
                                    <Col span={24}>
                                        <span className={styles.ptCount}>{accountData.list.length}条预设</span>
                                        {/* <Divider type="vertical"/>
                                        <span className={styles.ptCount}>将常用的项目设置归类并添加为预设，在添加项目时可快速应用。</span> */}
                                    </Col>
                                </Row>
                                <Table
                                    style={{marginTop: '10px'}}
                                    dataSource={accountData.list}
                                    columns={columns}
                                    pagination={false}
                                    // onChange={data => getList(data.current)}
                                />
                                <div style={{height: '30px'}}></div>
                            </div>
                        ) : null
                        }
                        {accountData.list.length <= 0 && !loading && (
                            <div style={{ marginTop: 80 }}>
                                <InfoEmptyData description="暂无项目预设"/>
                                <span className={styles.remarkTip}>将常用的项目设置归类并添加为预设，在添加项目时可快速应用。</span>
                            </div>
                        )}
                    </Content>
                </Spin>
            </Card>
            {showModal &&
                <Modal
                    visible={showModal}
                    maskClosable={false}
                    centered
                    title="添加项目预设"
                    okText="确定"
                    cancelText="取消"
                    cancelButtonProps={{className: 'footerCanceltext'}}
                    width={600}
                    onCancel={() => handleModal(false)}
                    onOk={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                console.log(values)
                                addPresetName(values)
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info)
                            })
                    }}
                >
                    <p style={{padding: "0 0 8px 0"}}>预设名称</p>
                    <div className={style.member}>
                        <Form name="nameForm" form={form} ref={formRef}>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: '请给预设命个名'
                                    }
                                ]}
                                validateTrigger={['onChange', 'onBlur', 'onKeyUp']}
                                getValueFromEvent={ValidateRulesAndMsg.getValueFromEvent}
                            >
                                <Input
                                    value={inputValue}
                                    placeholder='请输入名称，最大20个字'
                                    maxLength={20}
                                    onChange={e => setInputValue(e.target.value)}
                                />
                            </Form.Item>
                        </Form></div>
                </Modal>
            }
            {showDetails && <PresetForm
                visible={showDetails}
                maskClosable={false}
                centered
                title={<span>设置预设：{inputValue}</span>}
                width={600}
                onCreate={addPreset}
                names={inputValue}
                infoDetails={infoDetails}
                presetDetail={editPreset}
                pricesheetList={pricesheetList}
                pricesheetId={pricesheetId}
                onCancel={()=>{lastStep('up')}}
            >
            </PresetForm>}
            <Modal
                visible={visible}
                title={`删除预设`}
                okText="删除"
                cancelText="取消"
                centered
                width={600}
                cancelButtonProps={{className: 'footerCanceltext'}}
                confirmLoading={loading}
                onCancel={() => setVisible(false)}
                maskClosable={false}
                closable={false}
                onOk={removePreset}
            >
                <div className={styles.desc}>
                    删除预设将不可恢复，确定删除？
                </div>
            </Modal>
        </Layout>
      </Layout>
    )
}

export default PresetList
