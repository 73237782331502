import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'

// 默认语言为 en-US，所以如果需要使用其他语言，推荐在入口文件全局设置 locale
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

ReactDOM.render(
  <ConfigProvider locale={zhCN} autoInsertSpaceInButton={false}>
    <App />
  </ConfigProvider>,
  document.getElementById('root')
)
