import { YesOrNoTypes } from 'constants/Options'
import { getOption, getOptionBykeyName } from 'utils/Tools'
import moment from "moment";

export const WebSettingSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    brandLogo: data&&data.brandLogo?data.brandLogo:'', // 品牌logo
    brandLogoPhotoInfo: data.brandLogoPhotoInfo,
    customerLogo: data.customerLogo, // 客片模块logo
    customerLogoPhotoInfo: data.customerLogoPhotoInfo,
    websiteIcon: data.websiteIcon, // 网站图标
    websiteIconPhotoInfo: data.websiteIconPhotoInfo,
    websiteLayoutType: data.websiteLayoutType, // logo布局类型 LEFT_RIGHT:左右, TOP_DOWN:上下
    accessSuffix: data.accessSuffix, // 访问地址后缀
    accessUrl: data.accessUrl, // 访问地址后缀
    domain: data.domain, // c端域名 自有域名
    domain2c: data.domain2c, // c端域名
    watermark: data.watermark, // 水印图
    watermarkPhotoInfo: data.watermarkPhotoInfo,
    watermarkSize: data.watermarkSize, // 水印图尺寸 百分比
    watermarkPellucidity: data.watermarkPellucidity, // 水印图透明度 百分比
    watermarkPosition: data.watermarkPosition, // 水印图位置 从左上到右下从左往右依次是0-8
    language: data.language, // 访问地址后缀
    // sceneSettingValue: '', // 场景设置逻辑值
    downloadImg: getOptionBykeyName(
      'value',
      data.sceneSettingValueRule1,
      'key',
      YesOrNoTypes
    ), // 下载的照片（下载原片不会应用水印）Y:是, N:否
    shareImg: getOptionBykeyName(
      'value',
      data.sceneSettingValueRule2,
      'key',
      YesOrNoTypes
    ), // 分享的照片 Y:是, N:否
    photoImg: getOptionBykeyName(
      'value',
      data.sceneSettingValueRule3,
      'key',
      YesOrNoTypes
    ), // 客片展示  Y:是, N:否
    websiteTitle: data.websiteTitle, // 网站标题
    websiteFooter: data.websiteFooter, // 页面底部文字
    websiteDesc: data.websiteDesc, // 网站描述
    websiteKeywords: data.websiteKeywords, // 页面底部文字
    poweredBy: getOptionBykeyName(
      'value',
      data.poweredBy,
      'key',
      YesOrNoTypes
    ),  //是否显示Powered by Infotos
  }
  return newData
}

export const WebSettingAddSelector = (data) => {
  return {
    ...data,
    brandLogo: data.brandLogo, // 品牌logo
    customerLogo: data.customerLogo, // 客片模块logo
    websiteIcon: data.websiteIcon, // 网站图标
    websiteLayoutType: data.websiteLayoutType, // logo布局类型 LEFT_RIGHT:左右, TOP_DOWN:上下
    accessSuffix: data.accessSuffix, // 访问地址后缀
    watermark: data.watermark, // 水印图
    watermarkSize: data.watermarkSize, // 水印图尺寸 百分比
    watermarkPellucidity: data.watermarkPellucidity, // 水印图透明度 百分比
    watermarkPosition: data.watermarkPosition, // 水印图位置 从左上到右下从左往右依次是0-8
    // sceneSettingValue: '', // 场景设置逻辑值
    sceneSettingValueRule1: getOption(data.downloadImg, 'value', YesOrNoTypes), // 下载的照片（下载原片不会应用水印）Y:是, N:否
    sceneSettingValueRule2: getOption(data.shareImg, 'value', YesOrNoTypes), // 分享的照片 Y:是, N:否
    sceneSettingValueRule3: getOption(data.photoImg, 'value', YesOrNoTypes), // 客片展示  Y:是, N:否
    websiteTitle: data.websiteTitle, // 网站标题
    websiteFooter: data.websiteFooter, // 页面底部文字
    websiteDesc: data.websiteDesc, // 网站描述
    websiteKeywords: data.websiteKeywords, // 页面底部文字
    poweredBy: getOption(data.poweredBy, 'value', YesOrNoTypes), //是否显示Powered by Infotos
  }
}
export const getRecycleListSelecter = (res) => {
  let newData = {
    ...res.data,
  }
  const {data} = res.data
  console.log(newData.data.list[0])
  newData.data = {
    pagination: {
      current: data.pageNo,
      pageSize: data.pageSize,
      total: data.totalCount,
    },
    list: data.list.map((img) => ({
      ...img,
      createdTime: !!img.createTime
        ? '删除于'+moment(img.createTime).format('YYYY/MM/DD HH:mm')
        : '',
      photoUrl:''
    })),
  }
  return newData
}
