/*
 * @Author: melon
 * @Date: 2020-07-04 23:20:35
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-21 21:21:38
 */
import Api from 'apis/Api.js'
import Mock, { Random } from 'mockjs'
import { AccountData } from './Data/account'
import {
  errorResponse,
  ListPaginationDataResponse,
  successResponse,
  qiniuTokenData,
} from './Data/common'
// 客片
import {
  getBookmarkList,
  getPhotoCategoryData,
  getPhotoCategoryForMove,
  getPhotosByBookmark,
  getPhotosByCategory,
  getProjectById,
  getProjectCategoryData,
  getProjects,
  getBookmarkCategoryForMove,
} from './Data/customerPhoto'
// 商业信息
import { EnterpriseData } from './Data/enterprise'
// 服务信息
import {
  CategorySelectors,
  EnterpriseServiceData,
  getEnterpriseServiceList,
} from './Data/enterpriseService'
import { CheckPhoneData, LoginUserData } from './Data/member'
// 消息
import { getMessageList, getNotReadMessageList } from './Data/message'
//网站设置
import { getWebSettingsData } from './Data/settings'
// 展示分类
import {
  getShowcaseCategoryData,
  MoveOrCopyCategory,
} from './Data/showcaseCategory'
// 展示图片
import {
  getShowcasePhotoList,
  ShowcasePhotoPreviewUrl,
  ShowcasePhotoDownloadUrl,
} from './Data/showcasePhoto'
// 展示设置
import { ShowcaseSettingData } from './Data/showcaseSetting'
// 订阅计划
import { SubscribeData, SubscribeDetailData } from './Data/subscribe'
// 订单
import { getMyStorageList, getCartList } from './Data/order'

//  dashboard
import {
  getDashboradProjectData,
  getDashboradCustomerData,
} from './Data/dashboard'

const getQueryVariableObj = (url) => {
  const index = url.indexOf('?')
  const query = url.slice(index + 1)
  var vars = query.split('&')
  let VariableObj = {}
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    VariableObj[pair[0]] = pair[1]
  }
  return VariableObj
}

const getPagination = ({ pageNo = '1', pageSize = '10' }) => {
  return {
    pageNo: Number(pageNo),
    pageSize: Number(pageSize),
  }
}

export default {
  /**
   * mock start
   */
  start() {
    // 设置延迟
    Mock.setup({
      timeout: '500-1000',
    })
    // 初始化函数
    /* ---------------- qiniuyunstart----------------*/

    // 七牛云获取token
    // Mock.mock(
    //   RegExp(Api.member.getQiniuToken + '(\\?.*)*$'),
    //   'get',
    //   qiniuTokenData
    // )

    /* ---------------- qiniuyun end----------------*/

    /* ---------------- 会员 member start----------------*/
    // 登录
    Mock.mock(RegExp(Api.member.login + '(\\?.*)*$'), 'post', (config) => {
      return config.body === '{}'
        ? errorResponse()
        : successResponse(LoginUserData)
    })

    // 获取手机号是否已注册
    Mock.mock(RegExp(Api.member.checkPhone + '(\\?.*)*$'), 'get', (config) => {
      return config.body === '{}'
        ? errorResponse()
        : successResponse(CheckPhoneData)
    })

    // 获取验证码
    Mock.mock(RegExp(Api.member.verifyCode + '(\\?.*)*$'), 'post', (config) => {
      return config.body === '{}' ? errorResponse() : successResponse()
    })

    // 注册
    Mock.mock(RegExp(Api.member.register + '(\\?.*)*$'), 'post', (config) => {
      return config.body === '{}' ? errorResponse() : successResponse()
    })

    // 忘记密码
    Mock.mock(RegExp(Api.member.forgetPsw + '(\\?.*)*$'), 'post', (config) => {
      return config.body === '{}' ? errorResponse() : successResponse()
    })

    /* ---------------- 会员 member end----------------*/

    /* ------- 消息动态 start------- */
    // 获取未读消息列表
    Mock.mock(RegExp(Api.message.notRead + '(\\?.*)*$'), 'get', (config) => {
      const query = getQueryVariableObj(config.url)
      const pagination = getPagination(query)

      return config.body === '{}'
        ? errorResponse()
        : ListPaginationDataResponse(
            pagination,
            getNotReadMessageList(pagination)
          )
    })
    // 获取消息动态列表
    Mock.mock(RegExp(Api.message.list + '(\\?.*)*$'), 'get', (config) => {
      const query = getQueryVariableObj(config.url)
      const pagination = getPagination(query)

      return config.body === '{}'
        ? errorResponse()
        : ListPaginationDataResponse(pagination, getMessageList(pagination))
    })

    /* ------- 消息动态 end------- */
    /* ---------------- 账号设置 accountSetting start----------------*/

    // 获取账号信息
    Mock.mock(
      RegExp(Api.accountSetting.info + '(\\?.*)*$'),
      'get',
      (config) => {
        return config.body === '{}'
          ? errorResponse()
          : successResponse(AccountData)
      }
    )

    // 更新密码
    Mock.mock(
      RegExp(Api.accountSetting.changePassword + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )

    // 更换头像
    Mock.mock(
      RegExp(Api.accountSetting.changeAvatar + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )

    // 更新账号信息
    Mock.mock(
      RegExp(Api.accountSetting.changeAccountInfo + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )

    /* ---------------- 账号设置 accountSetting end----------------*/

    /* ---------------- 商业信息 enterprise start----------------*/

    // 获取商业信息
    Mock.mock(
      RegExp(Api.enterprise.enterpriseInfo + '(\\?.*)*$'),
      'get',
      (config) => {
        return config.body === '{}'
          ? errorResponse()
          : successResponse(EnterpriseData)
      }
    )

    // 更新商业信息
    Mock.mock(
      RegExp(Api.enterprise.enterpriseEdit + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )

    /* ---------------- 商业信息 enterprise  end----------------*/

    /* ---------------- 服务信息 enterpriseService start----------------*/

    // 获取信息服务列表
    Mock.mock(
      RegExp(Api.enterpriseService.enterpriseServiceList + '(\\?.*)*$'),
      'get',
      (config) => {
        return config.body === '{}'
          ? errorResponse()
          : successResponse(getEnterpriseServiceList())
      }
    )

    // 获取单个服务信息
    Mock.mock(
      RegExp(Api.enterpriseService.getById + '(\\?.*)*$'),
      'get',
      (config) => {
        return config.body === '{}'
          ? errorResponse()
          : successResponse(EnterpriseServiceData)
      }
    )

    // 查询分类选择列表
    Mock.mock(
      RegExp(Api.enterpriseService.getCategorySelector + '(\\?.*)*$'),
      'get',
      (config) => {
        return config.body === '{}'
          ? errorResponse()
          : successResponse(CategorySelectors)
      }
    )

    // 新建/编辑 服务分类
    Mock.mock(
      RegExp(Api.enterpriseService.enterpriseServiceEdit + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )

    // 删除
    Mock.mock(
      RegExp(Api.enterpriseService.remove + '(\\?.*)*$'),
      'delete',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )

    /* ---------------- 服务信息 enterpriseService end----------------*/

    /* ---------------- 订阅信息 subscribe  start----------------*/
    // 获取订阅信息
    Mock.mock(
      RegExp(Api.subscribe.subscribe + '(\\?.*)*$'),
      'get',
      (config) => {
        return config.body === '{}'
          ? errorResponse()
          : successResponse(SubscribeData)
      }
    )
    // 获取订阅详情
    Mock.mock(
      RegExp(Api.subscribe.getSubscribeDetail + '(\\?.*)*$'),
      'get',
      (config) => {
        return config.body === '{}'
          ? errorResponse()
          : successResponse(SubscribeDetailData)
      }
    )

    /* ---------------- 订阅信息 subscribe  end----------------*/

    /* ---------------- 订单 start----------------*/
    // 获取暂存列表
    Mock.mock(RegExp(Api.order.getMyStorage + '(\\?.*)*$'), 'get', (config) => {
      const query = getQueryVariableObj(config.url)
      const pagination = getPagination(query)

      return config.body === '{}'
        ? errorResponse()
        : ListPaginationDataResponse(
            {
              ...pagination,
              categoryId: Number(query.categoryId),
              showcaseType: query.showcaseType,
              isShow: query.isShow,
            },
            getMyStorageList(pagination)
          )
    })

    // 删除暂存
    Mock.mock(
      RegExp(Api.order.removeMyStorage + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )

    // 获取购物车列表
    Mock.mock(RegExp(Api.order.myCart + '(\\?.*)*$'), 'get', (config) => {
      return config.body === '{}'
        ? errorResponse()
        : successResponse(getCartList())
    })
    // 添加到购物车
    Mock.mock(RegExp(Api.order.addCart + '(\\?.*)*$'), 'post', (config) => {
      return config.body === '{}' ? errorResponse() : successResponse()
    })
    // 删除购物车
    Mock.mock(RegExp(Api.order.removeCart + '(\\?.*)*$'), 'post', (config) => {
      return config.body === '{}' ? errorResponse() : successResponse()
    })
    /* ---------------- 订单 end----------------*/
    /* ------- 网站 -- 展示设置 start------- */
    // 获取
    Mock.mock(
      RegExp(Api.showcaseSetting.getAll + '(\\?.*)*$'),
      'get',
      (config) => {
        return config.body === '{}'
          ? errorResponse()
          : successResponse(ShowcaseSettingData)
      }
    )
    // 编辑
    Mock.mock(
      RegExp(Api.showcaseSetting.edit + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )

    // 获取关于预览地址
    Mock.mock(
      RegExp(Api.showcaseSetting.aboutUrl + '(\\?.*)*$'),
      'get',
      (config) => {
        return config.body === '{}'
          ? errorResponse()
          : successResponse(ShowcasePhotoPreviewUrl)
      }
    )

    // 获取联系预览地址
    Mock.mock(
      RegExp(Api.showcaseSetting.contactUrl + '(\\?.*)*$'),
      'get',
      (config) => {
        return config.body === '{}'
          ? errorResponse()
          : successResponse(ShowcasePhotoPreviewUrl)
      }
    )
    /* ------- 网站 -- 展示设置 end------- */

    /* ------- 网站 -- 展示图片 start------- */
    // 分页查询
    Mock.mock(
      RegExp(Api.showcasePhoto.getListForPage + '(\\?.*)*$'),
      'get',
      (config) => {
        const query = getQueryVariableObj(config.url)
        const pagination = getPagination(query)

        return config.body === '{}'
          ? errorResponse()
          : ListPaginationDataResponse(
              {
                ...pagination,
                categoryId: Number(query.categoryId),
                showcaseType: query.showcaseType,
                isShow: query.isShow,
              },
              getShowcasePhotoList(pagination)
            )
      }
    )
    // 获取展示预览地址
    Mock.mock(
      RegExp(Api.showcasePhoto.previewUrl + '(\\?.*)*$'),
      'get',
      (config) => {
        return config.body === '{}'
          ? errorResponse()
          : successResponse(ShowcasePhotoPreviewUrl)
      }
    )
    // 获取打包下载地址
    Mock.mock(
      RegExp(Api.showcasePhoto.getDownloadUrl + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}'
          ? errorResponse()
          : successResponse(ShowcasePhotoDownloadUrl)
      }
    )
    // 编辑/添加
    Mock.mock(RegExp(Api.showcasePhoto.add + '(\\?.*)*$'), 'post', (config) => {
      return config.body === '{}' ? errorResponse() : successResponse()
    })
    // 添加 描述
    Mock.mock(
      RegExp(Api.showcasePhoto.addDescription + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )
    // 调整排序
    Mock.mock(
      RegExp(Api.showcasePhoto.changeSort + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )
    // 显示到首页
    Mock.mock(
      RegExp(Api.showcasePhoto.copyToIndex + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )
    // 删除
    Mock.mock(
      RegExp(Api.showcasePhoto.remove + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )
    // 移动、复制客片到其他分类
    Mock.mock(
      RegExp(Api.showcasePhoto.moveOrCopy + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )
    // 校验图片名称是否存在
    Mock.mock(
      RegExp(Api.showcasePhoto.checkImage + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )

    /* ------- 网站 -- 展示图片 end------- */
    /* ------- 网站 -- 展示分类 start------- */
    // 查询展示分类
    Mock.mock(
      RegExp(Api.showcaseCategory.getAll + '(\\?.*)*$'),
      'get',
      (config) => {
        const query = getQueryVariableObj(config.url)
        return config.body === '{}'
          ? errorResponse()
          : successResponse(
              getShowcaseCategoryData({
                showcaseType: query.showcaseType,
              })
            )
      }
    )
    // 删除
    Mock.mock(
      RegExp(Api.showcaseCategory.remove + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )
    // 编辑/新增展示分类
    Mock.mock(
      RegExp(Api.showcaseCategory.edit + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )

    // 批量编辑展示分类
    Mock.mock(
      RegExp(Api.showcaseCategory.batchEdit + '(\\?.*)*$'),
      'post',
      (config) => {
        return config.body === '{}' ? errorResponse() : successResponse()
      }
    )
    //
    Mock.mock(
      RegExp(Api.showcaseCategory.getAllMoveOrCopyCategory + '(\\?.*)*$'),
      'get',
      (config) => {
        return config.body === '{}'
          ? errorResponse()
          : successResponse(MoveOrCopyCategory)
      }
    )

    /* ------- 网站 -- 展示分类 end------- */
    /*---------  设置------start------ */
    Mock.mock(RegExp(Api.settings.webEdit + '(\\?.*)*$'), 'post', (config) => {
      return config.body === '{}' ? errorResponse() : successResponse()
    })
    Mock.mock(
      RegExp(Api.settings.webInof + '(\\?.*)*$'),
      'get',
      successResponse(getWebSettingsData())
    )

    /*---------  设置------end------ */
    /*---------  客片------start------ */

    Mock.mock(
      RegExp(Api.customerPhoto.category + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getProjectCategoryData())
      }
    )

    Mock.mock(
      RegExp(Api.customerPhoto.addCategory + '(\\?.*)*$'),
      'post',
      successResponse({})
    )

    Mock.mock(
      RegExp(Api.customerPhoto.saveProjectCategory + '(\\?.*)*$'),
      'post',
      successResponse({})
    )

    Mock.mock(
      RegExp(Api.customerPhoto.projects + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getProjects(query.categoryId))
      }
    )
    Mock.mock(
      RegExp(Api.customerPhoto.project + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getProjectById(query.id))
      }
    )
    Mock.mock(
      RegExp(Api.customerPhoto.addProject + '(\\?.*)*$'),
      'post',
      successResponse({ id: Random.guid() })
    )
    Mock.mock(
      RegExp(Api.customerPhoto.editProject + '(\\?.*)*$'),
      'post',
      successResponse({})
    )

    Mock.mock(
      RegExp(Api.customerPhoto.removeProject + '(\\?.*)*$'),
      'post',
      successResponse({ id: Random.guid() })
    )

    Mock.mock(
      RegExp(Api.customerPhoto.photoCategory + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getPhotoCategoryData())
      }
    )

    Mock.mock(
      RegExp(Api.customerPhoto.photoCategoryForMove + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getPhotoCategoryForMove())
      }
    )
    Mock.mock(
      RegExp(Api.customerPhoto.moveOrCopyPhotos + '(\\?.*)*$'),
      'post',
      successResponse({})
    )
    Mock.mock(
      RegExp(Api.customerPhoto.removePhotos + '(\\?.*)*$'),
      'post',
      successResponse({})
    )
    Mock.mock(
      RegExp(Api.customerPhoto.savePhotoCategory + '(\\?.*)*$'),
      'post',
      successResponse({})
    )

    Mock.mock(
      RegExp(Api.customerPhoto.photos + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getPhotosByCategory(query))
      }
    )

    Mock.mock(RegExp(Api.customerPhoto.bookmark + '(\\?.*)*$'), 'get', () => {
      return successResponse(getBookmarkList())
    })

    Mock.mock(
      RegExp(Api.customerPhoto.bookmarkCategoryForMove + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getBookmarkCategoryForMove())
      }
    )

    Mock.mock(
      RegExp(Api.customerPhoto.saveBookmark + '(\\?.*)*$'),
      'post',
      successResponse({})
    )

    Mock.mock(
      RegExp(Api.customerPhoto.removeBookmark + '(\\?.*)*$'),
      'post',
      successResponse({})
    )

    Mock.mock(
      RegExp(Api.customerPhoto.bookmarkPhoto + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getPhotosByBookmark(query))
      }
    )

    Mock.mock(
      RegExp(Api.customerPhoto.moveOrCopyBookmarkPhotos + '(\\?.*)*$'),
      'post',
      successResponse({})
    )
    Mock.mock(
      RegExp(Api.customerPhoto.removeBookmarkPhotos + '(\\?.*)*$'),
      'post',
      successResponse({})
    )
    /*---------  客片------end------ */
    /*---------  dashboard------start------ */
    Mock.mock(
      RegExp(Api.dashboard.projectInfo + '(\\?.*)*$'),
      'get',

      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getDashboradProjectData(query))
      }
    )
    Mock.mock(
      RegExp(Api.dashboard.customerInfo + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getDashboradCustomerData(query))
      }
    )
    /*---------  dashboard------end------ */
  },
}
