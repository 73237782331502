import React, {useEffect, useState} from 'react'
import {Button, Layout, Modal} from 'antd'
import { PAGE_PATH } from 'apis/Router'
import { isEmpty } from 'lodash/fp'
import Imgs from 'imgs'
import {Link, useHistory} from 'react-router-dom'
// 内容
import ContentWrapper from '../Components/Content'
// 侧边栏
import SiderWrapper from '../Components/Sider'
import DialogWithIcon from 'components/DialogWithIcon'
import {
    editEnterpriseLevel as _editEnterpriseLevel,
} from 'reduxModules/subscribe'

const { Content, Sider } = Layout
const Dashboard = (props) => {
  const history = useHistory()
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState('');
    const [htmlTexts, setHtmlTexts] = useState('');
    const user = sessionStorage.getItem('user')
    ? JSON.parse(sessionStorage.getItem('user'))
    : {}
  if (isEmpty(user)) {
    history.push(PAGE_PATH.login)
  }
    useEffect(() => {
        isPastDue()
    }, [])
    //判定是否过期
    const isPastDue=async()=> {
        const res = await _editEnterpriseLevel()
        if ([0].includes(res.code)) {
            let serviceDuration= new Date(res.data.serviceDuration)
            var today = new Date()
            let day=Math.floor((serviceDuration - today)/(1*24*60*60*1000))
            //过期
            if (today.getTime()>serviceDuration.getTime()) {
                setVisible(true)
                setTitle('订阅已到期')
                setHtmlTexts('您的账户订阅已到期，已转为免费版本，全部项目状态已转为下线，图片与设置保持不变，项目数据将在15天后被释放，数据不可恢复。为了保障您的服务正常运行，请及时续费订阅。')
            } 
            else if (day<30&&day>0) {
                setVisible(true)
                setTitle('订阅还有'+day+'天即将到期')
                setHtmlTexts('您的订阅即将到期，到期后账号将会转为免费版，全部项目会自动下线（图片与设置保持不变），到期后数据将为您保留15天，逾期未续费项目存储将会被释放，数据不可恢复。为了保障您的服务正常运行，请及时续费订阅。')
            }
        }
    }
  //关闭过期窗口
    const hidetit =()=>{
        setVisible(false)
    }
  return (
    <Layout style={{ height: '100vh' }}>
      <SiderWrapper />
      <ContentWrapper />
      <DialogWithIcon hidetit={hidetit} visible={visible} title={title} htmlTexts={htmlTexts}/>
    </Layout>
  )
}

export default Dashboard
