/*
 * @Author: melon
 * @Date: 2020-07-14 09:51:47
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-11 17:26:03
 */
import { YesOrNoOptions } from 'constants/Options'
import { map } from 'lodash/fp'
import Mock from 'mockjs'

const YesOrNoOptionsValues = map(({ value }) => value)(YesOrNoOptions)

const { Random } = Mock

// 分页查询展示图片
export const getShowcasePhotoList = ({
  pageNo = 1,
  pageSize = 10,
  categoryId = undefined,
  showcaseType = undefined,
  isShow = undefined,
}) => {
  if (['WORKS'].includes(showcaseType) && !categoryId) {
    return []
  }
  return Array.from({ length: pageSize }, (v, i) => {
    const imgWidth = Random.natural(50, 960)
    const imgHeight = Random.natural(50, 960)
    return {
      id: Random.id(), // id
      showcaseCategoryId: categoryId, // 所属展示图片分类id
      photoName: Random.name(), // 图片名称
      photoInfo: {
        // 图片信息
        photoKey: 'string', // 图片Key
        photoUrl: [
          // 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
          // 'http://photo.infotos.cn/logo4.jfif1594463001',
          // 'http://photo.infotos.cn/logo3.jfif1594463406',
          // 'http://photo.infotos.cn/iconMessage.png1594463431',
        ][Random.natural(0, 3)], // 图片完整Url
        // photoUrl: Random.image(`${imgWidth}x${imgHeight}`, Random.color()),

        photoSize: Random.natural(1, 500), // 图片大小(kb)
        photoFormat: ['png', 'jpeg', 'svg', 'jpg'][Random.natural(0, 3)], // 图片格式
        photoWidth: imgWidth, // 图片宽度(px)
        photoHeight: imgHeight, // 图片高度(px)
      },
      showcaseType: showcaseType, // 展示类型 INDEX:首页, WORKS:作品
      isSales: YesOrNoOptionsValues[Random.natural(0, 1)], // 是否销售 Y:是, N:否
      isShow: isShow, // 是否展示 Y:是, N:否
      description: Random.cword(20), // 描述
      sort: 10 * (Number(pageNo) - 1) + i + 1, // 排序
    }
  })
}

export const ShowcasePhotoPreviewUrl = {
  url: [
    // 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // 'http://photo.infotos.cn/logo4.jfif1594463001',
    // 'http://photo.infotos.cn/logo3.jfif1594463406',
    // 'http://photo.infotos.cn/iconMessage.png1594463431',
  ][Random.natural(0, 3)], // 图片Url
}

export const ShowcasePhotoDownloadUrl = {
  packageDownloadName: Random.name(), // 文件名
  packageDownloadUrl: [
    // 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // 'http://photo.infotos.cn/logo4.jfif1594463001',
    // 'http://photo.infotos.cn/logo3.jfif1594463406',
    // 'http://photo.infotos.cn/iconMessage.png1594463431',
  ][Random.natural(0, 3)], // 打包下载Url
}
