/*
 * @Author: melon
 * @Date: 2020-07-05 22:27:13
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-13 09:54:41
 */
import { CategoryCodes } from 'constants/Options'
import { map } from 'lodash/fp'
import Mock from 'mockjs'

const CategoryCodeValues = map(({ value }) => value)(CategoryCodes)

const { Random } = Mock

export const EnterpriseServiceData = {
  code: 'string',
  id: Random.id(),
  name: Random.ctitle() + '系列', // 名称
  price: Random.natural(10, 50000), // 价格
  categoryCode: CategoryCodeValues[Random.natural(0, 11)], // 分类code
  remark: Random.cword(20), // 描述
  coverKey: Random.name(), // 联系微信图片key
  coverPhotoInfo: {
    photoKey: 'string', // 图片Key
    photoUrl: [
      // 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      // 'http://photo.infotos.cn/logo4.jfif1594463001',
      // 'http://photo.infotos.cn/logo3.jfif1594463406',
      // 'http://photo.infotos.cn/iconMessage.png1594463431',
    ][Random.natural(0, 3)], // 图片完整Url
    photoSize: Random.natural(1, 500), // 图片大小(kb)
    photoFormat: ['png', 'jpg', 'jpeg'][Random.natural(0, 1)], // 图片格式
    photoWidth: Random.natural(1, 60), // 图片宽度(px)
    photoHeight: Random.natural(1, 60), // 图片高度(px)
  },
}

export const getEnterpriseServiceList = () =>
  Array.from({ length: 10 }, (v) => ({
    code: 'string',
    id: Random.id(),
    name: Random.ctitle() + '系列', // 名称
    price: Random.natural(10, 50000), // 价格
    categoryCode: CategoryCodeValues[Random.natural(0, 11)], // 分类code
    remark: Random.cword(20), // 描述
    coverKey: Random.name(), // 联系微信图片key
    coverPhotoInfo: {
      photoKey: 'string', // 图片Key
      photoUrl: [
        // 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // 'http://photo.infotos.cn/logo4.jfif1594463001',
        // 'http://photo.infotos.cn/logo3.jfif1594463406',
        // 'http://photo.infotos.cn/iconMessage.png1594463431',
      ][Random.natural(0, 3)], // 图片完整Url
      photoSize: Random.natural(1, 500), // 图片大小(kb)
      photoFormat: ['png', 'jpg', 'jpeg'][Random.natural(0, 1)], // 图片格式
      photoWidth: Random.natural(1, 60), // 图片宽度(px)
      photoHeight: Random.natural(1, 60), // 图片高度(px)
    },
  }))

export const CategorySelectors = map(({ value, label }) => ({
  code: value,
  name: label,
}))(CategoryCodes)
