/*
 * @Author: melon
 * @Date: 2020-08-11 16:59:42
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-11 17:46:48
 */
import { StorageTypes } from 'constants/Options'
import { map, multiply } from 'lodash/fp'
import Mock from 'mockjs'

const StorageTypesValues = map(({ value }) => value)(StorageTypes)

const { Random } = Mock

// 分页查询暂存
export const getMyStorageList = ({ pageNo = 1, pageSize = 10 }) => {
  return Array.from({ length: pageSize }, (v, i) => {
    const imgWidth = Random.natural(50, 960)
    const imgHeight = Random.natural(50, 960)
    return {
      id: Random.id(), // id
      projectId: Random.name(), // 项目id
      projectName: Random.name(), // 项目名称
      projectDesc: Random.cword(20), // 项目描述
      type: StorageTypesValues[Random.natural(0, 1)], // 类别 ALBUM:相册, OTHER:其他
      typeDesc: Random.cword(20), // 描述
      updateTime: Random.datetime(), // 最后更新时间
      projectCoverKey: Random.name(), // 封面图key
      projectCoverPhotoInfo: {
        //
        // 图片信息
        photoKey: 'string', // 图片Key
        photoUrl: [
          // 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
          // 'http://photo.infotos.cn/logo4.jfif1594463001',
          // 'http://photo.infotos.cn/logo3.jfif1594463406',
          // 'http://photo.infotos.cn/iconMessage.png1594463431',
        ][Random.natural(0, 3)], // 图片完整Url
        // photoUrl: Random.image(`${imgWidth}x${imgHeight}`, Random.color()),
        photoSize: Random.natural(1, 500), // 图片大小(kb)
        photoFormat: ['png', 'jpeg', 'svg', 'jpg'][Random.natural(0, 3)], // 图片格式
        photoWidth: imgWidth, // 图片宽度(px)
        photoHeight: imgHeight, // 图片高度(px)
      },
    }
  })
}

export const getCartList = () => {
  return Array.from({ length: 10 }, (v, i) => {
    const price = Random.natural(50, 1000, 2) // 单价
    const quantity = Random.natural(1, 20) // 数量
    const totalAmount = multiply(price)(quantity) // 总价
    const imgWidth = Random.natural(50, 960)
    const imgHeight = Random.natural(50, 960)
    return {
      id: Random.id(), // id
      projectId: Random.name(), // 项目id
      projectName: Random.name(), // 项目名称
      projectDesc: Random.cword(20), // 项目描述
      price,
      quantity,
      totalAmount,
      projectCoverKey: Random.name(), // 封面图key
      projectCoverPhotoInfo: {
        //
        // 图片信息
        photoKey: 'string', // 图片Key
        photoUrl: [
          // 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
          // 'http://photo.infotos.cn/logo4.jfif1594463001',
          // 'http://photo.infotos.cn/logo3.jfif1594463406',
          // 'http://photo.infotos.cn/iconMessage.png1594463431',
        ][Random.natural(0, 3)], // 图片完整Url
        // photoUrl: Random.image(`${imgWidth}x${imgHeight}`, Random.color()),
        photoSize: Random.natural(1, 500), // 图片大小(kb)
        photoFormat: ['png', 'jpeg', 'svg', 'jpg'][Random.natural(0, 3)], // 图片格式
        photoWidth: imgWidth, // 图片宽度(px)
        photoHeight: imgHeight, // 图片高度(px)
      },
    }
  })
}
