/*
 * @Author: melon
 * @Date: 2020-07-02 16:20:31
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-13 21:29:43
 */

import { Button, ConfigProvider, Divider, Popconfirm, Spin, Table } from 'antd'
import classNames from 'classnames'
import commonComponent from 'components/index'
import { map } from 'lodash/fp'
import React, { forwardRef, useEffect, useState } from 'react'
import {
  editEnterpriseInfo as _edit,
  getEnterpriseInfo as _getInfo,
  getEnterpriseList as _getList,
  removeEnterpriseInfo as _delete,
} from 'reduxModules/enterpriseService'
import ServiceItemForm from '../Components/ServiceForm'
import styles from './Service.module.less'
const { ProductCard, MyMessage, InfoEmptyData } = commonComponent
const mapWithIndex = map.convert({ cap: false })

const ServicePage = (props) => {
  const [serviceItemFormVisible, setServiceItemFormVisible] = useState(false)
  const [formData, setFormData] = useState({})
  const [modalType, setModalType] = useState('add')
  const [list, setList] = useState([])
  const [requesting, setRequesting] = useState(false)

  // 关闭弹窗
  const hideServiceItemForm = (ServiceItemForm) => {
    // 清除表单数据
    ServiceItemForm.resetFields()
    // 关闭弹窗
    setServiceItemFormVisible(false)
  }

  // 显示更新弹窗
  const showServiceItemForm = async (type, id = undefined) => {
    let data = {}
    if (id) {
      // 获取详情
      try {
        const res = await _getInfo({ id })
        if ([0].includes(res.code)) {
          const {
            data: { formattedData },
          } = res

          data = formattedData
        }
      } catch (error) {}
    }
    setFormData(data)
    setModalType(type)
    setServiceItemFormVisible(true)
  }

  // 保存服务信息
  const saveForm = async (values, ServiceItemForm) => {
    // 派发请求
    const body = {
      ...formData,
      ...values,
    }
    try {
      const resData = await _edit(body)
      if (resData.data && [0].includes(resData.data.code)) {
        MyMessage.success('保存成功')
        // 派发查询列表请求
        getList()

        hideServiceItemForm(ServiceItemForm)
      } else {
        MyMessage.error('保存失败' + resData.data.msg)
      }
    } catch (error) {}
  }

  const onRemove = async (id) => {
    // 派发请求
    const body = { id }
    try {
      const resData = await _delete(body)
      if (resData.data && [0].includes(resData.data.code)) {
        MyMessage.success('删除成功')
        // 派发查询列表请求
        getList()

        hideServiceItemForm(ServiceItemForm)
      } else {
        MyMessage.error('删除失败')
      }
    } catch (error) {}
  }
  const getList = async () => {
    try {
      setRequesting(true)
      const res = await _getList()
      if ([0].includes(res.code)) {
        const {
          data: {
            formattedData: { formattedList },
          },
        } = res
        setList(formattedList)
        setRequesting(false)
      }
      return res
    } catch (error) {
    } finally {
      setRequesting(false)
    }
  }
  useEffect(() => {
    getList()
  }, [])
  const ServiceList = ({ dataSource = [], onRemove, onEdit,requesting }) => {
    const columns = [
      {
        title: '',
        dataIndex: 'name',
        render: (_, { serverName, remark, price, id, coverUrl }) => (
          <ProductCard
            imgStyle={{ width: 150, height: 100, objectFit: 'cover' }}
            coverDivStyle={{ width: 150, height: 100 }}
            imgSrc={coverUrl}
            header={
              <div>
                <div className={classNames('Inf_product_card_title')}>
                  {serverName} {price ? `· ￥${price}` : null}
                </div>
                <div className={classNames('Inf_product_card_remarks')}>
                  {remark}
                </div>
              </div>
            }
            footer={
              <div className={styles.service_item_opt}>
                <Button
                  key={'edit' + id}
                  className={styles.service_item_btn}
                  onClick={() => onEdit('edit', id)}
                  type="text"
                >
                  编辑
                </Button>
                <Divider type="vertical" />
                <Popconfirm
                  title="确认删除？"
                  onConfirm={() => onRemove(id)}
                  okText="确认"
                  cancelText="取消"
                >
                  <Button
                    className={classNames(styles.service_item_btn)}
                    key={'delete' + id}
                    type="text"
                  >
                    删除
                  </Button>
                </Popconfirm>
              </div>
            }
          />
        ),
      },
    ]
    return (
      <ConfigProvider
        renderEmpty={() =>
          <div>
            {!requesting&&dataSource.length==0&&(<>
              <InfoEmptyData description="暂无服务数据" />
              <span className={styles.remarkTip}>添加服务信息可以让浏览者更多的了解你</span>
            </>)}
          </div>
      }
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          showHeader={false}
        />
      </ConfigProvider>
    )
  }

  return (
    <div className={styles.service_wrapper}>
      <div>
        <Button
          className={styles.add_btn}
          onClick={() => showServiceItemForm('add')}
        >
          添加服务
        </Button>
        <Divider></Divider>
      </div>
      {/* 服务列表 */}
      <Spin tip="数据加载中" spinning={requesting}>
        <ServiceList
          requesting={requesting}
          dataSource={list}
          onRemove={onRemove}
          onEdit={showServiceItemForm}
        />
      </Spin>

      {/* 表单弹窗 */}
      <ServiceItemForm
        visible={serviceItemFormVisible}
        onCancel={hideServiceItemForm}
        onCreate={saveForm}
        type={modalType}
        formData={formData}
        key={formData.id}
      />
    </div>
  )
}

export default forwardRef(ServicePage)
