//收益记录
import {
  Button,
  Layout,
  Menu,
  Divider,
  Dropdown,
  PageHeader,
  Spin,
  Select,
  Col,
  Row,
  Input,
  Tooltip,
  Card,
  Table,
  Pagination,
  Modal,
  Form, DatePicker
} from 'antd'
import {InfoCircleOutlined, UserOutlined, SearchOutlined,CloseCircleFilled} from '@ant-design/icons';
import components from 'components'
import {ProjectStatus, ArrangementTypes} from 'constants/Options'
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import { PAGE_PATH } from 'apis/Router'
import {
  getInfoClient as _getInfoClient,
  editClient as _editClient,
  addClient as _addClient,
  removeClient as _removeClient,
} from 'reduxModules/client'
import {
  addOrderAdd as _addOrderAdd,
  deliverGoods as _deliverGoods,
  getOrderAll as _getOrderAll,
  getOrderDetails as _getOrderDetails,
} from 'reduxModules/store'
import CommonComponent from 'components/index'
import classNames from "classnames";
import myMessage from 'components/Message'
import styles from './earnings.module.less'
import { earningsType } from 'constants/Options'
import {
  getOptionBykeyName,
} from 'utils/Tools'
import Imgs from 'imgs'
import moment from "moment";
import {map} from "lodash/fp";
const { RangePicker } = DatePicker;
const {Header, Footer, Sider, Content} = Layout
const { MyMessage, InfoEmptyData } = CommonComponent
const { Option,OptGroup  } = Select
// const {ClientProfoto} = Imgs

const EarningsRecord = ({name,ref}) => {
  const [form] = Form.useForm()
  let history = useHistory()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState({
    key:'1',
    month: '近一个月', //来源
    pageNo:1,
    pageSize:10,
    current:1,
    totalCount:20
  })
  // 返回
  const goBack = () => {
    history.goBack()
  }
  // 总数
  const [total, setTotal] = useState(0)
  useEffect(() => {
    getListByClient()
  }, [])
  // 获取订单列表
  const getListByClient = async () => {
    setLoading(true)
    // const res = await _getInfoClient({})
    let data={
      pageNum: selectedOrder.pageNo,
      pageSize: selectedOrder.pageSize,
      storeId: sessionStorage.getItem('user')
        ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
        :null
    }
    if(selectedOrder.research){
      data.research=selectedOrder.research
    }
    if(selectedOrder.status){
      data.status=selectedOrder.status
    }
    const res = await _getOrderAll(data)
    setLoading(false)
    if (res.code === '000000') {
      console.log(res.data)
      setOrderList(res.data.records)
      setTotal(res.data.total)
    } else {
      myMessage.error(res.mesg)
    }
  }
  const columns = [
    {
      title: '来源订单',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (_, item) =>  {return (
        <div style={{fontWeight: 'bold'}}>
          {item.orderNum}
        </div>
      )},
    },
    {
      title: '收益额',
      dataIndex: 'totalMoney',
      key: 'totalMoney',
      render: (_, item) =>  {return (
        <div>
          ￥{item.totalMoney}
        </div>
      )},
    },
    {
      title: '发生日期',
      dataIndex: 'orderDate',
      key: 'orderDate',
      render: (_, item) =>  {return (
        <div>
          {`${item.orderDate.replace(/-/g,'/').split(':')[0]}:${item.orderDate.replace(/-/g,'/').split(':')[1]}`}
        </div>
      )},
    },
    {
      title: '类别',
      dataIndex: 'orderSource',
      key: 'orderSource',
    },
    {
      title: '状态',
      dataIndex: 'statusText',
      key: 'statusText',
      align: 'right',
      /*render: (_, item) => {
          // if (+item.rolesId === 1) return ''
          return (
              <div>
                  <Dropdown
                      overlay={
                          <div className={styles.settingsMenu}>
                              <Menu>
                                  {/!* <Menu.Item><a key='0' onClick={() => handleItem('停用', item)}>停用</a></Menu.Item> *!/}
                                  {/!* <Menu.Item><a key='1' onClick={() => handleItem('添加客片', item)}>添加客片项目</a></Menu.Item>
                                  <Menu.Item><a key='2' onClick={() => handleItem('添加消费', item)}>添加消费记录</a></Menu.Item>
                                  <Menu.Item><a key='3' onClick={() => handleItem('添加标签', item)}>添加标签</a></Menu.Item>
                                  <Menu.Divider /> *!/}
                                  <Menu.Item><a key='4' onClick={() => handleItem('编辑', item)}>编辑</a></Menu.Item>
                                  <Menu.Divider />
                                  <Menu.Item><a key='5' onClick={() => handleItem('删除', item)}>删除</a></Menu.Item>
                              </Menu>
                          </div>
                      }
                      placement="bottomRight"
                      trigger={['click']}>
                      <span className="iconfont iconbtn_list_set"></span>
                  </Dropdown>
              </div>
          )
      }*/
    },
  ]

  // 显示月份
  const onClick = ({key}) => {
    //如果是自定义则弹框
    if(key=='other'){
      setVisible(true)
      console.log(selectedOrder)
      return
    }
    setSelectedOrder({
      key: key, //状态
      month:getOptionBykeyName(
        'value',
        key,
        'label', earningsType), //状态
      pageNo:selectedOrder.pageNo,
      pageSize:selectedOrder.pageSize,
    })
  };
  const onCreate=(val)=>{
    let startDate=moment(val.month[0]).format('YYYY/MM/DD')
    let endDate=moment(val.month[1]).format('YYYY/MM/DD')
    setSelectedOrder({
      month:startDate+'至'+endDate,
      key: startDate+'至'+endDate,
      pageNo:selectedOrder.pageNo,
      pageSize:selectedOrder.pageSize,
    })
    setVisible(false)
  }
  const menu = (
    <div className={styles.sortMenu}>
      <Menu onClick={onClick}>
        {/*SHOOTING_DAY_DESC, CREATE_TIME_DESC, PROJECT_NAME_DESC*/}
        <Menu.Item key="0" disabled className={styles.titleName}>显示</Menu.Item>
        {earningsType.map((item) =>
          <Menu.Item key={item.value}>{item.label}</Menu.Item>
        )}
        <Divider />
        <Menu.Item key="other">自定义</Menu.Item>
      </Menu>
    </div>
  );
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 366;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 366;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };
  return (
    <Layout>
      <PageHeader
        ghost={false}
        className={styles.titleBar}
        title={
          <div>
            <Button
              icon={
                <span
                  className={`iconfont iconbtn_back`}
                ></span>
              }
              onClick={goBack}
              className={styles.backBtn}
            />
            收益记录
          </div>
        }
      ></PageHeader>
      <Card bordered={false} className={classNames(styles.earningsRecord, styles.earnings)}>
        <Spin tip="数据加载中" spinning={loading}>
          {orderList.length > 0||selectedOrder.sourceEnum!=''||selectedOrder.research!=''?(
            <Row className={styles.controlBar}>
              <Col span={12}>
                <span className={styles.ptCount}>{total}条记录</span>
                <Divider type="vertical"/>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button type="link" className="link-btn">
                    显示{selectedOrder.month}的记录
                    <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                  </Button>
                </Dropdown>
              </Col>
            </Row>):null
          }
          <div className={styles.saleProceeds}>
            <div className={styles.sale}>
              <div className={styles.title}><span className="iconfont iconico_sales"></span>{selectedOrder.month}销售额</div>
              <div className={styles.earnTotal}>￥ <span>30822.00</span></div>
              <div className={styles.desc}>其中自有服务销售额￥1200元</div>
            </div>
            <div className={styles.flexs}></div>
            <div className={styles.proceeds}>
              <div className={styles.title}><span className="iconfont iconico_earnings"></span>{selectedOrder.month}收益额</div>
              <div className={styles.earnTotal}>￥ <span>800.00</span></div>
              <div className={styles.desc}>其中自有服务收益额￥120元</div>
            </div>
          </div>
          <div className={styles.hearders}>
            <div className={styles.title}>订单收益记录</div>
          </div>
          {orderList.length > 0 && (
            <Table
              // className={styles.tablesbody}
              style={{marginBottom: '30px'}}
              dataSource={orderList}
              columns={columns}
              pagination={false}
            />
          )}
        </Spin>
      </Card>
      <Modal
        visible={visible}
        title="自定义查询"
        okText="确定"
        cancelText="取消"
        centered
        cancelButtonProps={{className: 'footerCanceltext'}}
        onCancel={()=>{setVisible(false)}}
        maskClosable={false}
        width={600}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields()
              onCreate(values)
            })
            .catch((info) => {
              console.log('Validate Failed:', info)
            })
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="add_project_form"
          requiredMark={false}
          initialValues={{}}
        >

          <Form.Item
            name="month"
            label="选择起止日期（最大1年区间）"
            rules={[
              {
                required: true,
                message: '请选择',
              },
            ]}
          >
            <RangePicker
              value={dates || value}
              disabledDate={disabledDate}
              onCalendarChange={(val) => setDates(val)}
              onChange={(val) => setValue(val)}
              onOpenChange={onOpenChange}
              style={{width: '100%'}}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  )
}

export default EarningsRecord
