/*
 * @Author: melon
 * @Date: 2020-07-04 23:17:14
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-11 19:02:53
 */
// Servers
// Selectors
import {
  EnterpriseAddSelector,
  EnterpriseInfoSelector,
} from 'selectors/enterprise/EnterpriseSelector'
import { _editEnterpriseInfo, _getEnterpriseInfo } from 'servers/enterprise'

// Actions

// Action Creators

// init data

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action

export const getEnterpriseInfo = async (data) => {
  const res = await _getEnterpriseInfo(data)
  return new Promise(function (resolve, reject) {
    resolve(EnterpriseInfoSelector(res))
    reject({})
  })
}

export const editEnterpriseInfo = (data) =>
  _editEnterpriseInfo(EnterpriseAddSelector(data))
