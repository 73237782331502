import React, {useState, useEffect, useRef} from 'react'
import {
  Button,
  Modal,
  List,
  Typography,
  Tooltip,
  Menu,
  Dropdown,
  Row,
  Col, Input, Divider, Form, Select, Popover, Spin
} from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ShowQRCode } from './ShowQRCode'
import styles from './shareProjectForm.module.less'
import { base64Encode } from 'qiniu-js/esm/base64';
import Api from '../../../Apis/Api'
import myMessage from 'components/Message'
import {
  getInfoClient as _getInfoClient,
} from 'reduxModules/client'
import {
  shareEmail as _shareEmail,
  emailRecords as _emailRecords,
} from 'reduxModules/customerPhoto'
import { emailPattern } from 'utils/ValidateRulesAndMsg'
import { getEnterpriseInfo as _getInfo } from 'reduxModules/enterprise'
import classNames from "classnames";
import commonComponent from 'components/index'
import {color} from "echarts/src/export";
import {isEmpty} from "lodash";
const { TrimTextArea } = commonComponent
const { Option } = Select;
const { Text, Paragraph } = Typography
const activeStyle = { fontWeight: 'bolder', color: '#000',borderBottom:'1px solid #9B9B9B' }
const weixinactiveStyle = { fontWeight: 'bolder', color: '#000', }
const ShareProjectForm = ({ visible, onCancel, project ,infoName}) => {
  const keys = ['weixin', 'email', 'link']
  // 客户模式clientMode，访客模式visitorMode，浏览模式normalMode
  const weixinkeys = ['clientMode', 'visitorMode', 'normalMode']
  const [activeKey, setActiveKey] = useState('weixin')
  const [weixinactiveKey, setWeixinActiveKey] = useState('clientMode')
  const [QRcodeImg, setQRcodeImg] = useState('')
  const [ClientList, setClientList] = useState([])
  const [select, setSelect] = useState([])
  const [selectE, setSelectE] = useState([])
  const [copy, setCopy] = useState('')
  const [listData, setListData] = useState([
    {
      label: '微信扫码分享',
      content: '',
      showbutton: true,
      project: project,
    },
    { label: '通过Email', content: '', copyable: false },
    {
      label: '拷贝链接',
      content: '',
      copyable: true,
    },
  ])
  const [loading, setLoading] = useState(false)

  const [name, setName] = useState('')
  const [emails, setEmails] = useState([])
  const [emailsText, setEmailsText] = useState('')
  const [emailsNames, setEmailsNames] = useState([])
  const [showEmailsNames, setShowEmailsNames] = useState(false)
  const formRef = useRef()
  const [form] = Form.useForm()

  useEffect(() => {
    let prarme = 'https://static.infotos.co/wxShare/index.html?project_id='+project.id+"&accessType="+weixinactiveKey;
    let Img = Api.common.getQrcode + '?url=' + base64Encode(prarme);
    setQRcodeImg(Img)
    // project.copytext=project.projectFullUrl+'账号：'+!!project.customerResultVO?project.customerResultVO.customerPhone:''+'，密码:'+project.visitPassword+'，下载验证码：'+project.downloadCode
  }, [weixinactiveKey])

  useEffect(() => {
    // 设置100毫秒的延迟 确保from组件已经加载完毕
    if(activeKey=='email') {
      formRef.current.setFieldsValue({
        message: emailsText,
      });
    }
  }, [activeKey])

  useEffect(() => {
    getEmail()
    let arr=[],name='',pas='',code=''
    if(project.customerResultVO&&project.visitType!='OPEN') {
      if (project.customerResultVO.customerPhone) {
        arr.push('账号：' + project.customerResultVO.customerPhone)
        name = '通过账号：' + project.customerResultVO.customerPhone+'，'
      }else{
        arr.push('账号：' + project.customerResultVO.customerEmail)
        name = '通过账号：' + project.customerResultVO.customerEmail+'，'
      }
    }
      if(project.visitPassword&&project.visitType!='OPEN'){
        arr.push('密码：'+project.visitPassword)
        pas='密码：'+project.visitPassword+'登录访问。'
      }
      if(project.downloadCode&&project.isDownloadCode&&project.canDownload){
        arr.push('下载验证码：'+project.downloadCode)
        code='通过输入验证码：'+project.downloadCode+'下载文件。'
      }
      setCopy(arr.join('，'))
    let fornamePasCode=name+pas+code
    if(fornamePasCode!=''){
      fornamePasCode='可以'+name+pas+code+'\n'
    }
      let text='Hi，您的拍摄照片已经准备好，现在可以访问了。\n' +
          fornamePasCode +
          '\n' +
          '感谢您对我们的支持！\n' +
          infoName
      setEmailsText(text)
    setActiveKey('weixin')
    if(!project.customerId){
      setWeixinActiveKey('visitorMode')
    }else{
      setWeixinActiveKey('clientMode')
    }
  }, [project])
  // 获取客户列表
  const getListByClient = async (data) => {
    const res = await _getInfoClient({
      searchValue:data, //邮件/客户名称/客户电话
      pageNo:1,
      pageSize:10,
    })
    if (res.data.code === 0) {
      let arr=[...emails,...res.data.data.list]
      let result = [];
      result = uniqueFun(arr, "id");
      setClientList(res.data.data.list)
    } else {
      myMessage.error(res.data.msg)
    }
  }
  const uniqueFun=(arr, type)=> {
    const res = new Map();
    return arr.filter((a) => !res.has(a[type]) && res.set(a[type], 1));
  }
    // 搜索名称
  const handleSearch = (event) => {
    // e.persist()
    getListByClient(event)
  }
  const handleChange = (event) => {
    //同时发送邮箱不能大于10个
    if(form.getFieldValue('email').length>10){
      let ar=JSON.parse(JSON.stringify(form.getFieldValue('email')))
      ar.splice(ar.length-1,1)
      form.setFieldsValue({email:ar})
      return
    }
    let arr=JSON.parse(JSON.stringify(select))
    ClientList.map(v=>{
      if(event.includes(v.customerEmail)){
        arr.push(v)
      }
    })
    arr=uniqueFun(arr, "id")
    setSelect(arr)
  }
  const options = ClientList.map(d => (d.customerEmail?<Option key={d.customerEmail}>
    <p style={{color:'#4A4A4A',fontSize:'13px',display:'inline-block'}}>{d.customerName?d.customerName:d.customerEmail}</p>
    <span style={{color:'#9B9B9B',fontSize:'12px',paddingLeft:'5px'}}>({d.customerEmail})</span>
  </Option>:null
  ));
  //邮件已发送人员
  const getEmail= async()=>{
    let data={projectId:project.id}
    const res = await _emailRecords(data)
    if (res.data.code === 0) {
      setEmailsNames(res.data.data)
    } else {
      myMessage.error(res.data.msg)
    }
  }
  //邮件发送
  const setEmail= async()=>{
    form
        .validateFields()
        .then( async(values) => {
          let arr=[];
          // 915408299@qq.com
          let isemail=false
          values.email.map(v=>{
            if(!emailPattern.test(v)){
              isemail=true
            }
          })
          if(isemail){
            myMessage.warning("邮箱格式不正确");
            return
          }
          // console.log(isemail)
          // return
          let myem=JSON.parse(JSON.stringify(values.email))
          let em=[]
          select.map(v=>{
            if(myem.indexOf(v.customerEmail)!=-1){
              arr.push({
                customerId:v.id?v.id:'',
                customerName:v.customerName?v.customerName:'',
                email:v.customerEmail,
                message:values.message,
              })
              myem.splice(myem.indexOf(v.customerEmail),1)
            }
          })
          myem.map(v=>{
            arr.push({
              customerId:'',
              customerName:'',
              email:v,
              message:values.message,
            })
          })

          let data={
            projectId:project.id,
            projectShareDTOS:arr,
            //仅客户访问时就是只有默认；未关联客户时默认为访客模式（只显示默认与浏览，此时默认为访客模式） ，clientMode，visitorMode，normalMode
            accessType:project.customerId?'clientMode': 'visitorMode'
            //同步微信
            // accessType:'normalMode'
          }
          setLoading(true)
          const res = await _shareEmail(data)
          setLoading(false)
          if (res.data.code === 0) {
            getEmail()
            myMessage.success('发送成功')
          } else {
            myMessage.error(res.data.msg)
          }
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
        })
  }

  const addItem = () => {
    if (!isEmpty(name.trim())) {
      var reg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
      if(reg.test(name)){
        let obj={
          customerEmail:name
        }
        setEmails([...emails,obj])
        setClientList([obj,...ClientList])
        setName('')
      }else{
        myMessage.warning("邮箱格式不正确");
      }
    }
  }
  const onNameChange = (event) => {
    setName(event.target.value)
  }
  const isShowEmailsNames=()=>{
    setShowEmailsNames(!showEmailsNames)
  }
  return (
    <Modal
      visible={visible}
      title="分享项目"
      footer={null}
      width={600}
      centered
      onCancel={onCancel}
      maskClosable={false}
    >
      <div className={styles.body}>
        <div className={styles.tabs}>
          <Button
              type="link"
              className={styles.btn}
              style={activeKey === keys[0] ? activeStyle : null}
              onClick={()=>setActiveKey(keys[0])}
          >
            微信分享
          </Button>
          <Button
              type="link"
              className={styles.btn}
              style={activeKey === keys[1] ? activeStyle : null}
              onClick={()=>setActiveKey(keys[1])}
          >
            通过邮件
          </Button>
          <Button
              type="link"
              className={styles.btn}
              style={activeKey === keys[2] ? activeStyle : null}
              onClick={()=>setActiveKey(keys[2])}
          >
            拷贝链接
          </Button>
        </div>
        {activeKey === keys[0]? (
        <div className={styles.weixin}>
          {/*//当为仅客户访问时*/}
          {project.customerId?!project.isVisitor?(
              <div className={styles.tabs} >
                <Button
                    type="link"
                    className={styles.btn}
                    style={weixinactiveKey === weixinkeys[0] ? weixinactiveStyle : null}
                    onClick={()=>setWeixinActiveKey(weixinkeys[0])}
                >
                  客户模式
                </Button>
                <Divider type="vertical" />
                <Button
                    type="link"
                    className={styles.btn}
                    style={weixinactiveKey === weixinkeys[1] ? weixinactiveStyle : null}
                    onClick={()=>setWeixinActiveKey(weixinkeys[1])}
                >
                  访客模式
                </Button>
                <Divider type="vertical" />
                <Button
                    type="link"
                    className={styles.btn}
                    style={weixinactiveKey === weixinkeys[2] ? weixinactiveStyle : null}
                    onClick={()=>setWeixinActiveKey(weixinkeys[2])}
                >
                  浏览模式
                </Button>
                {/* <Divider type="vertical" /> */}
                <Popover content="客户模式仅允许客户账户查看，拥有查看与所有设置权限。访客模式仅密码即可登录查看与收藏。浏览模式仅可用于浏览查看。未关联客户（联系人）时仅允许访客模式与浏览模式，关联客户并关闭仅客户访问时则会显示多模式选择。"  placement="bottom"  overlayClassName={styles.popTip} >
                  <div className={styles.btn}>
                    <span className="icon iconfont iconico_tips" style={{color:'#9b9b9b'}}></span>
                  </div>
                </Popover>
              </div>
          ):null:(
              //未绑定联系人仅显示访客模式和浏览模式
              <div className={styles.tabs} >
                {/*<Button
                    type="link"
                    className={styles.btn}
                    style={weixinactiveKey === weixinkeys[0] ? weixinactiveStyle : null}
                    onClick={()=>setWeixinActiveKey(weixinkeys[0])}
                >
                  客户模式
                </Button>
                <Divider type="vertical" />*/}
                <Button
                    type="link"
                    className={styles.btn}
                    style={weixinactiveKey === weixinkeys[1] ? weixinactiveStyle : null}
                    onClick={()=>setWeixinActiveKey(weixinkeys[1])}
                >
                  访客模式
                </Button>
                <Divider type="vertical" />
                <Button
                    type="link"
                    className={styles.btn}
                    style={weixinactiveKey === weixinkeys[2] ? weixinactiveStyle : null}
                    onClick={()=>setWeixinActiveKey(weixinkeys[2])}
                >
                  浏览模式
                </Button>
                {/* <Divider type="vertical" /> */}
                <Popover content="客户模式拥有查看与设置权限，访客模式可查看与收藏，浏览模式仅可用于浏览查看。未关联客户时仅允许访客模式与浏览模式，关联客户并关闭仅客户访问时则会显示多模式选择。"  placement="bottom"  overlayClassName={styles.popTip} >
                  <div className={styles.btn}>
                    <span className="icon iconfont iconico_tips" style={{color:'#9b9b9b'}}></span>
                  </div>
                </Popover>
              </div>
          )}
          <div className={styles.code}>
            <img src={QRcodeImg} className={styles.code_img}></img>
          </div>
          <div className={styles.steps}>
            <div>
              <span className={styles.round}></span>
              <span>用微信扫码</span>
            </div>
            <div>
              <span className={styles.line}></span>
              <span className={styles.round}></span>
              <span>自动生成项目分享页</span>
            </div>
            {/* <div>
              <span className={styles.line}></span>
              <span className={styles.round}></span>
              <span>长按内容区域分享图片，或点击右上角「…」分享页面</span>
            </div> */}
            <div>
              <span className={styles.line}></span>
              <span className={styles.round}></span>
              <span>转发给朋友</span>
            </div>
          </div>
        </div>
            ):null}
        {activeKey === keys[1]? (
          <div className={styles.email}>
            <Form
                ref={formRef}
                form={form}
                layout="vertical"
                name="add_project_form"
                requiredMark={false}
                // className={styles.createProjectDialog}
                style={{paddingTop: '20px'}}
                initialValues={{
                  message: emailsText
                }}
            >
              <Form.Item
                  name="email"
                  label="接收人"
                  rules={[
                    {
                      required: true,
                      message: '请输入接收人Email',
                    },
                  ]}
              >
                <Select
                  showSearch
                  placeholder="输入Email并用逗号间隔..."
                  defaultActiveFirstOption={false}
                  maxTagCount={8}
                  // mode="multiple"
                  mode="tags"
                  tokenSeparators={[",","，","\t","\n"]}
                  removeIcon={
                    <span className={classNames('iconfont iconbtn_dialog_close', styles.clearButton)}></span>
                  }
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearch}
                  onChange={handleChange}
                  notFoundContent={null}
                  className={styles.select}
                  /*dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div>
                              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 6 }}>
                                <Input
                                    style={{ flex: 'auto' }}
                                    value={name}
                                    onChange={onNameChange}
                                    placeholder="请输入..."
                                    size="small"
                                    className={styles.noBorderInput}
                                />
                                <Button
                                    type="link"
                                    className={styles.linkBtn}
                                    style={{
                                      flex: 'none',
                                      padding: '6px',
                                      display: 'block',
                                    }}
                                    onClick={addItem}
                                >
                                  保存
                                </Button>
                              </div>
                        </div>
                      </div>
                  )}*/
                >
                  {options}
                </Select>
              </Form.Item>
              <Form.Item
                  name="message"
                  label="消息"
                  rules={[
                    {
                      required: true,
                      message: '请输入',
                    },
                  ]}
              >
                <TrimTextArea
                    placeholder="邮件消息可编辑"
                    style={{width:'100%', minHeight:'160px', fontSize: '14px'}}
                />
              </Form.Item>
              <div style={{textAlign:'center'}}>
                <Spin tip="邮件发送中" spinning={loading}>
                  <Button
                      type="primary"
                      onClick={setEmail}
                  >
                    发送邮件
                  </Button>
                </Spin>
              </div>
                {emailsNames.length>0?(
                    <div className={styles.sendRecord}>
                        <p onClick={isShowEmailsNames}><span className={classNames('iconfont iconbtn_a_dpdownmenu')}></span> 已发送给{emailsNames.length}人</p>
                      {showEmailsNames?(
                          <div>
                            {
                              emailsNames.map((v, i) => (
                                  <span>{v.customerName ? v.customerName + '(' + v.email + ')' : v.email}{i < emailsNames.length - 1 ? '，' : ''}</span>
                              ))
                            }
                          </div>
                      ):''}
                    </div>
                ):''}

            </Form>
          </div>
        ):null}
        {activeKey === keys[2]? (
            <div className={styles.link}>
              <div className={styles.linkcontent}>
                <p>{project.projectFullUrl}</p>
                <p>{copy}</p>
              </div>
              <div>
                <CopyToClipboard text={project.projectFullUrl+' '+copy}>
                  <Tooltip title="已复制" trigger={['click']}>
                    <Button type="primary">
                      复制
                    </Button>
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </div>
        ):null}
        {/*<List
          dataSource={listData}
          renderItem={(item) => (
            <List.Item className={styles.listItem}>
              <div>
                <Text className={styles.label} strong>
                  {item.label}
                </Text>
                <Text>{item.content}</Text>
              </div>
              <div>
                {item.copyable ? (
                  <CopyToClipboard text={item.content}>
                    <Tooltip title="已复制" trigger={['click']}>
                      <Button type="primary" size="small" className="black-btn">
                        复制
                      </Button>
                    </Tooltip>
                  </CopyToClipboard>
                ) : null}
              </div>
              <div>
                {item.showbutton ? (
                  <ShowQRCode project={item.project}></ShowQRCode>
                ) : // <Button type="primary" size="small" className="black-btn">
                //   显示二维码
                // </Button>
                null}
              </div>
            </List.Item>
          )}
        />*/}
      </div>
    </Modal>
  )
}

export default ShareProjectForm
