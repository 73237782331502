import Mock from 'mockjs'
import { random } from 'lodash'
const { Random } = Mock

export const getProjectCategoryData = () =>
  Array.from({ length: Random.natural(1, 5) }, (v, i) => ({
    id: i,
    name: Random.cword(2) + '类',
    'isDefault|1': ['Y', 'N'],
    sort: i,
  }))

export const getProjects = (categoryId) => {
  return Array.from({ length: Random.natural(1, 5) }, (v, i) => {
    const imgWidth = Random.natural(50, 960)
    const imgHeight = Random.natural(50, 960)
    return {
      id: categoryId + '_' + i,
      name: Random.cword(2) + '项目',
      shootingDay: Random.date('2020-MM-dd'),
      status: ['HIDDEN', 'ONLINE'][Random.natural(0, 1)],
      customerPhotoCount: Random.natural(10, 50),
      visitType: ['OPEN', 'PASSWORD'][Random.natural(0, 1)],
      isAllowVisitor: ['Y', 'N'][Random.natural(0, 1)],
      allowVisitorCustomerPhotoCategoryId: Random.natural(1, 10),
      coverPhotoIngo: {
        photoName: Random.word(2),
        photoKey: Random.guid(),
        photoUrl: Random.image(`${imgWidth}x${imgHeight}`, Random.color()),
        photoSize: Random.natural(50, 150),
        photoFormat: ['png', 'jpeg', 'svg', 'jpg'][Random.natural(0, 3)],
        photoWidth: imgWidth,
        photoHeight: imgHeight,
      },
    }
  })
}
export const getPhotoCategoryData = () =>
  Array.from({ length: Random.natural(2, 5) }, (v, i) => ({
    id: i + 1,
    name: Random.cword(2) + '片类',
    canDownload: ['Y', 'N'][Random.natural(0, 1)],
    canVisitor: ['Y', 'N'][Random.natural(0, 1)],
    photoCount: Random.natural(10, 100),
    sort: i,
  }))
export const getPhotoCategoryForMove = () =>
  Array.from({ length: Random.natural(2, 5) }, (v, i) => ({
    id: i + 1,
    name: Random.cword(2) + '片类',
    type: ['INDEX', 'SHOWCASE', 'CUSTOMER', 'BOOKMARK'][Random.natural(0, 3)],
    typeDesc: Random.ctitle(),
    photoCount: Random.natural(10, 100),
    sort: i,
  }))

export const getPhotosByCategory = (query) => {
  const pageNo = Number(query.pageNo)
  const pageSize = Number(query.pageSize)
  const totalCount = [8, 35, 68, 21, 33, 78, 15, 6][query.categoryId]
  const residue = totalCount - (pageNo - 1) * pageSize
  const pagePhotoCount = residue / pageSize > 1 ? pageSize : residue
  return {
    pageNo: pageNo,
    pageSize: pageSize,
    totalCount: totalCount,
    totalPage: Math.ceil(totalCount / pageSize),
    list: Array.from({ length: pagePhotoCount }, (v, i) => {
      const imgWidth = Random.natural(300, 960)
      const imgHeight = Random.natural(300, 960)
      return {
        id: query.categoryId + '_' + Random.guid(),
        customerPhotoCategoryId: query.categoryId,
        isCover: ['Y', 'N'][Random.natural(0, 1)],
        sort: i,
        photoName: Random.word(),
        photoInfo: {
          photoKey: Random.guid(),
          photoUrl: Random.image(`${imgWidth}x${imgHeight}`, Random.color()),
          photoSize: Random.natural(50, 150),
          photoFormat: ['png', 'jpeg', 'svg', 'jpg'][Random.natural(0, 3)],
          photoWidth: imgWidth,
          photoHeight: imgHeight,
        },
      }
    }),
  }
}
export const getProjectById = (query) => {
  const imgWidth = Random.natural(300, 960)
  const imgHeight = Random.natural(300, 960)
  return {
    id: query.id,
    coverPhotoInfo: {
      photoKey: Random.guid(),
      photoUrl: Random.image(`${imgWidth}x${imgHeight}`, Random.color()),
      photoSize: Random.natural(50, 150),
      photoFormat: ['png', 'jpeg', 'svg', 'jpg'][Random.natural(0, 3)],
      photoWidth: imgWidth,
      photoHeight: imgHeight,
    },
    projectCategoryId: Random.natural(1, 10),
    name: Random.cword(2, 5) + '项目',
    shootingDay: Random.date('2019-MM-DD'),
    loseDay: Random.date('2020-MM-DD'),
    projectUrlPrefix: Random.word(), // 项目地址前缀
    projectUri: Random.word(3, 8), // 项目地址uri
    projectFullUrl: Random.url('http', 'infotos.com'), //项目完整地址
    status: ['HIDDEN', 'ONLINE'][Random.natural(0, 1)], //项目状态 HIDDEN:隐藏, ONLINE:上线
    isGuestPieceShow: ['Y', 'N'][Random.natural(0, 1)], //客片栏目是否显示该影集 Y:是, N:否
    isSetPhotoHidden: ['Y', 'N'][Random.natural(0, 1)], //是否允许客户设置图片隐藏 Y:是, N:否
    visitType: ['OPEN', 'PASSWORD'][Random.natural(0, 1)], //访问类型 OPEN:公开访问, PASSWORD:密码访问
    visitPassword: Random.word(6, 8),
    isAllowVisitor: ['Y', 'N'][Random.natural(0, 1)], //是否允许访客访问
    allowVisitorPhotoCategoryId: Random.natural(1, 10), //允许访客访问的图片分类id， 为null则全部可以访问
    isAllowVisitorMeetingConditions: ['Y', 'N'][Random.natural(0, 1)], //访客访问需要通过关注微信访问（或通过输入手机/Email）Y:是, N:否
    canDownload: ['Y', 'N'][Random.natural(0, 1)], //是否允许下载图片 Y:是, N:否
    downloadSize: ['ORIGINAL', 'HIGH', 'MEDIUM', 'LOW'][Random.natural(0, 3)], //	下载尺寸类型 Y:是, N:否
    isDownloadCode: ['Y', 'N'][Random.natural(0, 1)], //下载需要验证码
    downloadCode: Random.string(6, 10), // 下载验证码
    isShowBookmark: ['Y', 'N'][Random.natural(0, 1)], //是否显示收藏夹及相关功能
    isMessage: ['Y', 'N'][Random.natural(0, 1)], //是否允许客户留言
    isAllowShare: ['Y', 'N'][Random.natural(0, 1)], //是否允许客户通过社交账号共享
    isVisitorDownload: ['Y', 'N'][Random.natural(0, 1)], //是否允许访客下载
    isShowPrintStore: ['Y', 'N'][Random.natural(0, 1)], //是否显示印坊PrintStore
    isRequestPhone: ['Y', 'N'][Random.natural(0, 1)], //是否允许请求访客手机号
    customerName: Random.cname(), //客户名称
    customerPhone: Random.phone(), //客户联系电话
    customerWechat: 'wx_' + Random.string(6, 10), //客户微信号
    customerEmail: Random.email(), //客户Email
    customerCountryGeoId: Random.natural(1, 20), // 客户收货地址 国家id
    customerProvinceGeoId: Random.natural(1, 20), // 客户收货地址 省id
    customerCityGeoId: Random.natural(1, 20), // 客户收货地址 市id
    customer_area_geo_id: Random.natural(1, 20), // 客户收货地址 区id
    customerAddress: Random.ctitle(), //客户收货地址 详细街道地址
    customerFullAddress: Random.ctitle(), //客户收货地址 全地址
    customerRemark: Random.ctitle(), //客户收货地址 客户备注
  }
}

export const getBookmarkList = () =>
  Array.from({ length: Random.natural(2, 5) }, (v, i) => ({
    id: i,
    name: Random.cword(2, 3) + '收藏夹',
    maxCount: Random.natural(10, 50),
    plusFee: Random.float(10, 100, 2, 2),
    remark: Random.cparagraph(),
    sort: i + 1,
  }))

export const getBookmarkCategoryForMove = () =>
  Array.from({ length: Random.natural(2, 5) }, (v, i) => ({
    id: i + 1,
    name: Random.cword(2) + '文件夹',
    type: ['INDEX', 'SHOWCASE', 'CUSTOMER', 'BOOKMARK'][Random.natural(0, 3)],
    typeDesc: Random.ctitle(),
    photoCount: Random.natural(10, 100),
    sort: i,
  }))

export const getPhotosByBookmark = (query) => {
  const pageNo = Number(query.pageNo)
  const pageSize = Number(query.pageSize)
  const totalCount = [53, 36, 18, 2, 40, 12, 16, 4][query.bookmarkId]
  const residue = totalCount - (pageNo - 1) * pageSize
  const pagePhotoCount = residue / pageSize > 1 ? pageSize : residue
  return {
    pageNo: pageNo,
    pageSize: pageSize,
    totalCount: totalCount,
    totalPage: Math.ceil(totalCount / pageSize),
    list: Array.from({ length: pagePhotoCount }, (v, i) => {
      const imgWidth = Random.natural(300, 960)
      const imgHeight = Random.natural(300, 960)
      return {
        id: query.bookmarkId + '_' + Random.guid(),
        bookmarkId: query.bookmarkId, //所属文件夹id
        sort: i,
        message: [true, false][Random.natural(0, 1)] ? Random.ctitle() : '', //  留言
        messageTime: Random.datetime(), //  留言时间
        isHide: ['Y', 'N'][Random.natural(0, 1)], //  是否隐藏
        photoInfo: {
          photoKey: Random.guid(),
          photoUrl: Random.image(`${imgWidth}x${imgHeight}`, Random.color()),
          photoSize: Random.natural(50, 150),
          photoFormat: ['png', 'jpeg', 'svg', 'jpg'][Random.natural(0, 3)],
          photoWidth: imgWidth,
          photoHeight: imgHeight,
        },
      }
    }),
  }
}
