import { Divider, Form, Typography } from 'antd'
import commonComponent from 'components/index'
import { AlignTypes } from 'constants/Options'
import { map } from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import { RequiredInputRule } from 'utils/ValidateRulesAndMsg'
import styles from './AboutInfo.module.less'
const { TrimTextArea, HoverOpt } = commonComponent
const { div } = Typography
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
}

const AboutInfoForm = (props) => {
  const { formData, canEdit, align, setCanEdit } = props
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({
      ...formData,
    })
  }, [form, formData])
  return (
    <div className={styles.web_about}>
      <Form
        form={form}
        ref={props.formRef}
        layout="vertical"
        name="About_form"
        className={styles.About_form}
        scrollToFirstError={true}
      >
        <Form.Item shouldUpdate>
          {() => {
            return (
              <Form.Item rules={[RequiredInputRule]} noStyle name="aboutTitle">
                {canEdit ? (
                  <TrimTextArea
                    autoSize
                    className={styles.title}
                    maxLength={200}
                    style={{ textAlign: align, backgroundColor: ' #F7F7F7' }}
                    onBlur={() => setCanEdit(false)}
                  />
                ) : (
                  <div className={styles.title} style={{ textAlign: align }}>
                    {form.getFieldValue('aboutTitle')}
                  </div>
                )}
              </Form.Item>
            )
          }}
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => {
            return (
              <Form.Item rules={[RequiredInputRule]} noStyle name="aboutDesc">
                {canEdit ? (
                  <TrimTextArea
                    autoSize
                    style={{
                      textAlign: align,
                      backgroundColor: ' #F7F7F7',
                    }}
                    onBlur={() => setCanEdit(false)}
                  />
                ) : (
                  <div
                    className={styles.about_text}
                    style={{ textAlign: align }}
                  >
                    {form.getFieldValue('aboutDesc')}
                  </div>
                )}
              </Form.Item>
            )
          }}
        </Form.Item>
      </Form>
    </div>
  )
}

const AboutInfo = ({ formData, align, formRef, setAlign }) => {
  const [canEdit, setCanEdit] = useState(false)

  return (
    <div style={{ margin: '5px 0' }}>
      <HoverOpt
        title={'文字'}
        width={250}
        content={
          <div className={styles.align_bth_wrapper}>
            <span className={styles.align_btn} onClick={() => setCanEdit(true)}>
              {canEdit ? '正在编辑' : '编辑'}
            </span>
            <Divider type="vertical" />
            {map((item) => (
              <span
                className={styles.align_btn}
                onClick={() => {
                  if ([align].includes(item.value.toLowerCase())) {
                    return
                  }
                  setAlign(item.value.toLowerCase())
                }}
                style={{
                  cursor: 'pointer',
                }}
                key={item.value}
              >
                {item.label}
              </span>
            ))(AlignTypes)}
          </div>
        }
      >
        <AboutInfoForm
          formRef={formRef}
          formData={formData}
          align={align}
          canEdit={canEdit}
          setCanEdit={setCanEdit}
        />
      </HoverOpt>
    </div>
  )
}

export default AboutInfo
