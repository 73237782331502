import { Button, Col, Divider, Form, Radio, Row, Slider, Switch } from 'antd'
import Components from 'components'
import CoverUpload from 'components/CoverUpload'
import Imgs from 'imgs'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { uploadImage } from 'utils/Uploader'
import styles from './markstaForm.module.less'
import {Link} from "react-router-dom";
import {
  editEnterpriseLevel as _editEnterpriseLevel,
} from 'reduxModules/subscribe'
import { PAGE_PATH } from 'apis/Router'

const { message } = Components
const MarkstaForm = ({ formRef, webData = {} }) => {
  const [form] = Form.useForm()
  const [infoDetails, setInfoDetails] = useState({})
  const [size, setSize] = useState(webData.watermarkSize)
  const [position, setPosition] = useState(webData.watermarkPosition)
  const [sizeStyle, setSizeStyle] = useState({
    transform: `scale(${(webData.watermarkSize / 30).toFixed(1)})`,
    WebkitTransform: `scale(${(webData.watermarkSize / 30).toFixed(1)})`,
    MozTransform: `scale(${(webData.watermarkSize / 30).toFixed(1)})`,
    OTransform: `scale(${(webData.watermarkSize / 30).toFixed(1)})`,
    MsTransform: `scale(${(webData.watermarkSize / 30).toFixed(1)})`,
  })
  const [positionImg, setPositionImg] = useState([])
  const [opacity, setOpacity] = useState(webData.watermarkPellucidity)
  const [fileList, setFileList] = useState(
    isEmpty(webData.watermark)
      ? []
      : [
          {
            url: webData.watermarkPhotoInfo.photoUrl,
          },
        ]
  )
  const [initialValues] = useState(webData)
  useEffect(() => {
    const newArray = []
    for (let index = 0; index < 9; index++) {
      newArray.push(
        <Col span={8} className={styles.positonArea} key={index}>
          {position === index && fileList.length > 0 && (
            <img
              src={fileList[0].url}
              className={styles.markstaImg}
              style={{
                opacity: opacity / 100,
                ...sizeStyle,
              }}
            />
          )}
        </Col>
      )
    }
    setPositionImg(newArray)
    // const getPositionImg = (position) => {
    //   let Imgs = []
    //   for (let index = 0; index < 9; index++) {
    //     positionImg.push(
    //       <Col span={8} className={styles.positonArea} key={index}>
    //         {position === index && (
    //           <img
    //             src={fileList[0].url}
    //             className={styles.markstaImg}
    //             style={{
    //               opacity: opacity / 100,
    //               ...sizeStyle,
    //             }}
    //           />
    //         )}
    //       </Col>
    //     )
    //   }
    //   return Imgs
    // }
  }, [position, size, opacity, fileList])
//权限
  const getWebDetails = async () => {
    const res = await _editEnterpriseLevel()
    if ([0].includes(res.code)) {
      setInfoDetails(res.data)
    }
  }
  useEffect(() => {
    getWebDetails()
  }, [])
  const customRequest = (info) => {
    const { file, onSuccess } = info

    // // 派发请求
    uploadImage({
      file: file,
      errorCallback: async () => {},
      completeCallback: async (res) => {
        const { hash, urlPrefix } = res

        form.setFieldsValue({
          watermark: hash,
        })
        setFileList([
          {
            url: urlPrefix + hash,
          },
        ])
        onSuccess(res)
      },
    })
  }
  const beforeUpload = (file) => {
    const isPng = file.type === 'image/png'
    if (!isPng) {
      message.error('请上传PNG格式得图片!')
    }
    const isLt1M = file.size / 1024 / 1024 < 1
    if (!isLt1M) {
      message.error('图片大小请控制在1MB以内!')
    }

    return isPng && isLt1M
  }
  const uploadProps = {
    fileList,
    customRequest: customRequest,
    beforeUpload: beforeUpload,
    accept: 'image/png',
  }

  const onChangeSize = (value) => {
    if (isNaN(value)) {
      return
    }
    const scaleRate = (value / 30).toFixed(2)
    setSize(value)
    form.setFieldsValue({
      watermarkSize: value,
    })
    setSizeStyle({
      transform: `scale(${scaleRate})`,
      '-webkit-transform': `scale(${scaleRate})`,
      '-moz-transform': `scale(${scaleRate})`,
      '-o-transform': `scale(${scaleRate})`,
      '-ms-transform': `scale(${scaleRate})`,
    })
  }
  const onChangeOpacity = (value) => {
    if (isNaN(value)) {
      return
    }
    setOpacity(value)
    form.setFieldsValue({
      watermarkPellucidity: value,
    })
  }

  const changePosition = (e) => {
    const value = e.target.value
    setPosition(value)
  }
  return (
    <div className={styles.formContrl}>
      {/*{infoDetails.multiUser=='N'?*/}
      {/*    <div>*/}
      {/*      <div style={{fontSize: '14px',color: '#4A4A4A',fontWeight:"bold"}}>功能受限</div>*/}
      {/*      <div style={{margin:'10px 0 30px'}}>您当前账户类型为免费版，无法使用该功能，升级到团队版即可邀请多人加入团队共同协作管理。</div>*/}
      {/*      <Button*/}
      {/*          key='ok'*/}
      {/*          type="primary">*/}
      {/*        <Link*/}
      {/*            to={PAGE_PATH.settings.children.subscribe}*/}
      {/*        >*/}
      {/*          升级订阅*/}
      {/*        </Link>*/}
      {/*      </Button>*/}
      {/*    </div>*/}
      {/*    :*/}
      <Form
        form={form}
        ref={formRef}
        layout="vertical"
        name="markstaForm"
        initialValues={initialValues}
        // initialValues={{ shareImg: true }}
      >
        <Form.Item
          label="水印设置"
          name="watermark"
          className={styles.markstaUpload}
        >
          <CoverUpload
            uploadProps={uploadProps}
            showButtons={{
              uploadButton: (
                <div className="uploadArea">
                  <Button type="primary" style={{ padding: '4px 40px' }}>
                    上传
                  </Button>
                  <p className="upload-hint">仅支持背景透明的PNG文件</p>
                </div>
              ),
              replaceButton: (
                <div className="uploadArea">
                  <Button type="primary" style={{ padding: '4px 40px' }}>
                    替换
                  </Button>
                  <p className="upload-hint">仅支持背景透明的PNG文件</p>
                </div>
              ),
            }}
          />
          <div className={styles.description}>
            说明：照片水印可应用于下载、分享或其他渠道的照片显示中，设置成功后可在添加图片界面选择应用。
          </div>
        </Form.Item>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="尺寸">
              <Form.Item noStyle name="watermarkSize">
                <Slider
                  min={1}
                  max={100}
                  onChange={onChangeSize}
                  disabled={!fileList.length}
                />
              </Form.Item>
              {size}%
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="透明度">
              <Form.Item noStyle name="watermarkPellucidity">
                <Slider
                  min={1}
                  max={100}
                  onChange={onChangeOpacity}
                  disabled={!fileList.length}
                />
              </Form.Item>
              {opacity}%
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="显示位置">
          <Row className={styles.position}>
            <Form.Item noStyle name="watermarkPosition">
              <Radio.Group
                buttonStyle="solid"
                onChange={changePosition}
                disabled={!fileList.length}
              >
                <Radio.Button value={0}></Radio.Button>
                <Radio.Button value={1}></Radio.Button>
                <Radio.Button value={2}></Radio.Button>
                <br />
                <Radio.Button value={3}></Radio.Button>
                <Radio.Button value={4}></Radio.Button>
                <Radio.Button value={5}></Radio.Button>
                <br />
                <Radio.Button value={6}></Radio.Button>
                <Radio.Button value={7}></Radio.Button>
                <Radio.Button value={8}></Radio.Button>
              </Radio.Group>
            </Form.Item>
            <div
              className={styles.example1}
              style={{ backgroundImage: `url(${Imgs.photoSample1})` }}
            >
              <Row>{positionImg}</Row>
            </div>

            <div
              className={styles.example2}
              style={{
                backgroundImage: `url(${Imgs.photoSample2})`,
              }}
            >
              <Row>{positionImg}</Row>
            </div>
          </Row>
          <div className={styles.description} style={{paddingTop: 10}}>
            提示：水印应用示例图仅供参考，不同形态的图形生成结果会有所差别，以最终输出为准。
          </div>
        </Form.Item>
        <Divider />
        <Form.Item label="应用场景">
          <Row className={styles.switch}>
            <Form.Item noStyle name="downloadImg" valuePropName="checked">
              <Switch disabled/>
            </Form.Item>
            <span>下载的照片（下载原片不会应用水印）</span>
          </Row>
          <Row className={styles.switch}>
            <Form.Item noStyle name="shareImg" valuePropName="checked">
              <Switch disabled/>
            </Form.Item>
            <span>分享的照片</span>
          </Row>
          <Row className={styles.switch}>
            <Form.Item noStyle name="photoImg" valuePropName="checked">
              <Switch disabled/>
            </Form.Item>
            <span>项目展示</span>
          </Row>
        </Form.Item>
      </Form>
      {/*}*/}
    </div>
  )
}
export default MarkstaForm
