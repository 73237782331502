import Mock from 'mockjs'
const { Random } = Mock

export const getWebSettingsData = () => {
  const hasData = Random.boolean()
  // return {
  // brandLogo: hasData ? Random.image() : null, // 品牌logo
  // customerLogo: hasData ? Random.image() : null, // 客片模块logo
  // websiteIcon: hasData ? Random.image('50x50', Random.color()) : null, // 网站图标
  // 'websiteLayoutType|1': hasData ? ['LEFT_RIGHT', 'TOP_DOWN'] : null, // logo布局类型 LEFT_RIGHT:左右, TOP_DOWN:上下
  // accessSuffix: hasData ? Random.word() : null, // 访问地址后缀
  // watermark: hasData ? Random.image() : null, // 水印图
  // watermarkSize: hasData ? Random.natural(1, 100) : null, // 水印图尺寸 百分比
  // watermarkPellucidity: hasData ? Random.natural(1, 100) : null, // 水印图透明度 百分比
  // watermarkPosition: data.watermarkPosition, // 水印图位置 从左上到右下从左往右依次是0-8
  // // sceneSettingValue: '', // 场景设置逻辑值
  // sceneSettingValueRule1: data.sceneSettingValueRule1, // 下载的照片（下载原片不会应用水印）Y:是, N:否
  // sceneSettingValueRule2: data.sceneSettingValueRule2, // 分享的照片 Y:是, N:否
  // sceneSettingValueRule3: data.sceneSettingValueRule3, // 客片展示  Y:是, N:否
  // websiteTitle: data.websiteTitle, // 网站标题
  // websiteFooter: data.websiteFooter, // 页面底部文字
  // websiteDesc: data.websiteDesc, // 网站描述
  // websiteKeywords: data.websiteKeywords, // 页面底部文字}
  // }

  return {
    accessSuffix: '',
    accessUrl: '',
    brandLogo: '',
    brandLogoPhotoInfo: {
      photoFormat: '',
      photoHeight: 0,
      photoKey: '',
      photoSize: 0,
      photoUrl: '',
      photoWidth: 0,
    },
    customerLogo: '',
    customerLogoPhotoInfo: {
      photoFormat: '',
      photoHeight: 0,
      photoKey: '',
      photoSize: 0,
      photoUrl: '',
      photoWidth: 0,
    },
    domain2c: '',
    sceneSettingValue: 0,
    sceneSettingValueRule1: 'Y',
    sceneSettingValueRule2: 'N',
    sceneSettingValueRule3: 'N',
    watermark: '',
    watermarkPellucidity: 100,
    watermarkPhotoInfo: {
      photoFormat: '',
      photoHeight: 0,
      photoKey: '',
      photoSize: 0,
      photoUrl: '',
      photoWidth: 0,
    },
    watermarkPosition: 0,
    watermarkSize: 100,
    websiteDesc: '',
    websiteFooter: '',
    websiteIcon: '',
    websiteIconPhotoInfo: {
      photoFormat: '',
      photoHeight: 0,
      photoKey: '',
      photoSize: 0,
      photoUrl: '',
      photoWidth: 0,
    },
    websiteKeywords: '',
    websiteLayoutType: '',
    websiteTitle: '',
  }
}
