/*
 * @Author: melon
 * @Date: 2020-07-04 23:16:32
 * @Last Modified by:   melon
 * @Last Modified time: 2020-07-04 23:16:32
 */
import request from 'utils/Request'

import Api from 'apis/Api.js'

// 登录
/**
 *
 * @param {account,password } param0
 *  account 账号
    password // 密码
 */
export const _login = async ({ account, password }) => {
  return await request({
    method: 'post',
    url: Api.member.login,
    data: {
      account,
      password,
    },
  })
}

// 获取手机号是否已注册
/**
 *
 * @param {mobile} param0
 * mobile 手机号
 */
export const _checkPhone = async ({ mobile }) => {
  return await request({
    method: 'get',
    url: Api.member.checkPhone,
    params: {
      mobile,
    },
  })
}

// 企业注册
/**
 *
 * @param {mobile, password,verifyCode, enterpriseName } param0
 *  mobile // 手机号
    password // 密码
    verifyCode // 验证码
    enterpriseName // 品牌名称
 */

export const _register = async ({
  mobile,
  password,
  verifyCode,
  enterpriseName,
}) => {
  return await request({
    method: 'post',
    url: Api.member.register,
    data: {
      mobile,
      password,
      verifyCode,
      enterpriseName,
    },
  })
}

// 忘记密码
export const _forgetPsw = async (data) => {
  return await request({
    method: 'post',
    url: Api.member.forgetPsw,
    data: data,
  })
}

// 发送短信验证码
export const _getVerifyCode = async (data) => {
  return await request({
    method: 'post',
    url: Api.member.verifyCode,
    data: data,
  })
}
