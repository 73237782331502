import { CloseOutlined } from '@ant-design/icons'
import { Form, Input, Textarea, Select, Badge, Button, Divider, Layout, Menu, Popover, Modal } from 'antd'
import { PAGE_PATH } from 'apis/Router'
import CommonComponent from 'components/index'
import Imgs from 'imgs'
import { isEmpty, map } from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  getAccountInfo as _getInfo,
  getAccountInfoAction,
  getAccountInfoFailAction,
  getAccountInfoSuccessAction,
} from 'reduxModules/account'
import {
  feedback as _feedback,
} from 'reduxModules/customerPhoto'
import { getNotReadList as _getNotReadList } from 'reduxModules/message'
import styles from './sider.module.less'
import components from 'components'
const { message} = components
const { ImageCardCell } = CommonComponent

const { MsgInfo } = Imgs

const { Sider } = Layout

const MyMsg = ({ visible, setVisible, msgData: { list, notReadTotal } }) => {
  let history = useHistory()
  const handleVisibleChange = (visible) => {
    setVisible(visible)
  }
  const Title = () => (
    <div className={styles.Inf_popover_title}>
      <span>消息通知</span>
      <span
        className={styles.Inf_popover_close}
        onClick={() => setVisible(false)}
      >
        <CloseOutlined />
      </span>
    </div>
  )

  const goToMsgPage = () => {
    history.push(PAGE_PATH.my.children.msg)
    setVisible(false)
  }

  return isEmpty(list) ? (
    <Button
      shape="circle"
      className={styles.messages}
      type="link"
      icon={
        <span className="iconfont" style={{ fontSize: 20 }}>
          &#xe615;
        </span>
      }
      onClick={goToMsgPage}
    />
  ) : (
    <Popover
      placement="rightTop"
      trigger="hover"
      title={<Title />}
      visible={visible}
      onVisibleChange={handleVisibleChange}
      overlayClassName={styles.Inf_popover}
      content={
        <div className={styles.Inf_msg_list}>
          {map((i) => (
            <div className={styles.Inf_msg_item} key={i.id}>
              <ImageCardCell
                imgSize={36}
                title={<div>{i.content}</div>}
                imgSrc={MsgInfo}
                description={
                  <div className={styles.msg_time}>{i.timeText}</div>
                }
              />

              <Divider></Divider>
            </div>
          ))(list)}

          <Button type="link" className={styles.more_btn} onClick={goToMsgPage}>
            查看全部
          </Button>
        </div>
      }
    >
      <Badge
        style={{
          minWidth: 16,
          height: 16,
          padding: 1,
          fontSize: 8,
          top: 12,
          right: 6,
          lineHeight: '13px',
          boxShadow: 'none',
          backgroundColor: '#E02020',
        }}
        count={notReadTotal}
        overflowCount={99}
      >
        <Button
          shape="circle"
          className={styles.messages}
          type="link"
          icon={
            <span className="iconfont" style={{ fontSize: 20 }}>
              &#xe615;
            </span>
          }
        />
      </Badge>
    </Popover>
  )
}

const SiderWrapper = (props) => {
  let history = useHistory()
  let location = useLocation()
  const dispatch = useDispatch()
  const accountInfo = useSelector(
    (state) => state.account.data.formattedData || {}
  )
  const { sidebarColorType, nickname } = accountInfo

  const [theme, setTheme] = useState('dark')
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [myVisible, setMyVisible] = useState(false)
  const [helpVisible, setHelpVisible] = useState(false)
  const [feedbackVisible, setFeedbackVisible] = useState(false)
  const [feedbackValue, setFeedbackValue] = useState('ABNORMAL_FUNCTION')
  const [feedbackText, setFeedbackText] = useState('')
  const [loading, setLoading] = useState(false)
  const [msgData, setMsgData] = useState({
    list: [],
    notReadTotal: 0,
  })
  const loginOut = () => {
    sessionStorage.removeItem('user')
    history.push(PAGE_PATH.login)
  }
  // 获取消息动态未读列表 默认只查询前五条
  const getUnreadMessage = async () => {
    try {
      const res = await _getNotReadList({
        pageSize: 5,
        pageNo: 1,
      })
      if ([0].includes(res.code) && res.data) {
        const {
          data: {
            formattedData: { formattedList, notReadTotal },
          },
        } = res
        setMsgData({
          list: formattedList,
          notReadTotal,
        })
      }
      return res
    } catch (error) {
    } finally {
    }
  }
  const getAccountInfo = async () => {
    dispatch(getAccountInfoAction())
    _getInfo()
      .then(async (res) => {
        if ([0].includes(res.code)) {
          dispatch(getAccountInfoSuccessAction(res.data))
        } else {
          dispatch(getAccountInfoFailAction(res.data.message))
        }
        return Promise.resolve(res)
      })
      .catch((error) => {
        dispatch(getAccountInfoFailAction(error))
        return Promise.reject(error)
      })
  }
  useEffect(() => {
    const user = sessionStorage.getItem('user')
      ? JSON.parse(sessionStorage.getItem('user'))
      : {}
    if (!isEmpty(user)) {
      getUnreadMessage()
      getAccountInfo()
    }
  }, [])

  useEffect(() => {
    const SiderTheme = ['LIGHT_COLOUR'].includes(sidebarColorType)
      ? 'light'
      : 'dark'
    setTheme(SiderTheme)
  }, [sidebarColorType])

  const onVisibleChange = (visible) => {
    setMyVisible(visible)
  }

  const doSelect = () => {
    setMyVisible(false)
  }

  const menu = (
    <div className={styles.accMenu}>
      <Menu onSelect={doSelect}>
        <Menu.Item>
          <Link to={PAGE_PATH.my.children.info}>
            我的账号
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={PAGE_PATH.my.children.msg}>我的消息</Link>
        </Menu.Item>
        {/* <Menu.Item>
          <Link to={PAGE_PATH.my.children.cache}>我的暂存</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={PAGE_PATH.my.children.shoppingcart}>订单记录</Link>
        </Menu.Item> */}
        {/* <Menu.Divider/>
        <Menu.Item>
          <Link to={PAGE_PATH.my.children.member}>团队成员</Link>
         </Menu.Item> */}
        <Menu.Divider/>
        <Menu.Item>
          <a type="text" onClick={loginOut}>
            退出
          </a>
        </Menu.Item>
      </Menu>
    </div>
  )

  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;
  const tofeedback= async()=>{
    const res = await _feedback()
    // console.log(res)
    // return
    if (res.data.code === 0) {
      message.success('提交成功')
    } else {
      message.error('请求失败')
    }
  }
  const showFeedback = async() => {
    form.resetFields();
    setFeedbackVisible(true)
  }
  //意见反馈
  const onFinish = async() => {
    let res=await _getInfo()
    // if (res.code === 200) {
    //   console.log(res)
    // }
    // return
    form.validateFields()
        .then(async (values) => {
          setLoading(true)
          let feedbackDTO={feedbackDTO :values}
          const res = await _feedback(values)
          setLoading(false)
          if (res.data && [0].includes(res.data.code)) {
            form.resetFields()
            setFeedbackVisible(false)
            message.success('提交成功，感谢您的支持！')
          }else{
            message.error('请求失败')
          }
        })
        .catch((info) => {
          console.log(info)
        })

  };
  // const onFinishFailed = (errorInfo: any) => {
  //   console.log('Failed:', errorInfo);
  // };
  const changeMenu = ({item, key, keyPath, domEvent}) => {
    //用于项目跳转其他页面在返回项目展示全部
    if(key==='/collection'){
      sessionStorage.setItem('newKey','all')
    }
  }
  return (
    <Sider
      // breakpoint="md"
      // collapsedWidth="200"
      className="them-sider"
      style={{
        overflow: 'none',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}
      width={150}
      theme={theme}
    >
      <div className={styles.logo}>
        <Link to={PAGE_PATH.home}><img
          src={theme === 'dark' ? Imgs.LogoWT : Imgs.LogoBK}
          alt=""
          className={styles.logoImg}
        /></Link>
      </div>
      <div className={styles.user}>
        <Popover
          content={menu}
          placement="rightTop"
          trigger={['click']}
          overlayClassName={styles.userMenu}
          visible={myVisible}
          onVisibleChange={onVisibleChange}
        >
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <span className={styles.userName}>{nickname}</span>
            {/* <DownOutlined style={{ color: '#aaacb1' }} /> */}
            <span>
              <span
                className="iconfont iconbtn_a_dpdownmenu"
                style={{ color: '#9B9B9B', fontSize: 8, verticalAlign: 4 }}
              ></span>
            </span>
          </a>
        </Popover>
        {/* <Dropdown overlay={menu} trigger={['click']}> */}

        {/* </Dropdown> */}
        {/* 高元@0415：消息模块后端完备，对接成功后再放出 */}
        {/* <MyMsg
          visible={popoverVisible}
          setVisible={setPopoverVisible}
          msgData={msgData}
        /> */}
      </div>
      <Menu
        mode="inline"
        style={{
          border: 'none',
        }}
        onClick={changeMenu}
        theme={theme}
        inlineIndent={30}
        defaultSelectedKeys={[location.pathname]}
      >
        <Menu.Item key={PAGE_PATH.home} className="sider-menu">
          <Link to={PAGE_PATH.home}>Dashboard</Link>
        </Menu.Item>
        <Menu.Item key={PAGE_PATH.customerPhoto.index} className="sider-menu">
          <Link to={PAGE_PATH.customerPhoto.index}>项目</Link>
        </Menu.Item>
        <Menu.Item key={PAGE_PATH.store.children.earnings} className="sider-menu">
          <Link to={PAGE_PATH.store.children.earnings}>销售</Link>
        </Menu.Item>
        <Menu.Item key={PAGE_PATH.client.children.list} className="sider-menu">
          <Link to={PAGE_PATH.client.children.list}>客户</Link>
        </Menu.Item>
        <Menu.Item key={PAGE_PATH.web.children.index} className="sider-menu">
          <Link to={PAGE_PATH.web.children.index}>网站</Link>
        </Menu.Item>
        <Menu.Item key={PAGE_PATH.settings.children.brand} className="sider-menu">
          <Link to={PAGE_PATH.settings.children.brand}>设置</Link>
        </Menu.Item>
      </Menu>
      <div className={styles.bottomMenus}>
        <Menu
          mode="inline"
          style={{
            border: 'none',
          }}
          theme={theme}
          className="bottom-menus"
        >
          {/* 高元@0415：待订单功能处理后端功能完整后再开启B端购物车，B端购物车数据需要与后端同步 */}
          {/* <Menu.Item key="5">
            <Link to={PAGE_PATH.shoppingcart}>
              <span className="iconfont iconbtn_a_shopcart"></span>
            </Link>
          </Menu.Item> */}
          <Menu.Item key="7">
            <a onClick={() => setHelpVisible(true)}>帮助</a>
          </Menu.Item>
          <Menu.Item key="8">
            <a onClick={() => showFeedback()}>意见反馈</a>
          </Menu.Item>
        </Menu>
      </div>

      <Modal
        title="问题或意见反馈"
        okText="提交"
        cancelText="取消"
        centered
        width={600}
        visible={feedbackVisible}
        maskClosable={false}
        // footer={null}
        onOk={onFinish}
        // onOk={() => {
        //   form
        //     .validateFields()
        //     .then((values) => {
        //       form.resetFields()
        //       onCreate(values)
        //     })
        //     .catch((info) => {
        //       console.log('Validate Failed:', info)
        //     })
        // }}
        onCancel={() => setFeedbackVisible(false)}
        cancelButtonProps={{className: 'footerCanceltext'}}
      >
        <Form name="basic"
              form={form}
          layout="vertical"
          className={styles.fbDialog}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
        >
          <Form.Item label="反馈类型" rules={[{ required: true }]} required name='feedBackEnumType' >
            <Select
              placeholder="请选择要反馈的类型"
              suffixIcon={
                <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
              }
            >
              <Option value="ABNORMAL_FUNCTION">功能异常</Option>
              <Option value="PRODUCT_PROPOSAL">产品建议</Option>
            </Select>
          </Form.Item>
          <Form.Item label="描述" rules={[{ required: true }]} required name='described'>
            <TextArea rows={3} placeholder="请详细描述下您的问题或建议"/>
          </Form.Item>
          {/* <Form.Item style={{textAlign:'right'}}>
            <Button type="text" style={{ border: 'none', height: 40, color: '#9B9B9B' }}>取消</Button>
            <Button type="primary" style={{ border: 'none', width: 100, height: 40 }} htmlType="submit">提交</Button>
          </Form.Item> */}
        </Form>
      </Modal>

      <Modal
        title="欢迎添加客服微信"
        centered
        width={600}
        visible={helpVisible}
        footer={null}
        onCancel={() => setHelpVisible(false)}
      >
        <div style={{ textAlign: 'center', paddingBottom: 20 }}>
          <p>
            <img src={Imgs.QRcode} />
          </p>
          <p>微信扫一扫添加客服咨询</p>
        </div>
      </Modal>
    </Sider>
  )
}

export default withRouter(SiderWrapper)
