/*
 * @Author: melon
 * @Date: 2020-07-14 09:51:47
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-22 15:56:43
 */
import { ImgCategory } from 'constants/Options'
import { map } from 'lodash/fp'
import Mock from 'mockjs'
const ImgCategoryValues = map(({ value }) => value)(ImgCategory)

const { Random } = Mock

export const getShowcaseCategoryData = ({ showcaseType = undefined }) =>
  Array.from({ length: Random.natural(20, 50) }, (v, i) => ({
    id: i + 1, // id
    showcaseType,
    name: Random.cword(5) + '分类', // 图片分类名称
    sort: i + 1,
  }))

export const MoveOrCopyCategory = Array.from(
  { length: Random.natural(1, 20) },
  (v, i) => ({
    id: i + 1, // id
    typeDesc: Random.ctitle(), //
    name: Random.cword(3) + '分类', // 图片分类名称
    type: ImgCategoryValues[Random.natural(0, 3)],
    photoCount: Random.natural(1, 100),
    sort: i + 1,
  })
)
