/*
 * @Author: melon
 * @Date: 2020-07-04 23:20:21
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-11 18:31:00
 */
import Mock from 'mockjs'
const { Random } = Mock
// 拓展mockjs
Mock.Random.extend({
  phone: function () {
    var phonePrefixs = ['132', '135', '189']
    return this.pick(phonePrefixs) + Mock.mock(/\d{8}/) //Number()
  },
})

// 登录用户信息
export const AccountData = {
  id: Random.id(),
  account: Random.phone(), // 账号
  avatar: '', // 头像key
  nickname: Random.cname(), // 用户昵称
  logo: '', // 商户Logo
  avatarPhotoInfo: {
    photoKey: 'string', // 图片Key
    photoUrl: [
      // 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      // 'http://photo.infotos.cn/logo4.jfif1594463001',
      // 'http://photo.infotos.cn/logo3.jfif1594463406',
      // 'http://photo.infotos.cn/iconMessage.png1594463431',
    ][Random.natural(0, 3)], // 图片完整Url
    photoSize: Random.natural(1, 500), // 图片大小(kb)
    photoFormat: ['png', 'jpg', 'jpeg'][Random.natural(0, 1)], // 图片格式
    photoWidth: Random.natural(1, 60), // 图片宽度(px)
    photoHeight: Random.natural(1, 60), // 图片高度(px)
  },
  mobile: Random.phone(),
  email: Random.email(),
  // sidebarColorType: ['LIGHT_COLOUR', 'DEEP_COLOUR'][Random.natural(0,1)],
}
