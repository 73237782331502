/*
 * @Author: melon
 * @Date: 2020-06-29 11:22:13
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-14 21:04:42
 */
import { Divider, Spin } from 'antd'
import { PAGE_PATH } from 'apis/Router'
import commonComponent from 'components/index'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import {
  getShowcaseSetting as _getInfo,
  editShowcaseSetting as _edit,
} from 'reduxModules/showcaseSetting'
import { saveWeb as _saveWeb, getWeb as _getWeb } from 'reduxModules/settings'
import WebSettingForm from '../Components/WebSettingForm'
import styles from './Setting.module.less'
import { flow, map, values, pick, filter, isEmpty, isNil } from 'lodash/fp'
const { MyMessage } = commonComponent

const WebSettings = (props, ref) => {
  const formRef = useRef()
  const [formData, setFormData] = useState({})
  const [requesting, setRequesting] = useState(false)
  const [webData, setWebData] = useState()
  const [defaultIndexType, setDefaultIndexType] = useState(undefined)

  const getWebData = async () => {
    const res = await _getWeb()
    if (res.code === 0) {
      setWebData(res.data)
    } else {
      MyMessage.error(res.msg)
    }
  }
  let history = useHistory()
  const getInfo = async () => {
    try {
      setRequesting(true)
      const res = await _getInfo()
      if ([0].includes(res.code)) {
        const {
          data: { formattedData },
        } = res
        setFormData({
          ...formattedData,
          language: formData.language,
        })
        setDefaultIndexType(formattedData.defaultIndexType)
      }
      return res
    } catch (error) {
      console.log(error)
    } finally {
      setRequesting(false)
    }
  }
  useEffect(() => {
    getInfo()
    getWebData()
  }, [])
  useImperativeHandle(ref, () => ({
    // onSaveForm 就是暴露给父组件的方法
    onSaveForm: () => {
      formRef.current
        .validateFields()
        .then(async (formValues) => {
          // formRef.current.resetFields()
          // 查看选择项是否全为隐藏
          const SwitchValues = flow(
            pick([
              'isShowIndex',
              'isShowWorks',
              'isShowCustomerPhoto',
              'isShowAbout',
              'isShowContact',
            ]),
            values
          )(formValues)
          const isAllHidden = flow(
            filter((item) => item),
            isEmpty
          )(SwitchValues)
          const noDefaultIndexType = isNil(defaultIndexType)

          // 若 不是全为隐藏 且没有设置默认首页 提醒选择默认首页
          if (!isAllHidden && noDefaultIndexType) {
            MyMessage.warning('请设置默认首页！')
          } else {
            const body = {
              ...formData,
              ...formValues,
              defaultIndexType: isAllHidden ? null : defaultIndexType,
            }
            try {
              const webRes = await _saveWeb({
                ...webData,
                language: formValues.language,
              })
              if (webRes.data && [0].includes(webRes.data.code)) {
                // 派发获取信息数据
                getWebData()
              } else {
                MyMessage.error('保存失败')
              }
            } catch(error) {}
            // 派发修改数据请求
            try {
              const resData = await _edit(body)
              if (resData.data && [0].includes(resData.data.code)) {
                MyMessage.success('保存成功')
                // 派发获取信息数据
                getInfo()
              } else {
                MyMessage.error('保存失败')
              }
            } catch (error) {}
          }
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
        })
    },
  }))
  const goToBusiness = () => {
    history.push(PAGE_PATH.settings.children.business)
  }
  return (
    <Spin tip="数据加载中" spinning={requesting}>
      {/* 网站基础设置 */}
      <div className={styles.web_setting_business}>
        <div className="Inf_page_title">网站信息</div>
        <div className="Inf_page_text" style={{fontSize:'13px'}}>
          说明：网站商业信息、品牌等基础信息，请前往{' '}
          <span className="Inf_page_blue_url" onClick={() => goToBusiness()} style={{fontSize:'13px'}}>
            商业信息设置
          </span>{' '}
          模块中进行设置。
        </div>
        <Divider />
      </div>
      <WebSettingForm
        formRef={formRef}
        formData={{ ...formData, ...webData}}
        defaultIndexType={defaultIndexType}
        setDefaultIndexType={setDefaultIndexType}
      />
    </Spin>
  )
}

export default forwardRef(WebSettings)
