import { ProjectStatus, VisitTypes, YesOrNoTypes,sourceTypes,DownloadImgSize } from 'constants/Options'
import { isEmpty, sortBy } from 'lodash'
import moment from 'moment'
import {
  getOption,
  getOptionBykeyName,
  initFormFeildDateValue,
} from 'utils/Tools'

export const ProjectCategorySelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = data.map((item) => ({
    key: item.id,
    ...item,
  }))
  return newData
}
// 添加项目类别
export const SaveProjectCategorySelector = (data) => {
  let newData = data.map((item, index) => ({
    id: item.id,
    name: item.name,
    sort: index,
  }))
  return newData
}

export const ProjectListSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = data.list.map((item) => ({
    key: item.id,
    ...item,
    img: item.coverPhotoIngo.photoUrl,
    shootingDay: item.shootingDay?moment(item.shootingDay).format('YYYY/MM/DD'):'',
    showLock: VisitTypes[1].key === item.visitType,
    statusDesc: getOption(item.status, 'label', ProjectStatus),
  }))
  newData.pagination= {
      current: data.pageNo,
      pageSize: data.pageSize,
      total: data.totalCount,
    }
  return newData
}

// 新增，修改调用一个selector，后端结构一致
export const AddProjectSelector = (data) => {
  console.log(data)
  let newData = {
    ...data,
    id: data.projectId, //项目id
    projectCategoryId: data.projectCategoryId || data.category, //项目分类
    shootingDay: data.shootingDay?moment(data.shootingDay).format('YYYY-MM-DD'):'', //拍摄时间
    loseDay: data.loseDay ? moment(data.loseDay).format('YYYY-MM-DD') : '', //失效时间
    name: data.name, //项目名称
    customerPhone: data.customerPhone, //客户电话
    customerName: data.customerName, //客户名称
    coverPhotoKey: data.coverPhotoKey
      ? data.coverPhotoKey
      : data.coverPhotoInfo
      ? data.coverPhotoInfo.photoKey
      : null, //封面图片key
    status: data.status, //项目状态
    uri: data.projectUri, // 项目地址
    isAllowShare: getOption(data.isAllowShare, 'value', YesOrNoTypes), //是否允许分享
    customerProvinceGeoId: data['areaArray'] ? data['areaArray'][0] : '',
    customerCityGeoId: data['areaArray'] ? data['areaArray'][1] : '',
    customerAreaGeoId: data['areaArray'] ? data['areaArray'][2] : '',
    isGuestPieceShow: getOption(data.isGuestPieceShow, 'value', YesOrNoTypes), //客片栏目是否显示该影集
    isAllowVisitorMeetingConditions: getOption(
      data.isAllowVisitorMeetingConditions,
      'value',
      YesOrNoTypes
    ), //访客访问需要通过关注微信访问
    isSetPhotoHidden: getOption(data.isSetPhotoHidden, 'value', YesOrNoTypes), //是否允许客户设置图片隐藏

    canDownload: getOption(data.canDownload, 'value', YesOrNoTypes), //是否允许下载照片
    isVisitorDownload: getOption(data.isVisitorDownload, 'value', YesOrNoTypes), //是否允许访客下载照片
    isDownloadCode: getOption(data.isDownloadCode, 'value', YesOrNoTypes), //下载是否需要验证码
    isShowBookmark: getOption(data.isShowBookmark, 'value', YesOrNoTypes), //是否显示收藏夹及相关功能
    isMessage: getOption(data.isMessage, 'value', YesOrNoTypes), //是否允许客户留言
    isVisitor: getOption(data.isVisitor, 'value', YesOrNoTypes), //是否允许仅客户访问
    isShowPrintStore: getOption(data.isShowPrintStore, 'value', YesOrNoTypes), //是否显示印坊PrintStore
    isRequestPhone: getOption(data.isRequestPhone, 'value', YesOrNoTypes), //是否允许请求访客手机号
    // bookmarkIsCollect: getOption(data.bookmarkIsCollect, 'value', YesOrNoTypes), //收藏夹是否允许收藏
    bookmarkIsMessage: getOption(data.bookmarkIsMessage, 'value', YesOrNoTypes), //收藏夹是否允许留言
    // isAuth2ShipDirectly: getOption(data.isAuth2ShipDirectly, 'value', YesOrNoTypes), //授权制造商将商品直接发送给客户
  }
  if(data.type=='Trail'){
    delete newData.isShowPrintStore
    delete newData.priceId
  }
  return newData
}

export const ProjectSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    ...data,
    shootingDay: initFormFeildDateValue(data, 'shootingDay'),
    loseDay: initFormFeildDateValue(data, 'loseDay'),
    isAllowShare: getOptionBykeyName(
      'value',
      data.isAllowShare,
      'key',
      YesOrNoTypes
    ), // 分享的照片 Y:是, N:否
    isGuestPieceShow: getOptionBykeyName(
      'value',
      data.isGuestPieceShow,
      'key',
      YesOrNoTypes
    ), //客片栏目是否显示该影集 Y:是, N:否

    isAllowVisitorMeetingConditions: getOptionBykeyName(
      'value',
      data.isAllowVisitorMeetingConditions,
      'key',
      YesOrNoTypes
    ), //访客访问需要通过关注微信访问
    isSetPhotoHidden: getOptionBykeyName(
      'value',
      data.isSetPhotoHidden,
      'key',
      YesOrNoTypes
    ), //是否允许客户设置图片隐藏
    areaArray: !isEmpty(data.customerProvinceGeoId)
      ? [
          data.customerProvinceGeoId,
          data.customerCityGeoId,
          data.customerAreaGeoId,
        ]
      : [], // 省市区
    canDownload: getOptionBykeyName(
      'value',
      data.canDownload,
      'key',
      YesOrNoTypes
    ),
    //是否允许访客下载
    isVisitorDownload: getOptionBykeyName(
      'value',
      data.isVisitorDownload,
      'key',
      YesOrNoTypes
    ),
    isDownloadCode: getOptionBykeyName(
      'value',
      data.isDownloadCode,
      'key',
      YesOrNoTypes
    ), //是否显示收藏夹及相关功能
    isShowBookmark: getOptionBykeyName(
      'value',
      data.isShowBookmark,
      'key',
      YesOrNoTypes
    ), //是否显示收藏夹及相关功能
    isMessage: getOptionBykeyName('value', data.isMessage, 'key', YesOrNoTypes), //是否显示收藏夹及相关功能
    isVisitor: getOptionBykeyName('value', data.isVisitor, 'key', YesOrNoTypes), //是否允许仅客户访问
    isShowPrintStore: getOptionBykeyName('value', data.isShowPrintStore,'key', YesOrNoTypes), //是否显示印坊PrintStore
    isRequestPhone: getOptionBykeyName('value', data.isRequestPhone,'key', YesOrNoTypes), //是否允许请求访客手机号
    bookmarkIsCollect: getOptionBykeyName('value', data.bookmarkIsCollect, 'key', YesOrNoTypes), //是否允许仅客户访问
    bookmarkIsMessage: getOptionBykeyName('value', data.bookmarkIsMessage, 'key', YesOrNoTypes), //是否允许仅客户访问
  }
  return newData
}

export const PhotoCategorySelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  const list = data.map((item, index) => ({
    key: index,
    ...item,
  }))
  newData.data = sortBy(list, (item) => item.sort)
  return newData
}

// 添加客片类别
export const SavePhotoCategorySelector = (data) => {
  let newData = {
    projectId: data.projectId,
    categoryList: data.categoryList.map((item, index) => ({
      id: item.id,
      name: item.name,
      sort: index,
    })),
  }
  return newData
}

export const PhotosListSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    pagination: {
      current: data.pageNo,
      pageSize: data.pageSize,
      total: data.totalCount,
    },
    list: data.list.map((img) => ({
      ...img,
      messageTime: isEmpty(img.messageTime)
        ? moment(img.messageTime).format('YYYY/MM/DD HH:mm')
        : '',
    })),
  }

  return newData
}

export const BookmarkListSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = data.map((item) => ({
    ...item,
    isShowExceedTip: getOptionBykeyName(
      'value',
      item.isShowExceedTip,
      'key',
      YesOrNoTypes
    ), //是否显示选片超量提示
  }))
  return newData
}

// 调整展示图片排序
export const CustormPhotoChangeSortSelector = (data) => {
  return data.map((id, index) => ({
    id,
    sort: index,
  }))
}

//分享邮件
export const shareEmailSelector = (res) => {
  let newData = {
    ...res.data,
  }
  return newData
}
//分享邮件
export const emailRecordsSelector = (res) => {
  let newData = {
    ...res.data,
  }
  return newData
}
//获取客片私有图片
export const HiddenListForPageSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    pagination: {
      current: data.pageNo,
      pageSize: data.pageSize,
      total: data.totalCount,
    },
    list: data.list.map((img) => ({
      ...img,
      messageTime: isEmpty(img.messageTime)
          ? moment(img.messageTime).format('YYYY/MM/DD HH:mm')
          : '',
    })),
  }
  return newData
}
//获取客片留言图片
export const MessageListForPageSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    pagination: {
      current: data.pageNo,
      pageSize: data.pageSize,
      total: data.totalCount,
    },
    list: data.list.map((img) => ({
      ...img,
      messageTime: isEmpty(img.messageTime)
          ? moment(img.messageTime).format('YYYY/MM/DD HH:mm')
          : '',
    })),
  }
  return newData
}
//查询项目浏览记录列表
export const ProjectRecordSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    pagination: {
      current: data.pageNo,
      pageSize: data.pageSize,
      total: data.totalCount,
    },
    list: data.list.map((item) => ({
      ...item,
      messageTime: isEmpty(item.messageTime)
          ? moment(item.messageTime).format('YYYY/MM/DD HH:mm')
          : '',
      createTime: isEmpty(item.createTime)
          ? moment(item.createTime).format('YYYY/MM/DD HH:mm')
          : '',
      downloadSize:getOptionBykeyName('key', item.downloadSize, 'label', DownloadImgSize),
      source:getOptionBykeyName('key', item.source, 'label', sourceTypes),
      nickName: item.visitorResultVO?item.visitorResultVO.nickName:'',
      headImgUrl: item.visitorResultVO?item.visitorResultVO.headImgUrl:'',
    })),
  }
  return newData
}

//根据项目ID获取收藏图片数量，隐藏图片数量，留言数量
export const ProjectStatisticsSelector = (res) => {
  let newData = {
    ...res.data,
  }
  return newData
}

export const  AddPresetSelector= (data) => {
  let newData = {
    ...data,
    isGuestPieceShow: getOption(data.isGuestPieceShow, 'value', YesOrNoTypes), //
    // isAllowVisitorMeetingConditions: getOption(data.isAllowVisitorMeetingConditions, 'value', YesOrNoTypes), //
    canDownload: getOption(data.canDownload, 'value', YesOrNoTypes), //
    isDownloadCode: getOption(data.isDownloadCode, 'value', YesOrNoTypes), //
    isVisitorMobile: getOption(data.isVisitorMobile, 'value', YesOrNoTypes), //
    isVisitorDownload: getOption(data.isVisitorDownload, 'value', YesOrNoTypes), //
    isAllowShare: getOption(data.isAllowShare, 'value', YesOrNoTypes), //
    isShowBookmark: getOption(data.isShowBookmark, 'value', YesOrNoTypes), //
    printingWorkshop: getOption(data.printingWorkshop, 'value', YesOrNoTypes),
    isMessage: getOption(data.isMessage, 'value', YesOrNoTypes),
    isSetPhotoHidden: getOption(data.isSetPhotoHidden, 'value', YesOrNoTypes),
    isVisitor: getOption(data.isVisitor, 'value', YesOrNoTypes),
    isShowPrintStore: getOption(data.isShowPrintStore, 'value', YesOrNoTypes),
    isRequestPhone: getOption(data.isRequestPhone, 'value', YesOrNoTypes),
  }
  return newData
}
export const ListPresetSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    list: data.map((img) => ({
      ...img,
      createTime: isEmpty(img.createTime)
          ? moment(img.createTime).format('YYYY/MM/DD HH:mm')
          : '',
      isGuestPieceShow: getOptionBykeyName('value', img.isGuestPieceShow, 'key', YesOrNoTypes), //
      isAllowVisitorMeetingConditions: getOptionBykeyName('value', img.isAllowVisitorMeetingConditions, 'key', YesOrNoTypes), //
      canDownload: getOptionBykeyName('value', img.canDownload, 'key', YesOrNoTypes), //
      isDownloadCode: getOptionBykeyName('value', img.isDownloadCode, 'key', YesOrNoTypes), //
      isVisitorMobile: getOptionBykeyName('value', img.isVisitorMobile, 'key', YesOrNoTypes), //
      isVisitorDownload: getOptionBykeyName('value', img.isVisitorDownload, 'key', YesOrNoTypes), //
      isAllowShare: getOptionBykeyName('value', img.isAllowShare, 'key', YesOrNoTypes), //
      isShowBookmark: getOptionBykeyName('value', img.isShowBookmark, 'key', YesOrNoTypes), //
      printingWorkshop: getOptionBykeyName('value', img.printingWorkshop, 'key', YesOrNoTypes), //
      isMessage: getOptionBykeyName('value', img.isMessage, 'key', YesOrNoTypes), //
      isSetPhotoHidden: getOptionBykeyName('value', img.isSetPhotoHidden, 'key', YesOrNoTypes), //
      isVisitor: getOptionBykeyName('value', img.isVisitor, 'key', YesOrNoTypes), //
      isShowPrintStore: getOptionBykeyName('value', img.isShowPrintStore, 'key', YesOrNoTypes),
      isRequestPhone: getOptionBykeyName('value', img.isRequestPhone, 'key', YesOrNoTypes),
    }))
  }
  return newData
}
