import {
  Button,
  Col,
  Divider, Form, Input,
  Layout,
  Modal,
  PageHeader,
  Row,
  Spin,
  Typography,
} from 'antd'
import components from 'components'
import download from 'downloadjs'
import { indexOf, isEmpty, uniq } from 'lodash'
import React, {useRef, useState} from 'react'
// import { Masonry } from 'react-virtualized'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import InfiniteScroll from 'react-infinite-scroller'
import styles from './photoPage.module.less'
import classNames from "classnames";

const {
  MoveOrCopyModal,
  PreviewCarouselModal,
  message,
  InfoEmptyData,
} = components
const { Header, Footer, Sider, Content } = Layout
const { Paragraph } = Typography
export default class PhotoPage extends React.Component {
  state = {
    checkedList: [], //  选中的图片
    showName: false,
    moveOrCopyModalVisible: false,
    deleteModalVisible: false, // 删除弹窗
    deleting: false, // 删除中
    moving: false, // 移动中
    coping: false, // 粘贴中
    categoryList: [],
    previewCarouselModalVisible: false, // 查看图片弹窗
    previewList: [], // 查看弹窗图片list
    previewCurrent: 0, // 查看弹窗当前siderNumber
    uploadModalVisible: true, //上传
  }
  componentDidMount() {}

  // 获取移动/复制类别列表
  getMoveOrCopyCategory = async () => {
    const { _getCategoryForMove, projectId, selectedCategory } = this.props
    const res = await _getCategoryForMove({ projectId: projectId })
    if (res.code === 0) {
      const categoryList = res.data.filter(
        (category) => category.id !== selectedCategory.key
      )
      this.setState({
        categoryList: categoryList,
      })
    } else {
      message.error(res.msg)
    }
  }

  // 选择
  doSelect = (item) => {
    const { checkedList } = this.state
    const index = indexOf(checkedList, item)
    if (index < 0) {
      checkedList.push(item)
      uniq(checkedList)
    } else {
      checkedList.splice(index, 1)
    }
    this.setState(checkedList)
  }
  // 选择全部
  selectAll = () => {
    const {
      photosData: { list = [] },
    } = this.props
    const allList = list.concat()
    this.setState({ checkedList: allList })
  }
  // 取消全选
  removeAll = () => {
    this.setState({ checkedList: [] })
  }
  // 显示文件名称
  showFileName = () => {
    const { showName } = this.state
    this.setState({ showName: !showName })
  }

  // 显示移动/复制弹窗
  showMoveOrCopyModal = async () => {
    if (this.state.categoryList.length < 1) {
      this.getMoveOrCopyCategory()
    }
    this.setState({
      moveOrCopyModalVisible: true,
    })
  }
  // 隐藏移动/复制弹窗
  hideMoveOrCopyModal = () => {
    this.setState({
      moveOrCopyModalVisible: false,
    })
  }
  // 移动
  onMove = async ({ targetCategoryId, categoryType }) => {
    const { checkedList } = this.state
    const { _moveOrCopyPhotos, reloadData } = this.props
    const ids = checkedList.map((item) => item.id)
    const body = {
      ids,
      targetCategoryId,
      categoryType,
      moveType: 'MOVE',
    }
    let res = {}
    // 派发修改数据请求
    try {
      await this.setState({
        moving: true,
      })
      res = await _moveOrCopyPhotos(body)
      await this.setState({
        moving: false,
      })
      if (res.data && [0].includes(res.data.code)) {
        message.success('移动成功')
        reloadData() // 重新获取数据
        // 清空选择的数据
        this.setState({
          checkedList: [],
        })
        // 派发获取列表数据
        // 关闭弹窗
        this.hideMoveOrCopyModal()
      } else {
        message.error('移动失败')
      }
    } catch (error) {
      res = {}
    }
    return res
  }

  // 复制
  onCopy = async ({ targetCategoryId, categoryType }) => {
    const { checkedList } = this.state
    const { _moveOrCopyPhotos, reloadData } = this.props
    const ids = checkedList.map((item) => item.id)
    const body = {
      ids,
      targetCategoryId,
      categoryType,
      moveType: 'COPY',
    }
    let res = {}
    // 派发修改数据请求
    try {
      await this.setState({
        coping: true,
      })
      res = await _moveOrCopyPhotos(body)
      await this.setState({
        coping: false,
      })
      if (res.data && [0].includes(res.data.code)) {
        message.success('复制成功')
        reloadData()
        // 清空选择的数据
        this.setState({
          checkedList: [],
        })
        // 派发获取列表数据
        // 关闭弹窗
        this.hideMoveOrCopyModal()
      } else {
        message.error('复制成功')
      }
    } catch (error) {
      res = {}
    }
    return res
  }
  // 显示删除框
  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    })
  }
  // 隐藏删除框
  hideDeleteModal = () => {
    this.setState({
      deleteModalVisible: false,
    })
  }
  // 设为封面
  setCover = () => {
    const { checkedList } = this.state
    const { _setCover, reloadData } = this.props
    const id = checkedList[0].id
    const body = {
      id,
    }
    this.props._setCover(body)
  }
  // 删除
  onRemove = async () => {
    const { checkedList } = this.state
    const { _removePhotos, reloadData } = this.props
    const ids = checkedList.map((item) => item.id)
    // 派发删除请求
    const body = {
      ids,
    }
    // 派发修改数据请求
    try {
      await this.setState({
        deleting: true,
      })
      const resData = await _removePhotos(body)
      await this.setState({
        deleting: false,
      })
      if (resData.data && [0].includes(resData.data.code)) {
        message.success('删除成功')
        // 清空选择的数据
        this.setState({
          checkedList: [],
        })
        // 派发获取列表数据
        reloadData() // 重新获取数据
        // 关闭弹窗
        this.hideDeleteModal()
      } else {
        message.error('删除失败')
      }
    } catch (error) {}
  }
  // 显示查看弹窗
  showPreviewCarouselModal = async () => {
    const { checkedList } = this.state
    const { list = [] } = this.props
    this.setState({
      previewCarouselModalVisible: true,
      previewList: checkedList.length > 0 ? checkedList : list,
      previewCurrent:
        checkedList.length > 0
          ? 0
          : list.findIndex((item) => checkedList[0].id === item.id),
    })
  }
  // 隐藏查看弹窗
  hidePreviewCarouselModal = () => {
    this.setState({
      previewCarouselModalVisible: false,
      previewList: [],
      previewCurrent: 0,
    })
  }
  // 添加找类别照片
  showAddPhotos = () => {
    this.props.onShow()
  }
  hiddenUploadModalVisible = () => {
    this.setState({ uploadModalVisible: false })
  }
  // 下载图片
  downloadImags = async () => {
    const { checkedList } = this.state
    const { _packagePhotos } = this.props
    const ids = checkedList.map((item) => item.id)
    const res = await _packagePhotos({ ids })
    if (res.data.code == 0) {
      const { data } = res.data
      download(data.packageDownloadUrl)
    }
  }
  onSortEnd = async ({ oldIndex, newIndex }) => {
    const {
      photosData: { pagination = {}, list = [] },
      setPhotosData,
      _changeSort,
    } = this.props
    const newList = arrayMove(list, oldIndex, newIndex)
    const newSortIds = newList.map((img) => img.id)

    const res = await _changeSort(newSortIds)
    if (res.data.code == 0) {
      setPhotosData({
        pagination: pagination,
        list: newList,
      })
    } else {
      message.error('系统问题！请联系管理员！')
    }
  }
  render() {
    const {
      selectedCategory,
      photosData: { pagination = {}, list = [] },
      loadData = () => {}, //读取分页数据
      loading,
      hasMore,
    } = this.props
    const {
      checkedList,
      showName,
      moveOrCopyModalVisible,
      categoryList,
      deleteModalVisible,
      deleting,
      moving,
      coping,
      previewCarouselModalVisible,
      previewList,
      previewCurrent,
      uploadModalVisible,
    } = this.state
    const beNotSelected = checkedList.length < 1
    const SortableContainer = sortableContainer(({ children }) => {
      return <>{children}</>
    })
    const SortableItem = sortableElement(({ img }) => {
      return (
        <Col key={img.id} className={styles.photoItem}>
          <div key={img.id} onClick={this.doSelect.bind(this, img)}>
            <div
              className={`${styles.photo} ${
                checkedList.includes(img) ? styles.selected : ''
              }`}
              style={{
                width: 130,
                height: 130,
              }}
            >
              <img
                draggable="false"
                src={img.thumbInfo['thumb'].photoUrl}
                className={styles.image}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
              {/* 喜欢与留言标记 */}
              <div className={styles.imgMessage}>
                  {img.likeMarkCount>0?(
                    <span className={styles.commented}>
                      <span className="iconfont iconico_favmarked_s"></span>
                      <span className={styles.count}>{img.likeMarkCount}</span>
                    </span>
                  ):''}
                  {/* {isEmpty(img.message) ? null : (
                    <span className={styles.commented}>
                    <span className="iconfont iconico_comtmarked_s"></span>
                  </span>
                  )} */}
               </div>

            </div>
            <div
              className={styles.photoName}
              style={{ visibility: showName ? '' : 'hidden' }}
              title={img.photoName}
            >
              <Paragraph ellipsis={{ rows: 2 }}>{img.photoName}</Paragraph>
            </div>
          </div>
        </Col>
      )
    })
    const clearAll = () => {
      this.props.searchInput.current.state.value = '';
      this.props._setIsSearch(false)
      this.props._setSearchText('')
    }
    const changeName = (event) => {
      console.log(event.target.value)
      this.props._setSearchText(event.target.value)
      // e.persist()
      // setSelectedCategory({
      //   key: selectedCategory.key,
      //   name: event.target.value,
      //   projectCategoryId: selectedCategory.projectCategoryId,
      //   sortEnumC: selectedCategory.sortEnumC,
      //   sortEnum: selectedCategory.sortEnum,
      //   status: selectedCategory.status
      // })
    }
    return (
      <div>
        <Header className="sub-header">
          <Spin spinning={loading}>
            <PageHeader
              ghost={false}
              title={
                <div style={{ fontWeight: 'bold' }}>
                  {selectedCategory.name}
                </div>
              }
              style={{
                padding: '24px 50px 23px',
              }}
              extra={[
                <Button
                  type="primary"
                  key="addPhoto"
                  onClick={this.showAddPhotos}
                >
                  添加照片
                </Button>,
              ]}
            ></PageHeader>
          </Spin>
        </Header>
        {(list.length <= 0 && !loading && !isEmpty(pagination)&&!this.props.searchText) && (
          <div style={{ marginTop: 200 }}>
            <InfoEmptyData description="该分类暂无数据" />
            <span className={styles.remarkTip}>点击右上角添加照片开始</span>
          </div>
        )}
        {(list.length > 0 || this.props.searchText)&&(
          <Content
            className={`sub-content ${styles.content}`}
            style={{
              overflowX: 'hidden',
              height: '100vh',
            }}
          >
            <div className={styles.topArea}>
              <div>
                <span className={styles.imgCount}>
                  {pagination.total}张照片
                  {beNotSelected ? null : `/${checkedList.length}个选择`}
                </span>
                <span style={{ display: beNotSelected ? 'none' : '' }}>
                  <Divider type="vertical" />
                  <Button
                    type="link"
                    className="link-btn"
                    onClick={this.selectAll}
                  >
                    全选
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    type="link"
                    className="link-btn"
                    onClick={this.removeAll}
                  >
                    取消选择
                  </Button>
                </span>
                <Divider type="vertical" />
                <Button
                  type="link"
                  className="link-btn"
                  onClick={this.showFileName}
                  //disabled={beNotSelected}
                >
                  {showName ? '隐藏' : '显示'}文件名
                </Button>
                <Divider type="vertical" />
                {!this.props.isSearch?(
                  <Button type="link" className="link-btn" onClick={() => this.props._setIsSearch(true)}>搜索</Button>
                ):(
                  <Input
                    className={this.props.bg || selectedCategory.name.length > 0 ? styles.searchs : styles.search}
                    placeholder="输入文件名搜索..."
                    onKeyUp={changeName}
                    defaultValue={this.props.searchText}
                    ref={this.props.searchInput}
                    onBlur={() => this.props._setBg(false)}
                    onFocus={() => this.props._setBg(true)}
                    // prefix={<Tooltip><SearchOutlined style={{color: 'rgba(0,0,0,.45)'}}/></Tooltip>}
                    prefix={<iconicon_search className="iconfont iconicon_search" SearchOutlined style={{color: 'rgba(0,0,0,.45)'}}/>}
                    suffix={
                      selectedCategory.name.length > 0 ?
                        <span
                          className={classNames('iconfont iconbtn_flclose', styles.clearInput)}
                          onClick={clearAll}></span>
                        : ''
                    }
                  />
                )}
                {/* <Divider type="vertical" />
                <Button type="link" className="link-btn">
                  购买
                </Button> */}
              </div>
              <div>
                <span style={{ display: beNotSelected ? 'none' : '' }}>
                  <Button
                    type="link"
                    className="link-btn"
                    disabled={beNotSelected}
                    onClick={this.showPreviewCarouselModal}
                  >
                    查看
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    type="link"
                    className="link-btn"
                    disabled={beNotSelected}
                    onClick={this.showMoveOrCopyModal}
                  >
                    移动/复制
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    type="link"
                    className="link-btn"
                    disabled={beNotSelected || checkedList.length > 1}
                    onClick={this.setCover}
                  >
                    设为封面
                  </Button>
                  {/* <Divider type="vertical" />
                  <Button
                    type="link"
                    className="link-btn"
                    disabled={beNotSelected}
                    onClick={this.downloadImags}
                  >
                    下载
                  </Button> */}
                  <Divider type="vertical" />
                  <Button
                    type="link"
                    className="link-btn"
                    disabled={beNotSelected}
                    onClick={this.showDeleteModal}
                  >
                    删除
                  </Button>
                </span>
              </div>
            </div>
            <InfiniteScroll
              initialLoad={false}
              pageStart={1}
              loadMore={loadData}
              hasMore={!loading && hasMore}
              useWindow={false}
              style={{ marginTop: 40 }}
            >
              <SortableContainer
                onSortEnd={this.onSortEnd}
                axis="xy"
                distance={5}
              >
                <Row gutter={24}>
                  {list.map((img, index) => (
                    <SortableItem
                      key={`item-${index}`}
                      index={index}
                      img={img}
                    />
                  ))}
                </Row>
              </SortableContainer>
              <Row justify={'center'}> {loading && hasMore && <Spin />}</Row>
              {/* <div style={{ height: '60vh' }}></div> */}
            </InfiniteScroll>
          </Content>
        )}
        {/* 移动/复制弹窗 */}
        <MoveOrCopyModal
          visible={moveOrCopyModalVisible}
          onCancel={this.hideMoveOrCopyModal}
          data={categoryList}
          onMove={this.onMove}
          moveLoading={moving}
          onCopy={this.onCopy}
          copyLoading={coping}
          isPage={true}
        />

        <Modal
          visible={deleteModalVisible}
          title="删除照片"
          okText="确定"
          cancelText="取消"
          centered
          onCancel={this.hideDeleteModal}
          onOk={this.onRemove}
          confirmLoading={deleting}
          maskClosable={false}
          width={600}
          closable={false}
          cancelButtonProps={{className: 'footerCanceltext'}}
        >
          <div className="Inf_page_title">
            所选图片与相关记录将会被移除，20天内可在回收站恢复，确定继续？
          </div>
        </Modal>

        {/* 查看弹窗 */}
        <PreviewCarouselModal
          visible={previewCarouselModalVisible}
          onCancel={this.hidePreviewCarouselModal}
          list={previewList}
          key={previewList}
          previewCurrent={previewCurrent}
          showKey={'photoName'}
        />
      </div>
    )
  }
}
