/*
 * @Author: melon
 * @Date: 2020-08-11 16:41:37
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-13 12:04:25
 */
import moment from 'moment'
import { isEmpty, map, isNil } from 'lodash/fp'
// 获取暂存列表
export const StorageListSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data

  newData.data = {
    list: data,
    formattedData: {
      list:
        isEmpty(data) || isEmpty(data.list)
          ? []
          : map((item) => ({
              ...item,
              name: item.projectName, // 项目名称
              src: !isEmpty(item.projectCoverPhotoInfo)
                ? item.projectCoverPhotoInfo.photoUrl
                : '',
              description: item.projectDesc, // 项目描述
              typeDesc: item.typeDesc, // 类别描述
              time: !isNil(item.updateTime)
                ? moment(item.updateTime).format('YYYY/MM/DD HH:mm')
                : '', // 最后更新时间
            }))(data.list),
      pagination: isEmpty(data)
        ? {
            pageNo: 1,
            current: 1,
            pageSize: 10,
            total: 0,
          }
        : {
            pageNo: Number(data.pageNo),
            current: Number(data.pageNo),
            pageSize: Number(data.pageSize),
            total: Number(data.totalCount),
          },
    },
  }

  return newData
}

// 获取购物车列表
export const CartListSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    list: data,
    formattedData: {
      list: isEmpty(data)
        ? []
        : map((item) => ({
            ...item,
            name: item.projectName, // 项目名称
            src: !isEmpty(item.projectCoverPhotoInfo)
              ? item.projectCoverPhotoInfo.photoUrl
              : '',
            description: item.projectDesc, // 项目描述
            price: item.price,
            amount: item.quantity,
            money: item.totalAmount,
          }))(data),
    },
  }

  return newData
}

// 添加到购物车
export const CartAddSelector = (data) => {
  return {
    ...data,
  }
}

// 删除购物车
export const CartRemoveSelector = (data) => {
  return {
    ...data,
  }
}
