import React, {Component, useState} from 'react'
import {Modal} from 'antd'
import styles from './DialogWtihIcon.module.less'

const titleModel = (props) => {
    const { visible,title, htmlTexts,hidetit} = props
    const hide=()=>{
        hidetit();
    }
    return (
        <Modal
            visible={visible}
            centered
            title=''
            closable={false}
            maskClosable={false}
            width={600}
            footer={[]}
            className={styles.dialogBox}
        >
            <div className={styles.theIcon}>
                <span className="icon iconfont iconico_remind" style={{fontSize:'50px',color:'#C1502A'}}></span>
                <div className={styles.title}>{title}</div>
                <div>{htmlTexts}</div>
                <div className={styles.submitButton} onClick={hide}>继续</div>
                <div className={styles.remarkText}>如需联系客服请点击帮助</div>
            </div>
        </Modal>
    )
}
export default titleModel
