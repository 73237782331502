//销售收益
import {
  Button,
  Layout,
  Menu,
  Divider,
  Dropdown,
  PageHeader,
  Spin,
  Select,
  Col,
  Row,
  Input,
  Tooltip,
  Card,
  Table,
  Pagination,
  Modal,
  Form, DatePicker
} from 'antd'
import {InfoCircleOutlined, UserOutlined, SearchOutlined,CloseCircleFilled} from '@ant-design/icons';

import components from 'components'
import {ProjectStatus, ArrangementTypes} from 'constants/Options'
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import { PAGE_PATH } from 'apis/Router'
import {
  getInfoClient as _getInfoClient,
  editClient as _editClient,
  addClient as _addClient,
  removeClient as _removeClient,
} from 'reduxModules/client'
import {
  addOrderAdd as _addOrderAdd,
  deliverGoods as _deliverGoods,
  getOrderAll as _getOrderAll,
  getOrderDetails as _getOrderDetails,
} from 'reduxModules/store'
import CommonComponent from 'components/index'
import classNames from "classnames";
import myMessage from 'components/Message'
import styles from './earnings.module.less'
import { orderType } from 'constants/Options'
import {
  getOptionBykeyName,
} from 'utils/Tools'
import Imgs from 'imgs'
import moment from "moment";
import {map} from "lodash/fp";
const {Header, Footer, Sider, Content} = Layout
const { MyMessage, InfoEmptyData } = CommonComponent
// const {ClientProfoto} = Imgs

const Earnings = ({name,ref}) => {
  let history = useHistory()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [selectedOrder, setSelectedOrder] = useState({
    sourceEnum: '', //来源
    statusText: '全部', //来源
    status: '', //分组
    research:'',//邮件/名称/电话
    pageNo:1,
    pageSize:10,
    current:1,
    totalCount:20
  })
  useEffect(() => {
    getListByClient()
  }, [])
  // 获取订单列表
  const getListByClient = async () => {
    setLoading(true)
    // const res = await _getInfoClient({})
    let data={
      pageNum: selectedOrder.pageNo,
      pageSize: selectedOrder.pageSize,
      storeId: sessionStorage.getItem('user')
        ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
        :null
    }
    if(selectedOrder.research){
      data.research=selectedOrder.research
    }
    if(selectedOrder.status){
      data.status=selectedOrder.status
    }
    const res = await _getOrderAll(data)
    setLoading(false)
    if (res.code === '000000') {
      console.log(res.data)
      setOrderList(res.data.records)
    } else {
      myMessage.error(res.mesg)
    }
  }
  const columns = [
    {
      title: '来源订单',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (_, item) =>  {return (
        <div style={{fontWeight: 'bold'}}>
          {item.orderNum}
        </div>
      )},
    },
    {
      title: '收益额',
      dataIndex: 'totalMoney',
      key: 'totalMoney',
      render: (_, item) =>  {return (
        <div>
          ￥{item.totalMoney}
        </div>
      )},
    },
    {
      title: '发生日期',
      dataIndex: 'orderDate',
      key: 'orderDate',
      render: (_, item) =>  {return (
        <div>
          {`${item.orderDate.replace(/-/g,'/').split(':')[0]}:${item.orderDate.replace(/-/g,'/').split(':')[1]}`}
        </div>
      )},
    },
    {
      title: '类别',
      dataIndex: 'orderSource',
      key: 'orderSource',
    },
    {
      title: '状态',
      dataIndex: 'statusText',
      key: 'statusText',
      align: 'right',
      /*render: (_, item) => {
          // if (+item.rolesId === 1) return ''
          return (
              <div>
                  <Dropdown
                      overlay={
                          <div className={styles.settingsMenu}>
                              <Menu>
                                  {/!* <Menu.Item><a key='0' onClick={() => handleItem('停用', item)}>停用</a></Menu.Item> *!/}
                                  {/!* <Menu.Item><a key='1' onClick={() => handleItem('添加客片', item)}>添加客片项目</a></Menu.Item>
                                  <Menu.Item><a key='2' onClick={() => handleItem('添加消费', item)}>添加消费记录</a></Menu.Item>
                                  <Menu.Item><a key='3' onClick={() => handleItem('添加标签', item)}>添加标签</a></Menu.Item>
                                  <Menu.Divider /> *!/}
                                  <Menu.Item><a key='4' onClick={() => handleItem('编辑', item)}>编辑</a></Menu.Item>
                                  <Menu.Divider />
                                  <Menu.Item><a key='5' onClick={() => handleItem('删除', item)}>删除</a></Menu.Item>
                              </Menu>
                          </div>
                      }
                      placement="bottomRight"
                      trigger={['click']}>
                      <span className="iconfont iconbtn_list_set"></span>
                  </Dropdown>
              </div>
          )
      }*/
    },
  ]
  return (
    <Layout>
      <PageHeader
        ghost={false}
        className={styles.titleBar}
        title={
          <div>
            首页
          </div>
        }
      ></PageHeader>
      <Card bordered={false} className={styles.earnings}>
        <Spin tip="数据加载中" spinning={loading}>
          <div className={styles.saleProceeds}>
            <div className={styles.sale}>
              <div className={styles.title}><span className="iconfont iconico_sales"></span>年销售总额</div>
              <div className={styles.earnTotal}>￥ <span>30822.00</span></div>
              <div className={styles.desc}>其中自有服务销售总额￥1200元</div>
              <div className={styles.forOrder}>来源于<span>98</span>个订单</div>
            </div>
            <div className={styles.flexs}></div>
            <div className={styles.proceeds}>
              <div className={styles.title}><span className="iconfont iconico_earnings"></span>当前收益</div>
              <div className={styles.earnTotal}>￥ <span>800.00</span></div>
              <div className={styles.desc}>总额至少达￥500，则可每月申请提现。<span><Link
                to={PAGE_PATH.store.WithdrawRecord}
              >查看提现记录</Link></span></div>
              <div className={styles.withdraw}>申请提现</div>
            </div>
          </div>
          <div className={styles.hearders}>
            <div className={styles.title}>最近订单收益（显示20条）</div>
            <div className={styles.more}><Link
              to={PAGE_PATH.store.storeEarningsRecord}
            >查看更多</Link></div>
          </div>
          {orderList.length > 0 && (
            <Table
              // className={styles.tablesbody}
              style={{marginBottom: '30px'}}
              dataSource={orderList}
              columns={columns}
              pagination={false}
            />
          )}
        </Spin>
      </Card>

    </Layout>
  )
}

export default Earnings
