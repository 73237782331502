/*
 * @Author: melon
 * @Date: 2020-06-28 21:12:00
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-22 11:26:49
 */
import { Button, Col, Form, Input, Row } from 'antd'
import { PAGE_PATH } from 'apis/Router'
import classNames from 'classnames'
import myMessage from 'components/Message/index'
import { TrimInput, TrimPswInput } from 'components/WithTrim/index'
import React from 'react'
import {
  checkPhone as _checkPhone,
  getVerifyCode as _getVerifyCode,
} from 'reduxModules/member'
import { CheckPhoneSelector } from 'selectors/Register/RegisterSelectors'
import {
  phonePattern,
  PhoneValidateRule,
  PswValidateRule,
} from 'utils/ValidateRulesAndMsg'
import styles from './Form.module.less'
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
}

const tailLayout = {
  wrapperCol: { span: 24 },
}

class RegisterForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 60, // 秒数初始化为60秒
      getCodeChange: true, // 文案默认为 '获取短信验证码'
      btnRegisterLoading: false,
    }
  }
  formRef = React.createRef()
  onFinish = async (values) => {
    // console.log('Success:', values)
    const { doSubmit, history } = this.props
    await this.setState({
      btnRegisterLoading: true,
    })
    try {
      const res = await doSubmit(values)
      // 跳转登录页
      if (res.data && [0].includes(res.data.code)) {
        setTimeout(() => {
          history.push(PAGE_PATH.login)
        }, 1000)
      }
    } catch (error) {
    } finally {
      await this.setState({
        btnRegisterLoading: false,
      })
    }
  }

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  goLogin = () => {
    const { history } = this.props
    history.push(PAGE_PATH.login)
  }
  // 获取验证码
  getMessageCode = async () => {
    let state = this.state
    this.formRef.current
      .validateFields(['mobile'])
      .then(async (values) => {
        if (!state.getCodeChange) {
          return
        }
        // 派发请求
        try {
          const { mobile } = values
          const res = await _getVerifyCode({ mobile })
          if (res.data && [0].includes(res.data.code)) {
            myMessage.success('获取验证码成功')
            let count = state.count
            const timer = setInterval(() => {
              this.setState(
                {
                  getCodeChange: false,
                  count: count--,
                },
                () => {
                  if (count === 0) {
                    clearInterval(timer)
                    this.setState({
                      getCodeChange: true,
                      count: 60,
                    })
                  }
                }
              )
            }, 1000)
          } else {
            myMessage.error(res.data.msg || '系统繁忙')
          }

          return res
        } catch (error) {
          myMessage.error('系统繁忙')
        } finally {
        }
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo)
      })
  }
  render() {
    const { count, getCodeChange } = this.state
    let getCodeText = '获取短信验证码'
    if (!getCodeChange) {
      getCodeText = count + 's'
    }
    return (
      <div className={classNames(styles.register_form_wrapper)}>
        <Form
          className={classNames(styles.register_form)}
          {...layout}
          ref={this.formRef}
          name="register"
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          validateTrigger="onSubmit"
        >
          <Input type="password" hidden autoComplete="new-password" />

          <div className={styles.Title}>注册即可免费使用</div>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="品牌名称"
                name="enterpriseName"
                rules={[{ required: true, message: '请输入品牌名称!' }]}
                className='hiddenStar'
              >
                <TrimInput className={styles.user_register_input} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="手机号"
                name="mobile"
                hasFeedback
                rules={[
                  { required: true, message: '请输入手机号!' },
                  {
                    ...PhoneValidateRule,
                  },
                  ({ getFieldValue }) => ({
                    validator: async (rule, value) => {
                      if (value && phonePattern.test(value)) {
                        await _checkPhone({ mobile: value }).then((res) => {
                          const {
                            data: { data: resData = {}, code },
                          } = res
                          const { canVerify } = CheckPhoneSelector(resData)
                          if (res.data && [0].includes(code) && !canVerify) {
                            return Promise.reject('该手机号已经被注册')
                          } else {
                            return Promise.resolve()
                          }
                        })
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
                className='hiddenStar'
              >
                <TrimInput
                  // onChange={this.onChangeMobile}
                  className={styles.user_register_input}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="verifyCode"
                label="验证码"
                rules={[{ required: true, message: '请输入验证码' }]}
                className='hiddenStar'
              >
                <TrimInput
                  className={styles.user_register_input}
                  style={{ width: '100%' }}
                  addonAfter={
                    <Button
                      type="link"
                      htmlType="button"
                      style={{ color: '#1AA77D', fontSize: 12 }}
                      onClick={this.getMessageCode}
                    >
                      {getCodeText}
                    </Button>
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="密码"
                name="password"
                rules={[
                  { required: true, message: '请输入您的密码!' },
                  { ...PswValidateRule },
                ]}
                className='hiddenStar'
              >
                <TrimPswInput
                  placeholder="须由6位以上的数字与字母组成"
                  className={styles.user_register_input}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <p className={styles.register_attention}>
                注册或登录即表示同意 <a href={'https://www.infotos.co/privacy.html'} target={'_blank'}>隐私政策</a> 和 <a href={'https://www.infotos.co/termsofservice.html'} target={'_blank'}>服务条款</a> 。
              </p>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} className={styles.register_btn_wrapper}>
              <div>
                已有帐户？
                <Button
                  type="link"
                  htmlType="button"
                  onClick={this.goLogin}
                  className={styles.reset_psw_btn_item}
                >
                  登录
                </Button>
              </div>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.register_btn}
              >
                注册
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default RegisterForm
