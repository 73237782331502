/*
 * @Author: melon
 * @Date: 2020-07-02 16:10:25
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-22 11:25:46
 */

import { Button, Checkbox, Col, Form, Input, Row } from 'antd'
import { PAGE_PATH } from 'apis/Router'
import classNames from 'classnames'
import { TrimInput, TrimPswInput } from 'components/WithTrim/index'
import React from 'react'
import {
  LoginAccountValidateRule,
  PswValidateRule,
} from 'utils/ValidateRulesAndMsg'
import styles from './Form.module.less'

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
}

const tailLayout = {
  wrapperCol: { span: 24 },
}
class LoginForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      btnLoginLoading: false,
    }
  }
  onFinish = async (values) => {
    const { doSubmit, history } = this.props
    await this.setState({
      btnLoading: true,
    })
    try {
      const res = await doSubmit(values)
      // 跳转首页
      if (res.data && [0].includes(res.data.code)) {
        setTimeout(() => {
          history.push(PAGE_PATH.home)
        }, 500)
      }
    } catch (error) {
    } finally {
      await this.setState({
        btnLoading: false,
      })
    }
  }

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  goRestPsw = () => {
    const { history } = this.props
    history.push(PAGE_PATH.forgetPsw)
  }

  render() {
    const { btnLoading } = this.state
    const { formData } = this.props

    return (
      <div className={classNames(styles.login_form_wrapper)}>
        <Form
          className={classNames(styles.login_form)}
          {...layout}
          name="login"
          initialValues={formData}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          validateTrigger="onSubmit"
        >
          <Input type="password" hidden autoComplete="new-password" />
          <div className={styles.Title}>登录</div>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="手机/Email"
                name="account"
                rules={[
                  { required: true, message: '请输入您的手机号或者Email地址!' },
                  LoginAccountValidateRule,
                ]}
                className='hiddenStar'
              >
                <TrimInput className={styles.user_login_input} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="密码"
                name="password"
                rules={[
                  { required: true, message: '请输入您的密码!' },
                  { ...PswValidateRule },
                ]}
                className='hiddenStar'
              >
                <TrimPswInput className={styles.user_login_input} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                {...tailLayout}
                name="remember"
                valuePropName="checked"
              >
                <Checkbox>记住我</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} className={styles.login_btn_wrapper}>
              <Button
                type="link"
                htmlType="button"
                onClick={this.goRestPsw}
                className={styles.login_btn_item}
              >
                忘记密码？
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.login_btn}
                loading={btnLoading}
              >
                登录
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default LoginForm
