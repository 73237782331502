import {
    Button,
    Checkbox,
    Col,
    ConfigProvider,
    DatePicker,
    Divider,
    Dropdown,
    Form,
    Input,
    Layout,
    List,
    Menu,
    message,
    PageHeader,
    Row,
    Select,
    Switch,
    Table,
    Tooltip,
    Typography,
    Radio, Spin, Card, Modal,
} from 'antd'
import components from 'components'
import Imgs from 'imgs'
import {isEmpty} from 'lodash'
import Mock from 'mockjs'
import React, {useEffect, useState} from 'react'
import {getName} from 'utils/Help'
import {uploadImage} from 'utils/Uploader'
import styles from './activitiesPage.module.less'
import CommonComponent from 'components/index'

const {ProfotoDefault} = Imgs
const {MyMessage, InfoEmptyData} = CommonComponent
const {Random} = Mock
const {Header, Footer, Sider, Content} = Layout
const {Text} = Typography
const {AddSelect, ProvinceSelectCascader} = components
const {TextArea} = Input
const keys = ['view', 'share', 'collect', 'download', 'order']
const activeStyle = {fontWeight: 'bolder', color: '#000'}

const {DefaultCoverPNG} = Imgs
const customizeRenderEmpty = () => <div></div>
const codes = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
// 浏览记录
const ViewRdBody = ({
                        onAdd,
                        categorys,
                        showRemove,
                        projectDetail,
                        coverImg,
                        setCoverImg,
                        customRequest,
                        _projectRecord,
                        _removeRecord,
                        activeKey
                    }) => {

    const [lists, setList] = useState({})
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [deleteVisible, setDeleteVisible] = useState(false)
    const [deleteItem, setDeleteItem] = useState({})
    useEffect(() => {
        if (activeKey == keys[0]) {
            reloadprojectRecord()
        }
    }, [activeKey])
    // 获取浏览记录
    const projectRecord = async ({pagination}) => {
        let res = null
        setLoading(true)
        if (!projectDetail.id) {
            return
        }
        // return
        res = await _projectRecord({
            projectId: projectDetail.id,
            ...pagination,
        })
        setLoading(false)
        setHasMore(false)
        if (!res) {
            return
        }
        if (res.code === 0) {
            console.log(res.data,'浏览')
            const {pagination, list} = res.data
            const newPhotosData = {
                pagination,
                list: list,
                // list: pagination.current === '1' ? list : list.concat(list),
            }
            setList(newPhotosData)
            // setHasMore(pagination.current * pagination.pageSize < pagination.total)
            setHasMore(true)
        } else {
            message.error(res.msg)
        }
    }
    const loadprojectRecord = (page) => {
        console.log(page)
        if (hasMore) {
            console.log(page)
            projectRecord({
                pagination: {pageNo: page},
            })
        }
    }
    const reloadprojectRecord = () => {
        setList({list: []})
        projectRecord({
            pagination: {pageNo: 1},
        })
    }

    const handleItem = async (type, item) => {
        if (type === '删除') {
            setDeleteVisible(true)
            setDeleteItem(item)
        }
    }
    const deleteModal = async () => {
        let idDTO = {id: deleteItem.id}
        const res = await _removeRecord(idDTO)
        if (res.data.code === 0) {
            reloadprojectRecord()
            setDeleteVisible(false)
            // history.push(`/collection/${res.data.data.id}/photos`)
        }
    }
    const columns = [
        {
            title: '账号',
            dataIndex: 'nickName',
            key: 'nickName',
            render: (_, item) => {
                return (
                    <div className={styles.names}>
                        {item.accessType=='clientMode'?(
                            <span>
                                <img src={ProfotoDefault}/>
                                <span className={styles.nickname}>{item.signinInfoResultVO&&item.signinInfoResultVO.customerPhone?item.signinInfoResultVO.customerPhone:item.signinInfoResultVO.customerEmail}</span>
                                <span className={styles.client}>客户</span>
                            </span>
                        ):(
                            <span>
                                <img src={item.headImgUrl || ProfotoDefault}/>
                                <span className={styles.nickname}>{item.accessType!='normalMode'||(item.visitorResultVO&&item.visitorResultVO.nickName)?getName(item,'browse'):'访客'}</span>
                            </span>
                        )}
                    </div>
                )
            },
        },
        {
            title: '浏览环境',
            dataIndex: 'clientTypeSimple',
            key: 'clientTypeSimple',
        },
        {
            title: '访问地点',
            dataIndex: 'clientType',
            key: 'clientType',
            render: (_, item) => {
                return (
                    <div>
                        {item.country} {item.province}{item.city}
                    </div>
                )
            },
        },
        {
            title: '访问时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        /* {
           title: '创建日期',
           dataIndex: 'createTime',
           key: 'createTime',
           render: (_, item) =>  {return (
               <div>
                 {moment(item.createTime).format('YYYY/MM/DD')}
               </div>
           )},
         },*/

        //  暂时注释删除按钮
        // {
        //     title: '',
        //     dataIndex: 'opt',
        //     key: 'opt',
        //     width: 20,
        //     render: (_, item) => {
        //         // if (+item.rolesId === 1) return ''
        //         return (
        //             <div>
        //                 <Dropdown
        //                     overlay={
        //                         <div className={styles.settingsMenu}>
        //                             <Menu>
        //                                 {/* <Menu.Divider /> */}
        //                                 <Menu.Item><a key='5' onClick={() => handleItem('删除', item)}>删除</a></Menu.Item>
        //                             </Menu>
        //                         </div>
        //                     }
        //                     placement="bottomRight"
        //                     trigger={['click']}>
        //                     <span className="iconfont iconbtn_list_set"></span>
        //                 </Dropdown>
        //             </div>
        //         )
        //     }
        // }
    ]
    return (
        <div className={styles.baseForm}>
            <Card bordered={false}>
                <Spin tip="数据加载中" spinning={loading} className={styles.spinTip}>
                    {lists.list&&lists.list.length > 0 && (
                        <div>
                            <div className={styles.ptCount}>{lists.pagination.total}条记录</div>
                            <Table
                                style={{margin: '20px 0'}}
                                dataSource={lists.list}
                                columns={columns}
                                onChange={data => loadprojectRecord(data.current)}
                                pagination={{
                                    // defaultPageSize: 20,
                                    current:lists.pagination.pageNo,
                                    total:lists.pagination.total,
                                    hideOnSinglePage: true,
                                    showSizeChanger: false,
                                    position:["bottomCenter"],
                                    pageSize:20,
                                    // pageSizeOptions:false
                                    // onChange:(current,pageSize)=>{
                                    //     loadprojectRecord(current,pageSize)
                                    // }
                                }}
                            />
                        </div>
                    )}
                    {lists.list&&lists.list.length <= 0 && !loading && (
                        <InfoEmptyData description="暂无浏览记录"/>
                    )}
                </Spin>
            </Card>
            <Modal
                title="删除浏览记录"
                visible={deleteVisible}
                onOk={deleteModal}
                onCancel={() => setDeleteVisible(false)}
                okText="确认"
                cancelText="取消"
                width={600}
                centered
                closable={false}
                maskClosable={false}
                cancelButtonProps={{className: 'footerCanceltext'}}
            >
                <div className="Inf_page_title">操作将不可恢复，确定删除该条记录？</div>
            </Modal>
        </div>
    )
}
// 分享记录
const ShareRdBody = ({
                         form,
                         photoCategorys,
                         projectDetail,
                         changeCategoryIds,
                         _shareRecord,
                         _removeShare,
                         activeKey
                     }) => {

    const [lists, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [deleteVisible, setDeleteVisible] = useState(false)
    const [deleteItem, setDeleteItem] = useState({})
    useEffect(() => {
        if (activeKey == keys[1]) {
            reloadprojectRecord()
        }
    }, [activeKey])
    // 获取浏览记录
    const projectRecord = async ({pagination}) => {
        let res = null
        setLoading(true)
        if (!projectDetail.id) {
            return
        }
        // return
        res = await _shareRecord({
            projectId: projectDetail.id,
            ...pagination,
        })
        setLoading(false)
        setHasMore(false)
        if (!res) {
            return
        }
        if (res.code === 0) {
            console.log(res.data,'分享')
            const {pagination, list} = res.data
            const newPhotosData = {
                pagination,
                list: list,
            }
            setList(newPhotosData)
            setHasMore(true)
        } else {
            message.error(res.msg)
        }
    }
    const loadprojectRecord = (page) => {
        if (hasMore) {
            projectRecord({
                pagination: {pageNo: page},
            })
        }
    }
    const reloadprojectRecord = () => {
        setList({list: []})
        projectRecord({
            pagination: {pageNo: 1},
        })
    }

    const handleItem = async (type, item) => {
        if (type === '删除') {
            setDeleteVisible(true)
            setDeleteItem(item)
        }
    }
    const deleteModal = async () => {
        let idDTO = {id: deleteItem.id}
        const res = await _removeShare(idDTO)
        if (res.data.code === 0) {
            reloadprojectRecord()
            setDeleteVisible(false)
            // history.push(`/collection/${res.data.data.id}/photos`)
        }
    }
    const columns = [
        {
            title: '账号',
            dataIndex: 'nickName',
            key: 'nickName',
            render: (_, item) => {
                return (
                    <div className={styles.names}>
                        <img src={item.headImgUrl || ProfotoDefault}/>
                        <span
                            className={styles.nickname}>{getName(item,'share')}</span>
                        {item.accessType=='clientMode'?(
                            <span className={styles.client}>客户</span>
                        ):null}
                    </div>
                )
            },
        },
        {
            title: '分享内容',
            dataIndex: 'content',
            key: 'content',
        },
        {
            title: '来源',
            dataIndex: 'source',
            key: 'source',
        },
        {
            title: '操作时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '',
            dataIndex: 'opt',
            key: 'opt',
            width: 20,
            render: (_, item) => {
                // if (+item.rolesId === 1) return ''
                return (
                    <div>
                        <Dropdown
                            overlay={
                                <div className={styles.settingsMenu}>
                                    <Menu>
                                        {/* <Menu.Divider /> */}
                                        <Menu.Item><a key='5' onClick={() => handleItem('删除', item)}>删除</a></Menu.Item>
                                    </Menu>
                                </div>
                            }
                            placement="bottomRight"
                            trigger={['click']}>
                            <span className="iconfont iconbtn_list_set"></span>
                        </Dropdown>
                    </div>
                )
            }
        }
    ]
    return (
        <div className={styles.baseForm}>
            <Card bordered={false} className={styles.tables}>
                <Spin tip="数据加载中" spinning={loading} className={styles.spinTip}>
                    {lists.list&&lists.list.length > 0 && (
                        <div>
                            <div className={styles.ptCount}>{lists.pagination.total}条记录</div>
                            <Table
                                style={{margin: '20px 0'}}
                                dataSource={lists.list}
                                columns={columns}
                                onChange={data => loadprojectRecord(data.current)}
                                pagination={{
                                    current:lists.pagination.pageNo,
                                    total:lists.pagination.total,
                                    hideOnSinglePage: true,
                                    showSizeChanger: false,
                                    position:["bottomCenter"],
                                    pageSize:20,
                                }}
                            />
                        </div>
                    )}
                    {lists.list&&lists.list.length <= 0 && !loading && (
                        <InfoEmptyData description="暂无分享记录"/>
                    )}
                </Spin>
            </Card>
            <Modal
                title="删除分享记录"
                visible={deleteVisible}
                onOk={deleteModal}
                onCancel={() => setDeleteVisible(false)}
                okText="确认"
                cancelText="取消"
                width={600}
                closable={false}
                maskClosable={false}
                centered
                cancelButtonProps={{className: 'footerCanceltext'}}
            >
                <div className="Inf_page_title">操作将不可恢复，确定删除该条记录？</div>
            </Modal>
        </div>
    )
}

// 收藏记录
const CollectRdBody = ({
                           form,
                           projectDetail,
                           photoCategorys,
                           changeDownCategoryIds, infoDetails,
                           _projectBookmarkRecord,
                           _removeBookmarkRecord,
                           activeKey
                       }) => {

    const [lists, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [deleteVisible, setDeleteVisible] = useState(false)
    const [deleteItem, setDeleteItem] = useState({})
    useEffect(() => {
        if (activeKey == keys[2]) {
            reloadprojectRecord()
        }
    }, [activeKey])
    // 获取记录
    const projectRecord = async ({pagination}) => {
        let res = null
        setLoading(true)
        if (!projectDetail.id) {
            return
        }
        // return
        res = await _projectBookmarkRecord({
            projectId: projectDetail.id,
            ...pagination,
        })
        setLoading(false)
        setHasMore(false)
        if (!res) {
            return
        }
        if (res.code === 0) {
            console.log(res.data,'收藏')
            const {pagination, list} = res.data
            const newPhotosData = {
                pagination,
                list: list,
            }
            setList(newPhotosData)
            setHasMore(true)
        } else {
            message.error(res.msg)
        }
    }
    const loadprojectRecord = (page) => {
        if (hasMore) {
            projectRecord({
                pagination: {pageNo: page},
            })
        }
    }
    const reloadprojectRecord = () => {
        setList({list: []})
        projectRecord({
            pagination: {pageNo: 1},
        })
    }

    const handleItem = async (type, item) => {
        if (type === '删除') {
            setDeleteVisible(true)
            setDeleteItem(item)
        }
    }
    const deleteModal = async () => {
        let idDTO = {id: deleteItem.id}
        const res = await _removeBookmarkRecord(idDTO)
        if (res.data.code === 0) {
            reloadprojectRecord()
            setDeleteVisible(false)
            // history.push(`/collection/${res.data.data.id}/photos`)
        }
    }
    const columns = [
        {
            title: '账号',
            dataIndex: 'nickName',
            key: 'nickName',
            render: (_, item) => {
                return (
                    <div className={styles.names}>
                        <img src={item.headImgUrl || ProfotoDefault}/>
                        <span
                            className={styles.nickname}>{getName(item,'bookmark')}</span>
                        {item.accessType=='clientMode'?(
                        <span className={styles.client}>客户</span>
                    ):null}
                    </div>
                )
            },
        },
        {
            title: '收藏图片',
            dataIndex: 'clientType',
            key: 'clientType',
            render: (_, item) => {
                return (
                    <div className={styles.hisRecords}>
                        {/* <div className={styles.photoBlock}> */}
                            <img className={styles.photo} src={item.bookmarkPhotoResultVO&&item.bookmarkPhotoResultVO.thumbInfo&&item.bookmarkPhotoResultVO.thumbInfo.thumb.photoUrl ? item.bookmarkPhotoResultVO.thumbInfo.thumb.photoUrl : ''}/>
                        {/* </div> */}
                        {/* <span className={styles.photoName}>{item.photoName}</span> */}
                        {item.photoBaseInfoVO&&item.photoBaseInfoVO.photoName?(
                        <span className={styles.photoName}>{item.photoBaseInfoVO.photoName}</span>):
                        (<span>{item.photoName}</span>)}
                    </div>
                )
            },
        },
        {
            title: '收藏夹',
            dataIndex: 'bookmarkName',
            key: 'bookmarkName',
            render: (_, item) => {
                return (
                    <div>
                        <span>{item.bookmarkResultVO.name?item.bookmarkResultVO.name:''}</span>
                    </div>
                )
            },
        },
        {
            title: '来源',
            dataIndex: 'source',
            key: 'source',
        },
        {
            title: '收藏时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '',
            dataIndex: 'opt',
            key: 'opt',
            width: 20,
            render: (_, item) => {
                // if (+item.rolesId === 1) return ''
                return (
                    <div>
                        <Dropdown
                            overlay={
                                <div className={styles.settingsMenu}>
                                    <Menu>
                                        {/* <Menu.Divider /> */}
                                        <Menu.Item><a key='5' onClick={() => handleItem('删除', item)}>删除</a></Menu.Item>
                                    </Menu>
                                </div>
                            }
                            placement="bottomRight"
                            trigger={['click']}>
                            <span className="iconfont iconbtn_list_set"></span>
                        </Dropdown>
                    </div>
                )
            }
        }
    ]
    return (
        <div className={styles.baseForm}>
            <Card bordered={false} className={styles.tables}>
                <Spin tip="数据加载中" spinning={loading} className={styles.spinTip}>
                    {lists.list&&lists.list.length > 0 && (
                        <div>
                            <div className={styles.ptCount}>{lists.pagination.total}条记录</div>
                            <Table
                                style={{margin: '20px 0'}}
                                dataSource={lists.list}
                                columns={columns}
                                onChange={data => loadprojectRecord(data.current)}
                                pagination={{
                                    current:lists.pagination.pageNo,
                                    total:lists.pagination.total,
                                    hideOnSinglePage: true,
                                    howSizeChanger: false,
                                    position:["bottomCenter"],
                                    pageSize:20,
                                }}
                            />
                        </div>
                    )}
                    {lists.list&&lists.list.length <= 0 && !loading && (
                        <InfoEmptyData description="暂无收藏记录"/>
                    )}
                </Spin>
            </Card>
            <Modal
                title="删除收藏记录"
                visible={deleteVisible}
                onOk={deleteModal}
                onCancel={() => setDeleteVisible(false)}
                okText="确认"
                cancelText="取消"
                width={600}
                closable={false}
                maskClosable={false}
                centered
                cancelButtonProps={{className: 'footerCanceltext'}}
            >
                <div className="Inf_page_title">操作将不可恢复，确定删除该条记录？</div>
            </Modal>
        </div>
    )
}

// 下载记录
const DownloadRdBody = ({
                            showBookmarkForm,
                            editBookmark,
                            deleteBookmark,
                            projectDetail,
                            _projectDownloadRecord,
                            _removeDownloadRecord,
                            activeKey
                        }) => {

    const [lists, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [deleteVisible, setDeleteVisible] = useState(false)
    const [deleteItem, setDeleteItem] = useState({})
    useEffect(() => {
        if (activeKey == keys[3]) {
            reloadprojectRecord()
        }
    }, [activeKey])
    // 获取浏览记录
    const projectRecord = async ({pagination}) => {
        let res = null
        setLoading(true)
        if (!projectDetail.id) {
            return
        }
        // return
        res = await _projectDownloadRecord({
            projectId: projectDetail.id,
            ...pagination,
        })
        setLoading(false)
        setHasMore(false)
        if (!res) {
            return
        }
        if (res.code === 0) {
            console.log(res.data,'下载')
            const {pagination, list} = res.data
            const newPhotosData = {
                pagination,
                list: list,
            }
            setList(newPhotosData)
            setHasMore(true)
        } else {
            message.error(res.msg)
        }
    }
    const loadprojectRecord = (page) => {
        if (hasMore) {
            projectRecord({
                pagination: {pageNo: page},
            })
        }
    }
    const reloadprojectRecord = () => {
        setList({list: []})
        projectRecord({
            pagination: {pageNo: 1},
        })
    }

    const handleItem = async (type, item) => {
        if (type === '删除') {
            setDeleteVisible(true)
            setDeleteItem(item)
        }
    }
    const deleteModal = async () => {
        let idDTO = {id: deleteItem.id}
        const res = await _removeDownloadRecord(idDTO)
        if (res.data.code === 0) {
            reloadprojectRecord()
            setDeleteVisible(false)
            // history.push(`/collection/${res.data.data.id}/photos`)
        }
    }
    const columns = [
        {
            title: '账号',
            dataIndex: 'nickName',
            key: 'nickName',
            render: (_, item) => {
                return (
                    <div className={styles.names}>
                        <img src={item.headImgUrl?item.headImgUrl:ProfotoDefault}/>
                        <span className={styles.nickname}>{getName(item,'download')}</span>
                        {item.accessType=='clientMode'?(
                            <span className={styles.client}>客户</span>
                        ):null}
                    </div>
                )
            },
        },
        {
            title: '下载图片或分类',
            dataIndex: 'clientType',
            key: 'clientType',
            render: (_, item) => {
                return (
                    <div className={styles.hisRecords}>
                        <img className={styles.photo} src={item.photoBaseInfoVO&&item.photoBaseInfoVO.photoUrl? item.photoBaseInfoVO.photoUrl : ''}/>
                        {item.photoBaseInfoVO&&item.photoBaseInfoVO.photoName?(
                            <span className={styles.photoName}>{item.photoBaseInfoVO.photoName}</span>):
                            (<span>{item.content}</span>)}
                    </div>
                )
            },
        },
        {
            title: '下载尺寸',
            dataIndex: 'downloadSize',
            key: 'downloadSize',
        },
        {
            title: '来源',
            dataIndex: 'source',
            key: 'source',
        },
        {
            title: '下载时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        /* {
           title: '创建日期',
           dataIndex: 'createTime',
           key: 'createTime',
           render: (_, item) =>  {return (
               <div>
                 {moment(item.createTime).format('YYYY/MM/DD')}
               </div>
           )},
         },*/

        //  暂时注释删除按钮
        // {
        //     title: '',
        //     dataIndex: 'opt',
        //     key: 'opt',
        //     width: 20,
        //     render: (_, item) => {
        //         // if (+item.rolesId === 1) return ''
        //         return (
        //             <div>
        //                 <Dropdown
        //                     overlay={
        //                         <div className={styles.settingsMenu}>
        //                             <Menu>
        //                                 {/* <Menu.Divider /> */}
        //                                 <Menu.Item><a key='5' onClick={() => handleItem('删除', item)}>删除</a></Menu.Item>
        //                             </Menu>
        //                         </div>
        //                     }
        //                     placement="bottomRight"
        //                     trigger={['click']}>
        //                     <span className="iconfont iconbtn_list_set"></span>
        //                 </Dropdown>
        //             </div>
        //         )
        //     }
        // }
    ]
    return (
        <div className={styles.baseForm}>
            <Card bordered={false} className={styles.tables}>
                <Spin tip="数据加载中" spinning={loading} className={styles.spinTip}>
                    {lists.list&&lists.list.length > 0 && (
                        <div>
                            <div className={styles.ptCount}>{lists.pagination.total}条记录</div>
                            <Table
                                style={{margin: '20px 0'}}
                                dataSource={lists.list}
                                columns={columns}
                                onChange={data => loadprojectRecord(data.current)}
                                pagination={{
                                    current:lists.pagination.pageNo,
                                    total:lists.pagination.total,
                                    hideOnSinglePage: true,
                                    // showSizeChanger: false,
                                    position:["bottomCenter"],
                                    pageSize:20,
                                }}
                            />
                        </div>
                    )}
                    {lists.list&&lists.list.length <= 0 && !loading && (
                        <InfoEmptyData description="暂无下载记录"/>
                    )}
                </Spin>
            </Card>
            <Modal
                title="删除下载记录"
                visible={deleteVisible}
                onOk={deleteModal}
                onCancel={() => setDeleteVisible(false)}
                okText="确认"
                cancelText="取消"
                width={600}
                closable={false}
                maskClosable={false}
                centered
                cancelButtonProps={{className: 'footerCanceltext'}}
            >
                <div className="Inf_page_title">操作将不可恢复，确定删除该条记录？</div>
            </Modal>
        </div>
    )
}
export default class ActivitiesPage extends React.Component {
    formRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            activeKey: keys[0],
            coverImg:
                isEmpty(props.projectDetail.coverPhotoInfo) ||
                isEmpty(props.projectDetail.coverPhotoInfo.photoUrl)
                    ? [
                        {
                            url: DefaultCoverPNG,
                        },
                    ]
                    : [
                        {
                            url: props.projectDetail.coverPhotoInfo.photoUrl,
                        },
                    ],
            photoCategoryIds: props.projectDetail
                ? props.projectDetail.allowVisitorPhotoCategoryIds
                : [],
            canDownloadCategoryIds: [],
        }
    }

    customRequestCoverImg = (info) => {
        const {onProgress, file, onSuccess, onError} = info
        // // 派发请求
        uploadImage({
            file: file,
            errorCallback: async () => {
            },
            completeCallback: async (res) => {
                const {hash, urlPrefix} = res

                this.formRef.current.setFieldsValue({
                    coverPhotoKey: hash,
                })
                this.setState({
                    coverImg: [
                        {
                            url: urlPrefix + hash,
                        },
                    ],
                })
            },
        })
    }
    changeAcitive = (key) => {
        this.setState({activeKey: key})
    }

    toCreate = () => {
        this.formRef.current.submit()
    }
    onFinish = (values) => {
        const {photoCategoryIds, canDownloadCategoryIds} = this.state
        this.props.onSave({
            ...values,
            allowVisitorPhotoCategoryIds: photoCategoryIds,
            canDownloadCategoryIds: canDownloadCategoryIds,
        })
    }

    changeCategoryIds = (ids) => {
        this.setState({photoCategoryIds: ids})
    }

    changeDownCategoryIds = (ids) => {
        this.setState({canDownloadCategoryIds: ids})
    }
    setCoverImg = (info) => {
        this.setState({coverImg: info.fileList.filter((file) => !!file.status)})
    }
    showArea = (formRef) => {
        const {activeKey, coverImg} = this.state
        const {
            addCategory,
            projectCategorys,
            photoCategorys,
            showRemove,
            showBookmarkForm,
            bookmarks,
            editBookmark,
            deleteBookmark,
            projectDetail,
            infoDetails,
            _projectRecord,
            _removeRecord,
            _shareRecord,
            _removeShare,
            _projectBookmarkRecord,
            _removeBookmarkRecord,
            _projectDownloadRecord,
            _removeDownloadRecord,
        } = this.props
        let formBody = (
            <div>
                <div
                    style={{display: activeKey === keys[0] ? '' : 'none'}}
                >
                    <ViewRdBody
                        onAdd={addCategory}
                        categorys={projectCategorys}
                        showRemove={showRemove}
                        projectDetail={projectDetail}
                        coverImg={coverImg}
                        activeKey={activeKey}
                        _projectRecord={_projectRecord}
                        _removeRecord={_removeRecord}
                        setCoverImg={this.setCoverImg}
                        customRequest={this.customRequestCoverImg}
                    />
                </div>
                <div
                    style={{display: activeKey === keys[1] ? '' : 'none'}}
                >
                    <ShareRdBody
                        form={formRef}
                        photoCategorys={photoCategorys}
                        projectDetail={projectDetail}
                        activeKey={activeKey}
                        changeCategoryIds={this.changeCategoryIds}
                        visitTypeValueChange={this.props.visitTypeValueChange}
                        _shareRecord={_shareRecord}
                        _removeShare={_removeShare}
                    />
                </div>
                <div
                    style={{display: activeKey === keys[2] ? '' : 'none'}}
                >
                    <CollectRdBody
                        form={formRef}
                        projectDetail={projectDetail}
                        photoCategorys={photoCategorys}
                        infoDetails={infoDetails}
                        activeKey={activeKey}
                        changeDownCategoryIds={this.changeDownCategoryIds}
                        _projectBookmarkRecord={_projectBookmarkRecord}
                        _removeBookmarkRecord={_removeBookmarkRecord}
                    />
                </div>
                <div
                    style={{display: activeKey === keys[3] ? '' : 'none'}}
                >
                    <DownloadRdBody
                        showBookmarkForm={showBookmarkForm}
                        list={bookmarks}
                        activeKey={activeKey}
                        deleteBookmark={deleteBookmark}
                        editBookmark={editBookmark}
                        projectDetail={projectDetail}
                        _projectDownloadRecord={_projectDownloadRecord}
                        _removeDownloadRecord={_removeDownloadRecord}
                        form={formRef}
                    />
                </div>
                {/*<div
              style={{display: activeKey === keys[4] ? '' : 'none' }}
          >
            <InfoFormBody />
          </div>*/}
                <div
                    style={{display: activeKey === keys[5] ? '' : 'none'}}
                >
                </div>
            </div>
        )
        return <div>{formBody}</div>
    }

    render() {
        const {name, projectDetail} = this.props
        const {activeKey} = this.state
        const initialValues = projectDetail
        return (
            <div>
                <Header className="sub-header" style={{height: 120}}>
                    <PageHeader
                        ghost={false}
                        title={<div style={{fontWeight: 'bold'}}>项目动态与记录</div>}
                        style={{
                            padding: '24px 50px 23px',
                        }}
                        // extra={[
                        //   <Button type="primary" key="saveSettings" onClick={this.toCreate}>
                        //     保存
                        //   </Button>,
                        // ]}
                    >
                        <div>
                            <Button
                                type="link"
                                className="tab-btn"
                                style={activeKey === keys[0] ? activeStyle : null}
                                onClick={this.changeAcitive.bind(this, keys[0])}
                            >
                                浏览
                            </Button>
                            {projectDetail.isAllowShare && (
                            <span>
                                <Divider type="vertical"/>
                                <Button
                                    type="link"
                                    className="tab-btn"
                                    style={activeKey === keys[1] ? activeStyle : null}
                                    onClick={this.changeAcitive.bind(this, keys[1])}
                                >
                                    分享
                                </Button>
                            </span>
                            )}
                            {projectDetail.isShowBookmark && (
                            <span>
                                <Divider type="vertical"/>
                                <Button
                                    type="link"
                                    className="tab-btn"
                                    style={activeKey === keys[2] ? activeStyle : null}
                                    onClick={this.changeAcitive.bind(this, keys[2])}
                                >
                                    收藏
                                </Button>
                            </span>
                            )}
                            {projectDetail.canDownload && (
                            <span>
                                <Divider type="vertical"/>
                                <Button
                                    type="link"
                                    className="tab-btn"
                                    style={activeKey === keys[3] ? activeStyle : null}
                                    onClick={this.changeAcitive.bind(this, keys[3])}
                                >
                                    下载
                                </Button>
                            </span>
                            )}
                            {/* {projectDetail.isShowPrintStore && (
                            <span>
                                <Divider type="vertical" />
                                <Button
                                    type="link"
                                    className="tab-btn"
                                    style={activeKey === keys[4] ? activeStyle : null}
                                    onClick={this.changeAcitive.bind(this, keys[4])}
                                >
                                    订购
                                </Button>
                            </span>
                            )} */}
                        </div>
                    </PageHeader>
                </Header>
                <Content
                    className="sub-content"
                    style={{minHeight: 'calc(100vh - 120px)', paddingTop: '145px'}}
                >
                    <Form
                        ref={this.formRef}
                        onFinish={this.onFinish}
                        layout="vertical"
                        className={styles.recordListForm}
                        initialValues={initialValues}
                        key={projectDetail.id}
                    >
                        {this.showArea(this.formRef)}
                    </Form>
                </Content>
            </div>
        )
    }
}
