import { message } from 'antd'
import React from 'react'

const myMessage = {
  success: (content) => {
    message.success({
      duration: 3,
      content: content,
      icon: <span className="iconfont iconicon_success"></span>,
      className: 'message-success',
    })
  },
  error: (content) => {
    message.error({
      duration: 3,
      content: content,
      icon: <span className="iconfont iconicon_error"></span>,
      className: 'message-error',
    })
  },
  warning: (content) => {
    message.warning({
      duration: 3,
      content: content,
      icon: <span className="iconfont iconicon_attention" style={{paddingRight: 8}}></span>,
      className: 'message-warning',
    })
  },
}
export default myMessage
