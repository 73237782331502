import Mock from 'mockjs'
import { random } from 'lodash'
const { Random } = Mock

export const getDashboradProjectData = (options) => {
  return {
    type: options.type,
    totalCount: Random.natural(1, 100),
    list: Array.from({ length: Random.natural(1, 10) }, (v) => ({
      time: Random.datetime(),
      count: Random.natural(0, 20),
    })),
  }
}
export const getDashboradCustomerData = (options) => {
  return {
    type: options.type,
    totalCount: Random.natural(1, 100),
    list: Array.from({ length: Random.natural(1, 10) }, (v) => ({
      time: Random.datetime(),
      count: Random.natural(0, 20),
    })),
  }
}
