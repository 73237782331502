import Api from 'apis/Api'
import * as qiniu from 'qiniu-js'
import moment from 'moment'
import { uuid } from 'utils/Tools'
import request from 'utils/Request'
/**
 * 获取签名等信息
 * @param {*} imageName - 文件名称
 */
const getAuthorization = async (imageName) => {
  const response = await request({
    method: 'get',
    url: Api.common.getQiniuToken,
    params: {},
  })
  return await response.data
}

/** 
 * 
 * @param {*} param0 
 * file, 文件流
  fileName, 文件名
  nextCallback = () => {}, 上传进度回调
  errorCallback = () => {}, 上传成功回调
  completeCallback = () => {}, 上传错误回调
 */
export async function uploadImage({
  userId,
  file,
  nextCallback = () => {},
  errorCallback = () => {},
  completeCallback = () => {},
}) {
  const authorizationRes = await getAuthorization()
  if (authorizationRes.data && [0].includes(authorizationRes.code)) {
    const { token, urlPrefix } = authorizationRes.data

    var key = undefined // 此处不传递 key，这样生成的唯一标识符是上传成功后的hash值
    if(userId){
       key = userId + "/" + uuid()
    }
    const config = { region: qiniu.region.z2 }
    const putExtra = {}
    const observable = qiniu.upload(file, key, token, putExtra, config)
    const observer = {
      next(res) {
        // 接收上传进度信息，res 参数是一个带有 total 字段的 object，包含loaded、total、percent三个属性，提供上传进度信息
        // ...
        nextCallback(res)
      },
      error(err) {
        // 上传错误后触发，当不是 xhr 请求错误时，会把当前错误产生原因直接抛出，诸如 JSON 解析异常等；当产生 xhr 请求错误时，参数 err 为一个包含 code、message、isRequestError 三个属性的 object
        console.error('========>qiniu err=====>')
        console.error(err)
        console.error('========>qiniu err end=====>')
        // ...
        errorCallback(err)
      },
      complete(res) {
        // 接收上传完成后的后端返回信息，res 参数为一个 object， 为上传成功后后端返回的信息，具体返回结构取决于后端sdk的配置
        // ...
        completeCallback({
          ...res,
          urlPrefix,
        })
      },
    }
    const subscription = observable.subscribe(observer) // 上传开始
    return subscription
  }
}

// 获取上传文件的 type
/**
 *
 * @param {*} fileName 包含文件类型后缀的文件名
 * 示例 传入 excel.hheihei.txt 输出 txt
 */
export const getFileTypeByFileName = function (fileName) {
  const regex = /\.\w+$/
  const type = fileName.match(regex)[0]
  return type.length ? type.slice(1).toLowerCase() : ''
}

// 根据url获取文件的type
/**
 *
 * @param {*} url 包含文件类型后缀的url
 * 示例 传入 excel.hheihei.txt 输出 txt
 */
export const getFileTypeByInterUrl = function (url) {
  const regex = /\.\w+$/
  const isInterUrl = regex.test(url)
  const type = isInterUrl ? url.match(regex)[0] : []
  return type.length ? type.slice(1).toLowerCase() : ''
}

// 获取返回的图片地址的 阿里云 oss 字符串
export const getImageNameInUrl = function (url) {
  const regex = /.com\/\S+\?/g
  const matchArray = url.match(regex)
  const name = matchArray ? matchArray[0] : ''
  return name.length ? name.slice(5, -1) : ''
}
