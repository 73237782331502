/*
 * @Author: melon
 * @Date: 2020-07-02 16:10:25
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-17 10:30:37
 */

import { Button, DatePicker, Form, Select } from 'antd'
import classNames from 'classnames'
import commonComponent from 'components/index'
import React from 'react'
import {
  PhoneValidateRule,
  RequiredInputRule,
  RequiredSelectRule,
} from 'utils/ValidateRulesAndMsg'
import styles from './Contact.module.less'
const { TrimInput, TrimTextArea } = commonComponent

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
}

const tailLayout = {
  wrapperCol: { span: 24 },
}
class WebContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      btnLoginLoading: false,
    }
  }
  onFinish = async (values) => {
    const { doSubmit, history } = this.props

    try {
      // 跳转首页
    } catch (error) {
    } finally {
    }
  }

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  render() {
    const { btnLoading } = this.state
    const { formData, servicelist } = this.props
    return (
      <div className={classNames(styles.form_wrapper)}>
        <Form
          className={classNames(styles.form)}
          {...layout}
          name="login"
          initialValues={formData}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <div className={styles.title}>预约或提交你的需求</div>
          <Form.Item name="NAME" rules={[RequiredInputRule]} label="姓名或称呼">
            <TrimInput placeholder="" disabled />
          </Form.Item>
          <Form.Item
            name="NAME"
            rules={[RequiredInputRule, PhoneValidateRule]}
            label="电话"
          >
            <TrimInput placeholder="" disabled />
          </Form.Item>
          {servicelist.length > 0 && (
            <Form.Item
              name="NAME"
              //rules={[RequiredSelectRule]}
              label="意向服务"
            >
              <Select
                placeholder=""
                disabled
                suffixIcon={
                  <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                }
              />
            </Form.Item>
          )}
          <Form.Item name="NAME" label="有确定的时间吗？">
            <DatePicker placeholder="" disabled  style={{width: '100%',}} />
          </Form.Item>
          <Form.Item
            name="NAME"
            //rules={[RequiredSelectRule]}
            label="你如何找到我的？"
          >
            <Select
                placeholder=""
                disabled
                suffixIcon={
                  <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                }
            />
          </Form.Item>
          <Form.Item name="NAME" label="备注或留言">
            <TrimTextArea
              style={{ height: '170px' }}
              placeholder="请告诉我你的具体拍摄需求…"
              disabled
            />
          </Form.Item>
          <Form.Item>
            <Button className={styles.submit_btn} loading={btnLoading}>
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

export default WebContactForm
