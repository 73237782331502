/*
 * @Author: melon
 * @Date: 2020-07-04 23:16:47
 * @Last Modified by:   melon
 * @Last Modified time: 2020-07-04 23:16:47
 */
// 忘记密码数据

export const ForgetPswSelector = (data) => {
  return {
    ...data,
    account: data.account, // 账号
    password: data.password, // 密码
    surePassword: data.surePassword, // 确认密码
    verifyCode: data.verifyCode, // 验证码
  }
}
