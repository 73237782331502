//销售设置
import {
    Button,
    Checkbox,
    Col,
    ConfigProvider,
    DatePicker,
    Divider,
    Dropdown,
    Form,
    Input,
    Layout,
    List,
    Menu,
    PageHeader,
    Row,
    Select,
    Switch,
    Table,
    Tooltip,
    Typography,
    Radio, Modal,
} from 'antd'
import components from 'components'
// import CoverUpload from 'components/CoverUpload'
// import { DownloadImgSize, ProjectStatus, VisitTypes } from 'constants/Options'
// import Imgs from 'imgs'
import { isEmpty } from 'lodash'
// import { map } from 'lodash/fp'
import Mock from 'mockjs'
import CommonComponent from 'components/index'
import React, {useEffect, useState} from 'react'
// import { CopyToClipboard } from 'react-copy-to-clipboard'
// import { getSelectOptions } from 'utils/Help'
// import { uploadImage } from 'utils/Uploader'
// import { EmailValidateRule, PhoneValidateRule } from 'utils/ValidateRulesAndMsg'
import styles from './settings.module.less'
import {
  addPolicy as _addPolicy,
  editPolicy as _editPolicy,
  getPolicy as _getPolicy,
  deletePolicy as _deletePolicy,
  addFreight as _addFreight,
  deleteFreight as _deleteFreight,
  editFreight as _editFreight,
  getFreight as _getFreight,
  addProductType as _addProductType,
  deleteProductType as _deleteProductType,
  editProductType as _editProductType,
  getProductType as _getProductType,
} from 'reduxModules/store'
// import AddProductOption from "./AddProductOption";
import myMessage from 'components/Message'
import { getOptionBykeyName} from 'utils/Tools'
const { Random } = Mock
const { Header, Footer, Sider, Content } = Layout
const { Text } = Typography
const { AddSelect, ProvinceSelectCascader } = components
const { TextArea } = Input
const keys = ['basics', 'freight', 'commodity', 'policy']
const activeStyle = { fontWeight: 'bolder', color: '#000' }
const hideStyle = { display: 'none'}
const {message, InfoEmptyData} = CommonComponent
const { Option } = Select

// 基础设置
const StoreBasicBlock = ({

                      }) => {
    const [form] = Form.useForm()
    const [currency, setCurrency] = useState([
        {code:'CNRMB',value:'人民币CNY（中国大陆）'},
    ])
    useEffect(() => {
      //设置默认货币
      form.setFieldsValue({
        currency: 'CNRMB',
      })
    }, [])
    // const [sale, setSale] = useState(projectDetail.isOpenSale)
    // const changeSale = (checked) => {
    //     setSale(checked)
    // }
    return (
        <div className={styles.baseForm}>
            <Form
                form={form}
                layout="vertical"
                name="productForm"
                // className={styles.productForm}
            >
                <Row gutter={24} className={styles.productContent}>
                    <Col span={24} >
                        <Form.Item label="开启销售" name="websiteFooter">
                            <Form.Item name="isOpenSale" noStyle valuePropName="checked">
                                <Switch defaultChecked
                                    // onChange={changeSale}
                                />
                            </Form.Item>
                            <span className={styles.switchLabel}>
                                {/* {sale ? '是' : '否'} */}是
                            </span>
                            <div className={styles.desc}>说明：打开或关闭所有应用销售相关功能的项目或页面。</div>
                        </Form.Item>
                        {/* <div style={{ display: sale ? '' : 'none', width: '100%' }}> */}
                          <Form.Item label="结算货币" name="currency" style={{width:285}}>
                              <Select
                                  disabled
                                  placeholder="请选择"
                                  suffixIcon={
                                      <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                                  }
                              >
                                  {/*<Option value="">默认</Option>*/}
                                  {currency.map(v=>(
                                      <Option value={v.code}>{v.value}</Option>
                                  ))}
                              </Select>
                          </Form.Item>
                          <Form.Item label="提交订购后的订单确认消息" name="websiteDesc">
                              <TextArea disabled rows={3} placeholder="[#商业品牌#] 已收到您的订购#订单号#，我们将会尽快处理您的订单，感谢订购。"/>
                              <div className={styles.desc}>说明：当前短信消息仅支持供应商商品的购买。</div>
                          </Form.Item>
                          <Form.Item label="发货后的消息" name="websiteDesc">
                              <TextArea disabled rows={3} placeholder="[#商业品牌#] 您的订单#订单号#已通过#物流公司#发货，物流单号#运单号#，请保持电话畅通，注意查收。再次感谢您的支持。"/>
                              <div className={styles.desc}>说明：当前短信消息仅支持供应商商品的购买。</div>
                          </Form.Item>
                        {/* </div> */}
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

// 运费设置
const StoreShipBlock = ({

                         }) => {
    const [form] = Form.useForm()
    const [freightList, setFreightList] = useState([
        // {shipMethod:'顺丰快递',destination:'中国大陆',freight:'15.00'},
        // {shipMethod:'顺丰速运',destination:'中国香港，中国澳门，中国台湾地区',freight:'25.00'},
        // {shipMethod:'DHL',destination:'国际',freight:'100.00'},
    ])
    const [destinationList, setDestinationList] = useState([
        {code:'CN',value:'中国大陆'},
        {code:'CNOTH',value:'中国香港，中国澳门，中国台湾地区'},
        {code:'INT',value:'国际'},
    ])
    const [selectedFreight, setSelectedFreight] = useState({
        pageNo:1,
        pageSize:20,
        current:10,
        totalCount:20
    })
    const [addVisible, setAddVisible] = useState(false)
  const [checkItem, setCheckItem] = useState({})
  const [visible, setVisible] = useState(false)
  useEffect(()=>{
    getFreight()
  },[])
  const getFreight= async ()=>{
    // console.log(sessionStorage.getItem('user'))
    const res = await _getFreight({
      storeId:sessionStorage.getItem('user')
        ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
        :null,
      pageNum:selectedFreight.pageNo,
      pageSize:selectedFreight.pageSize,
    })
    if (res.status=='200'&&res.data.code === '000000') {
      res.data.data.records.map(v=>{
        // v.createdTime=v.createdTime.split(' ')[0].replace(/-/g,'/')
      })
      setFreightList(res.data.data.records)
    } else {
      myMessage.error(res.data.mesg)
    }
  }
    //页码跳转
    const onBaseClick=(current,pageSize)=>{
      setSelectedFreight({
            pageNo:current,
            pageSize:selectedFreight.pageSize,
            totalCount:20
        })
    }
    // 操作
    const handleItem = async (type, item) => {
      setCheckItem(item)
      if(type === 'edit'){
        setAddVisible(true)
        form.setFieldsValue({
          name:item.name,
          region: item.region,
          freight: item.freight,
        })
      }else if(type === 'delete'){
        setVisible(true)
      }
    }
  const removeFreight=async()=>{
    const res = await _deleteFreight({
      id:checkItem.id
    })
    if (res.status=='200'&&res.data.code === '000000') {
      setVisible(false)
      getFreight()
      myMessage.success('删除成功')
    } else {
      myMessage.error(res.data.mesg)
    }
  }

    //添加运费
    const addFreight=async (values)=>{
      let res
      if(!checkItem.id) {
        res=await _addFreight({
          region: values.region,
          freight: values.freight,
          name: values.name,
          storeId: sessionStorage.getItem('user')
            ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
            : null,
        })
      }else{
        res=await _editFreight({
          region: values.region,
          freight: values.freight,
          name: values.name,
          id: checkItem.id,
          storeId: sessionStorage.getItem('user')
            ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
            : null,
        })
      }
      if (res.status=='200'&&res.data.code === '000000') {
        setAddVisible(false)
        getFreight()
        myMessage.success(`${!checkItem.id?'添加':'更新'}成功`)
      } else {
        myMessage.error(res.data.mesg)
      }
    }
    const columns = [
        {
            title: '运输物流名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '目的地',
            dataIndex: 'region',
            key: 'region',
            render: (_, item) =>  {return (
              <div>
                {getOptionBykeyName(
                  'code',
                  item.region,
                  'value',
                  destinationList
                )}
              </div>
            )},
        },
        {
            title: '基础运费/单',
            dataIndex: 'freight',
            key: 'freight',
            center:'right',
            render: (_, item) =>  {return (
                <div>
                    ￥{item.freight}
                </div>
            )},
        },
        {
            title: '',
            dataIndex: 'opt',
            key: 'opt',
            render: (_, item) => {
                // if (+item.rolesId === 1) return ''
                return (
                    <div className={styles.menuBtn}>
                        <Dropdown
                            overlay={
                                <div className={styles.settingsMenu}>
                                    <Menu>
                                        <Menu.Item><a key='1' onClick={() => handleItem('edit', item)}>编辑</a></Menu.Item>
                                        <Menu.Divider />
                                        <Menu.Item><a key='2' onClick={() => handleItem('delete', item)}>删除</a></Menu.Item>
                                    </Menu>
                                </div>
                            }
                            placement="bottomRight"
                            trigger={['click']}>
                            <span className="iconfont iconbtn_list_set"></span>
                        </Dropdown>
                    </div>
                )
            }
        }
    ]
    return (
        <div className={styles.shipForm}>
            <Button className={styles.add_btn} onClick={()=>{
              setAddVisible(true)
              setCheckItem({})
              form.setFieldsValue({
                name:'',
                region: '',
                freight: '',
              })
            }}>添加基础运费</Button>
            <div className={styles.desc}>说明：若您的商品通过供应商进行服务，则无需设置运费，系统将会自动处理，运输方式及费用将由供应商决定。若您的商品来自自有服务，则此处设置的运费将会在客户提交订单时供选择。</div>
            <Table
                // className={styles.tablesbody}
                style={{marginBottom: '30px',marginTop:'20px'}}
                dataSource={freightList}
                columns={columns}
                pagination={{
                    simple:false,
                    current:selectedFreight.pageNo,
                    total:selectedFreight.totalCount,
                    hideOnSinglePage: true,
                    howSizeChanger: false,
                    position:["bottomCenter"],
                    pageSize:20,
                    onChange:(current,pageSize)=>{
                        onBaseClick(current,pageSize)
                    }
                }}
            />
            <Modal
                visible={addVisible}
                title={`${!checkItem.id?'添加':'编辑'}基础运费`}
                okText="确定"
                cancelText="取消"
                centered
                cancelButtonProps={{className: 'footerCanceltext'}}
                onCancel={()=>{
                    setAddVisible(false)
                }}
                maskClosable={false}
                width={600}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            addFreight(values)
                        })
                        // .catch((info) => {
                        //     console.log('Validate Failed:', info)
                        // })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="add_project_form"
                    requiredMark={false}
                    className={styles.createProjectDialog}
                    initialValues={{
                        presetId:'',
                    }}
                >
                    <Form.Item
                        name="name"
                        label="运输物流名称"
                        rules={[
                            {
                                required: true,
                                message: '请输入',
                            },
                        ]}>
                        <Input placeholder="请输入运输物流名称或公司名，例如顺丰快递" maxLength={30}/>
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="region" label="目的地"
                                       rules={[
                                        {
                                            required: true,
                                            message: '请选择',
                                        },
                                    ]}>
                                <Select
                                    placeholder="请选择运输目的地"
                                    suffixIcon={
                                        <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                                    }
                                >
                                    {/* <Option value="">默认</Option> */}
                                    {destinationList.map(v=>(
                                        <Option value={v.code}>{v.value}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="freight"
                                label="每单基础运费"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入',
                                    },
                                ]}>
                                <Input placeholder="请输入" maxLength={30}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
          <Modal
            visible={visible}
            title={`删除基础运费`}
            okText="确定"
            cancelText="取消"
            centered
            width={600}
            cancelButtonProps={{className: 'footerCanceltext'}}
            onCancel={() => setVisible(false)}
            maskClosable={false}
            closable={false}
            onOk={removeFreight}
          >
            <div className={styles.desc}>
              删除将不可恢复，确定继续？
            </div>
          </Modal>
        </div>
    )
}

// 商品分类设置
const StoreCategoryBlock = ({}) => {
    const [form] = Form.useForm()
    const [productTypeList, setproductTypeList] = useState([
        {categoryName:'相册',categoryType:'系统分类'},
        {categoryName:'框画',categoryType:'系统分类'},
        {categoryName:'打印',categoryType:'系统分类'},
        {categoryName:'摆台',categoryType:'系统分类'},
        {categoryName:'日历/台历',categoryType:'自定义分类'},
        {categoryName:'贺卡',categoryType:'自定义分类'},
    ])
    const [selectedgetProductType, setSelectedgetProductType] = useState({
        pageNo:1,
        pageSize:20,
        current:10,
        totalCount:20
    })
    const [addVisible, setAddVisible] = useState(false)
  const [checkItem, setCheckItem] = useState({})
  const [visible, setVisible] = useState(false)
  useEffect(()=>{
    getProductType()
  },[])
  const getProductType= async ()=>{
    // console.log(sessionStorage.getItem('user'))
    const res = await _getProductType({
      storeId:sessionStorage.getItem('user')
        ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
        :null,
      pageNum:selectedgetProductType.pageNo,
      pageSize:selectedgetProductType.pageSize,
    })
    if (res.status=='200'&&res.data.code === '000000') {
      res.data.data.records.map(v=>{
        v.createdTime=v.createdTime?v.createdTime.split(' ')[0].replace(/-/g,'/'):''
      })
      setproductTypeList(res.data.data.records)
    } else {
      myMessage.error(res.data.mesg)
    }
  }
    //页码跳转
    const onBaseClick=(current,pageSize)=>{
      setSelectedgetProductType({
            pageNo:current,
            pageSize:selectedgetProductType.pageSize,
            totalCount:20
        })
    }
    // 操作
    const handleItem = async (type, item) => {
      setCheckItem(item)
      if(type === 'edit'){
        setAddVisible(true)
        form.setFieldsValue({
          productTypeName:item.productTypeName,
        })
      }else if(type === 'delete'){
        setVisible(true)
      }
    }

  const removeProductType=async()=>{
    const res = await _deleteProductType({
      id:checkItem.id
    })
    if (res.status=='200'&&res.data.code === '000000') {
      setVisible(false)
      getProductType()
      myMessage.success('删除成功')
    } else {
      myMessage.error(res.data.mesg)
    }
  }
    //添加类型
    const addCategory= async (values)=>{
      let res
      if(!checkItem.id) {
        res=await _addProductType({
          productTypeName: values.productTypeName,
          storeId: sessionStorage.getItem('user')
            ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
            : null,
        })
      }else{
        res=await _editProductType({
          productTypeName: values.productTypeName,
          id: checkItem.id,
          storeId: sessionStorage.getItem('user')
            ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
            : null,
        })
      }
      if (res.status=='200'&&res.data.code === '000000') {
        setAddVisible(false)
        getProductType()
        myMessage.success(`${!checkItem.id?'添加':'更新'}成功`)
      } else {
        myMessage.error(res.data.mesg)
      }
    }
    const columns = [
        {
            title: '分类名称',
            dataIndex: 'productTypeName',
            key: 'productTypeName',
        },
        {
            title: '类别',
            dataIndex: 'productTypeDefine',
            key: 'productTypeDefine',
            center:'right',
            render: (_, item) =>  {return (
                <div>
                    {item.productTypeDefine}
                </div>
            )},
        },
        {
            title: '',
            dataIndex: 'opt',
            key: 'opt',
            render: (_, item) => {
                // if (+item.rolesId === 1) return ''

                return (
                    <div className={styles.menuBtn}>
                      {item.productTypeDefine != '系统分类' &&
                      <Dropdown
                        overlay={
                          <div className={styles.settingsMenu}>
                            <Menu>
                              <Menu.Item><a key='1' onClick={() => handleItem('edit', item)}>编辑</a></Menu.Item>
                              <Menu.Divider/>
                              <Menu.Item><a key='2'
                                            onClick={() => handleItem('delete', item)}>删除</a></Menu.Item>

                            </Menu>

                          </div>

                        }
                        placement="bottomRight"
                        trigger={['click']}>
                        <span className="iconfont iconbtn_list_set"></span>
                      </Dropdown>
                      }
                    </div>
                )
            }
        }
    ]
    return (
        <div className={styles.categoryForm}>
            <Button className={styles.add_btn} onClick={()=>{
              setAddVisible(true)
              setCheckItem({})
              form.setFieldsValue({
                productTypeName:'',
              })
            }}>添加商品分类</Button>
            <Table
                style={{marginBottom: '30px',marginTop:'20px'}}
                dataSource={productTypeList}
                columns={columns}
                pagination={{
                    simple:false,
                    current:selectedgetProductType.pageNo,
                    total:selectedgetProductType.totalCount,
                    hideOnSinglePage: true,
                    howSizeChanger: false,
                    position:["bottomCenter"],
                    pageSize:20,
                    onChange:(current,pageSize)=>{
                        onBaseClick(current,pageSize)
                    }
                }}
            />
            <Modal
                visible={addVisible}
                title={`${!checkItem.id?'添加':'编辑'}商品分类`}
                okText="确定"
                cancelText="取消"
                centered
                cancelButtonProps={{className: 'footerCanceltext'}}
                onCancel={()=>{
                    setAddVisible(false)
                }}
                maskClosable={false}
                width={600}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            addCategory(values)
                        })
                        // .catch((info) => {
                        //     console.log('Validate Failed:', info)
                        // })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="add_project_form"
                    requiredMark={false}
                    className={styles.createProjectDialog}
                    initialValues={{
                        presetId:'',
                    }}
                >
                    <Form.Item
                        name="productTypeName"
                        label="分类名称"
                        rules={[
                            {
                                required: true,
                                message: '请输入',
                            },
                        ]}>
                        <Input placeholder="请输入分类名称" maxLength={30}/>
                    </Form.Item>
                </Form>
            </Modal>
          <Modal
            visible={visible}
            title={`商品类型`}
            okText="确定"
            cancelText="取消"
            centered
            width={600}
            cancelButtonProps={{className: 'footerCanceltext'}}
            onCancel={() => setVisible(false)}
            maskClosable={false}
            closable={false}
            onOk={removeProductType}
          >
            <div className={styles.desc}>
              删除将不可恢复，确定继续？
            </div>
          </Modal>
        </div>
    )
}

// 政策管理
const StorePolicyBlock = ({}) => {
    const [form] = Form.useForm()
    const [ClientList, setClientList] = useState([
        {policyName:'支付与退换货',createDate:'2022/09/21'},
        {policyName:'用户隐私政策',createDate:'2022/05/02'},
    ])
    const [selectedPolicy, setSelectedPolicy] = useState({
        pageNo:1,
        pageSize:20,
        current:10,
        totalCount:20
    })
    const [addVisible, setAddVisible] = useState(false)
    const [checkItem, setCheckItem] = useState({})
    const [visible, setVisible] = useState(false)
    useEffect(()=>{
      getPolicy()
    },[])
    const getPolicy= async ()=>{
      // console.log(sessionStorage.getItem('user'))
      const res = await _getPolicy({
        storeId:sessionStorage.getItem('user')
          ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
          :null,
        pageNum:selectedPolicy.pageNo,
        pageSize:selectedPolicy.pageSize,
      })
      if (res.status=='200'&&res.data.code === '000000') {
        res.data.data.records.map(v=>{
          v.createdTime=v.createdTime.split(' ')[0].replace(/-/g,'/')
        })
        setClientList(res.data.data.records)
      } else {
        myMessage.error(res.data.mesg)
      }
    }
    //页码跳转
    const onBaseClick=(current,pageSize)=>{
      setSelectedPolicy({
            pageNo:current,
            pageSize:selectedPolicy.pageSize,
            totalCount:20
        })
    }
    // 操作
    const handleItem = async (type, item) => {
      setCheckItem(item)
        if(type === 'edit'){
          setAddVisible(true)
          form.setFieldsValue({
            name:item.name,
            describe:item.describe,
          })
        }else if(type === 'delete'){
          setVisible(true)
        }
    }
    const removePolicy=async()=>{
      const res = await _deletePolicy({
        id:checkItem.id
      })
      if (res.status=='200'&&res.data.code === '000000') {
        setVisible(false)
        getPolicy()
        myMessage.success('删除成功')
      } else {
        myMessage.error(res.data.mesg)
      }
    }
    //添加
    const addPolicy=async(values)=>{
      let res
      if(!checkItem.id) {
        res=await _addPolicy({
          describe: values.describe,
          name: values.name,
          storeId: sessionStorage.getItem('user')
            ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
            : null,
        })
      }else{
        res=await _editPolicy({
          describe: values.describe,
          name: values.name,
          id: checkItem.id,
          storeId: sessionStorage.getItem('user')
            ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
            : null,
        })
      }
      if (res.status=='200'&&res.data.code === '000000') {
        setAddVisible(false)
        getPolicy()
        myMessage.success(`${!checkItem.id?'添加':'更新'}成功`)
      } else {
        myMessage.error(res.data.mesg)
      }
    }
    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '创建日期',
            dataIndex: 'createdTime',
            key: 'createdTime',
            center:'right',
            render: (_, item) =>  {return (
                <div>
                    {item.createdTime}
                </div>
            )},
        },
        {
            title: '',
            dataIndex: 'opt',
            key: 'opt',
            render: (_, item) => {
                // if (+item.rolesId === 1) return ''

                return (
                    <div className={styles.menuBtn}>
                        <Dropdown
                            overlay={
                                <div className={styles.settingsMenu}>
                                    <Menu>
                                        <Menu.Item><a key='1' onClick={() => handleItem('edit', item)}>编辑</a></Menu.Item>
                                        <Menu.Divider />
                                        <Menu.Item><a key='2' onClick={() => handleItem('delete', item)}>删除</a></Menu.Item>
                                    </Menu>
                                </div>
                            }
                            placement="bottomRight"
                            trigger={['click']}>
                            <span className="iconfont iconbtn_list_set"></span>
                        </Dropdown>
                    </div>
                )
            }
        }
    ]
    return (
        <div className={styles.policyForm}>
            <Button className={styles.add_btn} onClick={()=>{
              setAddVisible(true)
              setCheckItem({})
              form.setFieldsValue({
                id:'',
                name:'',
                describe:'',
              })
            }}>添加政策</Button>
            <div className={styles.desc}>说明：政策表示商品购买过程中的相关描述，例如退换货政策、隐私政策、配送政策等，该内容描述将会出现在客户帮助界面中。</div>
            <Table
                style={{marginBottom: '30px',marginTop:'20px'}}
                dataSource={ClientList}
                columns={columns}
                pagination={{
                    simple:false,
                    current:selectedPolicy.pageNo,
                    total:selectedPolicy.totalCount,
                    hideOnSinglePage: true,
                    howSizeChanger: false,
                    position:["bottomCenter"],
                    pageSize:20,
                    onChange:(current,pageSize)=>{
                        onBaseClick(current,pageSize)
                    }
                }}
            />
            <Modal
                visible={addVisible}
                title={`${!checkItem.id?'添加':'编辑'}政策`}
                okText="确定"
                cancelText="取消"
                centered
                cancelButtonProps={{className: 'footerCanceltext'}}
                onCancel={()=>{
                    setAddVisible(false)
                }}
                maskClosable={false}
                width={600}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            addPolicy(values)
                        })
                        // .catch((info) => {
                        //     console.log('Validate Failed:', info)
                        // })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="add_project_form"
                    requiredMark={false}
                    className={styles.createProjectDialog}
                    initialValues={{
                        presetId:'',
                    }}
                >
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[
                            {
                                required: true,
                                message: '请输入',
                            },
                        ]}>
                        <Input placeholder="请输入" maxLength={30}/>
                    </Form.Item>
                    <Form.Item
                        name="describe"
                        label="政策描述"
                        rules={[
                            {
                                required: true,
                                message: '请输入描述',
                            },
                        ]}>
                        <TextArea placeholder="请输入该政策的内容描述，最大500字" maxLength={500}/>
                    </Form.Item>
                </Form>
            </Modal>
          <Modal
            visible={visible}
            title={`删除政策`}
            okText="确定"
            cancelText="取消"
            centered
            width={600}
            cancelButtonProps={{className: 'footerCanceltext'}}
            onCancel={() => setVisible(false)}
            maskClosable={false}
            closable={false}
            onOk={removePolicy}
          >
            <div className={styles.desc}>
              删除将不可恢复，确定继续？
            </div>
          </Modal>
        </div>
    )
}

export default class Settings extends React.Component {
    formRef = React.createRef()
    constructor(props) {
        super(props)
        this.state = {
            activeKey: keys[0],

        }
    }
    // customRequestCoverImg = (info) => {
    //     const { onProgress, file, onSuccess, onError } = info
    //     // // 派发请求
    //     uploadImage({
    //         file: file,
    //         errorCallback: async () => {},
    //         completeCallback: async (res) => {
    //             const { hash, urlPrefix } = res

    //             this.formRef.current.setFieldsValue({
    //                 coverPhotoKey: hash,
    //                 // status:projectDetail.status
    //             })
    //             this.setState({
    //                 coverImg: [
    //                     {
    //                         url: urlPrefix + hash,
    //                     },
    //                 ],
    //             })
    //         },
    //     })
    // }
    changeAcitive = (key) => {
        this.setState({ activeKey: key })
    }

    // toSaveStoreSets = () => {
    //     this.formRef.current.submit()
    // }
    onFinish = (values) => {
        const { photoCategoryIds, canDownloadCategoryIds } = this.state
        this.props.onSave({
            ...values,
            allowVisitorPhotoCategoryIds: photoCategoryIds,
            canDownloadCategoryIds: canDownloadCategoryIds,
        })
    }

    changeCategoryIds = (ids) => {
        // console.log('ids', ids)
        this.setState({ photoCategoryIds: ids })
    }

    changeDownCategoryIds = (ids) => {
        this.setState({ canDownloadCategoryIds: ids })
    }
    setCoverImg = (info) => {
        this.setState({ coverImg: info.fileList.filter((file) => !!file.status) })
    }

    showArea = (formRef) => {
        const { activeKey, coverImg,isCoverImg } = this.state
        // const {
        //     addCategory,
        //     projectCategorys,
        //     photoCategorys,
        //     showRemove,
        //     showBookmarkForm,
        //     bookmarks,
        //     editBookmark,
        //     deleteBookmark,
        //     projectDetail,
        //     infoDetails
        // } = this.props

        // const setStatus=(status)=>{
        //     this.formRef.current.setFieldsValue({
        //         status:status,
        //     })
        // }
        let storeSetBody = (
            <div>
                <div
                    style={{ width: '80%', display: activeKey === keys[0] ? '' : 'none' }}
                >
                    <StoreBasicBlock
                        form={formRef}
                        // onAdd={addCategory}
                        // categorys={projectCategorys}
                        // showRemove={showRemove}
                        // projectDetail={projectDetail}
                        // coverImg={coverImg}
                        // isCoverImg={isCoverImg}
                        // setCoverImg={this.setCoverImg}
                        // customRequest={this.customRequestCoverImg}
                        // setStatus={setStatus}
                    />
                </div>
                <div
                    style={{ width: '80%', display: activeKey === keys[1] ? '' : 'none' }}
                >
                    <StoreShipBlock
                        form={formRef}
                        // photoCategorys={photoCategorys}
                        // projectDetail={projectDetail}
                        // changeCategoryIds={this.changeCategoryIds}
                        visitTypeValueChange={this.props.visitTypeValueChange}
                    />
                </div>
                <div
                    style={{ width: '80%', display: activeKey === keys[2] ? '' : 'none' }}
                >
                    <StoreCategoryBlock
                        form={formRef}
                        // projectDetail={projectDetail}
                        // photoCategorys={photoCategorys}
                        // infoDetails={infoDetails}
                        // changeDownCategoryIds={this.changeDownCategoryIds}
                    />
                </div>
                <div
                    style={{ width: '80%', display: activeKey === keys[3] ? '' : 'none' }}
                >
                    <StorePolicyBlock
                        // showBookmarkForm={showBookmarkForm}
                        // list={bookmarks}
                        // deleteBookmark={deleteBookmark}
                        // editBookmark={editBookmark}
                        // projectDetail={projectDetail}
                        form={formRef}
                    />
                </div>
            </div>
        )
        return <div>{storeSetBody}</div>
    }


    render() {
        // const { name, projectDetail } = this.props
        const { activeKey } = this.state
        // const initialValues = projectDetail

        return (
            <div>
                <Header className="sub-header" style={{ height: 120 }}>
                    <PageHeader
                        ghost={false}
                        className={styles.titleBar}
                        title={<div>设置</div>}
                        extra={[
                            <Button style={activeKey !== keys[0] ? hideStyle : null} type="primary" key="saveSettings"
                                // onClick={this.toSaveStoreSets}
                            >
                              保存
                            </Button>,
                        ]}
                    >
                        <div>
                            <Button
                                type="link"
                                className="tab-btn"
                                style={activeKey === keys[0] ? activeStyle : null}
                                onClick={this.changeAcitive.bind(this, keys[0])}
                            >
                                基础设置
                            </Button>
                            <Divider type="vertical" />
                            <Button
                                type="link"
                                className="tab-btn"
                                style={activeKey === keys[1] ? activeStyle : null}
                                onClick={this.changeAcitive.bind(this, keys[1])}
                            >
                                运费设置
                            </Button>
                            <Divider type="vertical" />
                            <Button
                                type="link"
                                className="tab-btn"
                                style={activeKey === keys[2] ? activeStyle : null}
                                onClick={this.changeAcitive.bind(this, keys[2])}
                            >
                                商品类型
                            </Button>
                            <Divider type="vertical" />
                            <Button
                                type="link"
                                className="tab-btn"
                                style={activeKey === keys[3] ? activeStyle : null}
                                onClick={this.changeAcitive.bind(this, keys[3])}
                            >
                                政策管理
                            </Button>
                        </div>
                    </PageHeader>
                </Header>
                <Content
                    className="sub-content"
                    // style={{ minHeight: 'calc(100vh - 120px)',paddingLeft:0,paddingRight:0}}
                    style={{
                        overflowX: 'hidden',
                        // height: 'calc(100vh - 88px)',
                        // padding:'50px 50px ',
                        // background:'#fff'
                    }}
                >
                    <Form
                        // ref={this.formRef}
                        // onFinish={this.onFinish}
                        // layout="vertical"
                        className={styles.storeSetsForm}
                        // initialValues={initialValues}
                        // key={projectDetail.id}
                        // forceRender={true}
                    >
                        {this.showArea(this.formRef)}
                    </Form>
                </Content>
            </div>
        )
    }
}

