/*
 * @Author: melon
 * @Date: 2020-07-02 16:11:39
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-11 21:07:43
 */

import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  message,
  Modal,
  Row,
  Select,
  Upload,
} from 'antd'
import { TrimInput, TrimPswInput } from 'components/WithTrim/index'
import { SidebarAppearance } from 'constants/Options'
import Imgs from 'imgs'
import { map } from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import { uploadImage } from 'utils/Uploader'
import { PswValidateRule } from 'utils/ValidateRulesAndMsg'
import commonComponent from 'components/index'
import { useHistory } from 'react-router-dom'
import { PAGE_PATH } from 'apis/Router'

import {
  changePassword as _changePassword,
  changeAvatar as _changeAvatar,
} from 'reduxModules/account'

import styles from './AccountForm.module.less'
const { User } = Imgs
const { MyMessage } = commonComponent

const { Meta } = Card
const { Option } = Select

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
}

const tailLayout = {
  wrapperCol: { span: 24 },
}

const UpdatePswForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm()
  const [saving, setSaving] = useState(false)
  const history = useHistory()
  const onOk = async () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          setSaving(true)
          const res = await onCreate(values, form)
        } catch (error) {
        } finally {
          setSaving(false)
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }
  const goRestPsw = () => {
    history.push(PAGE_PATH.forgetPsw)
  }
  return (
    <Modal
      visible={visible}
      title="更新密码"
      okText="更新"
      cancelText="取消"
      centered
      width={600}
      maskClosable={false}
      onCancel={() => {
        form.resetFields()
        onCancel(form)
      }}
      footer={<div className={styles.own_footer}>
        {/* <div className={styles.own_footer_text} onClick={goRestPsw}>忘记密码</div> */}
        <Button type="link" className="link-btn" onClick={goRestPsw} style={{color:'#066DD5',width: 'auto'}}>
          忘记密码
        </Button>
        <div>
          <Button
            key="cancel"
            onClick={() => {
              form.resetFields()
              onCancel(form)
            }}
            loading={saving}
            style={{ border: 'none'}}
          >
            取消
          </Button>
          <Button
            // style={{ border: 'none', width: 150, height: 45 }}
            key="submit"
            type="primary"
            loading={saving}
            onClick={onOk}
          >
            提交
          </Button>
        </div>
      </div>}
    >
      <Form form={form} layout="vertical" name="update_Psw_Form">
        <Form.Item
          name="oldPassword"
          label="旧密码"
          rules={[
            {
              required: true,
              message: '请输入您的旧密码!',
            },
            PswValidateRule,
          ]}
        >
          <TrimPswInput placeholder="输入旧密码" />
        </Form.Item>
        <Form.Item
          name="nowPassword"
          label="新密码"
          rules={[
            {
              required: true,
              message: '请输入您的新密码!',
            },
            PswValidateRule,
          ]}
          hasFeedback
        >
          <TrimPswInput placeholder="6位以上的数字与字母组合" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="再次输入新密码"
          dependencies={['nowPassword']}
          hasFeedback
          rules={[
            {
              required: true,
              message: '请确认你的新密码!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('nowPassword') === value) {
                  return Promise.resolve()
                }
                return Promise.reject('两次输入密码不一致!')
              },
            }),
          ]}
        >
          <TrimPswInput />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const AccountForm = (props) => {
  const { formData, getInfo } = props
  const [form] = Form.useForm()
  const [avatarUploading, setAvatarUploading] = useState(false)

  const [updatePswFormVisible, setUpdatePswFormVisible] = useState(false)
  const { avatarPhoto, nickname, account } = formData
  let history = useHistory()

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('请上传JPG或PNG格式的图片!')
    }
    const isLt2M = file.size / 1024 / 1024 < 1
    if (!isLt2M) {
      message.error('图片大小请控制在1MB以内!')
    }
    return isJpgOrPng && isLt2M
  }
  // 上传头像Upload改变
  const handleChangeAvatar = (info) => {
    if (info.file.status === 'uploading') {
      setAvatarUploading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      setAvatarUploading(false)
    }
  }

  // 上传自定义
  const customAvatarUploadRequest = (info) => {
    const { file, onSuccess, onError } = info
    // 派发请求
    uploadImage({
      file: file,
      fileName: 'user_logo',
      errorCallback: async () => {
        MyMessage.error('上传头像失败')
      },
      completeCallback: async (res) => {
        const { hash } = res
        // 派发更新的头像请求
        try {
          const resData = await _changeAvatar({
            avatar: hash,
          })
          if (resData.data && [0].includes(resData.data.code)) {
            MyMessage.success('更新头像成功')
            // 派发获取信息数据
            getInfo()
          } else {
            MyMessage.error('更新头像失败')
          }
        } catch (error) {
        } finally {
        }
      },
    })
  }
  // 关闭弹窗
  const hideUpdatePswForm = (updatePswForm) => {
    // 清除表单数据
    updatePswForm.resetFields()
    // 关闭弹窗
    setUpdatePswFormVisible(false)
  }

  // 显示更新弹窗
  const showUpdatePswForm = () => setUpdatePswFormVisible(true)

  // 更新密码
  const updatePsw = async (values, updatePswForm) => {
    // 派发更新密码请求
    try {
      const resData = await _changePassword(values)
      if (resData.data && [0].includes(resData.data.code)) {
        MyMessage.success('更新成功，请用新密码重新登录')
        hideUpdatePswForm(updatePswForm)
        setTimeout(() => {
          history.push(PAGE_PATH.login)
        }, 1000)
        return resData
      } else if (resData.data && [14005].includes(resData.data.code)) {
        MyMessage.error('旧密码错误，请检查后再试')
      } else {
        MyMessage.error('更新失败')
      }
    } catch (error) {}
  }
  useEffect(() => {
    form.setFieldsValue({
      ...formData,
    })
  }, [form, formData])
  return (
    <div className={styles.account_form_wrapper}>
      <Form
        form={form}
        ref={props.formRef}
        className={styles.account_form}
        layout="vertical"
        name="AccountForm"
        initialValues={formData}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Card style={{ minWidth: 400, marginTop: 16 }} bordered={false}>
              <Meta
                avatar={
                  <Avatar
                    style={{ borderRadius: '50%' }}
                    size={68}
                    src={avatarPhoto}
                  />
                }
                title={
                  <span>
                    {nickname}, {account}
                  </span>
                }
                description={
                  <div className={styles.user_btn_wrapper}>
                    <Upload
                      name="avatar"
                      accept = '.jpg, .jpeg, .png'
                      showUploadList={false}
                      customRequest={customAvatarUploadRequest}
                      beforeUpload={beforeUpload}
                      onChange={handleChangeAvatar}
                    >
                      <Button className={styles.upload_btn}>上传头像</Button>
                    </Upload>

                    <Button
                      className={styles.reset_psw_btn}
                      onClick={showUpdatePswForm}
                    >
                      更新密码
                    </Button>
                  </div>
                }
              />
            </Card>
          </Col>
        </Row>
          <Divider />
        <Row gutter={24}>
          <Col>
            <Form.Item
              label="用户名"
              name="nickname"
              rules={[{ required: true, message: '请输入您的用户名!' }]}
            >
              <TrimInput
                placeholder="请输入"
                className={styles.Inf_normal_input}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="手机/Email" name="mobile" rules={[]}>
              <TrimInput className={styles.Inf_normal_input} disabled />
            </Form.Item>
          </Col>
        </Row>
          <Divider />
        <Row gutter={24}>
          <Col>
            <Form.Item
              label="侧边栏外观"
              name="sidebarColorType"
              //rules={[{ required: true, message: '请选择' }]}
            >
              <Select
                placeholder="请选择"
                suffixIcon={
                  <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                }
                className={styles.Inf_normal_select}
              >
                {map(({ value, key, label }) => (
                  <Option value={value} key={value}>
                    {label}
                  </Option>
                ))(SidebarAppearance)}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <UpdatePswForm
        visible={updatePswFormVisible}
        onCancel={hideUpdatePswForm}
        onCreate={updatePsw}
      />
    </div>
  )
}
export default AccountForm
