/*
 * @Author: melon
 * @Date: 2020-07-10 10:16:33
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-10 16:23:11
 */
import Api from 'apis/Api.js'
import request from 'utils/Request'

// 分页查询未读消息
/**
 *
 * @param {*} param0
 *  pageNo 当前页
 *  pageSize 分页大小
 */
export const _getNotReadList = async ({ pageNo = 1, pageSize = 10 }) => {
  return await request({
    method: 'get',
    url: Api.message.notRead,
    params: { pageNo, pageSize },
  })
}

// 分页查询所有消息
/**
 *
 * @param {*} param0
 *  pageNo 当前页
 *  pageSize 分页大小
 */
export const _getMessageList = async ({ pageNo = 1, pageSize = 10 }) => {
  return await request({
    method: 'get',
    url: Api.message.list,
    params: { pageNo, pageSize },
  })
}
