/*
 * @Author: melon
 * @Date: 2020-07-04 23:17:14
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-11 16:58:55
 */
// Servers
// Selectors

import { _getSubscribeInfo, _getSubscribeDetail } from 'servers/subscribe'

import {
  SubscribeInfoSelector,
  SubscribeDetailSelector,
} from 'selectors/subscribe/SubscribeSelectors'
import {
  _editEnterpriseLevel,
} from 'servers/enterprise'

// Actions

// Action Creators

// init data

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action

export const getSubscribeInfo = async (data) => {
  const res = await _getSubscribeInfo(data)
  return new Promise(function (resolve, reject) {
    resolve(SubscribeInfoSelector(res))
    reject({})
  })
}

// 查询订阅详情
export const getSubscribeDetail = async (data) => {
  const res = await _getSubscribeDetail(data)
  return new Promise(function (resolve, reject) {
    resolve(SubscribeDetailSelector(res))
    reject({})
  })
}
// 查询订阅详情
export const editEnterpriseLevel = async (data) => {
  const res = await _editEnterpriseLevel(data)
  return new Promise(function (resolve, reject) {
    resolve(SubscribeDetailSelector(res))
    reject({})
  })
}
