import {
    _accountList,
    _roles,
    _changeRole,
    _remove
} from 'servers/account'
import {Button, Select, Table, Menu, Dropdown, Spin, Modal} from 'antd'
import {SettingOutlined} from '@ant-design/icons'
import AddMember from '../Components/AddMember'
import InviteMember from '../Components/InviteMember'
import MyMessage from 'components/Message/index'
import React, {forwardRef, useEffect, useState} from 'react'
import styles from './Member.module.less'
import Imgs from 'imgs'
import {Link} from "react-router-dom";
import { PAGE_PATH } from 'apis/Router'

const {Option} = Select
const {memberIcon} = Imgs

const MemberPage = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [showInvite, setShowInvite] = useState(false)
    const [accountData, setAccountList] = useState({list: [], pagination: []})
    const [roleList, setRoleList] = useState([])
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false);
    const [showVisible, setshowVisible] = useState({
        multiUser:false,
        accessControl:false
    });
    const [currentChange, setCurrentChange] = useState()
    const getList = async () => {
        setLoading(true)
        try {
            const res = await _accountList({
                pageNo: 1,
                pageSize: 20,
            })
            if (res.status === 200 && res.data.code === 0) {
                let data = res.data.data
                setAccountList({
                    list: data.records,
                    // list: reverse(data.records),
                    pagination: {
                        total: data.total,
                        pageNo: data.current
                    }
                })
                setLoading(false)
            }
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }
    const getRoles = async () => {
        try {
            const res = await _roles()
            if (res.status === 200 && res.data.code === 0) {
                setRoleList(res.data.data)
            }
        } catch (error) {
        }
    }
    const handleModal = (value) => {
        setShowModal(value)
    }
    const changeShowInvite = value => {
        if (value) {
            setShowModal(false)
            setShowInvite(true)
        } else {
            setShowModal(true)
            setShowInvite(false)
        }
    }
    const handleItem = async (type, item) => {
        if(type === '移除'){
            setVisible(true)
            setCurrentChange(item);
        }
    }
    const removeAccount = async () => {
        // setLoading(true)
        const res = await _remove({memberId: currentChange.id})
        if(res.status === 200 && res.data.code === 0) {
            setVisible(false)
            MyMessage.success('移除成功')
            getList(accountData.pagination.pageNo)
        }
    }
    const changeRole = async (value, data) => {
        if(data.rolesId === value) return
        // setLoading(true)
        const memberAccountDTO = {
            mobile: data.mobile,
            rolesId: value
        }
        try {
            let res = await _changeRole({memberAccountDTO})
            if(res.status === 200 && res.data.code === 0) {
                getList(accountData.pagination.pageNo)
                MyMessage.success('修改成功')
            }else {
                MyMessage.error(res.data.msg)
            }
        }catch (e) {}
        finally {
            // setLoading(false)
        }
    }

    useEffect(() => {
        getRoles()
        getList()
        isshow()
    }, [])
    const accountCell = ({avatarPhotoInfo: {photoUrl}, mobile, nickname }) => (<div className={styles.account_cell}>
        <img
            src={photoUrl || memberIcon}/>
        <div>
            <p className={styles.nickname}>{nickname}</p>
            <p className={styles.mobile}>{mobile}</p>
        </div>
    </div>)
    const isshow =()=>{
        if(props.infoDetails.multiUser){
            if(props.infoDetails.multiUser=='Y'){
                setshowVisible({
                    multiUser:true,
                    accessControl:false
                })
            }else{
                setshowVisible({
                    multiUser:false,
                    accessControl:true
                })
            }
        }

    }
    const columns = [
        {
            title: '账号',
            dataIndex: 'mobile',
            key: 'mobile',
            render: (_, item) => accountCell(item),
        },
        {
            title: '角色',
            dataIndex: 'rolesId',
            key: 'rolesId',
            render: (_, item) => {
                let rolesId = item.rolesId
                if (+rolesId === 1) return <span>创建者</span>
                const name = roleList.find(item => item.id === rolesId)
                return name ? (
                    <div className={styles.role_cell}>
                        <Select
                            onChange={value => changeRole(value, item)}
                            value={name.name}
                            bordered={false}
                            suffixIcon={
                                <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                            }
                        >
                            {roleList.map(item => (
                                <Option value={item.id} key={item.code}>{item.name}</Option>
                            ))}
                        </Select>
                    </div>
                ) : rolesId
            }
        },
        {
            title: '',
            dataIndex: 'opt',
            key: 'opt',
            width: 100,
            render: (_, item) => {
                if (+item.rolesId === 1) return ''
                return (
                    <div className={styles.menuBtn}>
                    <Dropdown
                        overlay={
                            <div className={styles.settingsMenu}>
                            <Menu>
                                {/* <Menu.Item key='0' onClick={() => handleItem('停用', item)}>停用</Menu.Item> */}
                                <Menu.Item key='1' onClick={() => handleItem('移除', item)}>移除</Menu.Item>
                            </Menu>
                            </div>
                        }
                        trigger={['click']}>
                        <span className="iconfont iconbtn_list_set"></span>
                    </Dropdown>
                    </div>
                )
            }
        }
    ]
    return (
        <div className={styles.member_wrapper}>
                <div style={{display:showVisible.multiUser?'block':'none'}}>
                    <Button
                        className={styles.add_btn}
                        onClick={() => {
                            handleModal(true)
                        }}
                    >
                        添加成员
                    </Button>
                    {/* <div style={{marginTop: '20px'}}><span className={styles.span}>说明：管理员角色拥有所有权限。成员角色可创建并管理自己的项目，其他仅可浏览。</span></div> */}
                    {showModal && <AddMember
                        visible={showModal}
                        setShowModal={setShowModal}
                        accountData={accountData.list}
                        getList={getList}
                        roleList={roleList}
                        changeShowInvite={changeShowInvite}
                        handleModal={handleModal}/>}
                    {showInvite && <InviteMember changeShowInvite={changeShowInvite}/>}
                    <Spin tip="加载中" spinning={loading}>
                        {accountData.list.length > 0 &&
                        <Table
                          style={{marginTop: '20px'}}
                          dataSource={accountData.list}
                          pagination={false}
                          columns={columns}
                          onChange={data => getList(data.current)}
                        />
                        }
                        <div style={{height: '30px'}}></div>
                    </Spin>
                    <Modal
                        visible={visible}
                        title={`删除用户`}
                        okText="删除"
                        cancelText="取消"
                        centered
                        width={600}
                        cancelButtonProps={{className: 'footerCanceltext'}}
                        confirmLoading={loading}
                        onCancel={() => setVisible(false)}
                        maskClosable={false}
                        closable={false}
                        onOk={removeAccount}
                    >
                        <div className={styles.desc}>
                            移除账户将不可恢复，若需找回可重新再次添加，确定删除？
                        </div>
                    </Modal>
                </div>
                <div style={{display:showVisible.accessControl&&loading?'block':'none'}}>
                    <div style={{fontSize: '14px',color: '#4A4A4A',fontWeight:"bold"}}>功能受限</div>
                    <div style={{margin:'10px 0 30px'}}>您当前的账户类型不支持多用户功能，升级到团队版即可邀请多人加入共同协作管理。</div>
                    <Button
                        key='ok'
                        type="primary">
                        <Link
                            to={PAGE_PATH.settings.children.subscribe}
                        >
                            升级订阅
                        </Link>
                    </Button>
                </div>
        </div>
    )
}

export default forwardRef(MemberPage)
