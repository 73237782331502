/*
 * @Author: melon
 * @Date: 2020-07-06 12:38:09
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-19 18:25:21
 */
import { isEmpty, map } from 'lodash/fp'

// 列表

export const EnterpriseServiceListSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    list: data,
    formattedData: {
      formattedList: isEmpty(data)
        ? []
        : map((item) => ({
            ...item,
            serverName: item.name, // 名称
            price: item.price, // 价格
            categoryCode: item.price, // 分类code
            remark: item.remark, // 描述
            coverKey: item.coverKey, // 联系微信图片key
            coverPhotoInfo: item.coverPhotoInfo,
            coverUrl: !isEmpty(item.coverPhotoInfo)
              ? item.coverPhotoInfo.photoUrl
              : '',
          }))(data),
    },
  }
  return newData
}

//详情

export const EnterpriseServiceInfoSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    list: data,
    formattedData: {
      ...data,
      serverName: data.name, // 名称
      price: data.price, // 价格
      categoryCode: data.categoryCode, // 分类code
      remark: data.remark, // 描述
      coverKey: data.coverKey, // 联系微信图片key
      coverPhotoInfo: data.coverPhotoInfo,
      fileList: !isEmpty(data.coverPhotoInfo)
        ? [
            {
              url: data.coverPhotoInfo.photoUrl,
            },
          ]
        : [],
    },
  }
  return newData
}

// 分类

export const EnterpriseServiceCategoryInfoSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    ...data,
    formattedData: !isEmpty(data)
      ? map((item) => ({
          ...item,
          key: item.code,
          value: item.code,
          label: item.name,
        }))(data)
      : [],
  }
  return newData
}

// 修改
export const EnterpriseServiceAddSelector = (data) => {
  const idInfo = data.id ? { id: data.id } : {}
  return {
    ...idInfo,
    name: data.serverName, // 名称
    price: data.price, // 价格
    categoryCode: data.categoryCode, // 分类code
    remark: data.remark, // 描述
    coverKey: data.coverKey, // 联系微信图片key
  }
}
