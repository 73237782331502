import React, { useRef } from 'react'

import { Layout, Card, Tabs, Avatar } from 'antd'

import { Route, Switch } from 'react-router-dom'

import Routes, { PAGE_PATH } from 'apis/Router'
import DashboardPage from './DashboardPage'
// 首页

import styles from './content.module.less'

const { Content } = Layout

const ContentWrapper = () => {
  return (
    <Layout
      style={{ marginLeft: 150, height: '100vh', backgroundColor: '#fff' }}
    >
      <Content>
        <Switch>
          <Route path={PAGE_PATH.home} exact component={DashboardPage} />
          {Routes.map((route, i) => (
            <Route key={i} path={route.path} exact={route.exact}>
              <route.component routes={route.routes} name={route.name} />
            </Route>
          ))}
        </Switch>
      </Content>
    </Layout>
  )
}

export default ContentWrapper
