/*
 * @Author: melon
 * @Date: 2020-06-29 11:22:13
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-18 17:03:34
 */
import { Spin } from 'antd'
import classNames from 'classnames'
import commonComponent from 'components/index'

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
} from 'react'
import {
  editShowcaseSetting as _edit,
  getShowcaseSetting as _getInfo,
} from 'reduxModules/showcaseSetting'
import styles from './About.module.less'
import AboutInfo from '../Components/AboutInfo'
import ServiceList from '../Components/ServiceList'
const { MyMessage, HoverOpt } = commonComponent

const WebAbout = (props, ref) => {
  const formRef = useRef(null)
  const [formData, setFormData] = useState({})
  const [requesting, setRequesting] = useState(false)
  const [align, setAlign] = useState('')
  const [enterpriseServiceList, setEnterpriseServiceList] = useState([])
  const [enterpriseServiceVisible, setEnterpriseServiceVisible] = useState(false)

  const getInfo = async () => {
    try {
      setRequesting(true)
      const res = await _getInfo()
      if ([0].includes(res.code)) {
        const {
          data: { formattedData },
        } = res
        setFormData(formattedData)
        setAlign(formattedData.aboutAlignType)
        setEnterpriseServiceVisible(formattedData.isShowEnterpriseService)
        setEnterpriseServiceList(formattedData.enterpriseServiceList)
      }
      return res
    } catch (error) {
      console.log('========>error=====>')
      console.log(error)
    } finally {
      setRequesting(false)
    }
  }
  useEffect(() => {
    getInfo()
  }, [])
  useImperativeHandle(ref, () => ({
    // onSaveForm 就是暴露给父组件的方法
    onSaveForm: async () => {
      formRef.current
        .validateFields()
        .then(async (formValues) => {
          const body = {
            ...formData,
            ...formValues,
            aboutAlignType: align,
            isShowEnterpriseService: enterpriseServiceVisible,
          }
          // formRef.current.resetFields()
          // 派发修改数据请求
          try {
            const resData = await _edit(body)
            if (resData.data && [0].includes(resData.data.code)) {
              MyMessage.success('保存成功')
              // 派发获取信息数据
              getInfo()
            } else {
              MyMessage.error('保存失败')
            }
          } catch (error) {}
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
        })
    },
  }))

  return (
    <Spin tip="数据加载中" spinning={requesting}>
      <div className={classNames('Inf_page', styles.about_wrapper)}>
        {/* 关于信息 */}
        <AboutInfo
          formRef={formRef}
          formData={formData}
          align={align}
          setAlign={setAlign}
        />
        {/* 服务 */}
        <ServiceList
          enterpriseServiceVisible={enterpriseServiceVisible}
          setEnterpriseServiceVisible={setEnterpriseServiceVisible}
          list={enterpriseServiceList}
          setList={setEnterpriseServiceList}
        />
      </div>
    </Spin>
  )
}

export default forwardRef(WebAbout)
