/*
 * @Author: melon
 * @Date: 2020-07-06 12:29:55
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-16 17:40:52
 */
import { isEmpty, flow, find, prop } from 'lodash/fp'
import moment from 'moment'
import { EditionTypes } from 'constants/Options'

export const SubscribeInfoSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    ...data,
    formattedData: isEmpty(data)
      ? {}
      : {
          ...data,
          id: data.id, // id
          editionType: data.editionType, // 订阅版本 [ FREE:免费版, BASIC:基础版, NORMAL:标准版, PRO:专业版, BUSINESS:商业版, PREMIUM:高级版 ]
          editionTypeDesc: data.editionTypeDesc, // 订阅版本描述
          editionPrice: data.editionPrice, // 订阅价格
          expireTime: !isEmpty(data.expireTime)
            ? moment(data.expireTime).format('YYYY/MM/DD')
            : '暂未开通', // 过期时间
          useDb: data.useDb, // 使用存储空间数(kb)
          totalDb: data.totalDb, // 总共存储空间数(kb)
          percent: data.totalDb > 0 ? data.useDb / data.totalDb : 0,
        },
  }
  return newData
}

export const SubscribeDetailSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data

  newData.data = {
    ...data,
    formattedData: !isEmpty(data)
      ? {
          ...data,
          editionType: data.editionType, // 订阅版本 [ FREE:免费版, BASIC:基础版, NORMAL:标准版, PRO:专业版, BUSINESS:商业版, PREMIUM:高级版 ]
          projectCount: data.projectCount, // 客片项目数
          editionPrice: data.editionPrice || 0, // 订阅价格
          monthPrice: data.editionPrice || 0, // 月价格
          yearPrice: data.editionPrice ? 12 * data.editionPrice : 0, // 年价格
          funcList: data.funcList, // 客片项目数
          dbDesc: data.dbDesc, // 存储描述
          totalDb: data.totalDb, // 总共存储空间数(kb)
          editionTypeDesc: flow(
            find(({ value }) => value === data.editionType),
            prop('label')
          )(EditionTypes), // 升级版本
        }
      : {},
  }
  return newData
}
