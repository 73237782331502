/*
 * @Author: melon
 * @Date: 2020-07-04 23:20:25
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-06 16:41:05
 */
import Mock from 'mockjs'
const { Random } = Mock

// 验证手机是否注册
export const CheckPhoneData = {
  code: 0,
  msg: '服务器异常',
  data: {
    whether: ['Y', 'N'][Random.natural(1, 1)],
  },
  ts: 1356019200000,
}

// 登录用户信息
export const LoginUserData = {
  // nickname: Random.cname(), // 用户昵称
  // enterpriseName: Random.name() + '企业名字',
  // logo: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png', // 商户Logo
  // logoPhotoInfo: {
  //   photoKey: 'string', // 图片Key
  //   photoUrl:
  //     'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png', // 图片完整Url
  //   photoSize: Random.natural(1, 500), // 图片大小(kb)
  //   photoFormat: ['png', 'jpg', 'jpeg'][Random.natural(0, 1)], // 图片格式
  //   photoWidth: Random.natural(1, 60), // 图片宽度(px)

  //   photoHeight: Random.natural(1, 60), // 图片高度(px)
  // },
  // sidebarColor: Random.color(), // 侧边栏色值
  // accessToken: Random.name() + Random.id(), // accessToken

  // 真实数据
  accessToken: '4FDB785BDE464338BF020702D11C8C19',
  enterpriseName: 'melon的小店',
  logo: '',
  logoPhotoInfo: {
    photoFormat: '',
    photoHeight: 0,
    photoKey: '',
    photoSize: 0,
    photoUrl: '',
    photoWidth: 0,
  },
  nickname: 'melon',
  sidebarColor: '#2B2930',
}
