/*
 * @Author: melon
 * @Date: 2020-06-29 11:22:13
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-22 11:14:02
 */
import { Button, Spin } from 'antd'
import { PAGE_PATH } from 'apis/Router'
import classNames from 'classnames'
import myMessage from 'components/Message/index'
import Imgs from 'imgs'
import { isEmpty } from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { login as _login } from 'reduxModules/member'
import { setAccessToken } from 'utils/Request'
import { Decrypt, Encrypt } from 'utils/Secret'
// 登录表单
import LoginForm from '../Components/Form'
import styles from './index.module.less'

const { LogoWT, Wxcode } = Imgs

const Login = () => {
  const history = useHistory()
  const [sendRequesting, setSendRequesting] = useState(false)
  const [formData, setFormData] = useState({})

  const doLogin = async (values) => {
    // 派发登录请求
    try {
      setSendRequesting(true)
      const res = await _login(values)
      if (res.data && [0].includes(res.data.code)) {
        const {
          data: { data: resData },
        } = res
        const { accessToken = undefined } = resData
        // 设置 登录用户信息 数据
        sessionStorage.setItem('user', JSON.stringify(resData))
        localStorage.setItem('remember', values.remember ? true : false)
        // 如果勾选记住我 设置登录信息
        if (values.remember) {
          const loginInfo = {
            account: Encrypt(values.account),
            password: Encrypt(values.password),
          }
          localStorage.setItem('loginInfo', JSON.stringify(loginInfo))
        } else {
          localStorage.removeItem('loginInfo')
        }
        // 设置 token 数据
        setAccessToken(accessToken)
        myMessage.success('欢迎')
      } else {
        myMessage.error(res.data.msg || '登录失败')
      }
      return res
    } catch (error) {
      myMessage.error('系统繁忙')
    } finally {
      setSendRequesting(false)
    }
  }

  const goRegister = () => {
    history.push(PAGE_PATH.register)
  }
  useEffect(() => {
    const remember = localStorage.getItem('remember')
    const loginInfo = localStorage.getItem('loginInfo')
      ? JSON.parse(localStorage.getItem('loginInfo'))
      : {}

    if (['true'].includes(remember) && !isEmpty(loginInfo)) {
      setFormData({
        remember: true,
        account: Decrypt(loginInfo.account),
        password: Decrypt(loginInfo.password),
      })
    } else {
      setFormData({
        remember: false,
        account: undefined,
        password: undefined,
      })
    }
  }, [])

  return (
    <div className={styles.login_page}>
      <div className={classNames(styles.login_show_info_container)}>
        <div className={classNames(styles.left_header)}>
          <p className={classNames(styles.welcome)}>Welcome to</p>
          <div className={styles.brands}>
            <img src={LogoWT} alt=""></img>
          </div>
        </div>
        <div className={classNames(styles.left_content)}>
          <p className={styles.brands_text}>
            开始建立并管理你的业务，为客户提供专业优雅的体验。
          </p>
          {/* <Button onClick={() => goRegister()} className={styles.register_btn}>
            创建账号
          </Button> */}
        </div>
        {/* <div className={styles.left_footer}>
          <img src={Wxcode} className={styles.code_img}></img>
          <span className={styles.follow_text}>
            欢迎关注我们的微信公众号，获取更多资料和优惠。
          </span>
        </div> */}
      </div>

      <div className={classNames(styles.login_form_container)}>
        <Spin
          tip="登录中..."
          spinning={sendRequesting}
          size="large"
          style={{ width: '100%', height: '100%' }}
        >
          <LoginForm
            doSubmit={doLogin}
            history={history}
            formData={formData}
            key={formData.remember}
          />
        </Spin>
      </div>
    </div>
  )
}

export default Login
