/*
 * @Author: melon
 * @Date: 2020-07-05 22:18:27
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-13 10:10:02
 */
import Mock from 'mockjs'
const { Random } = Mock

export const EnterpriseData = {
  name: Random.cword(4) + '企业名称', // 企业名称
  remark: Random.cword(100), // 企业简介
  contactNumber: Random.phone(), // 联系电话
  email: Random.email(), // 联系email
  provinceGeoId: '110000', // 省id
  cityGeoId: '110100', // 市id
  areaGeoId: '110105', // 区id
  address: Random.city(true), // 详细街道地址
  wechatImageKey: Random.name(), // 联系微信图片key
  wechatImagePhotoInfo: {
    photoKey: 'string', // 图片Key
    photoUrl:
      'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png', // 图片完整Url
    photoSize: Random.natural(1, 500), // 图片大小(kb)
    photoFormat: ['png', 'jpg', 'jpeg'][Random.natural(0, 1)], // 图片格式
    photoWidth: Random.natural(1, 60), // 图片宽度(px)
    photoHeight: Random.natural(1, 60), // 图片高度(px)
  },
  weiboUrl: Random.url(), // 微博地址
  wechatNo: 'string', // 微信号/微信公众号
  instagram: Random.url(), // instagram
}
