import { isEmpty, map } from 'lodash/fp'
import moment from "moment";

// 获取
export const getInfoClientSelector = (res) => {
  let newData = {
    ...res,
  }
  // console.log(newData,'newdata')
  return newData
}

// 查询
export const clientInfosSelector = (res) => {
  let newData = {
    ...res,
  }
  return newData
}
// 修改
export const editClientSelector = (res) => {
  let newData = {
    ...res,
  }
  return newData
}

// 添加
export const addClientSelector = (data) => {
  return {
    ...data,
  }
}

// 删除
export const removeClientSelector = (data) => {
  return {
    ...data,
  }
}
// 查询B端客户管理记录信息
export const customerRecordSelector = (res) => {
  // console.log(res)
  // return
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    ...data,
    commentData: {
      commentList: isEmpty(data.list)
          ? []
          : map((item) => ({
            ...item,
            timeText: moment().isAfter(moment(item.createTime), 'day')
                ? moment(item.createTime).format('YYYY/MM/DD HH:mm')
                : moment(item.createTime).fromNow(),
            nickname:item.memberInfoResultVO.nickname
          }))(data.list),
      notReadTotal: data.totalCount,
      formattedPagination: {
        current: Number(data.pageNo),
        pageSize: Number(data.pageSize),
        total: Number(data.totalCount),
      },
    },
  }
  return newData
}


