import {Button, Col, DatePicker, Form, Input, Layout, Menu, Modal, Row} from 'antd'
import AddSelect from 'components/AddSelect'
import React, {useEffect, useState} from 'react'
import styles from './addProjectForm.module.less'
import {Link, useHistory} from "react-router-dom";
import {PAGE_PATH} from 'apis/Router'
import components from 'components'
import {
  getProjectCategory as _getProjectCategory,
} from 'reduxModules/customerPhoto'
import {
  saveProjectCategory as _saveProjectCategory,
} from 'reduxModules/customerPhoto'
const {message, CategoryModal, InfoEmptyData} = components
const {Header, Footer, Sider, Content} = Layout
const ProjectMenu = ({
                     name,
                     setNewKeys
                }) => {
    let history = useHistory()
    const [selectedKey, setSelectedKey] = useState('all')
    const [fname, setFname] = useState('全部')
    const [newKey, setNewKey] = useState('')
    const [projectCategorys, setProjectCategorys] = useState([])
  const [loadingCategory, setLoadingCategory] = useState(false)
  const [categoryModalVisible, setCategoryModalVisible] = useState(false)
  useEffect(() => {
    getProjectCategory()
  }, [])
  //获取项目类别
  const getProjectCategory = async () => {
    setLoadingCategory(true)
    const res = await _getProjectCategory()
    if (res.code === 0) {
      let key=sessionStorage.getItem('newKey')?sessionStorage.getItem('newKey'):'all'
      if(window.location.pathname==='/collection/preset'){
        setSelectedKey('preset')
      }else if(window.location.pathname==='/collection/recycle'){
        setSelectedKey('recycle')
      }else{
        setSelectedKey('key')
      }
      setProjectCategorys(res.data)
      setLoadingCategory(false)
    } else {
      message.error(res.msg)
    }
  }
    const changeMenu = ({item, key, keyPath, domEvent}) => {
      // console.log('presetrecycle'.includes(key),key)
      const oldNewKey=sessionStorage.getItem('newKey')
      //预设与回收站
    if('presetrecycle'.includes(key)){
      // if('preset'===key||key==='recycle'){
      sessionStorage.setItem('newKey','other')
      // history.push(key)
    }else{
      let menuLabel = ''
      // if (key == 'preset') {
      //   setFname('项目预设')
      //   return
      // }
      if (key !== null && key !== undefined) {
        const matched = projectCategorys.filter(
          (item) => item.id.toString() === key
        )
        menuLabel = matched.length > 0 && matched[0].name
      }
      const name = key === 'all' ? '全部' : menuLabel
      const newKey = key === 'all' ? '' : key
      sessionStorage.setItem('newKey',newKey)
      if(setNewKeys){setNewKeys(newKey,name)}
      if(oldNewKey==='other') { //不是项目则跳转到项目页
        history.push(PAGE_PATH.customerPhoto.index)
      }
    }
  }
  const toShowCategoryModal = () => {
    setCategoryModalVisible(true)
  }
  // 新增或修改，排序项目类
  const doSaveCategory = async (values) => {
    const res = await _saveProjectCategory(values)
    setCategoryModalVisible(false)
    if (res.data.code === 0) {
      message.success('提交成功')
      getProjectCategory()
    } else {
      message.error('请求失败')
    }
  }
    return (
      <div>
        <Sider
          style={{
              overflowX: 'hidden',
              height: '100vh',
              position: 'fixed',
          }}
          theme="light"
          className="sub-sidebar"
        >
            <div className="sub-title">{name}</div>
            <Menu
              mode="inline"
              theme="light"
              style={{border: 'none', maxHeight: '100%'}}
              onClick={changeMenu}
              inlineIndent={30}
              className="sub-sider-menu"
              defaultSelectedKeys={[selectedKey]}
            >
                <Menu.Item key={'all'}>全部</Menu.Item>
                {projectCategorys.map((category) => (
                  <Menu.Item key={category.id}>{category.name}</Menu.Item>
                ))}
                <Menu.Item disabled>
                    <Button
                      type="link"
                      onClick={toShowCategoryModal}
                      className="link-btn"
                    >
                        管理分类
                    </Button>
                </Menu.Item>
                <Menu.Divider/>
                <Menu.Item key={'preset'}>
                    <Link to={PAGE_PATH.customerPhoto.children.preset}>
                        项目预设
                    </Link>
                </Menu.Item>
                <Menu.Item key={'recycle'}>
                    <Link to={PAGE_PATH.customerPhoto.children.recycle}>
                        回收站
                    </Link>
                </Menu.Item>
            </Menu>
        </Sider>
        <CategoryModal
          visible={categoryModalVisible}
          onCreate={doSaveCategory}
          onCancel={() => {
            setCategoryModalVisible(false)
          }}
          key={loadingCategory}
          list={projectCategorys}
          title={'项目分类管理'}
          tip={'确定删除该项目分类？'}
          />
      </div>
    )
}

export default ProjectMenu
