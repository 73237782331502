import { Col, Dropdown, Menu, Row } from 'antd'
import { PAGE_PATH } from 'apis/Router'
import { ProjectStatus } from 'constants/Options'
import Imgs from 'imgs'
import { isEmpty } from 'lodash'
import React, {useEffect, useState} from 'react'
import { Link, useHistory } from 'react-router-dom'
import { getFullRoute } from 'utils/Tools'
import styles from './projectPage.module.less'
import classNames from "classnames";

const { DefaultCoverPNG } = Imgs
const ProjectPage = ({
  list = [],
  showRemove,
  showShare,
  changeStatus,
  toPreview,
  toPath
}) => {
  let history = useHistory()
  const [projectId, setProjectId] = useState(null)
  const [imgnum, setImgnum] = useState(0)
    useEffect(() => {
        // 监听
        window.addEventListener('resize', handleResize);
        // 销毁
        return () => window.removeEventListener('resize', handleResize)
    }, []);
    const handleResize = () => {
        let arr=document.getElementsByClassName('img-parent')
        let w=document.getElementsByClassName('masonry-img')[0].offsetWidth
        for(let a=0;a<arr.length;a++){
            arr[a].style.height=Math.round(w*2/3)+'px'
        }
    };
  const changeMenu = ({item, key, keyPath, domEvent}) => {
    console.log(item,'+', key,'+', keyPath,'+', domEvent)
    toPath(key)
  }
  const getMenu = (project) => {
    return (
    <div className={styles.projectMenu}>
      {/*<Menu onClick={changeMenu}>*/}
      {/*  <Menu.Item key={getFullRoute(PAGE_PATH.customerPhoto.details, {*/}
      {/*    projectId: project.id,*/}
      {/*    showKey: 'photos',*/}
      {/*  })}>*/}
      <Menu>
        <Menu.Item>
          <Link
            to={getFullRoute(PAGE_PATH.customerPhoto.details, {
              projectId: project.id,
              showKey: 'photos',
            })}
          >
            图片管理
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            to={getFullRoute(PAGE_PATH.customerPhoto.details, {
              projectId: project.id,
              showKey: 'settings',
            })}
          >
            设置
          </Link>
        </Menu.Item>
        {!project.customerId?'':(
            <Menu.Item>
              <Link
                  to={getFullRoute(PAGE_PATH.customerPhoto.details, {
                    projectId: project.id,
                    showKey: 'fav',
                  })}
              >
                客户反馈
              </Link>
            </Menu.Item>
        )}

        <Menu.Item>
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={showShare.bind(this, project)}
          >
            {project.status === ProjectStatus[1].key
                ? '分享'
                : '上线并分享'}
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={toPreview.bind(this, project)}
          >
            预览
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={changeStatus.bind(this, project)}
          >
            {project.status !== ProjectStatus[0].key
              ? ProjectStatus[0].label
              : '上线'}
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={showRemove.bind(this, project)}
          >
            删除项目
          </a>
        </Menu.Item>
      </Menu>
    </div>
    )
  }
  const onLoadImg = (e,i) => {
      if(i==0){
          let w=e.currentTarget.offsetWidth
          let arr=document.getElementsByClassName('img-parent')
          for(let a=0;a<arr.length;a++){
              arr[a].style.height=Math.round(w*2/3)+'px'
          }
          setWidth(w)
      }
   }
   const setWidth=()=>{

   }
  return (
    <div>
      <Row gutter={35} className="grid">
        {list.map((project,i) => {
          return (
              <Col key={project.key}
                className="grid-item"
                // xs={24}
                // sm={24}
                // md={12}
                // lg={8}
                // xl={6}
                // xxl={4}
              >
              <div className={styles.project}>
                <Link
                  to={getFullRoute(PAGE_PATH.customerPhoto.details, {
                    projectId: project.id,
                    showKey: 'photos',
                  })}
                >
                  <div className={classNames('img-parent', styles.projectImgs)}>
                    <img
                      src={isEmpty(project.img) ? DefaultCoverPNG : project.img}
                      className={classNames('masonry-img',styles.img)}
                      onLoad={(e) => {
                        e.stopPropagation()
                        onLoadImg(e,i)
                    }}
                    />
                    <div className={styles.topArea}>
                      {/* <svg
                        className={`icon ${styles.lock}`}
                        aria-hidden="true"
                        style={{
                          visibility: project.showLock ? '' : 'hidden',
                        }}
                      >
                        <use xlinkHref="#iconicon_protected"></use>
                      </svg> */}
                      <span
                        style={{
                          visibility: project.showLock ? '' : 'hidden',
                        }}
                        className={`iconfont iconico_protect ${styles.lock}`}
                      ></span>
                    </div>
                  </div>
                </Link>
                <div className={styles.projectLabel}>
                  <div className={styles.projectName}>{project.name}</div>
                    <span className={styles.menu}>
                      <Dropdown
                        overlay={getMenu.bind(this, project)}
                        placement="bottomRight"
                        trigger={['click']}
                      >
                        <span className="iconfont iconbtn_projectmenu icon-btn" style={{fontWeight: 'normal', color: '#757575'}}></span>
                      </Dropdown>
                    </span>
                  </div>
                <div className={styles.remark}>
                  {`${project.shootingDay?project.shootingDay:'未定义日期'} · ${project.customerPhotoCount}张照片`}
                  { `${project.statusDesc}`=='隐藏'?'':` · 已${project.statusDesc}` }
                </div>
              </div>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}
export default ProjectPage
