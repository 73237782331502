import Api from 'apis/Api.js'
import request from 'utils/Request'

// 查询客户列表信息
export const _getInfoClient = async ( params ) => {
  return await request({
    method: 'get',
    url: Api.client.infoClient,
    params: params,
  })
}

// 编辑
export const _clientInfos = async (params) => {
  return await request({
    method: 'get',
    url: Api.client.clientInfos,
    params: params,
  })
}

// 编辑
export const _editClient = async (data) => {
  return await request({
    method: 'post',
    url: Api.client.uplateClient,
    data: data,
  })
}

// 添加
export const _addClient = async (data) => {
  return await request({
    method: 'post',
    url: Api.client.addClient,
    data: data,
  })
}

// 删除
export const _removeClient = async (data) => {
  return await request({
    method: 'post',
    url: Api.client.removeClient,
    data: data,
  })
}

// 查询B端客户管理记录信息
export const _customerRecord = async (params) => {
  return await request({
    method: 'get',
    url: Api.client.customerRecord,
    params: params,
  })
}
