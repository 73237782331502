import { Form, Input, Modal } from 'antd'
import React, {useState} from 'react'
import styles from './removeProjectForm.module.less'

const RemoveProjectForm = ({ visible, onCreate, onCancel, project }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)


  const checkRemoveField = (rule, value) => {
    if (value && value === '删除') {
      return Promise.resolve()
    }

    return Promise.reject('请输入“删除”')
  }
  return (
    <Modal
      visible={visible}
      title={`删除项目#${project.name}#`}
      okText="删除"
      cancelText="取消"
      width={600}
      centered
      cancelButtonProps={{className: 'footerCanceltext'}}
      confirmLoading={loading}
      onCancel={onCancel}
      maskClosable={false}
      closable={false}
      onOk={() => {
        form
          .validateFields()
          .then(async () => {
            setLoading(true)
            form.resetFields()
            await onCreate()
            setLoading(false)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <div className={styles.desc}>
        该项目所有图片与相关记录将会被移除，20天内可在回收站恢复，若继续请在输入框内输入“删除”并提交。
      </div>
      <Form form={form} layout="vertical" name="remove_project_form">
        <Form.Item
          name="remove"
          style={{margin: '10px 0 0'}}
          rules={[
            {
              validateTrigger: 'submit',
              validator: checkRemoveField,
            },
          ]}
        >
          <Input placeholder="请输入“删除”并点击提交"/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RemoveProjectForm
