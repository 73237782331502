import { Button, Divider, Input, Select } from 'antd'
import { isEmpty } from 'lodash'
import React, { useRef, useState } from 'react'
import styles from './index.module.less'
const { Option } = Select
/**
 *
 * @param {array} options- 下拉选项的选项
 * @param {function} onAdd- 外部保存新增选项方法(可选)
 */
const AddSelect = ({ value, onChange, options = [], onAdd }) => {
  const [name, setName] = useState('')
  // const [selectedKey, setSelectedKey] = useState(undefined)
  const [isEditing, setIsEditing] = useState(false)
  const selectRef = useRef()
  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue)
    }
  }

  const onNameChange = (event) => {
    setName(event.target.value)
  }

  const addItem = () => {
    if (!isEmpty(name.trim())) {
      onAdd(name)
      setName('')
    }
    setIsEditing(false)
  }
  const onChangeSelect = (newKey) => {
    // setSelectedKey(newKey)
    triggerChange(newKey)
  }

  return (
    <Select
      showSearch
      suffixIcon={
        <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
      }
      onChange={onChangeSelect}
      placeholder="请选择"
      style={{ width: '100%' }}
      value={value}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div>
            {isEditing ? (
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 6 }}>
                <Input
                  style={{ flex: 'auto' }}
                  value={name}
                  onChange={onNameChange}
                  placeholder="请输入..."
                  size="small"
                  className={styles.noBorderInput}
                />
                <Button
                  type="link"
                  className={styles.linkBtn}
                  style={{
                    flex: 'none',
                    padding: '6px',
                    display: 'block',
                  }}
                  onClick={addItem}
                >
                  保存
                </Button>
              </div>
            ) : (
              <Button
                type="link"
                className={styles.linkBtn}
                onClick={() => setIsEditing(true)}
              >
                添加新的分类
              </Button>
            )}
          </div>
        </div>
      )}
    >
      {options.map((item) => (
        <Option key={item.id}>{item.name}</Option>
      ))}
    </Select>
  )
}
export default AddSelect
