/*
 * @Author: melon
 * @Date: 2020-07-02 16:11:39
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-13 21:28:44
 */

import { Button, Form, Modal, Select } from 'antd'
import commonComponent from 'components/index'
import {
  TrimInput,
  TrimInputNumber,
  TrimTextArea,
} from 'components/WithTrim/index'
import { map } from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import { getCategorySelector as _getCategorySelector } from 'reduxModules/enterpriseService'
import { uploadImage } from 'utils/Uploader'
import { RequiredSelectRule } from 'utils/ValidateRulesAndMsg'
import styles from './ServiceForm.module.less'

const { Option } = Select
const { MyMessage, CoverUpload } = commonComponent

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
}

const tailLayout = {
  wrapperCol: { span: 24 },
}

const ServiceItemForm = ({ visible, onCreate, onCancel, type, formData }) => {
  const [form] = Form.useForm()
  const [avatarUploading, setAvatarUploading] = useState(false)
  const [categoryCodeOptions, setCategoryCodeOptions] = useState([])
  const [fileList, setFileList] = useState([])
  const onChange = ({ file, fileList }) => {
    if (file.status === 'uploading') {
      setAvatarUploading(true)
      return
    }
    if (file.status === 'done') {
      // Get this url from response in real world.
      setAvatarUploading(false)
    }
  }
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      MyMessage.error('请上传JPG或PNG格式的图片!')
    }
    const isLt2M = file.size / 1024 / 1024 < 1
    if (!isLt2M) {
      MyMessage.error('图片大小请控制在1MB以内!')
    }
    return isJpgOrPng && isLt2M
  }
  // 上传自定义
  const customRequest = (info) => {
    const { file, onSuccess, onError } = info
    // 派发请求
    uploadImage({
      file: file,
      fileName: 'user_logo',
      errorCallback: async () => {
        MyMessage.error('上传失败')
      },
      completeCallback: async (res) => {
        MyMessage.success('上传成功')
        const { hash, urlPrefix } = res
        form.setFieldsValue({
          coverKey: hash,
        })
        setFileList([
          {
            url: urlPrefix + hash,
          },
        ])
        onSuccess(res)
      },
    })
  }
  const [saving, setSaving] = useState(false)
  const isAdd = ['add'].includes(type)
  const onOk = async () => {
    form
      .validateFields()
      .then((values) => {
        try {
          setSaving(true)
          form.resetFields()
          setFileList([])
          onCreate(values, form)
        } catch (error) {
        } finally {
          setSaving(false)
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }

  const getList = async () => {
    try {
      const res = await _getCategorySelector()
      if ([0].includes(res.code)) {
        const {
          data: { formattedData },
        } = res

        setCategoryCodeOptions(formattedData)
      }
    } catch (error) {
    } finally {
    }
  }
  useEffect(() => {
    const file = formData.fileList || []
    setFileList(file)
  }, [form, formData])

  useEffect(() => {
    getList()
  }, [])

  // console.log('formData', formData)
  // console.log('categoryCodeOptions', categoryCodeOptions)

  return (
    <Modal
      visible={visible}
      width={600}
      title={(isAdd ? '添加' : '编辑') + '服务'}
      okText={isAdd ? '创建' : '保存'}
      cancelText="取消"
      centered
      maskClosable={false}
      onCancel={() => {
        form.resetFields()
        onCancel(form)
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            form.resetFields()
            onCancel(form)
          }}
          style={{ border: 'none', height: 40, color: '#9B9B9B' }}
        >
          取消
        </Button>,
        <Button
          style={{ border: 'none', width: 100, height: 40 }}
          key="submit"
          type="primary"
          onClick={onOk}
        >
          {isAdd ? '创建' : '保存'}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        className={styles.service_form}
        name="service_item_Form"
        initialValues={formData}
      >
        <Form.Item
          label="服务名称"
          name="serverName"
          rules={[
            {
              required: true,
              message: '请输入服务名称',
            },
          ]}
        >
          <TrimInput maxLength={100} className={styles.Inf_large_input} />
        </Form.Item>

        <Form.Item label="封面图">
          <Form.Item
            name="coverKey"
            className={styles.coverUpload}
            rules={[
              {
                required: true,
                message: '输入上传封面图片',
              },
            ]}
          >
            <CoverUpload
              key="cover"
              uploadProps={{
                fileList,
                onChange,
                customRequest,
                beforeUpload,
                accept: '.jpeg,.jpg',
              }}
              showButtons={{
                uploadButton: (
                  <Button type="primary" style={{ padding: '4px 40px' }}>
                    上传
                  </Button>
                ),
                replaceButton: (
                  <Button type="primary" style={{ padding: '4px 40px' }}>
                    替换
                  </Button>
                ),
              }}
            />
          </Form.Item>
          <span key="description" className={styles.description}>
            该封面图将作为服务信息缩略图显示在关于页面。
          </span>
        </Form.Item>

        <Form.Item
          className={styles.Inf_ant_form_item}
          label="价格"
          name="price"
          // rules={[RequiredInputRule]}
        >
          <TrimInputNumber
            min={0}
            max={9999999999999}
            className={styles.Inf_normal_input}
          />
        </Form.Item>
        <Form.Item
          className={styles.Inf_ant_form_item}
          label="分类"
          name="categoryCode"
          rules={[RequiredSelectRule]}
          style={{ marginRight: 0, width: '250px' }}
        >
          <Select
            placeholder="请选择"
            suffixIcon={
              <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
            }
          >
            {map(({ value, key, label }) => (
              <Option value={value} key={'categoryCode' + key}>
                {label}
              </Option>
            ))(categoryCodeOptions)}
          </Select>
        </Form.Item>

        <Form.Item
          label="服务描述"
          name="remark"
          // rules={[
          //   {
          //     required: true,
          //     message: '请输入服务描述',
          //   },
          // ]}
        >
          <TrimTextArea
            maxLength={500}
            placeholder="可详细描述下你的服务详情，限100字"
            className={styles.Inf_large_input}
            style={{ height: 75 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ServiceItemForm
