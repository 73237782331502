//提现记录
import {
  Button,
  Layout,
  Menu,
  Divider,
  Dropdown,
  PageHeader,
  Spin,
  Select,
  Col,
  Row,
  Input,
  Tooltip,
  Card,
  Table,
  Pagination,
  Modal,
  Form, DatePicker
} from 'antd'
import {InfoCircleOutlined, UserOutlined, SearchOutlined,CloseCircleFilled} from '@ant-design/icons';
import components from 'components'
import {ProjectStatus, ArrangementTypes} from 'constants/Options'
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import { PAGE_PATH } from 'apis/Router'
import {
  getInfoClient as _getInfoClient,
  editClient as _editClient,
  addClient as _addClient,
  removeClient as _removeClient,
} from 'reduxModules/client'
import {
  addOrderAdd as _addOrderAdd,
  deliverGoods as _deliverGoods,
  getOrderAll as _getOrderAll,
  getOrderDetails as _getOrderDetails,
} from 'reduxModules/store'
import CommonComponent from 'components/index'
import classNames from "classnames";
import myMessage from 'components/Message'
import styles from './earnings.module.less'
import { earningsType } from 'constants/Options'
import {
  getOptionBykeyName,
} from 'utils/Tools'
import Imgs from 'imgs'
import moment from "moment";
import {map} from "lodash/fp";
const {Header, Footer, Sider, Content} = Layout
const { MyMessage, InfoEmptyData } = CommonComponent
// const {ClientProfoto} = Imgs

const WithdrawRecord = ({name,ref}) => {
  let history = useHistory()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [selectedOrder, setSelectedOrder] = useState({
    sourceEnum: '', //来源
    month: '近一个月', //来源
    status: '', //分组
    research:'',//邮件/名称/电话
    pageNo:1,
    pageSize:10,
    current:1,
    totalCount:20
  })
  // 返回
  const goBack = () => {
    history.goBack()
  }
  // 总数
  const [total, setTotal] = useState(0)
  useEffect(() => {
    getListByClient()
  }, [])
  // 获取订单列表
  const getListByClient = async () => {
    setLoading(true)
    // const res = await _getInfoClient({})
    let data={
      pageNum: selectedOrder.pageNo,
      pageSize: selectedOrder.pageSize,
      storeId: sessionStorage.getItem('user')
        ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
        :null
    }
    if(selectedOrder.research){
      data.research=selectedOrder.research
    }
    if(selectedOrder.status){
      data.status=selectedOrder.status
    }
    const res = await _getOrderAll(data)
    setLoading(false)
    if (res.code === '000000') {
      console.log(res.data)
      setOrderList(res.data.records)
      setTotal(res.data.total)
    } else {
      myMessage.error(res.mesg)
    }
  }
  const columns = [
    {
      title: '编号',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (_, item) =>  {return (
        <div style={{fontWeight: 'bold'}}>
          {item.orderNum}
        </div>
      )},
    },
    {
      title: '提现金额',
      dataIndex: 'totalMoney',
      key: 'totalMoney',
      // align: 'right',
      render: (_, item) =>  {return (
        <div>
          ￥{item.totalMoney}
        </div>
      )},
    },
    {
      title: '申请日期',
      dataIndex: 'orderDate',
      key: 'orderDate',
      render: (_, item) =>  {return (
        <div>
          {`${item.orderDate.replace(/-/g,'/').split(':')[0]}:${item.orderDate.replace(/-/g,'/').split(':')[1]}`}
        </div>
      )},
    },
    {
      title: '状态',
      dataIndex: 'statusText',
      key: 'statusText',
    },
    {
      title: '关联订单',
      dataIndex: '',
      key: '',
      align: 'right',
      render: (_, item) => {
          // if (+item.rolesId === 1) return ''
          return (
              <div>
                  <Dropdown
                      overlay={
                          <div className={styles.settingsMenu}>
                              <Menu>
                                  {/* <Menu.Item><a key='0' onClick={() => handleItem('停用', item)}>停用</a></Menu.Item> */}
                                  {/* <Menu.Item><a key='1' onClick={() => handleItem('添加客片', item)}>添加客片项目</a></Menu.Item>
                                  <Menu.Item><a key='2' onClick={() => handleItem('添加消费', item)}>添加消费记录</a></Menu.Item>
                                  <Menu.Item><a key='3' onClick={() => handleItem('添加标签', item)}>添加标签</a></Menu.Item>
                                  <Menu.Divider /> */}
                                  {/*<Menu.Item><a key='4' onClick={() => handleItem('编辑', item)}>编辑</a></Menu.Item>*/}
                                  {/*<Menu.Divider />*/}
                                  {/*<Menu.Item><a key='5' onClick={() => handleItem('删除', item)}>删除</a></Menu.Item>*/}
                              </Menu>
                          </div>
                      }
                      placement="bottomRight"
                      trigger={['click']}>
                      {/*<span className="iconfont iconbtn_list_set"></span>*/}
                      <span className={styles.checkOrders}>查看</span>
                  </Dropdown>
              </div>
          )
      }
    },
  ]
  // const handleItem = async (type, item) => {
  //   console.log(type)
  //   if (type === '编辑') {

  //   } else if (type === '删除') {

  //   }
  // }
  return (
    <Layout>
      <PageHeader
        ghost={false}
        className={styles.titleBar}
        title={
          <div>
            <Button
              icon={
                <span
                  className={`iconfont iconbtn_back`}
                ></span>
              }
              onClick={goBack}
              className={styles.backBtn}
            />
            提现记录
          </div>
        }
      ></PageHeader>
      <Card bordered={false} className={classNames(styles.withdrawRecord, styles.earnings)}>
        <Spin tip="数据加载中" spinning={loading}>
          {orderList.length > 0||selectedOrder.sourceEnum!=''||selectedOrder.research!=''?(
            <Row className={styles.controlBar}>
              <Col span={12}>
                <span className={styles.ptCount}>{total}条记录</span>
              </Col>
            </Row>):null
          }
          {orderList.length > 0 && (
            <Table
              // className={styles.tablesbody}
              style={{marginBottom: '30px'}}
              dataSource={orderList}
              columns={columns}
              pagination={false}
            />
          )}
        </Spin>
      </Card>

    </Layout>
  )
}

export default WithdrawRecord
