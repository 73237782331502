import { Card } from 'antd'
import Imgs from 'imgs'
import React from 'react'
import styles from './index.module.less'

const { photoSample } = Imgs
const { Meta } = Card

const ProductCard = ({
  imgSrc = photoSample,
  header = '这是标题',
  imgStyle = {},
  footer = '',
  coverDivStyle = {},
}) => {
  return (
    <div className={styles.Inf_product_card}>
      <div className={styles.Inf_product_card_img} style={coverDivStyle}>
        <img style={imgStyle} src={imgSrc}></img>
      </div>
      <div className={styles.Inf_product_card_content}>
        <div className={styles.Inf_product_card_header}>{header}</div>
        <div className={styles.Inf_product_card_footer}>{footer}</div>
      </div>
    </div>
  )
}

export default ProductCard
