import React from 'react'
import { Button, Divider } from 'antd'
import styles from './bottomNotice.module.less'

export default class BottomNotice extends React.Component {
  state = {
    visiable: false,
  }

  componentDidMount() {
    const { _overFavorite, bookMarkDetail } = this.props
    _overFavorite({ bookmarkId: bookMarkDetail.id }).then((res) => {
      if (res.data.code == 0) {
        this.setState({
          visiable: res.data.data,
        })
      }
    })
  }

  ignoreExceedTip = async () => {
    const { bookMarkDetail, _ignoreExceedTip } = this.props
    const res = await _ignoreExceedTip({ id: bookMarkDetail.id })
    if (res.data.code == 0) {
      this.setState({
        visiable: false,
      })
    }
  }

  render() {
    const { visiable } = this.state
    const { bookMarkDetail } = this.props
    const expendCount =
      bookMarkDetail.currentPhotoCount - bookMarkDetail.maxCount
    return (
      visiable && (
        <div
          className={styles.bottomNotice}
          // style={{
          //   display: bookMarkDetail.isShowExceedTip && visiable ? '' : 'none',
          // }}
        >
          <div className={styles.bottomNoticeContent}>
            <div>
              <div className={styles.desc}>
                <span
                  className={`iconfont iconico_remind ${styles.descIcon}`}
                ></span>
                客户提交的选择超出{expendCount}
                张照片，额外增加￥{expendCount * bookMarkDetail.plusFee}
                元待支付。
              </div>
            </div>
            <div className={styles.btns}>
              {/* <Button
                type="link"
                size="small"
                className={`link-btn ${styles.linkBtn}`}
              >
                发起收款
              </Button>
              <Divider type="vertical" /> */}
              <Button
                type="link"
                size="small"
                className={`link-btn ${styles.linkBtn}`}
                onClick={this.ignoreExceedTip}
              >
                忽略
              </Button>
            </div>
          </div>
        </div>
      )
    )
  }
}
