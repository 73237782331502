import {
    _addAccount
} from 'servers/account'
import {Modal, Button, Input, message, List, Select, Form} from 'antd'
import {DownOutlined} from '@ant-design/icons'
import React, {useState, useEffect} from 'react'
import MyMessage from 'components/Message/index'
import { phonePattern } from 'utils/ValidateRulesAndMsg'
import {
    checkPhone as _checkPhone,
} from 'reduxModules/member'
import { CheckPhoneSelector } from 'selectors/Register/RegisterSelectors'
import style from './AddMember.module.less'
import Imgs from 'imgs'

const {memberIcon, close} = Imgs
const {Option} = Select


const AddMember = (props) => {
    const [inputValue, setInputValue] = useState('')
    const [memberList, setMemberList] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    const [form] = Form.useForm()
    const { accountData, visible, setShowModal } = props;

    const handleOk = async () => {
        setBtnLoading(true)
        if(!memberList.length){
            MyMessage.warning('请输入手机号码添加成员')
            setBtnLoading(false)
            return
        }
        try {
            const res = await _addAccount({
                memberAccountDTOS: memberList
            })
            if(res.status === 200 && res.data.code === 0) {
                setMemberList([])
                props.getList()
            }
        } catch (e) {
        } finally {
            setBtnLoading(false)
            setShowModal(false)
            MyMessage.success('添加成功')
        }
    }

    const onKeyUp = async e => {
            const phone = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/;
            if (phone.test(inputValue)) {
                if (e.keyCode === 13) {
                    const count = accountData.length + memberList.length;
                    if (accountData.length + memberList.length > 19) {
                        MyMessage.error('仅支持添加最多20个成员用户')
                        return
                    }
                    const item = memberList.find(item => item.mobile === inputValue) ||
                    accountData.find(item => item.mobile === inputValue)
                    if (item) {
                        MyMessage.error('该号码已存在')
                        return
                    }
                    await _checkPhone({ mobile: inputValue }).then((res) => {
                        const {
                          data: { data: resData = {}, code },
                        } = res
                        const { canVerify } = CheckPhoneSelector(resData)
                        if (res.data && [0].includes(code) && !canVerify) {
                          MyMessage.error('该手机号已经被注册')
                          return Promise.reject('该手机号已经被注册')
                        } else {
                          return Promise.resolve()
                       }
                    })
                    const list = {
                        mobile: inputValue,
                        rolesId: '102'
                    }
                    setMemberList([...memberList, list])
                    form.resetFields()
                }
            }
    }

    const deleteItem = mobile => {
        const list = memberList.filter(item => item.mobile !== mobile)
        setMemberList(list)
    }

    const selectChange = (value, item) => {
        // console.log(value, item);
        const list = memberList.map(current => {
            if(current.mobile === item.mobile) {
                item.rolesId = value
            }
            return current
        })
        setMemberList(list)
    }

    return (
        <Modal
            visible={visible}
            maskClosable={false}
            centered
            title={<span>添加成员</span>}
            onOk={handleOk}
            width={600}
            onCancel={() => props.handleModal(false)}
            footer={[
                <p className={style.footerP} key='p'>
                    {/* <span
                        onClick={() => props.changeShowInvite(true)}
                        className={style.firstBtn}>
                        通过链接邀请
                    </span> */}
                    <div
                        className={style.secondBtn}
                    >
                        <Button
                            onClick={() => props.handleModal(false)}>取消</Button>
                    </div>
                    <Button loading={btnLoading} type="primary" onClick={handleOk}>
                        确定
                    </Button>
                </p>
            ]}
        >
            <p style={{padding: "0 0 8px 0"}}>手机号码</p>
            <div className={style.member}>
            <Form name="phoneForm" form={form}>
                <Form.Item
                    name="phone"
                    rules={[
                        {
                            pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/,
                            message: '请输入正确的手机号码',
                        },
                        {
                            required: true,
                            message: '请输入正确的手机号码'
                        }
                      ]}
                      validateTrigger={['onChange', 'onBlur', 'onKeyUp']}
                >
                    <Input
                        value={inputValue}
                        placeholder='请输入手机号并回车'
                        onKeyUp={e => onKeyUp(e)}
                        onChange={e => setInputValue(e.target.value)}
                    />
                </Form.Item>
            </Form></div>

            {
                memberList.length ? <List
                    itemLayout="horizontal"
                    dataSource={memberList}
                    renderItem={(item) => (
                        <List.Item>
                            <span>
                                <img
                                    onClick={() => {
                                        deleteItem(item.mobile)
                                    }}
                                    src={close}
                                    className={style.close}/>
                                <img src={memberIcon} className={style.memberIcon}/>
                                {item.mobile}
                            </span>
                            <div className={style.role_cell}>
                            <Select
                                onChange={value => selectChange(value, item)}
                                defaultValue="成员"
                                bordered={false}
                                style={{width: 140}}
                                suffixIcon={
                                    <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                                }
                            >
                                {props.roleList.map(item => (
                                    <Option value={item.id} key={item.code}>{item.name}</Option>
                                ))}
                            </Select>
                            </div>

                        </List.Item>
                    )}
                /> : ''
            }
        </Modal>
    )
}
export default AddMember
