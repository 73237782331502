import React , {useState, useEffect}from 'react'
import commonComponent from 'components/index'
import { Row, Col, Divider } from 'antd'
import { useHistory } from 'react-router-dom'

import { PAGE_PATH } from 'apis/Router'
import classNames from 'classnames'
import styles from './ServiceList.module.less'
import { map } from 'lodash/fp'
const { TrimTextArea, HoverOpt } = commonComponent

const ServiceCard = ({ data = {} }) => {
  const {
    id,
    name,
    price,
    categoryName,
    remark,
    coverKey,
    coverPhotoInfo,
  } = data
  return (
    <div className={styles.service_card_item}>
      {/* 图片 */}
      <div className={styles.service_item_image}>
        <img src={coverPhotoInfo.photoUrl} alt={coverKey} />
      </div>
      {/* 名称 */}
      <div className={classNames(styles.service_item_title)}>
        {name || categoryName} {price ? `· ￥${price}` : null}
      </div>
      {/* 描述 */}
      <div className={classNames(styles.service_item_des)}>{remark}</div>
    </div>
  )
}

const ServiceList = ({ list, enterpriseServiceVisible,setEnterpriseServiceVisible }) => {
  let history = useHistory()
  const [isShow, setIsShow] = useState(false)
  const onRemove = (id) => {}
  const goToService = () => {
    history.push(PAGE_PATH.settings.children.service)
  }
  useEffect(()=>{
    setIsShow(enterpriseServiceVisible)
  }, [enterpriseServiceVisible])
  return (
    <HoverOpt
      title="服务"
      min-width={256}
      content={
        <div className={styles.bth_wrapper}>
          <span
            className={styles.btn}
            onClick={() => setEnterpriseServiceVisible(!isShow)}
          >
            {isShow ? '不显示' : '显示'}
          </span>
          <Divider type="vertical" />
          <span onClick={goToService} style={{ cursor: 'pointer' }}>
            服务设置
          </span>
        </div>
      }
    >
      <div className={classNames(styles.service_wrapper)}>
        <div className={styles.service_title}>服务</div>
        <Row gutter={24}>
          {map((item) => (
            <Col span={8} style={{ minWidth: 200 }} key={item.id}>
              <ServiceCard data={item} />
            </Col>
          ))(list)}
        </Row>
      </div>
    </HoverOpt>
  )
}

export default ServiceList
