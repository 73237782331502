/*
 * @Author: melon
 * @Date: 2020-07-06 12:31:51
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-11 16:57:19
 */
import { isEmpty, map, isNil } from 'lodash/fp'
// 获取列表
export const ShowcasePhotoListSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    list: data,
    formattedData: {
      list:
        isEmpty(data) || isEmpty(data.list)
          ? []
          : map((item) => ({
              ...item,
            }))(data.list),
      pagination: isEmpty(data)
        ? {
            pageNo: 1,
            current: 1,
            pageSize: 10,
            total: 0,
          }
        : {
            pageNo: Number(data.pageNo),
            current: Number(data.pageNo),
            pageSize: Number(data.pageSize),
            total: Number(data.totalCount),
          },
    },
  }
  return newData
}

// 获取预览地址
export const ShowcasePhotoPreviewUrlSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    ...data,
    formattedData: !isEmpty(data)
      ? {
          ...data,
        }
      : {},
  }
  return newData
}

// 修改添加展示图片
export const ShowcasePhotoAddSelector = (data) => {
  const categoryIdObj = isNil(data.categoryId)
    ? {}
    : { categoryId: data.categoryId }
  return {
    ...categoryIdObj,
    showcaseType: data.showcaseType,
    isCover: data.isCover,
    isWaterMark: data.isWaterMark,
    photos: data.photos,
  }
}

// 添加描述
export const ShowcasePhotoAddDescriptionSelector = (data) => {
  return {
    ...data,
  }
}

// 调整展示图片排序
export const ShowcasePhotoChangeSortSelector = (data) => {
  return data.map((id, index) => ({
    id,
    sort: index,
  }))
}

// 显示到首页
export const ShowcasePhotoCopyToIndexSelector = (data) => {
  return {
    ...data,
  }
}

// 移动、复制客片到其他分类
export const ShowcasePhotoMoveOrCopySelector = (data) => {
  return {
    ...data,
  }
}

// 打包下载地址
export const ShowcasePhotoDownloadUrlSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    ...data,
    formattedData: !isEmpty(data)
      ? {
          ...data,
        }
      : {},
  }
  return newData
}
