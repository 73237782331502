// Servers
// Selectors
import {
  AddProjectSelector,
  BookmarkListSelector,
  PhotoCategorySelector,
  PhotosListSelector,
  ProjectCategorySelector,
  ProjectListSelector,
  ProjectSelector,
  SavePhotoCategorySelector,
  SaveProjectCategorySelector,
  CustormPhotoChangeSortSelector,
  shareEmailSelector,
  emailRecordsSelector,
  HiddenListForPageSelector,
  MessageListForPageSelector,
  ProjectStatisticsSelector,
  ProjectRecordSelector,
  AddPresetSelector,
  ListPresetSelector
} from 'selectors/customerPhoto/CustomerPhotoSelectors'
import {
  _addPhotos,
  _addProject,
  _addProjectCategory,
  _changeProjectStatus,
  _checkPhotos,
  _editProject,
  _getBookmarks,
  _getBookmarksForMove,
  _getPhotoCategory,
  _getPhotoCategoryForMove,
  _getPhotosByBookmark,
  _getPhotosByCategory,
  _getPreviewUrlByProject,
  _getProjectById,
  _getProjectCategory,
  _getProjectsByCategory,
  _ignoreExceedTip,
  _moveOrCopyBookmarkPhotos,
  _moveOrCopyPhotos,
  _packageBookmarkPhotos,
  _packagePhotos,
  _changeSort,
  _removeBookmark,
  _removeBookmarkPhotos,
  _removePhotos,
  _removeProject,
  _saveBookmark,
  _downloadBookmarkExcel,
  _downloadPrivateExcel,
  _savePhotoCategory,
  _saveProjectCategory,
  _setCover,
  _overFavorite,
  _changeLanguage,
  _fileNameList,
  _fileNameLists,
  _customerFileNameLists,
  _feedback,
  _shareEmail,
  _emailRecords,
  _getHiddenListForPage,
  _getMessageListForPage,
  _getProjectStatistics,
  _projectRecord,
  _removeRecord,
  _removeMessage,
  _removeHidden,
  _addPreset,
  _editPreset,
  _listPreset,
  _removePreset,
  _shareRecord,
  _removeShare,
  _projectBookmarkRecord,
  _removeBookmarkRecord,
  _projectDownloadRecord,
  _removeDownloadRecord,
  _editProjectsPriceId,
} from 'servers/customerPhoto'
// Actions

// Action Creators

// init data
const initPagination = {
  pageNo: 1,
  pageSize: 100,
}
const initPagination2 = {
  pageNo: 1,
  pageSize: 20,
}

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action
// 获取项目类型
export const getProjectCategory = async () => {
  const res = await _getProjectCategory()
  return new Promise(function (resolve, reject) {
    resolve(ProjectCategorySelector(res))
    reject(res)
  })
}

export const addProjectCategory = (data) => _addProjectCategory(data)

export const saveProjectCategory = (data) =>
  _saveProjectCategory(SaveProjectCategorySelector(data))
// 获取项目分类
export const getProjectsByCategory = async (params) => {
  const res = await _getProjectsByCategory(params)
  return new Promise(function (resolve, reject) {
    resolve(ProjectListSelector(res))
    reject(res)
  })
}
// 获取项目信息
export const getProjectById = async (params) => {
  const res = await _getProjectById(params)
  return new Promise(function (resolve, reject) {
    resolve(ProjectSelector(res))
    reject(res)
  })
}

// 新增项目
export const addProject = (data) => _addProject(AddProjectSelector(data))
// 修改项目（设置）
export const editProject = (data) => _editProject(AddProjectSelector(data))

// 修改项目状态
export const changeProjectStatus = (data) => _changeProjectStatus(data)

//  删除项目
export const removeProject = (data) => _removeProject(data)

// 修改语言
export const changeLanguage = (data) => _changeLanguage(data)

// 获取照片类型
export const getPhotoCategory = async (params) => {
  const res = await _getPhotoCategory(params)
  return new Promise(function (resolve, reject) {
    resolve(PhotoCategorySelector(res))
    reject(res)
  })
}

// 获取照片类型（移动/复制）
export const getPhotoCategoryForMove = async (params) => {
  const res = await _getPhotoCategoryForMove(params)
  return new Promise(function (resolve, reject) {
    resolve(PhotoCategorySelector(res))
    reject(res)
  })
}
//  新增照片

export const addPhotos = (data) => _addPhotos(data)

// 检查照片
export const checkPhotos = (data) => _checkPhotos(data)

//  移动或复制照片
export const moveOrCopyPhotos = (data) => _moveOrCopyPhotos(data)

//  删除照片
export const removePhotos = (data) => _removePhotos(data)

// 设为封面
export const setCover = (data) => _setCover(data)

// 获取预览地址

export const getPreviewUrlByProject = (params) =>
  _getPreviewUrlByProject(params)

// 打包下载

export const packagePhotos = (data) => _packagePhotos(data)

// 改变照片顺序
export const changeSort = (data) =>
  _changeSort(CustormPhotoChangeSortSelector(data))

// 保存照片类别
export const savePhotoCategory = async (data) =>
  _savePhotoCategory(SavePhotoCategorySelector(data))

export const getPhotosByCategory = async (params) => {
  const res = await _getPhotosByCategory({ ...initPagination, ...params })
  return new Promise(function (resolve, reject) {
    resolve(PhotosListSelector(res))
    reject(res)
  })
}

// 获取收藏夹
export const getBookmarkList = async (params) => {
  const res = await _getBookmarks(params)
  //debugger
  res.data.data.forEach((element) => {
    if (!element.maxCount) {
      element.maxCountValue = '不限'
    } else {
      element.maxCountValue = element.maxCount
    }
  })
  return new Promise(function (resolve, reject) {
    resolve(BookmarkListSelector(res))
    reject(res)
  })
}

export const getBookmarksForMove = async (params) => {
  const res = await _getBookmarksForMove(params)
  return new Promise(function (resolve, reject) {
    resolve(PhotoCategorySelector(res))
    reject(res)
  })
}

// 新增/修改收藏夹
export const saveBookmark = (data) => _saveBookmark(data)

//下载Excel
export const downloadBookmarkExcel = (data) => _downloadBookmarkExcel(data)
export const downloadPrivateExcel = (data) => _downloadPrivateExcel(data)

// 删除收藏夹
export const removeBookmark = (data) => _removeBookmark(data)

// 获取收藏夹照片
export const getPhotosByBookmark = async (params) => {
  const res = await _getPhotosByBookmark({ ...initPagination, ...params })
  return new Promise(function (resolve, reject) {
    resolve(PhotosListSelector(res))
    reject(res)
  })
}

//  移动或复制照片
export const moveOrCopyBookmarkPhotos = (data) =>
  _moveOrCopyBookmarkPhotos(data)

//  删除照片
export const removeBookmarkPhotos = (data) => _removeBookmarkPhotos(data)
//  移除留言
export const removeMessage = (data) => _removeMessage(data)
//  移除私有影藏
export const removeHidden = (data) => _removeHidden(data)

// 打包收藏夹照片
export const packageBookmarkPhotos = (data) => _packageBookmarkPhotos(data)

// b端显示c端超量提示
export const overFavorite = (data) => _overFavorite(data)

// 忽略超量提示
export const ignoreExceedTip = (data) => _ignoreExceedTip(data)
// 获取收藏夹文件名
export const fileNameList = (data) => _fileNameList(data)
// 获取收藏夹选中文件名
export const fileNameLists = (data) => _fileNameLists(data)
// 获取私有/留言选中文件名
export const customerFileNameLists = (data) => _customerFileNameLists(data)
// 添加意见反馈
export const feedback = (data) => _feedback(data)
//邮件分享
export const shareEmail = (data) => _shareEmail(data)
//邮件已分享人员
export const emailRecords = (data) => _emailRecords(data)
//获取客片私有图片
// export const hiddenListForPageSelector = (data) => _getHiddenListForPage(data)
export const getHiddenListForPage = async (data) => {
  const res = await _getHiddenListForPage({ ...initPagination, ...data })
  return new Promise(function (resolve, reject) {
    resolve(HiddenListForPageSelector(res))
    reject(res)
  })
}
//获取客片留言图片

export const getMessageListForPage = async (data) => {
  const res = await _getMessageListForPage({ ...initPagination, ...data })
  return new Promise(function (resolve, reject) {
    resolve(MessageListForPageSelector(res))
    reject(res)
  })
}
//根据项目ID获取收藏图片数量，隐藏图片数量，留言数量

export const getProjectStatistics = async (data) => {
  const res = await _getProjectStatistics({ ...data })
  return new Promise(function (resolve, reject) {
    resolve(ProjectStatisticsSelector(res))
    reject(res)
  })
}
//查询项目浏览记录列表
export const projectRecord = async (data) => {
  const res = await _projectRecord({...initPagination2, ...data })
  return new Promise(function (resolve, reject) {
    resolve(ProjectRecordSelector(res))
    reject(res)
  })
}
//添加项目预设
export const addPreset = (data) => _addPreset(AddPresetSelector(data))
//修改项目预设
export const editPreset = (data) => _editPreset(AddPresetSelector(data))
// 查询项目预设
// export const listPreset =  (data) => _listPreset(data)
export const listPreset = async (data) => {
  const res = await _listPreset({ ...data })
  return new Promise(function (resolve, reject) {
    resolve(ListPresetSelector(res))
    reject(res)
  })
}
// 移除项目预设
export const removePreset = (data) => _removePreset(data)
// 查询分享
// export const listPreset =  (data) => _listPreset(data)
export const shareRecord = async (data) => {
  const res = await _shareRecord({...initPagination2, ...data })
  return new Promise(function (resolve, reject) {
    resolve(ProjectRecordSelector(res))
    reject(res)
  })
}
// 移除分享
export const removeShare = (data) => _removeShare(data)
// 移除浏览
export const removeRecord = (data) => _removeRecord(data)

// 查询分享
export const projectBookmarkRecord = async (data) => {
  const res = await _projectBookmarkRecord({...initPagination2, ...data })
  return new Promise(function (resolve, reject) {
    resolve(ProjectRecordSelector(res))
    reject(res)
  })
}
// 移除分享
export const removeBookmarkRecord = (data) => _removeBookmarkRecord(data)
// 查询分享
export const projectDownloadRecord = async (data) => {
  const res = await _projectDownloadRecord({...initPagination2, ...data })
  return new Promise(function (resolve, reject) {
    resolve(ProjectRecordSelector(res))
    reject(res)
  })
}
// 移除分享
export const removeDownloadRecord = (data) => _removeDownloadRecord(data)
export const editProjectsPriceId = (data) => _editProjectsPriceId(data)
