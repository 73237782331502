import Api from 'apis/Api.js'
import request from 'utils/Request'

// 查询展示分类
/**
 * 
 * @param {*} param0 
 * 
 *  * showcaseType *
string
(query)	
展示类型

Available values : INDEX, WORKS
 */
export const _getShowcaseCategory = async ({ showcaseType }) => {
  return await request({
    method: 'get',
    url: Api.showcaseCategory.getAll,
    params: {
      showcaseType,
    },
  })
}

// 查询复制移动展示分类 - 用于[作品复制/移动]业务
export const _getAllMoveOrCopyCategory = async () => {
  return await request({
    method: 'get',
    url: Api.showcaseCategory.getAllMoveOrCopyCategory,
  })
}

// 编辑/新增展示分类

export const _editShowcaseCategory = async (data) => {
  return await request({
    method: 'post',
    url: Api.showcaseCategory.edit,
    data: data,
  })
}

// 批量编辑展示分类
export const _batchEdit = async (data) => {
  return await request({
    method: 'post',
    url: Api.showcaseCategory.batchEdit,
    data: data,
  })
}

// 删除
export const _removeShowcaseCategory = async (data) => {
  return await request({
    method: 'post',
    url: Api.showcaseCategory.remove,
    data: data,
  })
}
