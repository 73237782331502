import { Button, Divider, Form, Col, Row, Slider, Switch, Input } from 'antd'
import CoverUpload from 'components/CoverUpload'
import React, { useState } from 'react'
import { WebLayout } from 'constants/Options'
import styles from './SEOForm.module.less'
const { TextArea } = Input
const layout = {
  wrapperCol: { span: 16 },
}
const MarkstaForm = ({ formRef, webData }) => {
  const [form] = Form.useForm()
  const [initialValues] = useState(webData)

  return (
    <div className={styles.formContrl}>
      <Form
        {...layout}
        form={form}
        ref={formRef}
        layout="vertical"
        name="SEOForm"
        initialValues={initialValues}
      >
        <Form.Item label="网站标题" name="websiteTitle">
          <Input />
        </Form.Item>
        <Form.Item label="页面底部文字" name="websiteFooter">
          <Input />
        </Form.Item>
        <Divider />
        <Form.Item label="网站描述/Description" name="websiteDesc">
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item label="关键词/Keywords" name="websiteKeywords">
          <TextArea rows={3} />
        </Form.Item>
      </Form>
    </div>
  )
}
export default MarkstaForm
