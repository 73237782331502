import { LoadingOutlined } from '@ant-design/icons'
import {Button, Checkbox, List, Divider, Modal, Progress, Spin, Upload, Popconfirm} from 'antd'
import { has, isEmpty } from 'lodash'
import React, { useEffect, useState, useRef } from 'react'
import { fileSizeBToM } from 'utils/Tools'
import { getWeb as _getWeb } from 'reduxModules/settings'
import { uploadImage } from 'utils/Uploader'
import styles from './index.module.less'
import { getTime } from 'utils/Tools'
import EXIF from 'exif-js'
import {
  editEnterpriseLevel as _editEnterpriseLevel,
} from 'reduxModules/subscribe'
import {dimValueGetter} from "echarts/src/component/marker/markerHelper";
import {Link} from "react-router-dom";
/**
 *  UploadModal的子组件，缩小后的moda
 * @param {array} list- 默认展示的列表
 * @param {function} onCreate- 保存的方法
 */

const { Dragger } = Upload
const { confirm } = Modal;
const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />

const FooterComponent = ({ uploading }) => {
  return (
    <div className={styles.footer}>
      {uploading ? null : (
        <div className={styles.footerText}>
          * 添加的单张图片文件小于50M，且仅支持JPG/JEPG文件
        </div>
      )}
    </div>
  )
}

const BodyComponent = ({
                         uploading,
                         uploadFileList,
                         uploadedCount,
                         totalSize,
                         setMinStatus,
                         uploadProps,
                         cover,
                         waterMark,
                         onChangeWaterMark,
                         onChangeCover,price
                       }) => {
  useEffect(() => {
    getWebData();
    getWebDetails();
  }, [])
  const [infoDetails, setInfoDetails] = useState({})
  const [webData, setWebData] = useState({});
  const [newVisible, setNewVisible] = useState(true);
  //权限
  const getWebDetails = async () => {
    const res = await _editEnterpriseLevel()
    if ([0].includes(res.code)) {
      setInfoDetails(res.data)
    }
  }
  const getWebData = async () => {
    const res = await _getWeb()
    if (res.code === 0) {
      setWebData(res.data)
    }
  }
  const showIcon = (item) => {
    if ('uploading' === item.status) {
      return <Spin indicator={antIcon} />
    } else if ('repeating' === item.status || 'bigError' === item.status) {
      return <span style={{ color: 'red' }}>!</span>
    } else if ('done' === item.status) {
      return (
        <span className={`iconfont iconicon_success ${styles.doneIcon}`}></span>
      )
    }
  }
  const onChange = (e, type) => {
    const value = e.target.checked
    if (type === 'origin') {
      onChangeCover(value)
      return null;
    }
    onChangeWaterMark(value)
  }
  const showConfirm=()=> {
    Modal.info({
      title: '功能受限',
      icon: '',
      width: 600,
      content: '您当前已超过账户存储容量，已暂停上传功能，如需帮助请咨询客服。',
      okText: '知道了',
      onOk() {
        console.log('OK');
      },
    })
    // confirm({
    //   title: '功能受限',
    //   icon: '',
    //   content: '您的使用量已超过！',
    //   onOk() {
    //     console.log('OK');
    //   },
    // });
  }
  let body = null
  const totalCount = uploadFileList.filter((file) =>
    ['uploading', 'done'].includes(file.status)
  ).length
  const complateRate =
    totalCount > 0 ? ((uploadedCount * 100) / totalCount).toFixed(2) : 0
  body = (
    <div>
      <div
        className={styles.dragger}
        style={{ display: uploading ? 'none' : '' ,position:'relative'}}
      >
        <Dragger {...uploadProps}>
          <div className={styles.uploadIcon}>
            <span
              className="iconfont iconfileupload"
              style={{ fontSize: 60 }}
            ></span>
          </div>
          <div className={styles.hint}>
            将照片拖到这里或点击按钮开始
          </div>
          <div className={styles.uploadBtn}>
            <Button type="primary" style={{ width: 150, height: 40 }}>
              选择并上传
            </Button>
          </div>
          {(infoDetails.useDb/infoDetails.totalDb*100).toFixed(2)>=80?(
            <div className={styles.fullReminder}><span style={{fontWeight: "bold"}}>您当前存储已使用{(infoDetails.useDb/infoDetails.totalDb*100).toFixed(2)>=100?100:(infoDetails.useDb/infoDetails.totalDb*100).toFixed(2)}%</span><br/>为不影响正常使用请及时扩容或清理项目，如需帮助请联系客服。</div>
          ):('')
          }
        </Dragger>
        {!price?(
          <div className={styles.repeating}>
            {webData.watermark && infoDetails.watermark=='Y'?[
              <span>
            <Checkbox onChange={(e) => onChange(e, 'water')} checked={waterMark}>应用照片水印</Checkbox>
            <Divider type="vertical" style={{ marginLeft: '20px', marginRight: '28px' }} />
          </span>]:''}
            <Checkbox onChange={(e) => onChange(e, 'origin')} checked={cover}>
              替换重复照片
            </Checkbox>
          </div>
        ):null}


        {(infoDetails.useDb/infoDetails.totalDb*100).toFixed(2)>=100?(
          <div style={{position:'absolute', width:'100%', height:'100%', top:'0'}} onClick={showConfirm}></div>
        ):('')}
      </div>

      <div style={{ display: !uploading ? 'none' : '' }}>
        <div>
          <div className={styles.progressInfo}>
            <div>
              共{totalSize}MB，已完成{uploadedCount}/{totalCount}
            </div>
            <div>{totalCount > 0 ? complateRate : 0}%</div>
          </div>
          <Progress percent={complateRate} showInfo={false} />
        </div>
        <div>
          <List
            size="small"
            footer={<div></div>}
            dataSource={uploadFileList}
            className={styles.uploadingList}
            renderItem={(item) => (
              <List.Item className={styles.uploadingListItem}>
                <div className={styles.uploadingItem}>
                  <div className={styles.row1}>
                    <div className={styles.uploadingItemStart}>
                      <span className={styles.statusIcon}>
                        {showIcon(item)}
                      </span>
                      <span className={styles.uploadingItemName}>
                        {item.name}
                      </span>
                    </div>

                    {item.status === 'repeating' ||
                    item.status === 'bigError' ? (
                      item.status === 'repeating' ? (
                        <div
                          className={styles.uploadingItemEnd}
                          style={{ flexDirection: 'row-reverse' }}
                        >
                          <span style={{ color: 'red' }}>重复的</span>
                        </div>
                      ) : (
                        <div
                          className={styles.uploadingItemEnd}
                          style={{ flexDirection: 'row-reverse' }}
                        >
                          <span style={{ color: 'red' }}>文件过大</span>
                        </div>
                      )
                    ) : (
                      <div className={styles.uploadingItemEnd}>
                        <Progress percent={item.percent} showInfo={false} />
                      </div>
                    )}
                  </div>
                  <div className={styles.row2}>
                    <div className={styles.uploadingItemSize}>{`${fileSizeBToM(
                      item.size
                    )}M`}</div>
                    <div className={styles.uploadingTo}>
                      {item.status == 'done'
                        ? item.options.name
                        : `上传到${item.options.name}`}
                    </div>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
        <div className={styles.upLoadingFooter}>
          {totalCount - uploadedCount > 0 ? (
            <div>还有{totalCount - uploadedCount}张在上传</div>
          ) : (
            <div>已上传完成</div>
          )}

          {/*<div>
            <a onClick={() => setMinStatus(true)}>隐藏上传窗口</a>
          </div>*/}
        </div>
      </div>
    </div>
  )

  return body
}
const TitleComponent = ({ uploaded, uploadProps, doCancel, showUploadBtn,isCancel,setCancelVisible }) => {
  return (
    <div className={styles.title}>
      <span>{uploaded ? '已完成上传' : '正在上传...'}</span>
      <span>
        {/*:(*/}
        {/*  <Button onClick={()=>setCancelVisible(true)}>取消</Button>*/}
        {/*)}*/}
        {/*<Button onClick={doCancel}>{uploaded ? '关闭' : '取消'}</Button>*/}
        <Upload {...uploadProps}>
          <Button
            className="primary-default-btn"
            style={{ display: showUploadBtn ? '' : 'none' }}
          >
            添加照片
          </Button>
        </Upload>
        {uploaded&&(
          <Button onClick={doCancel}>完成</Button>
        )}
      </span>
      <Modal
        title="取消上传"
        visible={isCancel}
        onOk={doCancel}
        onCancel={()=>setCancelVisible(false)}
        okText="确定"
        cancelText="取消"
        width={400}
        // maskClosable={false}
        // closable={false}
        centered
        cancelButtonProps={{className: 'footerCanceltext'}}
      >
        <div>确定取消并终止上传？</div>
      </Modal>
    </div>
  )
}

const MiniComponent = ({
                         setMinStatus,
                         setShowUploadBtn,
                         uploadFileList,
                         uploadedCount,
                         totalSize,
                       }) => {
  const totalCount = uploadFileList.filter((file) =>
    ['uploading', 'done'].includes(file.status)
  ).length
  const complateRate =
    totalCount > 0 ? ((uploadedCount * 100) / totalCount).toFixed(2) : 0

  return (
    <div className={styles.miniUpload}>
      <div>
        <span className="iconfont iconicon_uploading"></span>
        <span>
          {uploadedCount === totalCount ? '' : '正在上传…'}已完成{uploadedCount}
          /{totalCount}（{complateRate}
          %），共{totalSize}MB。
        </span>
      </div>
      <div>
        <Button
          type="link"
          className="link-btn"
          size="small"
          onClick={() => {
            setMinStatus(false)
            setShowUploadBtn(false)
          }}
        >
          查看详情
        </Button>
      </div>
    </div>
  )
}
// options:{key：'',name:''}必传项, key为类别，name为上传到#name#
class UploadModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      minStatus: false,
      uploading: false,
      uploaded: false,
      checkUploadNames: {},
      uploadFileList: [],
      fileList: [],
      uploadedCount: 0,
      totalSize: 0,
      showUploadBtn: false,
      cover: false,
      waterMark: false,
      allQiniuUpload: [],
      isCancel:false,
      isUploading:false,
      cancelUpload:false,
      // uploadlenth:1,
      // uploadlist:[],
      // waitinglist:[],
      // infos:[],
    }
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  // 隐藏上传功能
  setMinStatus = (status) => {
    this.setState({
      minStatus: status,
    })
  }

  setShowUploadBtn = (status) => {
    this.setState({
      showUploadBtn: status,
    })
  }
  //上传实现
  customRequest = async (info) => {
    let { onSuccess, file } = info
    const { addPhotos, _checkPhotos,setLoadingUpload } = this.props
    // setLoadingUpload(false)
    // let { uploadFileList, cover, allQiniuUpload, waterMark,cancelUpload ,uploadlist,uploadlenth,waitinglist,infos } = this.state
    let { uploadFileList, cover, allQiniuUpload, waterMark,cancelUpload } = this.state
    // infos.push(info)
    this.setState({
      // infos,
      isUploading: false,
    })
    // console.log(infos)
    var that = this
    console.log(file==uploadFileList[uploadFileList.length-1])
    console.log(_checkPhotos && !cover && file.status === 'uploading')
    // return
    // 后端检查
    if (_checkPhotos && !cover && file.status === 'uploading') {
      const checkRes = await _checkPhotos({
        categoryId: file.options.key,
        imageNames: file.name,
        imageTime: file.imageTime
      })
      if (checkRes.data && checkRes.data.code == 0) {
        const checkList = checkRes.data.data
        if (checkList.length > 0) {
          const checkFile = checkList[0]
          if (checkFile.isExist == 'Y') {
            const fileIndex = uploadFileList.findIndex(
              (item) => item.name === checkFile.imageName
            )
            uploadFileList[fileIndex].status = 'repeating'
            file.status = 'repeating'
          }
        }
      }
    }

    const index = uploadFileList.findIndex(
      (item) => item.uid == file.uid && item.status == 'uploading'
    )
    // console.log(file,uploadlist)
    if (file.status == 'uploading') {
      const user = sessionStorage.getItem('user')
        ? JSON.parse(sessionStorage.getItem('user'))
        : {}
      const userId = isEmpty(user) ? undefined : user.userId
      if (userId === undefined) {
        return
      }
      // 派发请求
      const subscription = await uploadImage({
        userId: userId,
        file: file,
        errorCallback: async () => {},
        nextCallback: async (res) => {
          const { total } = res
          uploadFileList[index].percent = total.percent
          that.setState({ uploadFileList })
        },
        completeCallback: async (res) => {
          // let indexNum=uploadlist.indexOf(uploadFileList[index])
          // console.log(indexNum,uploadFileList)
          // uploadlist.splice(indexNum,1)
          // waitinglist.map(v=>{
          //   if(v.status=='waiting'&&uploadlist.length<uploadlenth){
          //     v.status = 'uploading'
          //     uploadlist.push(v)
          //     uploadFileList.push(v)
          //   }
          // })
          // that.setState({
          //   uploadlist,
          //   uploadFileList
          // })
          uploadFileList[index].status = 'done'
          const uploadedCount = uploadFileList.filter(
            (item) => item.status == 'done'
          ).length
          const uploadedAllCount = uploadFileList.filter((item) =>
            ['uploading', 'done'].includes(item.status)
          ).length
          // 派发更新的头像请求
          try {
            addPhotos({ ...res, file: uploadFileList[index], cover, waterMark })
            onSuccess(res, file)
            that.setState({
              uploadedCount,
              uploaded: uploadedCount === uploadedAllCount,
            })
          } catch (error) {
          } finally {
          }
        },
      })
      let newQiniuUpload = allQiniuUpload.concat()
      newQiniuUpload.push(subscription)
      that.setState({
        allQiniuUpload: newQiniuUpload,
      })
    }
    that.setState({
      uploadFileList,
    })
  }
  /*upload=async (file)=>{
    let that=this
    let { uploadFileList, cover, allQiniuUpload, waterMark,cancelUpload ,uploadlist,uploadlenth } = this.state
    const subscription=await uploadImage({
      userId: userId,
      file: file,
      errorCallback: async () => {},
      nextCallback: async (res) => {
        const { total } = res
        uploadFileList[index].percent = total.percent
        that.setState({ uploadFileList })
      },
      completeCallback: async (res) => {
        let indexNum=uploadlist.indexOf(uploadFileList[index])
        console.log(indexNum,uploadFileList)
        uploadlist.splice(indexNum,1)
        uploadFileList.map(v=>{
          if(v.status=='waiting'&&uploadlist.length<uploadlenth){
            v.status = 'uploading'
            uploadlist.push(v)
            // that.beforeUpload(v,uploadFileList)
          }
        })
        that.setState({
          uploadlist
        })
        uploadFileList[index].status = 'done'
        const uploadedCount = uploadFileList.filter(
          (item) => item.status == 'done'
        ).length
        const uploadedAllCount = uploadFileList.filter((item) =>
          ['uploading', 'done'].includes(item.status)
        ).length
        // 派发更新的头像请求
        try {
          addPhotos({ ...res, file: uploadFileList[index], cover, waterMark })
          onSuccess(res, file)
          that.setState({
            uploadedCount,
            uploaded: uploadedCount === uploadedAllCount,
          })
        } catch (error) {
        } finally {
        }
      },
    })
    return subscription
  }*/
  beforeUpload = async (file, fileList) => {
    let { uploadFileList, checkUploadNames, cover} = this.state
    // let { uploadFileList, checkUploadNames, cover,uploadlenth,uploadlist ,waitinglist} = this.state
    const isJpg = file.type === 'image/jpeg' || file.type === 'image/jpg'
    const isFileBig = file.size / 1024 / 1024 < 50
    const { options = { key: 'nokey', name: '未设定' },setLoadingUpload } = this.props
    let that=this
    this.setState({
      isUploading: true,
      cancelUpload: false,
    })
    // setLoadingUpload(true)
    let totalSize = 0
// console.log(YY + MM + DD +" "+hh + mm + ss);
    let time=await this.getFileTime(file)
    // 找出文件在fileList中的位置
    const fileIndex = fileList.findIndex((item) => item.name === file.name)
    if (isJpg) {
      // 本地检查
      if (!has(checkUploadNames, options.key) && !cover) {
        checkUploadNames[options.key] = []
      }
      //判断是否可以覆盖，以及是否重复
      if (
        !cover &&
        !isEmpty(checkUploadNames) &&
        checkUploadNames[options.key].includes(file.name)
      ) {
        fileList[fileIndex].status = 'repeating'
      } else {
        // 判断是否过大
        if (!isFileBig) {
          fileList[fileIndex].status = 'bigError'
        } else {
          // if(uploadlist.length<uploadlenth) {
            fileList[fileIndex].status = 'uploading'
            // uploadlist.push(fileList[fileIndex])
            // that.setState({
            //   uploadlist
            // })
          // }else{
          //   fileList[fileIndex].status = 'waiting'
          //   waitinglist.push(fileList[fileIndex])
          //   that.setState({
          //     waitinglist
          //   })
          // }
          if (!cover) {
            //不可覆盖才去做检查
            checkUploadNames[options.key].push(file.name)
          }
        }
      }
    } else {
      fileList[fileIndex].status = 'error'
    }
    if(!fileList[fileIndex].imageTime){
      fileList[fileIndex].imageTime=time
    }
    const newUploadFileList = [...uploadFileList, ...fileList].filter(
      (item) => item.status !== 'error'
      // (item) => item.status !== 'error'&&item.status !== 'waiting'
    )
    newUploadFileList.forEach((file) => {
      if (isEmpty(file.options)) {
        file.options = options
      }
      if (file.status === 'uploading') {
        totalSize = totalSize + file.size
      }
    })
    this.setState({
      uploadFileList: newUploadFileList,
      totalSize: Number(fileSizeBToM(totalSize)),
      checkUploadNames,
    })
  }
  getFileTime=async (file)=>{
    // 通过exif获取文件的信息
    let time=await new Promise( async function (resolve, reject) {
      await EXIF.getData(file, function(res) {
        // const orientation = EXIF.getAllTags(file);
        // DateTimeDigitized: 数字化创建时间
        // DateTimeOriginal: 原始创建时间
        // DateTime: 创建时间
        let DateTimeOriginal=EXIF.getTag(file, 'DateTimeOriginal')
        if(!!DateTimeOriginal&&DateTimeOriginal.replace(/ /g,'')!=''){
          time=EXIF.getTag(file, 'DateTimeOriginal').split(' ')[0].replace(/:/g,'-')+'T'+EXIF.getTag(file, 'DateTimeOriginal').split(' ')[1]+'+08:00'
        }else{
          time=getTime(file.lastModified).replace(/ /g,'T')+'+08:00'
        }
        resolve(time);
      })
    });
    return time
  }
  onChange = (info) => {
    console.log(info)
    const { uploadFileList } = this.state
    if (uploadFileList.length > 0 && info.file.status == 'uploading') {
      this.setState({ uploading: true })
    }
    return
  }
  onChangeCover = (cover) => {
    this.setState({ cover })
  }
  onChangeWaterMark = (waterMark) => {
    this.setState({ waterMark })
  }
  doCancel = () => {
    const { allQiniuUpload} = this.state
    this.setCancelVisible(false)
    this.setState({
      cancelUpload: true,
    })
    // 七牛云终止上传
    allQiniuUpload.forEach((subscription) => subscription.unsubscribe())
    this.props.onCancel()
    this.setState({
      uploadFileList: [],
      fileList: [],
      uploading: false,
      checkUploadNames: {},
      allQiniuUpload: [],
    })
  }
  setCancelVisible=(val)=>{
    this.setState({isCancel:val})
  }
  render() {
    const { visible, onCancel,price,loadingUpload } = this.props
    const {
      minStatus,
      uploading,
      uploaded,
      uploadFileList,
      fileList,
      uploadedCount,
      totalSize,
      showUploadBtn,
      cover,
      waterMark,
      isCancel,
      isUploading,
      // uploadlist,
      // num,
    } = this.state
    const uploadProps = {
      name: 'file',
      multiple: true,
      customRequest: this.customRequest,
      beforeUpload: this.beforeUpload,
      onChange: this.onChange,
      showUploadList: false,
      // fileList: fileList,
      fileList: uploadFileList,
      accept: '.jpeg,.jpg',
    }
    return minStatus ? (
      <MiniComponent
        setMinStatus={this.setMinStatus}
        setShowUploadBtn={this.setShowUploadBtn}
        uploadFileList={uploadFileList}
        uploadedCount={uploadedCount}
        totalSize={totalSize}
      />
    ) : (
      <Modal
        className={styles.uploadModal}
        visible={visible}
        centered
        title={
          uploading ? (
            minStatus ? null : (
              <TitleComponent
                uploadProps={uploadProps}
                showUploadBtn={showUploadBtn}
                uploaded={uploaded}
                isCancel={isCancel}
                setCancelVisible={this.setCancelVisible}
                doCancel={this.doCancel}
              />
            )
          ) : (
            '添加图片'
          )
        }
        onCancel={() => {
          onCancel()
          this.doCancel()
        }}
        width={600}
        footer={
          minStatus ? null : uploadFileList.length > 0 ? null : (
            <FooterComponent uploading={uploading} />
          )
        }
        mask={!minStatus}
        closable={!uploading}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Spin spinning={isUploading}>
        <BodyComponent
          uploading={uploading}
          uploadFileList={uploadFileList}
          uploadedCount={uploadedCount}
          totalSize={totalSize}
          setMinStatus={this.setMinStatus}
          uploadProps={uploadProps}
          cover={cover}
          waterMark={waterMark}
          price={price}
          onChangeCover={this.onChangeCover}
          onChangeWaterMark={this.onChangeWaterMark}
        />
        </Spin>
      </Modal>
    )
  }
}
export default UploadModal
