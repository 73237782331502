import { Button, Table, Divider, Popconfirm } from 'antd'
import classNames from 'classnames'
import CommonComponent from 'components/index'
import Imgs from 'imgs'
import React from 'react'
import styles from './CacheList.module.less'

const { ImageCardCell, MyMessage } = CommonComponent
const { photoSample } = Imgs

class CacheList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      adding: false,
      currentId: null,
    }
  }
  onEdit = () => {}
  handleTableChange = (pagination) => {
      // console.log(pagination)
      this.props.getList({ pagination })
  }
  onAddCar = async (id) => {
    const { getList, onAddShopCart, pagination } = this.props
    await this.setState({
      adding: true,
      currentId: id,
    })
    try {
      const resData = await onAddShopCart(id)
      if (resData.data && [0].includes(resData.data.code)) {
        MyMessage.success('添加成功')
        // 派发获取列表数据
        getList({ pagination })
      } else {
        MyMessage.error('添加失败')
      }
    } catch (error) {
    } finally {
      await this.setState({
        adding: false,
        currentId: null,
      })
    }
  }
  onDelete = async (id) => {
    const { getList, onRemove, pagination } = this.props
    try {
      const resData = await onRemove(id)

      if (resData.data && [0].includes(resData.data.code)) {
        MyMessage.success('删除成功')
        // 派发获取列表数据
        getList({ pagination })
      } else {
        MyMessage.error('删除失败')
      }
    } catch (error) {}
  }
  render() {
    const { list, pagination } = this.props
    const { adding, currentId } = this.state
    const columns = [
      {
        title: '暂存项目',
        dataIndex: 'name',
        key: 'name',
        render: (_, { name, src, description }) => (
          <ImageCardCell
            imgSize={68}
            title={name}
            imgSrc={src}
            description={description}
          />
        ),
      },
      {
        title: '类别',
        dataIndex: 'typeDesc',
        key: 'typeDesc',
      },
      {
        title: '最近更新时间',
        dataIndex: 'time',
        key: 'time',
      },
      {
        title: '',
        dataIndex: 'opt',
        key: 'opt',
        width: 100,
        render: (_, { id }) => (
          <div>
            <Button
              className={styles.opt_btn}
              key={'addShopCart' + id}
              type="primary"
              onClick={() => this.onAddCar(id)}
              loading={currentId === id && adding}
            >
              加入购物车
            </Button>
            <div className={styles.inline_btn_wrapper}>
              <Button
                className={classNames('Inf_text_btn')}
                key={'edit' + id}
                type="text"
                onClick={() => this.onEdit(id)}
              >
                编辑
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                title="确认删除"
                onConfirm={() => this.onDelete(id)}
                okText="确认"
                cancelText="取消"
              >
                <Button
                  className={classNames('Inf_text_btn')}
                  key={'delete' + id}
                  type="text"
                >
                  删除
                </Button>
              </Popconfirm>
            </div>
          </div>
        ),
      },
    ]

    return (
      <div className={styles.Inf_cache_list}>
        <div className="Inf_total_right" style={{ paddingTop: 0 }}>
          共{pagination.total}条
        </div>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={list}
          pagination={{
            ...pagination,
            showSizeChanger: false,
          }}
          onChange={this.handleTableChange}
        />
      </div>
    )
  }
}
export default CacheList
