/*
 * @Author: melon
 * @Date: 2020-07-04 15:21:35
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-22 11:24:23
 */

import { Button, Col, Form, Input, Row } from 'antd'
import { PAGE_PATH } from 'apis/Router'
import classNames from 'classnames'
import myMessage from 'components/Message/index'
import { TrimInput, TrimPswInput } from 'components/WithTrim/index'
import React from 'react'
import { getVerifyCode as _getVerifyCode } from 'reduxModules/member'
import { PhoneValidateRule, PswValidateRule } from 'utils/ValidateRulesAndMsg'
import styles from './Form.module.less'
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
}

const tailLayout = {
  wrapperCol: { span: 24 },
}

class ForgetForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 60, // 秒数初始化为60秒
      getCodeChange: true, // 文案默认为 '获取短信验证码'
      btnLoginLoading: false,
    }
  }
  formRef = React.createRef()

  onFinish = async (values) => {
    const { doSubmit, history } = this.props
    await this.setState({
      btnRegisterLoading: true,
    })
    try {
      const res = await doSubmit(values)
      // 跳转登录页
      if (res.data && [0].includes(res.data.code)) {
        setTimeout(() => {
          history.push(PAGE_PATH.login)
        }, 1000)
      }
    } catch (error) {
    } finally {
      await this.setState({
        btnRegisterLoading: false,
      })
    }
  }

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  goLogin = () => {
    const { history } = this.props
    history.push(PAGE_PATH.login)
  }
  // 获取验证码
  getMessageCode = async () => {
    let state = this.state
    this.formRef.current
      .validateFields(['mobile'])
      .then(async (values) => {
        if (!state.getCodeChange) {
          return
        }
        // 派发请求
        try {
          const { mobile } = values
          const res = await _getVerifyCode({ mobile })
          if (res.data && [0].includes(res.data.code)) {
            myMessage.success('获取验证码成功')
            let count = state.count
            const timer = setInterval(() => {
              this.setState(
                {
                  getCodeChange: false,
                  count: count--,
                },
                () => {
                  if (count === 0) {
                    clearInterval(timer)
                    this.setState({
                      getCodeChange: true,
                      count: 60,
                    })
                  }
                }
              )
            }, 1000)
          } else {
            myMessage.error(res.data.msg || '系统繁忙')
          }

          return res
        } catch (error) {
          myMessage.error('系统繁忙')
        } finally {
        }
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo)
      })
  }

  render() {
    const { count, getCodeChange } = this.state
    let getCodeText = '获取短信验证码'
    if (!getCodeChange) {
      getCodeText = count + 's'
    }
    return (
      <div className={classNames(styles.forget_psw_form_wrapper)}>
        <Form
          className={classNames(styles.forget_psw_form)}
          {...layout}
          name="forget-psw"
          ref={this.formRef}
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          validateTrigger="onSubmit"
        >
          <Input type="password" hidden autoComplete="new-password" />
          <div className={styles.Title}>忘记密码</div>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="手机号"
                name="mobile"
                rules={[
                  { required: true, message: '请输入您的手机号!' },
                  {
                    ...PhoneValidateRule,
                  },
                ]}
                className='hiddenStar'
                validateTrigger="onChange"
              >
                <TrimInput className={styles.user_forget_psw_input} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="verifyCode"
                label="验证码"
                rules={[{ required: true, message: '请输入验证码' }]}
                className='hiddenStar'
              >
                <TrimInput
                  className={styles.user_forget_psw_input}
                  style={{ width: '100%' }}
                  addonAfter={
                    <Button
                      type="link"
                      htmlType="button"
                      style={{ color: '#1AA77D', fontSize: 12 }}
                      onClick={this.getMessageCode}
                    >
                      {getCodeText}
                    </Button>
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="password"
                label="新密码"
                rules={[
                  {
                    required: true,
                    message: '请输入您的密码!',
                  },
                  {
                    ...PswValidateRule,
                  },
                ]}
                hasFeedback
                className='hiddenStar'
              >
                <TrimPswInput placeholder="须由6位以上的数字与字母组成" className={styles.user_forget_psw_input} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="surePassword"
                label="再次输入新密码"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: '请确认你的新密码!',
                  },

                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject('两次输入密码不一致!')
                    },
                  }),
                ]}
                className='hiddenStar'
              >
                <TrimPswInput className={styles.user_forget_psw_input} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} className={styles.forget_psw_btn_wrapper}>
              <div>
                已有帐户？
                <Button
                  type="link"
                  htmlType="button"
                  onClick={this.goLogin}
                  className={styles.forget_psw_btn_item}
                >
                  登录
                </Button>
              </div>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.forget_psw_btn}
              >
                更新密码
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default ForgetForm
