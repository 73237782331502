// 引入路径
import { PAGE_PATH } from 'apis/Router'
import classNames from 'classnames'
import Imgs from 'imgs'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
// 登录表单
import RegisterForm from '../Components/Form'
import styles from './index.module.less'
import { Spin } from 'antd'
import { register as _register } from 'reduxModules/member'
import myMessage from 'components/Message/index'

const { LogoWT, LogoBK, User } = Imgs

const RegisterPage = () => {
  const [sendRequesting, setSendRequesting] = useState(false)

  let history = useHistory()

  const doRegister = async (values) => {
    // 派发注册请求
    try {
      setSendRequesting(true)
      const res = await _register(values)
      if (res.data && [0].includes(res.data.code)) {
        myMessage.success('注册成功')
      } else {
        myMessage.error(res.data.msg || '注册失败')
      }
      return res
    } catch (error) {
      myMessage.error('系统繁忙')
    } finally {
      setSendRequesting(false)
    }
  }

  const goLogin = () => {
    history.push(PAGE_PATH.login)
  }

  return (
    <div className={styles.register_page}>
      <div className={classNames(styles.register_show_info_container)}>
        <div className={styles.left_header}>
        <p className={classNames(styles.welcome)}>Welcome to</p>
          <div className={styles.brands}>
            <img src={LogoBK} alt=""></img>
          </div>
          <p className={styles.brands_text}>
            用现代的方式管理你的项目、作品集与品牌等，使交付流程变得有序自动化，使你的品牌与服务凸显专业且优雅。
          </p>
        </div>
        {/* <div className={styles.left_content}>
          <div className={styles.user_talk_text}>
            “这个是我目前找到的比较完整的能解决我所需的应用，客户很喜欢针对他独立的网站，客户的分享就能让我很容易的获取更多关注，同时我也可以很容易获取到反馈，可以快速的选择并设计相册，整个过程都是有序的，让我能把精力集中在本身的拍摄上。”
          </div>
          <div className={styles.user_info}>
            <img src={User} className={styles.code_img}></img>
            <span className={styles.user_text_wrapper}>
              <span className={styles.user_name}>张先生</span>
              <span className={styles.user_job}>独立婚礼摄影师</span>
            </span>
          </div>
        </div> */}
      </div>
      <div className={classNames(styles.register_form_container)}>
        <Spin
          tip="注册中..."
          spinning={sendRequesting}
          size="large"
          style={{ width: '100%', height: '100%' }}
        >
          <RegisterForm doSubmit={doRegister} history={history} />
        </Spin>
      </div>
    </div>
  )
}

export default RegisterPage
