import { Button, Checkbox, List, Modal } from 'antd'
import { isEmpty } from 'lodash/fp'
import React, { useState } from 'react'
import MyMessage from '../Message/index'
import styles from './index.module.less'

const MoveOrCopyModal = ({ visible, onCancel, data, onMove, onCopy, isPage, moveLoading, copyLoading }) => {
  const [targetCategory, setTargetCategory] = useState({})
  const [checkedIds, setCheckedIds] = useState([])
  const [checkedContent, setCheckedContent] = useState(false)

  const [loading, setLoading] = useState(false)
  const onChangeCheckBox = (e, item) => {
    if (e.target.checked) {
      // 如果选中 设置 目标分类和checkedIds
      setTargetCategory(item)
      setCheckedIds([item.id])
      // 判断选择是否网站内容
      if((item.type == 'INDEX' || item.type == 'SHOWCASE') && isPage){
        setCheckedContent(true)
      } else {
        setCheckedContent(false)
      }
    } else {
      setCheckedContent(false)
      // 如果取消选中 清空目标分类和checkedIds
      setTargetCategory({})
      setCheckedIds([])
    }
  }
  // 点击移动或复制
  const onClickMoveOrCopy = async (type) => {
    // 判断没选 targetCategory 就提醒选择
    if (isEmpty(targetCategory)) {
      MyMessage.error('请选择目录')
      return
    }
    setLoading(true)
    const body = {
      targetCategoryId: targetCategory.id,
      categoryType: targetCategory.type,
      type,
    }
    let resData = {}

    switch (type) {
      case 'MOVE': {
        resData = await onMove(body)
        break
      }

      case 'COPY': {
        resData = await onCopy(body)
        break
      }
      default:
        break
    }
    setLoading(false)
    if (resData.data && [0].includes(resData.data.code)) {
      // 清空目标分类和checkedIds
      setTargetCategory({})
      setCheckedIds([])
    }
  }

  const handleCancel = () => {
    // 清空目标分类和checkedIds
    setTargetCategory({})
    setCheckedIds([])
    onCancel()
  }

  return (
    <Modal
      maskClosable={false}
      title={
        <div>
          <p>移动或复制</p>
          <p className="Inf_page_text">请选择目标分类目录后点击移动或复制。</p>
        </div>
      }
      visible={visible}
      centered
      width={600}
      onCancel={handleCancel}
      footer={[
        <Button
          key="move"
          type="primary"
          disabled={isEmpty(targetCategory) || checkedContent || copyLoading }
          onClick={() => onClickMoveOrCopy('MOVE')}
          loading={moveLoading}
        >
          移动
        </Button>,
        <Button
          key="copy"
          type="primary"
          disabled={isEmpty(targetCategory) || moveLoading}
          onClick={() => onClickMoveOrCopy('COPY')}
          loading={copyLoading}
        >
          复制
        </Button>,
      ]}
      className={styles.Inf_move_or_copy_modal}
    >
      <List
        itemLayout="vertical"
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            extra={
              <Checkbox
                checked={checkedIds.includes(item.id)}
                onChange={(e) => onChangeCheckBox(e, item)}
              ></Checkbox>
            }
          >
            <div key={item.id}>
              {item.name}
              <span className={styles.forPadding}>{item.photoCount}张照片</span>
            </div>
          </List.Item>
        )}
      />
    </Modal>
  )
}

export default MoveOrCopyModal
