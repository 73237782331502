import {
  AlignTypes,
  DefaultIndexTypes,
  YesOrNoOptions,
} from 'constants/Options'
import Imgs from 'imgs'
import { map } from 'lodash/fp'
import Mock from 'mockjs'
const { LogoWT, Wxcode } = Imgs

const YesOrNoOptionsValues = map(({ value }) => value)(YesOrNoOptions)
const DefaultIndexTypesValues = map(({ value }) => value)(DefaultIndexTypes)
const AlignTypesValues = map(({ value }) => value)(AlignTypes)

const { Random } = Mock

export const ShowcaseSettingData = {
  aboutTitle: Random.cword(3), // 关于-标题
  aboutDesc: Random.ctitle(200), // 关于-描述
  aboutAlignType: AlignTypesValues[Random.natural(0, 2)], // 关于-对齐类型
  isShowEnterpriseService: YesOrNoOptionsValues[Random.natural(0, 1)], // 是否显示服务
  enterpriseServiceList: Array.from(
    { length: Random.natural(0, 10) },
    (v, i) => ({
      id: Random.id(), // id
      name: ['儿童摄影', '家庭摄影', 'FAMILY AND MATERNITY'][
        Random.natural(0, 2)
      ], // 名称
      coverKey: Random.cword(3), // 封面图key
      coverPhotoInfo: {
        photoKey: Random.cword(3), // 图片Key
        photoUrl: [
          // 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
          // 'http://photo.infotos.cn/logo4.jfif1594463001',
          // 'http://photo.infotos.cn/logo3.jfif1594463406',
          // 'http://photo.infotos.cn/iconMessage.png1594463431',
        ][Random.natural(0, 3)], // 图片完整Url
        photoSize: Random.natural(1, 500), // 图片大小(kb)
        photoFormat: ['png', 'jpg', 'jpeg'][Random.natural(0, 1)], // 图片格式
        photoWidth: Random.natural(1, 60), // 图片宽度(px)
        photoHeight: Random.natural(1, 60), // 图片高度(px)
      },
      price: Random.natural(1000, 5000), // 价格
      categoryCode: Random.cword(3), // 分类code
      categoryName: Random.cword(3), // 分类名称
      remark: [
        '90 minutes-2 hours / Two locations / Pre session consultation / Professionally edited Hi Res file / Online gallery to download, share photos and order prints / Full printing rights to the images',
        '3小时拍摄 / 120张底片 / 20张精修全送',
        '全天拍摄 / 600张底片 / 80张精修全送 / 提供相册 / 服装与化妆请自备',
      ][Random.natural(0, 2)], // 描述
    })
  ),
  contactTitle: Random.cword(3), // 联系-标题
  contactAddress: Random.county(true) + '和睦路2号元谷社区创意园1-101', // 联系-地址
  contactEmail: Random.email(), // 联系-email
  contactPhone: Random.phone(), // 联系-联系电话
  contactWechatImageKey: Random.name(), // 联系-微信二维码图片key
  contactWechatImagePhotoInfo: {
    photoKey: Random.cword(3), // 图片Key
    photoUrl: Wxcode, // 图片完整Url
    photoSize: Random.natural(1, 500), // 图片大小(kb)
    photoFormat: ['png', 'jpg', 'jpeg'][Random.natural(0, 1)], // 图片格式
    photoWidth: Random.natural(1, 60), // 图片宽度(px)
    photoHeight: Random.natural(1, 60), // 图片高度(px)
  },
  defaultIndexType: DefaultIndexTypesValues[Random.natural(0, 4)], // 默认首页类型
  isShowIndex: YesOrNoOptionsValues[Random.natural(0, 1)], // 是否显示首页
  indexName: Random.cword(3), // 首页显示名称
  isShowWorks: YesOrNoOptionsValues[Random.natural(0, 1)], // 是否显示作品
  worksName: Random.cword(3), // 作品显示名称
  isShowCustomerPhoto: YesOrNoOptionsValues[Random.natural(0, 1)], // 是否显示客片
  customerPhotoName: Random.cword(3), // 客片显示名称
  isShowAbout: YesOrNoOptionsValues[Random.natural(0, 1)], // 是否显示关于
  aboutName: Random.cword(3), //关于显示名称
  isShowContact: YesOrNoOptionsValues[Random.natural(0, 1)], // 是否显示联系
  contactName: Random.cword(3), // 联系显示名称
}
