/*
 * @Author: melon
 * @Date: 2020-07-02 16:20:31
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-11 21:04:25
 */

import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  useState,
} from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { Spin } from 'antd'
import { pick } from 'lodash/fp'

import {
  getAccountInfo as _getInfo,
  changeAccountInfo as _changeAccountInfo,
  getAccountInfoAction,
  getAccountInfoSuccessAction,
  getAccountInfoFailAction,
} from 'reduxModules/account'
import AccountForm from '../Components/AccountForm'
import commonComponent from 'components/index'

const { MyMessage } = commonComponent

const Account = (props, ref) => {
  const formRef = useRef()
  const dispatch = useDispatch()
  const formData = useSelector(
    (state) => state.account.data.formattedData || {}
  )
  const requesting = useSelector((state) => state.account.fetching || false)

  useImperativeHandle(ref, () => ({
    // onSaveForm 就是暴露给父组件的方法
    onSaveForm: () => {
      formRef.current
        .validateFields()
        .then(async (values) => {
          // formRef.current.resetFields()

          const body = pick(['nickname', 'sidebarColorType'])(values)
          // 派发修改账户数据请求
          try {
            const resData = await _changeAccountInfo(body)
            if (resData.data && [0].includes(resData.data.code)) {
              MyMessage.success('保存成功')
              // 派发获取信息数据
              getAccountInfo()
            } else {
              MyMessage.error('保存失败')
            }
          } catch (error) {}
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
        })
    },
  }))
  const getAccountInfo = async () => {
    dispatch(getAccountInfoAction())
    _getInfo()
      .then(async (res) => {
        if ([0].includes(res.code)) {
          dispatch(getAccountInfoSuccessAction(res.data))
        } else {
          dispatch(getAccountInfoFailAction(res.data.message))
        }
        return Promise.resolve(res)
      })
      .catch((error) => {
        dispatch(getAccountInfoFailAction(error))
        return Promise.reject(error)
      })
  }
  useEffect(() => {
    getAccountInfo()
  }, [])

  return (
    <Spin tip="数据加载中" spinning={requesting}>
      <AccountForm
        formRef={formRef}
        formData={formData}
        getInfo={getAccountInfo}
      />
    </Spin>
  )
}

export default forwardRef(Account)
