/*
 * @Author: melon
 * @Date: 2020-07-04 23:16:41
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-06 16:42:03
 */
// 注册数据
export const RegisterSelector = (data) => {
  return {
    ...data,
    mobile: data.mobile, // 手机号
    password: data.password, // 密码
    verifyCode: data.verifyCode, // 验证码
    enterpriseName: data.enterpriseName, // 品牌名称
  }
}

//  验证码数据
export const VerifyCodeSelector = (data) => {
  return {
    ...data,
    mobile: data.mobile, // 手机号
  }
}

// 检查手机是否注册

export const CheckPhoneSelector = (data) => {
  return {
    ...data,
    canVerify: ['N'].includes(data.whether),
  }
}
