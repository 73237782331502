import {Button, Col, DatePicker, Form, Modal, Row} from 'antd'
import AddSelect from 'components/AddSelect'
import { VersionsTypes } from 'constants/Options'
import styles from './accessControl.module.less'
import { PAGE_PATH } from 'apis/Router'
import components from 'components'
import classNames from 'classnames'
import React, { useState } from 'react'
import { getFullRoute } from 'utils/Tools'
import {Link} from "react-router-dom";

const AccessControl = ({
                            visible,
                            onCancel,
                          htmlTexts
                        }) => {
    const [form] = Form.useForm()
    const upgrade=()=>{

    }
    return (
        <Modal
            visible={visible}
            title="功能受限"
            okText="升级订阅"
            centered
            cancelButtonProps={{className: 'footerCanceltext'}}
            onCancel={onCancel}
            width={600}
            footer={[
                <Button
                    key='ok'
                    type="primary"
                    onClick={upgrade}>
                    <Link
                        to={PAGE_PATH.settings.children.subscribe}
                    >
                        升级订阅
                    </Link>
                </Button>

            ]}
        >
            {/*<div className={styles.upgrade}>您当前的账户无法使用该功能，如需使用请升级到更高版本。</div>*/}
            <div className={styles.upgrade}>{htmlTexts}</div>
        </Modal>
    )
}

export default AccessControl
