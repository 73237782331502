import { Button, Form, InputNumber, Popconfirm, Table } from 'antd'
import classNames from 'classnames'
import CommonComponent from 'components/index'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { getNumberArraySum } from 'utils/Tools'
import styles from './List.module.less'
const { ProductCard, MyMessage } = CommonComponent

const EditableContext = React.createContext()

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef()
  const form = useContext(EditableContext)
  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }

  const save = async (e) => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `请输入${title}`,
          },
        ]}
      >
        <InputNumber min={1} ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingLeft: 10,
          paddingRight: 24,
          border: '1px solid #d9d9d9',
          width: '85px',
          height: '45px',
          lineHeight: '45px',
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

class ShopCartList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deleting: false,
      currentId: null,
    }
  }
  onRemove = async (id) => {
    const { getList, onDelete } = this.props
    await this.setState({
      deleting: true,
      currentId: id,
    })
    try {
      const resData = await onDelete(id)
      if (resData.data && [0].includes(resData.data.code)) {
        MyMessage.success('删除成功')
        // 派发获取列表数据
        getList()
      } else {
        MyMessage.error('删除失败')
      }
    } catch (error) {
    } finally {
      await this.setState({
        deleting: false,
        currentId: null,
      })
    }
  }
  render() {
    const {
      doPay,
      dataSource,
      total,
      handleSaveAmount,
      selectedRowKeys,
      setSelectedRows,
      setSelectedRowKeys,
      setTotal,
    } = this.props
    const { deleting, currentId } = this.state
    const columns = [
      {
        title: '购买项目',
        dataIndex: 'name',
        render: (_, { name, src, description, id }) => (
          <ProductCard
            imgStyle={{ width: 60, height: 60 }}
            coverDivStyle={{ width: 60, height: 60 }}
            imgSrc={src}
            header={
              <div>
                <div className={classNames('Inf_product_card_title')}>
                  {name}
                </div>
                <div className={classNames('Inf_product_card_remarks')}>
                  {description}
                </div>
              </div>
            }
            footer={
              <Popconfirm
                title="确认删除"
                onConfirm={() => this.onRemove(id)}
                okText="确认"
                cancelText="取消"
              >
                <Button
                  className={classNames('Inf_text_btn', styles.delete_btn)}
                  key={'delete' + id}
                  type="text"
                  disabled={currentId === id && deleting}
                >
                  删除
                </Button>
              </Popconfirm>
            }
          />
        ),
      },
      {
        title: '单价',
        dataIndex: 'price',
        render: (text) => <span>￥ {text}</span>,
      },
      {
        title: '数量',
        dataIndex: 'amount',
        editable: true,
        width: 200,
      },
      {
        title: '金额',
        dataIndex: 'money',
        render: (text) => <span>￥ {text}</span>,
      },
    ]
    const components = {
      body: {
        cell: EditableCell,
        row: EditableRow,
      },
    }
    const shopCartColumns = columns.map((col) => {
      if (!col.editable) {
        return col
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSaveAmount,
        }),
      }
    })
    // rowSelection object indicates the need for row selection
    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        const numberArray = selectedRows.map(({ money }) => money)
        const newTotal = getNumberArraySum(numberArray, 2)
        // 计算total
        setTotal(newTotal)
        setSelectedRows(selectedRows)
        setSelectedRowKeys(selectedRowKeys)
      },
    }
    return (
      <div className={styles.Inf_shopCart_list}>
        <Table
          components={components}
          rowKey="id"
          columns={shopCartColumns}
          dataSource={dataSource}
          pagination={false}
          rowSelection={{
            ...rowSelection,
            columnWidth: '20px',
          }}
        />

        <div className={styles.pay_wrapper}>
          <div className={classNames(styles.pay_info)}>
            <p className={styles.pay_total}>
              合计 <span className={styles.pay_total_number}>￥{total}</span>
            </p>
            <p className={styles.freight_text}>不含运费</p>
          </div>
          <Button
            className={classNames(styles.pay_btn)}
            key={'pay'}
            onClick={doPay}
          >
            结算
          </Button>
        </div>
      </div>
    )
  }
}
export default ShopCartList
