/*
 * @Author: melon
 * @Date: 2020-07-02 16:20:31
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-17 21:13:05
 */
import { Spin } from 'antd'
import commonComponent from 'components/index'
import {
  getMyStorage as _getList,
  addCart as _addCart,
  removeMyStorage as _remove,
} from 'reduxModules/order'
import React, { forwardRef, useState, useEffect } from 'react'
import CacheList from '../Components/CacheList'
const { MyMessage, InfoEmptyData } = commonComponent
const CachePage = (props, ref) => {
  const [cacheData, seCacheData] = useState({ list: [], pagination: {} })
  // 是否加载中
  const [loading, setLoading] = useState(false)
  const onAddShopCart = async (id) => {
    // 派发添加请求
    const body = {
      id: id,
    }
    // 派发修改数据请求
    return await _addCart(body)
  }
  const getList = async ({ pagination = {} }) => {
    let res = null

    await setLoading(true)

    res = await _getList({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    })

    await setLoading(false)
    if (res.code === 0) {
      const {
        formattedData: { pagination: formattedPagination, list },
      } = res.data

      const newPhotosData = {
        pagination: formattedPagination,
        list,
      }
      seCacheData(newPhotosData)
    } else {
      MyMessage.error(res.msg)
    }
  }
  // 删除
  const onRemove = async (id) => {
    // 派发修改数据请求
    return await _remove({ id })
  }
  useEffect(() => {
    getList({})
  }, [])
  // console.log(cacheData)
  return (
    <Spin tip="数据加载中" spinning={loading}>
      {cacheData.list.length > 0 && (
        <CacheList
          list={cacheData.list}
          pagination={cacheData.pagination}
          onAddShopCart={onAddShopCart}
          getList={getList}
          onRemove={onRemove}
        />
      )}
      {cacheData.list.length <= 0 && !loading && (
        <InfoEmptyData description="暂无暂存" />
      )}
    </Spin>
  )
}

export default forwardRef(CachePage)
