// Servers
// Selectors
import {
  ShowcaseCategoryAddSelector,
  ShowcaseCategoryBatchEditSelector,
  ShowcaseCategoryListSelector,
  ShowcaseCategoryMoveOrCopyCategorySelector,
} from 'selectors/showcaseCategory/ShowcaseCategorySelectors'
import {
  _batchEdit,
  _editShowcaseCategory,
  _getAllMoveOrCopyCategory,
  _getShowcaseCategory,
} from 'servers/showcaseCategory'
// Actions

// Action Creators

// init data

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action
export const getShowcaseCategory = async (data) => {
  const res = await _getShowcaseCategory(data)

  return new Promise(function (resolve, reject) {
    resolve(ShowcaseCategoryListSelector(res))
    reject({})
  })
}

export const editShowcaseCategory = (data) =>
  _editShowcaseCategory(ShowcaseCategoryAddSelector(data))

export const batchEdit = (data) =>
  _batchEdit(ShowcaseCategoryBatchEditSelector(data))

export const getAllMoveOrCopyCategory = async () => {
  const res = await _getAllMoveOrCopyCategory()

  return new Promise(function (resolve, reject) {
    resolve(ShowcaseCategoryMoveOrCopyCategorySelector(res))
    reject({})
  })
}
