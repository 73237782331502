//价格表详情（自定义产品）
import React, {useEffect, useRef, useState, useImperativeHandle, forwardRef, useMemo} from "react";
import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Button,
  Menu,
  Dropdown,
  Divider,
  Layout,
  PageHeader,
  Switch,
  message,
  InputNumber,
  Image
} from "antd";
import styles from './newProduct.module.less'
import moment from "moment";
import {useParams, useHistory} from 'react-router-dom'
import AddProductOption from "./AddProductOption";
import myMessage from 'components/Message'
import {map} from "lodash/fp";
import commonComponent from 'components/index'
import {
  addProduct as _addProduct,
  editProduct as _editProduct,
  productType as _productType,
  uploadImg as _uploadImg,
} from 'reduxModules/store'
import { PriceValidateRule, pricePattern, extraPostageValidateRule } from 'utils/ValidateRulesAndMsg'

const {Option} = Select
const {TextArea} = Input
const {Content} = Layout
const {UploadModal} = commonComponent
const NewProduct = ({name}) => {
  let history = useHistory()
  const [form] = Form.useForm()
  let {pricesheetId} = useParams()
  const [loading, setLoading] = useState(false)

  const [supplierList, setSupplierList] = useState([
    {code: 'album', value: '相册'},
    {code: 'framePainting', value: '框画'},
    {code: 'printing', value: '打印'},
    // {code: 'digitaDownload', value: '数字下载'},
    // {code: 'productPackage', value: '产品包'},
    {code: 'swingTable', value: '摆台'},
    {code: 'other', value: '其他'},
  ])
  const [pageControlTypeEnum, setPageControlTypeEnum] = useState([
    {code: 'freeIncrease', value: '允许自由增页'},
    {code: 'fixedPage', value: '固定页数'},
  ])
  const [frmMaterialTypeEnum, setFrmMaterialTypeEnum] = useState([
    {code: 'wooden', value: '实木'},
    {code: 'metal', value: '金属'},
    {code: 'others', value: '其他'},
    {code: 'none', value: '无'},
  ])
  const [phontoNumType, setPhontoNumType] = useState([

    {code: 'anyQuantity', value: '任意数量'},
    {code: 'specificQuantity', value: '特定数量'},
    {code: 'specificRange', value: '特定范围'},
    // {code:'maximumQuantity',value:'最大数量'},
    // {code:'minimumQuantity',value:'最小数量'},
  ])

  const [productOptionFroms, setProductOptionFroms] = useState([
    // {
    //     optionName:'尺寸',
    //     optionValue:'8寸, 10寸, 12寸',
    //     serialNum: 0
    // },
  ])
  const [ProductPriceList, setProductPriceList] = useState([])
  const [visible, setVisible] = useState(false)
  const [list, setList] = useState([])
  const [curDragItem, setCurDragItem] = useState({});
  const [addVisible, setAddVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [checkOption, setCheckOption] = useState({});
  const [graph, setGraph] = useState({});
  const [graphVisible, setGraphVisible] = useState(false);
  // 上传的弹窗显示与否
  const [uploadModalVisible, setUploadModalVisible] = useState(false)
  // 上传弹窗ref
  const [uploadModalRef, setUploadModalRef] = useState()
  //照片数量
  const [photoNum, setPhotoNum] = useState(false)
  //页数控制方式
  const [pages, setPages] = useState(true)
  //材质
  const [frmMaterial, setFrmMaterial] = useState(false)
  //相册时是否允许增页
  const [isalbumPages, setIsalbumPages] = useState(false)
  //是否显示头像
  const [ChosePhoto, setChosePhoto] = useState(false)
  const [enterpriseId, setEnterpriseId] = useState('');
  const [pricesheet, setPricesheet] = useState({});
  const [photoMaxNummin, setPhotoMaxNummin] = useState(
    [
      {
        required: true,
        pattern: new RegExp(/^([1-9]\d*)$/, 'g'),
        message: '请输入整数',
      },
      {
        min: 2,
        message: '请大于最小数', trigger: 'blur'
      },
    ]
  );
  useEffect(() => {
    setPricesheet(JSON.parse(sessionStorage.getItem('pricesheet')))
    getListProductType()
    setEnterpriseId(sessionStorage.getItem('user')
      ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
      : null)
    //修改时赋值
    if (sessionStorage.getItem('product')) {
      let product = JSON.parse(sessionStorage.getItem('product'))
      //参数选择项存在且大于0时
      if(product.productOptionVos?.length>0) {
        product.productOptionVos.map(v => {
          if (v.optionName == 'albumPages') {
            v.optionName = '页数'
          }
        })
      }
      form.setFieldsValue({
        ...product,
        isChosePhoto: product.isChosePhoto == 'yes' ? true : false
      })
      setChosePhoto(form.getFieldValue('isChosePhoto'))
      setPhotoNum(product.photoNumType == 'anyQuantity' ? 0 : product.photoNumType == 'specificQuantity' ? 1 : 2)
      //框画和摆台的材质
      setFrmMaterial(product.typeId == 2 || product.typeId == 6)
      let arr = []
      if (!!product.imageId) {
        product.imageId.split(',').map((v, i) => {
          arr.push({
            id: v,
            imgs: product.imageUrls[i]
          })
        })
        setList(arr)
      }
      if (!!product.productOptionVos) {
        setProductOptionFroms(product.productOptionVos)
      }
      if (!!product.productSpecificationsVos) {
        setProductPriceList(product.productSpecificationsVos)
      }
      setPages(product.typeId == 1)
      if (form.getFieldValue('typeId') == 1 && form.getFieldValue('pageControlType') == 'fixedPage' ) {
        if(!product.productSpecificationsVos) {
          let albumPages = true
          productOptionFroms.map(v => {
            if (v.optionName == '页数') {
              albumPages = false
            }
          })
          if (albumPages) {
            productOptionFroms.push({
              // optionName: 'albumPages',
              optionName: '页数',
              optionValue: '20',
              serialNum: productOptionFroms.length == 0 ? 0 : productOptionFroms[productOptionFroms.length - 1].serialNum + 1
            })
            regroup()
          }
        }
      }
      //只有当相册且为加页时显示加页价格
      setIsalbumPages(form.getFieldValue('typeId') == 1 &&form.getFieldValue('pageControlType') == 'freeIncrease')
    } else {
      //当相册为固定页数时添加商品选项
      if (form.getFieldValue('typeId') == 1 && form.getFieldValue('pageControlType') == 'fixedPage') {
        let albumPages = true
        productOptionFroms.map(v => {
          if (v.optionName == '页数') {
            albumPages = false
          }
        })
        if (albumPages) {
          productOptionFroms.push({
            optionName: '页数',
            optionValue: '20',
            serialNum: productOptionFroms.length==0?0:productOptionFroms[productOptionFroms.length-1].serialNum+1
          })
          regroup()
        }
        setIsalbumPages(false)
      } else {
        setIsalbumPages(true)
      }
    }
  }, [])
  const len = (min) => {
    let num = parseInt(min) + 1
    let rmsg = ''
    rmsg += ' 最小为' + num
    let obj = [
      {
        required: true,
        pattern: new RegExp(/^([1-9]\d*)$/, 'g'),
        message: '请输入整数',
      },
      {min: num, message: rmsg, trigger: 'blur'}]
    return obj
  }
  // 获取列表
  const getListProductType = async () => {
    const res = await _productType({
      storeId: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).enterpriseId : null,
      pageNum: 1,
      pageSize: 1000,
    })
    if (res.status == '200' && res.data.code === '000000') {
      setSupplierList(res.data.data.records)
    } else {
      myMessage.error(res.data.mesg)
    }
  }
  let animationFlag = true;

  const dragStart = (e) => {
    console.log(e)
    // setCurDragItem(e);
    setCurDragItem(JSON.parse(e.target.getAttribute('drag-data')));
  }

  const dragEnd = (e) => {
    setCurDragItem({});
  }

  const onDragOver = (event) => {
    event.preventDefault();
    // console.log(event,event.target)
    let targetItem = JSON.parse(event?.target?.getAttribute('drag-data'));
    // return
    // console.log(animationFlag,curDragItem.id,targetItem?.id,curDragItem.id !== targetItem?.id)
    if (animationFlag && curDragItem.id && targetItem?.id && curDragItem.id !== targetItem?.id) {
      animationFlag = false;
      // 获取当前拖拽节点最新的位置
      let curNewestDragItemDom = getDomItem(curDragItem.id);
      let curNewestDragItemDomRect =
        curNewestDragItemDom?.getBoundingClientRect() || {};
      const targetRect = event.target.getBoundingClientRect();
      // 动画
      event.target.style.transition = 'all 200ms';
      curNewestDragItemDom.style.transition = 'all 200ms';

      event.target.style.transform = `translate3d(${curNewestDragItemDomRect.left - targetRect.left
      }px,${curNewestDragItemDomRect.top - targetRect.top}px,0)`;
      curNewestDragItemDom.style.transform = `translate3d(${targetRect.left - curNewestDragItemDomRect.left
      }px,${targetRect.top - curNewestDragItemDomRect.top}px,0)`;

      setTimeout(() => {
        //排序
        sortPosition(curDragItem.id, targetItem.id);
      }, 200);
    }
  }

  // 获取指定id的组件元素
  const getDomItem = (id) => {
    return document.getElementsByName('item_' + id)?.[0] || {};
  };

  // 元素换位置
  const sortPosition = (sourceId, targetId) => {
    if (sourceId && targetId && targetId !== sourceId) {
      // 真正的节点交换顺序
      let tmpList = JSON.parse(JSON.stringify(list))
      let sourceItem = tmpList.find((val) => val.id == sourceId);
      let sourceItemIndex = tmpList.findIndex((val) => val.id == sourceId);
      let targetItem = tmpList.find((val) => val.id == targetId);
      let targetItemIndex = tmpList.findIndex((val) => val.id == targetId);
      tmpList.splice(sourceItemIndex, 1, targetItem);
      tmpList.splice(targetItemIndex, 1, sourceItem);
      setList(tmpList);
      console.log(tmpList)

      // 清除动画及位移
      let sourceDom = getDomItem(sourceId);
      let targetDom = getDomItem(targetId);
      sourceDom.style.transition = '';
      sourceDom.style.transform = '';
      targetDom.style.transition = '';
      targetDom.style.transform = '';

      // 允许下次交换
      animationFlag = true;
    }
  }
  useEffect(() => {
    regroup()
  }, [pricesheetId])

  // const showVisible=()=>{
  //     setVisible(true)
  // }
  const addDeliver = (values) => {

  }
  // 返回
  const goBack = () => {
    history.goBack()
  }
  const columns = [
    {
      title: '选项',
      dataIndex: 'optionName',
      key: 'optionName',
      render: (_, item) => {
        return (
          <div>
            {item.optionName}
          </div>
        )
      },
    },
    {
      title: '价格',
      dataIndex: 'price',
      key: 'price',
      align: 'right',
    },
    {
      title: '加页价格/2P',
      dataIndex: 'pageSalesPrice',
      key: 'pageSalesPrice',
      align: 'right',
    },
    {
      title: '额外运费',
      dataIndex: 'salesPrice',
      key: 'salesPrice',
      align: 'right',
      render: (_, item) => {
        // if (+item.rolesId === 1) return ''
        return (
          <Input/>
        )
      }
    },
    {
      title: '显示',
      dataIndex: 'isDisplay',
      key: 'isDisplay',
      render: (_, item) => {
        // if (+item.rolesId === 1) return ''
        return (
          <div>
            {item.isDisplay == 'yes' ?
              <span className="iconfont iconproduct_show"></span> :
              <span className="iconfont iconproduct_hidden"></span>}
          </div>
        )
      }
    }
  ]
  const regroup = () => {
    let arr = []
    if (productOptionFroms.length == 0) {
      return
    }
    productOptionFroms.map((v) => {
      if(v.optionName=='页数') { //页数优化
        let pages=[]
        v.optionValue.split(',').map(va=>{
          pages.push(va+'页')
        })
        arr.push(pages)
      }else{
        arr.push(v.optionValue.split(','))
      }
    })
    setProductPriceList(combine(...arr).map((item) => {
      let obj = {
        imageId: '',
        imageUrl: '',
        optionName: item.join(','),
        salesPrice: form.getFieldValue('price'),
        pageSalesPrice: form.getFieldValue('costPrice'),
        extraFreight: null,
        isDisplay: 'yes',
      }
      return obj
    }))
  }
  const combine = (...chunks) => {
    let res = []
    let helper = function (chunkIndex, prev) {
      let chunk = chunks[chunkIndex]
      let isLast = chunkIndex === chunks.length - 1
      for (let val of chunk) {
        let cur = prev.concat(val)
        if (isLast) {
          // 如果已经处理到数组的最后一项了 则把拼接的结果放入返回值中
          res.push(cur)
        } else {
          helper(chunkIndex + 1, cur)
        }
      }
    }
    // 从属性数组下标为 0 开始处理
    // 并且此时的 prev 是个空数组
    helper(0, [])
    return res
  }
  // 选项价格设置
  const setInputValue = (val, e, i, t) => {
    let arr = JSON.parse(JSON.stringify(ProductPriceList))
    arr[i][t] = e
    setProductPriceList(arr)
  }
  const priceBlur = (val, e, i, t) => {
    let arr = JSON.parse(JSON.stringify(ProductPriceList))
    let reg = pricePattern
    if (reg.test(e)) {
      arr[i][t] = Number(e)
    } else {
      arr[i][t] = ''
      myMessage.warning('请输入正确的金额，如10.00或10')
    }
    setProductPriceList(arr)
  }

  //添加选项
  const onAddOption = async (values) => {
    let serialNum=[]
    productOptionFroms.map(v=>{
      serialNum.push(v.serialNum)
    })
    //修改时更改原值
    // if(serialNum.includes(values.serialNum)){
    if(checkOption.ind>=0){
      var ind = (serialNum || []).findIndex((item) => item === values.serialNum);
      productOptionFroms[checkOption.ind].optionName=values.name
      productOptionFroms[checkOption.ind].optionValue=values.values.join(',')
    }else {
      productOptionFroms.push({
        optionName: values.name,
        optionValue: values.values.join(','),
        serialNum:productOptionFroms.length==0?0:productOptionFroms[productOptionFroms.length-1].serialNum+1
      })
    }
    setProductOptionFroms(productOptionFroms)
    setAddVisible(false)
    //添加选项后重新计算选项参数值
    regroup()
    // const res = await _addProject(values)
    // if (res.data.code === 0) {
    //
    // }
  }
  //修改选项
  const editOption = async (val, i) => {
    setCheckOption({obj: val, ind: i})
    setAddVisible(true)
  }
  //删除示例图
  const deleteSampleGraph = async (val, i) => {
    setGraph({obj: val, ind: i})
    setGraphVisible(true)
  }
  const deleteGraph = async () => {
    list.splice(graph.ind, 1)
    setGraphVisible(false)
    setGraph({})
    myMessage.success('删除成功')
  }
  //删除选项
  const removeOption = async (val, i) => {
    setCheckOption({obj: val, ind: i})
    setDeleteVisible(true)
  }
  const deleteOption = async () => {
    productOptionFroms.splice(checkOption.ind, 1)
    setDeleteVisible(false)
    setCheckOption({})
    myMessage.success('删除成功')
    if (productOptionFroms.length == 0) {
      setProductPriceList([])
      return
    }
    regroup()
  }
  // 上传 ref
  const onRef = (ref) => {
    setUploadModalRef(ref)
  }
  // 展示上传的modal
  const doShowModalVisible = () => {
    setUploadModalVisible(true)
    uploadModalRef.setShowUploadBtn(true)
    uploadModalRef.setMinStatus(false)
  }
  // 隐藏上传的modal
  const doHiddenModalVisible = () => {
    setUploadModalVisible(false)
    uploadModalRef.setShowUploadBtn(false)
    uploadModalRef.setMinStatus(false)
  }
  // 新增照片
  const savePhotos = async (res) => {
    /*const addRes = await _addShowcasePhoto({
        showcaseType: 'WORKS',
        categoryId,
        isCover: res.cover ? 'Y' : 'N',
        isWaterMark: res.waterMark ? 'Y' : 'N',
        photos: [
            {
                imageKey: res.key,
                imageName: res.file.name,
            },
        ],
    })
    if (addRes.data.code === 0) {
        reloadData()
    }*/
  }
  const checkPhotos = async (data) => {
    // return await _checkImage({
    //     showcaseType: 'WORKS',
    //     ...data,
    // })
  }
  //表单值变化
  const valuesChange = (size) => {
    //照片数量限定
    !!size.photoNumType && setPhotoNum(size.photoNumType == 'anyQuantity' ? 0 : size.photoNumType == 'specificQuantity' ? 1 : 2)
    //页数控制方式
    // !!size.typeId&&setPages(size.typeId==1)
    if (!!size.typeId) {
      // 相册页数控制方式
      setPages(size.typeId == 1)
      //框画和摆台的材质
      setFrmMaterial(size.typeId == 2 || size.typeId == 6)
      if (size.typeId == 1) {
        //当为框画时赋值页数控制方式
        form.setFieldsValue({
          pageControlType: 'fixedPage'
        })
      }
      //  当不为相册时删除选项为页数的参数
      if(size.typeId!=1){
        let i=-1
        productOptionFroms.map((v,ind)=>{
          if(v.optionName=='页数'){
            i=ind
          }
        })
        if(i>-1){
          productOptionFroms.splice(i,1)
        }
        if(productOptionFroms.length>0) {
          regroup()
        }
      }
    }
    //当相册为固定页数时添加商品选项
    if (form.getFieldValue('typeId') == 1 && form.getFieldValue('pageControlType') == 'fixedPage') {
      let albumPages = true
      productOptionFroms.map(v => {
        if (v.optionName == '页数') {
          albumPages = false
        }
      })
      if (albumPages) {
        productOptionFroms.push({
          optionName: '页数',
          optionValue: '20',
          serialNum: productOptionFroms.length==0?0:productOptionFroms[productOptionFroms.length-1].serialNum+1
        })
        regroup()
      }
    }
    //只有当相册且为加页时显示加页价格
    setIsalbumPages(form.getFieldValue('typeId') == 1 &&form.getFieldValue('pageControlType') == 'freeIncrease')
    !!size.photoMinNum && setPhotoMaxNummin(
      [
        {
          required: true,
          pattern: new RegExp(/^([1-9]\d*)$/, 'g'),
          message: '请输入整数',
        },
        {
          min: size.photoMinNum + 10,
          message: '请大于最小数',
        },
      ])
  }
  const changeChosePhoto = (checked) => {
    // form.setFieldsValue({
    //   isChosePhoto:!checked
    // })
    setChosePhoto(checked)
    // console.log(form.getFieldValue('isChosePhoto'))
  }
  //保存参数
  const save = async () => {
    form
      .validateFields()
      .then((values) => {
        let a = false,b=false
        //当为相册时
        // if(form.getFieldValue('typeId') == 1&&ProductPriceList.length>0){
        // 当商品类型有选项时
        if(productOptionFroms.length>0){
          ProductPriceList.map(v => {
            if (!v.salesPrice||v.salesPrice==0) {
              a = true
            }
            console.log(isalbumPages)
            if ((!v.pageSalesPrice || v.pageSalesPrice == 0) &&isalbumPages) {
              b = true
            }
          })
        }else{
          //当没有商品选项时
          // 价格不存在或价格为0
          if(!form.getFieldValue('price')||form.getFieldValue('price')==0){
            a=true
          }
        }
        if (list.length == 0) {
          myMessage.warning('商品示例图不能为空！')
          return;
        }
        if (a) {
          myMessage.warning('价格不能为空且不能为0！')
          return;
        }
        if (b) {
          myMessage.warning('加页价格不能为空且不能为0！')
          return;
        }
        values.priceListId = pricesheetId
        supplierList.map(v => {
          if (values.typeId == v.id) {
            values.type = v.productTypeName
          }
        })
        values.storeId = enterpriseId
        let imgs = []
        list.map(v => {
          imgs.push(v.id)
        })
        values.imageId = imgs.join(',')
        values.isChosePhoto = values.isChosePhoto ? 'yes' : 'no'
        // form.resetFields()
        //当存在商品选项时赋值
        if(productOptionFroms.length>0) {
          values.productOptionFroms = productOptionFroms
          //当没有商品选项时
          values.productSpecificationsForms = ProductPriceList
          values.productOptionFroms.map(v => {
            if (v.optionName == '页数') {
              v.optionName = 'albumPages'
            }
          })
        }
        // console.log(values)
        // return
        addProduct(values)
        // return

      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }
  const addProduct = async (val) => {
    let res
    if (sessionStorage.getItem('product')) {
      val.id = JSON.parse(sessionStorage.getItem('product')).id
      val.productOptionEditFroms = val.productOptionFroms
      val.productSpecificationsEditForm = val.productSpecificationsForms
      res = await _editProduct(val)
    } else {
      res = await _addProduct(val)
    }
    if (res.status == '200' && res.data.code === '000000') {
      myMessage.success(sessionStorage.getItem('product') ? '更新成功' : '创建成功')
      goBack()
    } else {
      myMessage.error(res.data.mesg)
    }
  }
  //显示与隐藏
  const isShowHidden = (val, i) => {
    let arr = JSON.parse(JSON.stringify(ProductPriceList))
    arr[i].isDisplay = val.isDisplay == 'yes' ? 'no' : 'yes'
    setProductPriceList(arr)
  }
  // 图片上传
  const upload = async (e, obj) => {
    if (e.target.files[0].size > (1024 * 1024)) {
      myMessage.warning('图片不能大于2M！')
      return;
    }
    let formData = new FormData();
    formData.append('file', e.target.files[0])
    const res = await _uploadImg(formData)
    if (res.status == '200' && res.data.code === '000000') {
      if (obj >= 0) { //选项图片
        let arr = JSON.parse(JSON.stringify(ProductPriceList))
        arr[obj].imageId = res.data.data.id
        arr[obj].imageUrl = res.data.data.url
        setProductPriceList(arr)
      } else { //示例图
        let arr = JSON.parse(JSON.stringify(list))
        arr.push({
          id: res.data.data.id,
          imgs: res.data.data.url
        })
        setList(arr)
      }
    } else {
      myMessage.error(res.data.msg)
    }
  }
  return (
    <Layout>
      <PageHeader
        ghost={false}
        className={styles.titleBar}
        title={
          <div id="productCustomize">
            <Button
              icon={
                <span
                  className={`iconfont iconbtn_back`}
                ></span>
              }
              onClick={goBack}
              className={styles.backBtn}
            />
            {pricesheet.name} · {sessionStorage.getItem('product') ? '编辑' : '添加'}自定义商品
          </div>
        }
        extra={[
          <Button onClick={goBack} className={styles.cancelBtn}>取消</Button>,
          <Button onClick={save} type="primary">保存</Button>
        ]}
      ></PageHeader>
      <Content className={styles.newProductsPage}>
        <Spin tip="数据加载中" spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            name="productForm"
            className={styles.productForm}
            onValuesChange={valuesChange}
            initialValues={{
              photoNumType: 'anyQuantity',
              pageControlType: 'fixedPage',
              typeId: 1,
              photoNum: 1,
              isChosePhoto: false,
              frmMaterial:'none',
            }}
          >
            <Row gutter={24} className={styles.productContent}>
              <Col span={24}>
                <Form.Item label="商品名称"
                           name="name"
                           rules={[
                             {
                               required: true,
                               message: '请先添加商品名称',
                             },
                           ]}>
                  <Input/>
                </Form.Item>
                <Form.Item label="商品描述" name="describe">
                  <TextArea rows={3} placeholder="选填，请输入该商品的详细信息"/>
                </Form.Item>
              </Col>
              {productOptionFroms.length > 0 ?
                <Col span={12}>
                  <Form.Item label="价格"
                  >
                    <Input disabled placeholder="请在选项中设置"/>
                  </Form.Item>
                </Col> :
                <Col span={12}>
                  <Form.Item label="价格"
                             name="price"
                             rules={[PriceValidateRule]}>
                    <InputNumber controls={false}/>
                  </Form.Item>
                </Col>
              }
              {productOptionFroms.length > 0 ?
                <Col span={12}>
                  <Form.Item label="额外运费"
                  >
                    <Input disabled placeholder="请在选项中设置"/>
                  </Form.Item>
                </Col> : <Col span={12}>
                  <Form.Item label="额外运费"
                             name="extraPostage"
                             rules={[extraPostageValidateRule]}
                  >
                    <InputNumber controls={false}/>
                  </Form.Item>
                </Col>
              }
              <Col span={12}>
                <Form.Item
                  name="typeId"
                  label="商品类型"
                  rules={[
                    {
                      required: true,
                      message: '请选择',
                    },
                  ]}
                >
                  <Select
                    placeholder="请选择"
                    suffixIcon={
                      <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                    }
                  >
                    {/*<Option value="">默认</Option>*/}
                    {supplierList.map(v => (
                      <Option value={v.id}>{v.productTypeName}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {pages && (
                <Col span={12}>
                  <Form.Item
                    name="pageControlType"
                    label="页数控制方式"
                  >
                    <Select
                      placeholder="请选择"
                      suffixIcon={
                        <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                      }
                    >
                      {/*<Option value="">默认</Option>*/}
                      {pageControlTypeEnum.map(v => (
                        <Option value={v.code}>{v.value}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {frmMaterial && (
                <Col span={12}>
                  <Form.Item
                    name="frmMaterial"
                    label="材质"
                  >
                    <Select
                      placeholder="请选择"
                      suffixIcon={
                        <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                      }
                    >
                      {/*<Option value="">默认</Option>*/}
                      {frmMaterialTypeEnum.map(v => (
                        <Option value={v.code}>{v.value}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item label="商品示例图 *"
                           name="websiteFooter"
                >
                  <div className={styles.wrap}>
                    <ul
                      className={styles.list}
                      onDragOver={onDragOver}
                    >
                      {
                        list.map((val, i) => {
                          return (
                            <li
                              key={val.id}
                              name={'item_' + val.id}
                              className={styles.item}
                              draggable={true}
                              onDragStart={dragStart}
                              onDragEnd={dragEnd}
                              drag-data={JSON.stringify(val)}
                              style={{background: `url("${val.imgs}") center center/cover no-repeat`,backgroundPosition:'center'}}
                            >
                              {/*<img draggable={true}*/}
                              {/*              onDragStart={dragStart}*/}
                              {/*              onDragEnd={dragEnd}*/}
                              {/*              drag-data={JSON.stringify(val)} src={val.imgs}/>*/}
                              <span className="iconfont iconproduct_delete" onClick={() => {
                                deleteSampleGraph(val, i)
                              }}></span>
                            </li>
                          )
                        })
                      }
                      {list.length < 6 && (
                        <li className={styles.additem}>
                          {/*<li className={styles.additem} onClick={doShowModalVisible}>*/}
                          <span className="iconfont iconproduct_add"></span>
                          <input type="file" onChange={(e) => upload(e)}/>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className={styles.hint}>必填，最多只能上传6张产品图片，可拖拉排序，首张默认为封面。</div>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="商品选项"
                           name="websiteFooter"
                >
                  <div className={styles.hint}>选填，可为自己的商品设定不同的选项，例如颜色，尺寸等。
                    <span className={styles.addButton} onClick={() => {
                      setCheckOption({})
                      setAddVisible(true)
                    }}>
                                            {/* <span className="iconfont iconproduct_add"></span> */}
                      + 添加选项
                                        </span>
                  </div>
                  <div className={styles.productOpts}>
                    {productOptionFroms.map((v, ind) => (
                      <div key={ind}>
                                            <span>
                                                {v.optionName}：
                                              {v.optionValue}
                                            </span>
                        <span className="iconfont iconproduct_edit" onClick={() => editOption(v, ind)}></span>
                        {/*{(v.optionName != '页数' || isalbumPages) &&*/}
                        {(v.optionName != '页数') &&
                        <span className="iconfont iconproduct_delete"
                              onClick={() => removeOption(v, ind)}></span>
                        }
                      </div>
                    ))}
                  </div>
                </Form.Item>
              </Col>
              {productOptionFroms.length > 0 &&
              <Col span={24}>
                <Form.Item label="选项与价格设置"
                           name="websiteFooter"
                >
                  <div className={styles.Ptitle}>
                    <div className={styles.opt}>选项</div>
                    <div className={styles.price}>价格</div>
                    {/*{form.getFieldValue('typeId')==1&&*/}
                    {isalbumPages&&form.getFieldValue('typeId')==1 &&
                    <div className={styles.addPagePrice}>加页价格/2P</div>}
                    <div className={styles.shipping}>额外运费</div>
                    <div className={styles.isShow}>显示</div>
                  </div>
                  {ProductPriceList.map((val, i) => (
                    <div className={styles.content}>
                      <div className={styles.opt}>
                        {val.imageUrl ? (
                          <span className={styles.img}>
                                                      <img src={val.imageUrl} alt=""/>
                                                      <input type="file" onChange={(e) => upload(e, i)}/>
                                                    </span>
                        ) : (
                          <span className={styles.img}>
                                                        <span className="iconfont iconproduct_add"></span> <input
                            type="file" onChange={(e) => upload(e, i)}/>
                                                      </span>
                        )}
                        {val.optionName}
                      </div>
                      <div className={styles.price}>
                        <Input
                          className={`${!val.salesPrice?styles.inputred:''}`}
                          type="text"
                          value={val.salesPrice}
                          onChange={e => {
                            setInputValue(val, e.target.value, i, 'salesPrice');
                          }}
                          onBlur={e => {
                            priceBlur(val, e.target.value, i, 'salesPrice');
                          }}
                        />
                      </div>
                      {isalbumPages&&form.getFieldValue('typeId')==1 &&
                      <div className={styles.addPagePrice}>
                        <Input
                          className={`${!val.pageSalesPrice?styles.inputred:''}`}
                          type="text"
                          value={val.pageSalesPrice}
                          onChange={e => {
                            setInputValue(val, e.target.value, i, 'pageSalesPrice');
                          }}
                          onBlur={e => {
                            priceBlur(val, e.target.value, i, 'pageSalesPrice');
                          }}
                        />
                      </div>
                      }
                      <div className={styles.shipping}>
                        <Input
                          type="text"
                          value={val.extraFreight}
                          onChange={e => {
                            setInputValue(val, e.target.value, i, 'extraFreight');
                          }}
                          onBlur={e => {
                            priceBlur(val, e.target.value, i, 'extraFreight');
                          }}
                        />
                      </div>
                      <div className={styles.isShow}>
                        {val.isDisplay == 'yes' ?
                          <span className="iconfont iconproduct_show"
                                onClick={() => isShowHidden(val, i)}></span> :
                          <span className="iconfont iconproduct_hidden"
                                onClick={() => isShowHidden(val, i)}></span>}
                      </div>
                    </div>
                  ))}
                </Form.Item>
              </Col>
              }
              <Col span={24}>
                <Form.Item label="无需图像">
                  <Form.Item name="isChosePhoto" noStyle valuePropName="checked">
                    <Switch onChange={changeChosePhoto}/>
                  </Form.Item>
                  {/*<Switch />*/}
                  <div className={styles.desc}>说明：允许客户通过不需要图像直接将此商品添加到购物车或购买。</div>
                </Form.Item>
              </Col>
              {!ChosePhoto &&
              <Col span={12}>
                <Form.Item
                  name="photoNumType"
                  label="照片数量限定"
                  rules={[
                    {
                      required: true,
                      message: '请选择',
                    },
                  ]}
                >
                  <Select
                    placeholder="请选择"
                    suffixIcon={
                      <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                    }
                  >
                    {/*<Option value="">默认</Option>*/}
                    {phontoNumType.map(v => (
                      <Option value={v.code}>{v.value}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              }
              {photoNum == 1 && !ChosePhoto && (
                <Col span={12}>
                  <Form.Item label="照片数量限定"
                             name="photoNum"
                             rules={[
                               {
                                 required: true,
                                 pattern: new RegExp(/^([1-9]\d*)$/, 'g'),
                                 message: '请输入整数',
                               },
                             ]}
                  >
                    <Input/>
                  </Form.Item>
                </Col>
              )}
              {photoNum == 2 && !ChosePhoto && (
                <Col span={6} className={styles.miniMaxInput}>
                  <Form.Item label="最小数量"
                             name="photoMinNum"
                             rules={[
                               {
                                 required: true,
                                 pattern: new RegExp(/^([1-9]\d*)$/, 'g'),
                                 message: '请输入整数',
                               },
                             ]}
                  >
                    <InputNumber/>
                  </Form.Item>
                </Col>
              )}
              {photoNum == 2 && !ChosePhoto && (
                <Col span={6} className={styles.miniMaxInput}>
                  <Form.Item label="最大数量"
                             name="photoMaxNum"
                             rules={[
                               {
                                 required: true,
                                 pattern: new RegExp(/^([1-9]\d*)$/, 'g'),
                                 message: '请输入整数',
                               },
                             ]}
                  >
                    <InputNumber min={form.getFieldValue('photoMinNum') + 1}/>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        </Spin>
      </Content>
      <AddProductOption
        visible={addVisible}
        onCreate={onAddOption}
        onCancel={() => {
          setAddVisible(false)
        }}
        checkOption={checkOption}
      />
      <UploadModal
        onRef={onRef}
        visible={uploadModalVisible}
        addPhotos={savePhotos}
        onCancel={doHiddenModalVisible}
        options={{key: undefined, name: '首页'}}
        price={true}
        _checkPhotos={checkPhotos}
      />
      <Modal
        title="删除商品选项"
        visible={deleteVisible}
        onOk={deleteOption}
        onCancel={() => setDeleteVisible(false)}
        okText="确认"
        cancelText="取消"
        width={600}
        closable={false}
        centered
        maskClosable={false}
        cancelButtonProps={{className: 'footerCanceltext'}}
      >
        <div className="Inf_page_title">删除选项数据将不可恢复，可能会影响已有相关商品，确定删除？</div>
      </Modal>
      <Modal
        title="删除商品示例图"
        visible={graphVisible}
        onOk={deleteGraph}
        onCancel={() => setGraphVisible(false)}
        okText="确认"
        cancelText="取消"
        width={600}
        closable={false}
        centered
        maskClosable={false}
        cancelButtonProps={{className: 'footerCanceltext'}}
      >
        <div className="Inf_page_title">删除选项数据将不可恢复，可能会影响已有相关商品，确定删除？</div>
      </Modal>
    </Layout>
  )
}
export default NewProduct
