import {Col, DatePicker, Form, Input, Modal, Row, Select} from 'antd'
import AddSelect from 'components/AddSelect'
import React, {useEffect, useState} from 'react'
import styles from './addProductOption.module.less'
import classNames from "classnames";
import myMessage from 'components/Message'
const { Option } = Select

const AddOptionForm = ({
  visible,
  onCreate,
  onCancel,
  checkOption,
}) => {
  const [form] = Form.useForm()
  const [showMore, setShowMore] = useState(false)
  useEffect(() => {
    form.setFieldsValue({
      name:checkOption?.obj?.optionName,
      values:checkOption?.obj?.optionValue.split(','),
    })
  },)
  return (
    <Modal
      visible={visible}
      title="添加商品选项"
      okText="确定"
      cancelText="取消"
      centered
      cancelButtonProps={{className: 'footerCanceltext'}}
      onCancel={onCancel}
      maskClosable={false}
      width={600}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            let isNumber=false
            let reg=/^[1-9]{1,}[\d]*$/
            //判定页数是否为数字正整数
            values.values.map(v=>{
              // if(!(!isNaN(parseFloat(v)) && isFinite(v))){
              if(!reg.test(v)){
                isNumber=true
              }
            })
            if(values.name=='页数'&&isNumber){
              myMessage.warning('页数选项值必须为正整数')
              return;
            }
            form.resetFields()
            onCreate(values)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_project_form"
        requiredMark={false}
        className={styles.createProjectDialog}
        initialValues={{
          presetId:'',
        }}
      >
        <Form.Item
          name="name"
          label="名称"
          rules={[
            {
              required: true,
              message: '请输入选项名称',
            },
          ]}
        >
          <Input disabled={checkOption?.obj?.optionName=='页数'} placeholder="例如尺寸，颜色，纸张等" maxLength={30}/>
        </Form.Item>
            <Form.Item
              name="values"
              label="选项值"
              rules={[
                {
                  required: true,
                  message: '请输入选项，多个选项用逗号隔开',
                },
              ]}
            >
              <Select
                  mode="tags"
                  tokenSeparators={[",","，","\t","\n"]}
                  removeIcon={
                    <span className={classNames('iconfont iconbtn_dialog_close', styles.clearButton)}></span>
                  }
                  style={{ width: '100%' }}
                  placeholder="输入选项并用逗号间隔"
                  // onChange={handleChange}
                  open={false}>
              </Select>
            </Form.Item>
        {/*<div className={styles.more} onClick={()=>setShowMore(!showMore)}>更多选项/客户信息</div>*/}
      </Form>
    </Modal>
  )
}

export default AddOptionForm
