import { Button, Layout, Menu, PageHeader } from 'antd'
import { PAGE_PATH } from 'apis/Router'
import message from 'components/Message'
import React, { useEffect, useRef, useState } from 'react'
import { Link, Route, Switch, useLocation } from 'react-router-dom'
import { getWeb as _getWeb } from 'reduxModules/settings'
import {
  editEnterpriseLevel as _editEnterpriseLevel,
} from 'reduxModules/subscribe'
// import { RouteWithSubRoutes } from 'utils/Help'
import './index.module.less'

const { Header, Footer, Sider, Content } = Layout

const Settings = ({ routes, name }) => {
  document.title = `${name} - Infotos`
  let location = useLocation()
  const childRef = useRef()
  const [webData, setWebData] = useState({})
  const [infoDetails, setInfoDetails] = useState({})
  const getWebDetails = async () => {
    const res = await _editEnterpriseLevel()
    if ([0].includes(res.code)) {
      setInfoDetails(res.data)
    }
  }
  const getWebData = async () => {
    const res = await _getWeb()
    if (res.code === 0) {
      setWebData(res.data)
    } else {
      message.error(res.msg)
    }
  }
  useEffect(() => {
    getWebData()
    getWebDetails()
  }, [])
  const subSiderMenu = routes.map((menu) => (
    <Menu.Item key={menu.path}>
      <Link to={menu.path}>{menu.name}</Link>
    </Menu.Item>
  ))

  const onSave = () => {
    childRef.current.onSaveForm()
  }

  function RouteWithSubRoutes(route) {
    return (
      <Route
        path={route.path}
        render={(props) => (
          // pass the sub-routes down to keep nesting
          <route.component
            {...props}
            routes={route.routes}
            ref={childRef}
            webData={webData}
            infoDetails={infoDetails}
            getWebData={getWebData}
            setWebData={setWebData}
          />
        )}
      />
    )
  }

  const changeMenu = () => {
    getWebData()
  }
  const changeName = async(data) => {
    // console.log(data)
  }
  return (
    <Layout>
        <Sider
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
            }}
            theme="light"
            className="sub-sidebar"
        >

          <div className="sub-title">{name}</div>
          <Menu
              mode="inline"
              theme="light"
              defaultSelectedKeys={[location.pathname]}
              style={{border: 'none'}}
              className="sub-sider-menu"
              inlineIndent={30}
              onClick={changeMenu}
          >
            {subSiderMenu}
          </Menu>
        </Sider>
      <Layout style={{ marginLeft: 200 }}>

        {/*{name!='项目预设'?(*/}
        <Header className="sub-header">
          <Switch>
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                render={() => {
                  const name =
                    route.path === PAGE_PATH.settings.children.seo
                      // ? '网站' + route.name
                      ? '' + route.name
                      : route.name

                  return (
                    <PageHeader
                      ghost={false}
                      title={<div style={{ fontWeight: 'bold' }} id="forBack">{name}</div>}
                      style={{
                        padding: '24px 50px 23px',
                      }}
                      extra={
                        !['subscribe', 'service', 'member', 'recycle'].includes(route.page)
                          ? !(infoDetails.watermark == 'N'&&['marksta'].includes(route.page))?[
                            <Button
                            type="primary"
                            onClick={onSave}
                            key={`${route.path}_btn`}
                          >
                            保存
                          </Button>
                          ] :[]: []
                      }
                    ></PageHeader>
                  )
                }}
              />
            ))}
          </Switch>
        </Header>
            {/*):null}*/}
        <Content className="sub-content">
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route}/>
            ))}
          </Switch>
        </Content>
      </Layout>
    </Layout>
  )
}

export default Settings
