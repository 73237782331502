/*
 * @Author: melon
 * @Date: 2020-07-02 16:11:39
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-12 00:36:53
 */

import { Button, Col, Divider, Form, Row ,message,Input} from 'antd'
import commonComponent from 'components/index'
import { TrimInput, TrimTextArea } from 'components/WithTrim/index'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { uploadImage } from 'utils/Uploader'
import { EmailValidateRule, WxValidateRule, UrlValidateRule } from 'utils/ValidateRulesAndMsg'
import styles from './BusinessForm.module.less'

const { ProvinceSelectCascader, CoverUpload, MyMessage } = commonComponent

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
}

const tailLayout = {
  wrapperCol: { span: 24 },
}

const BusinessForm = (props) => {
  const { formData } = props
  const [form] = Form.useForm()
  const [avatarUploading, setAvatarUploading] = useState(false)
  const [fileList, setFileList] = useState([])
  const onChange = ({ file, fileList }) => {
    if (file.status === 'uploading') {
      setAvatarUploading(true)
      return
    }
    if (file.status === 'done') {
      // Get this url from response in real world.
      setAvatarUploading(false)
    }
  }
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      MyMessage.error('请上传JPG/PNG格式得图片!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      MyMessage.error('图片大小请不要超出2MB!')
    }
    return isJpgOrPng && isLt2M
  }
  // 上传自定义
  const customRequest = (info) => {
    const { file, onSuccess, onError } = info
    // 派发请求
    uploadImage({
      file: file,
      fileName: 'user_logo',
      errorCallback: async () => {
        MyMessage.error('上传失败')
      },
      completeCallback: async (res) => {
        MyMessage.success('上传成功')
        const { hash, urlPrefix } = res
        form.setFieldsValue({
          wechatImageKey: hash,
        })
        setFileList([
          {
            url: urlPrefix + hash,
          },
        ])
        onSuccess(res)
      },
    })
  }

  useEffect(() => {
    form.setFieldsValue({
      ...formData,
    })
    const file = formData.fileList || []
    if (file.length >= 1 && !isEmpty(file[0].url)){
      setFileList(file)
    }
  }, [form, formData])
  return (
    <div>
      <Form
        form={form}
        ref={props.formRef}
        layout="vertical"
        name="Business_Form"
        className={styles.business_form}
        scrollToFirstError={true}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="商业或品牌名称"
              name="name"
              rules={[{ required: true, message: '请输入您的品牌名称!' }]}
            >
              <TrimInput
                placeholder="请输入"
                className={styles.Inf_flexmax_input}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="商业简介" name="remark">
              <TrimTextArea
                maxLength={200}
                placeholder="输入您的商业或品牌简介。限200字。"
                className={styles.Inf_flexmax_input}
                style={{ height: 96 }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              className={styles.Inf_ant_form_item}
              label="联系电话"
              name="contactNumber"
              rules={[
                {
                  pattern: /[0-9]+/,
                  message: '请输入正确的联系电话',
                },
              ]}
            >
              <TrimInput className={styles.Inf_normal_input} />
            </Form.Item>
            <Form.Item
              className={styles.Inf_ant_form_item}
              label="联系Email"
              name="email"
              rules={[EmailValidateRule]}
            >
              <TrimInput className={styles.Inf_normal_input} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="所在地" style={{margin:0}}>
              <Form.Item
                label=""
                name="areaArray"
                className={styles.Inf_ant_form_item}
                style={{ marginRight: 13 }}
              >
                <ProvinceSelectCascader />
              </Form.Item>

              <Form.Item
                className={styles.Inf_ant_form_item}
                label=""
                name="address"
                // rules={[RequiredInputRule]}
              >
                <TrimInput className={styles.Inf_normal_input} />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
                name="website"
                label="网站"
                rules={[UrlValidateRule]}
            >
              <TrimInput
                prefix="https://"
                placeholder="请输入网站地址" style={{width:285}}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="联系微信"
              name="wechatImageKey"
              className={styles.wxUpload}
            >
              <CoverUpload
                uploadProps={{
                  fileList,
                  onChange,
                  customRequest,
                  accept: '.jpg, .jpeg, .png',
                  beforeUpload
                }}
                showButtons={{
                  uploadButton: (
                    <Button type="primary" style={{ padding: '4px 40px' }}>
                      上传
                    </Button>
                  ),
                  replaceButton: (
                    <Button type="primary" style={{ padding: '4px 40px' }}>
                      替换
                    </Button>
                  ),
                }}
              />
              <div className={styles.description}>
                说明：该微信将会作为联系信息显示在所有相关页面上，请上传客服人员的微信二维码。
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row span={24}>
          <Col span={24}>
            <Form.Item label="社交信息">
              <Form.Item
                label="微博"
                name="weiboUrl"
                rules={[
                  { pattern: /weibo.com/, message: '请输入正确的网址' },
                ]}
              >
                <TrimInput
                  prefix="https://"
                  placeholder="请填写微博链接地址"
                  className={styles.Inf_normal_input}
                />
              </Form.Item>
              <Form.Item
                label="微信号/微信公众号"
                name="wechatNo"
                rules={[WxValidateRule]}
              >
                <TrimInput
                  placeholder="请填写微信号或微信公众号"
                  className={styles.Inf_normal_input}
                />
              </Form.Item>
              <Form.Item
                label="Instagram"
                name="instagram"
                rules={[
                  {
                    pattern: /instagram.com/,
                    message: '请输入正确的网址',
                  },
                ]}
              >
                <TrimInput
                  prefix="https://"
                  placeholder="请填写链接地址"
                  className={styles.Inf_normal_input}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
export default BusinessForm
