//添加优惠券
import {Col, DatePicker, Form, Input, Modal, Row, Select, Switch, Checkbox} from 'antd'
import AddSelect from 'components/AddSelect'
import React, {useEffect, useState} from 'react'
import styles from './newcoupon.module.less'

const {Option} = Select

const NewCoupon = ({
                     visible,
                     onCreate,
                     onCancel,
                     // projectCategorys,
                     // onAdd,
                     couponType,
                     couponAssign,
                     productTypes,
                     selectedRows,
                   }) => {
  const [form] = Form.useForm()
  const [longTime, setLongTime] = useState(false)
  const [discountTypeText, setDiscountTypeText] = useState('优惠百分比')
  const [scopeContent, setscopeContent] = useState(1)
  useEffect(() => {
    if (!!selectedRows.discountName) {
      form.setFieldsValue({
        ...selectedRows,
      })
    }
  },[])
  const onChange = () => {
    setLongTime(!longTime)
  }
  //表单值变化
  const valuesChange = (size) => {
    !!size.discountType && setDiscountTypeText(size.discountType == 'percentage' ? '优惠百分比' : size.discountType == 'sunMoney' ? '优惠额' : ' ')
    if (!!size.scope) {
      setscopeContent(size.scope)
      if (size.scope == 'all') {
        form.setFieldsValue({
          scopeContent: '',
        })
      } else {
      }
    }
  }
  return (
    <Modal
      visible={visible}
      title={`${!!selectedRows.id?'编辑':'添加'}优惠券`}
      okText={`${!!selectedRows.id?'确定':'创建'}`}
      cancelText="取消"
      centered
      cancelButtonProps={{className: 'footerCanceltext'}}
      onCancel={onCancel}
      maskClosable={false}
      width={650}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            // form.resetFields()
            // values.permanentlyValidFlag = longTime ? 'yes' : 'no'
            values.permanentlyValidFlag = !values.endTime ? 'yes' : 'no'
            //存在最小金额为某类否则为所有
            values.scope=!values.minPrice?'all':'typeProduct'
            onCreate(values)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_counpon_form"
        requiredMark={false}
        className={styles.createDialog}
        onValuesChange={valuesChange}
        initialValues={{
          couponTypeId: '',
          couponAssignID: '',
          num: 10,
          receiveLimitNum: 1,
        }}
      >
        <Row gutter={16}>
          <Col span={!!selectedRows.discountName?24:12}>
            <Form.Item
              name="discountName"
              label="优惠券名称"
              rules={[
                {
                  required: true,
                  message: '给优惠券建个名称',
                },
              ]}
            >
              <Input placeholder="例如春节优惠" maxLength={30}/>
            </Form.Item>
          </Col>
          {!selectedRows.discountName &&
          <Col span={12}>
            <Form.Item
              name="num"
              label="发放数量"
              rules={[
                {
                  required: true,
                  message: '请输入1以上的整数',
                },
              ]}
            >
              <Input placeholder="请输入" maxLength={10}/>
            </Form.Item>
          </Col>
          }
          {!selectedRows.discountName &&
          <Col span={12}>
            <Form.Item
              name="discountType"
              label="优惠方式"
              rules={[
                {
                  required: true,
                  message: '请选择',
                },
              ]}
            >
              <Select
                placeholder="请选择"
                suffixIcon={
                  <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                }
              >
                {couponType.map(v => (
                  <Option value={v.value}>{v.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>}
          {/*{discountTypeText&&*/}
          {!selectedRows.discountName &&<Col span={12}>
            <Form.Item
              name="discountTypeContent"
              label={discountTypeText}
              rules={[
                {
                  required: discountTypeText != ' ',
                  message: '请输入',
                },
              ]}
            >
              <Input placeholder="" maxLength={10} disabled={discountTypeText == ' '}/>
            </Form.Item>
          </Col>}
          {/*<Col span={12}>
            <Form.Item
              name="scope"
              label="应用于"
              rules={[
                {
                  required: true,
                  message: '请选择',
                },
              ]}
            >
              <Select
                placeholder="请选择"
                suffixIcon={
                  <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                }
              >
                {couponAssign.map(v => (
                  <Option value={v.value}>{v.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>*/}
          {!selectedRows.discountName &&<Col span={12}>
            <Form.Item
              name="minPrice"
              label="最小订单金额"
              rules={[
                {
                  pattern: new RegExp(/^(([1-9]\d*)|\d)(\.\d{1,2})?$/, 'g'),
                  message: '请输入正确的金额，如100.65',
                }
              ]}
            >
              <Input placeholder="不输入则表示全部订单"/>
            </Form.Item>
          </Col>}
          {!selectedRows.discountName &&<Col span={12}>
            <Form.Item
              name="scopeContent"
              label="仅限特定商品分类"
              /*rules={[
                {
                  required: scopeContent != 'all',
                  message: '请选择',
                },
              ]}*/
            >
              {/* <Input placeholder="请选择"/> */}
              <Select
                placeholder="请选择"
                suffixIcon={
                  <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                }
                disabled
                // disabled={scopeContent == 'all'}
              >
                {productTypes.map(v => (
                  <Option value={v.id}>{v.productTypeName}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>}

          {/*<Col span={12}>
            <Form.Item
              name="startTime"
              label="开始生效时间"
              rules={[
                {
                  required: true,
                  message: '请选择',
                },
              ]}
            >
              <DatePicker showTime={{format: 'HH:mm', minuteStep: 30}}
                          format="YYYY-MM-DD HH:mm"
                          style={{width: '100%'}}/>
            </Form.Item>
          </Col>*/}
          {!selectedRows.discountName &&<Col span={12} className={styles.check}>
            <Form.Item
              name="endTime"
              label="截止日期"
              /*rules={[
                {
                  required: !longTime,
                  message: '请选择',
                },
              ]}*/
            >
              <DatePicker format="YYYY-MM-DD"
                          // showTime={{format: 'HH:mm', minuteStep: 30}}
                          disabled={longTime}
                          placeholder="不设置则表示永久有效"
                          style={{width: '100%'}}/>
            </Form.Item>
            {/*<Checkbox onChange={onChange}>永久有效</Checkbox>*/}
          </Col>}
          {!selectedRows.discountName &&<Col span={12}>
            <Form.Item
              name="receiveLimitNum"
              label="用户领取数限制"
              // rules={[
              //   {
              //     required: true,
              //     message: '请输入',
              //   },
              // ]}
            >
              <Input placeholder="请输入" disabled/>
            </Form.Item>
          </Col>}
        </Row>
      </Form>
    </Modal>
  )
}

export default NewCoupon
