import {Col, DatePicker, Form, Input, Modal, Row, Select, Switch} from 'antd'
import AddSelect from 'components/AddSelect'
import React, {useEffect, useState} from 'react'
import styles from './newpricesheet.module.less'

const {Option} = Select

const NewPricesheet = ({
                         visible,
                         onCreate,
                         onCancel,
                         checkAssign,
                         supplier
                       }) => {
  const [form] = Form.useForm()
  // const [showMore, setShowMore] = useState(false)
  if (!!checkAssign.name) {
    form.setFieldsValue({
      name: checkAssign.name,
    })
  }
  useEffect(() => {
    console.log(checkAssign)
  }, [])
  return (
    <Modal
      visible={visible}
      title={`${!!checkAssign.id?'编辑'+checkAssign.name:'添加价格表'}`}
      okText={`${!!checkAssign.id?'保存':'创建'}`}
      cancelText="取消"
      centered
      cancelButtonProps={{className: 'footerCanceltext'}}
      onCancel={onCancel}
      maskClosable={false}
      width={600}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            supplier.map(v => {
              if (v.id == values.supplierEnterpriseId||v.id == checkAssign.supplierEnterpriseId) {
                values.supplierEnterpriseName = v.supplierName
              }
            })
            onCreate(values)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_pricesheet_form"
        requiredMark={false}
        className={styles.createDialog}
        initialValues={{
          productsValid: true
        }}
      >

        <Form.Item
          name="name"
          label="价格表名称"
          rules={[
            {
              required: true,
              message: '给价格表建个名称',
            },
          ]}
        >
          <Input placeholder="例如通用价格表" maxLength={30}/>
        </Form.Item>
        {!checkAssign.name &&
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="supplierEnterpriseId"
              label="印刷服务提供"
              rules={[
                {
                  required: true,
                  message: '请选择',
                },
              ]}
            >
              <Select
                placeholder="请选择"
                suffixIcon={
                  <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                }
              >
                {/*<Option value="">默认</Option>*/}
                {supplier.map(v => (
                  <Option value={v.id}>{v.supplierName}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name=""
              label="结算货币？"
              // rules={[
              //   {
              //     required: true,
              //     message: '请选择',
              //   },
              // ]}
            >
              <Select
                placeholder="人民币CNY（中国大陆）"
                suffixIcon={
                  <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                }
                disabled
              >
                {/*<Option value="">默认</Option>*/}
                {/* {supplier.map(v=>(
                      <Option value={v.id}>{v.supplierName}</Option>
                  ))} */}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item name="productsValid" noStyle valuePropName="checked">
              <Switch  defaultChecked disabled/>
            </Form.Item>
            <span className={styles.switchLabel}>
              添加常见的打印输出商品
            </span>
          </Col> */}
        </Row>
        }
      </Form>
    </Modal>
  )
}

export default NewPricesheet
