import { Button, Upload } from 'antd'
import React, { useState } from 'react'

const CoverUpload = ({ uploadProps = {}, showButtons = {} }) => {
  const [hover, setHover] = useState(false)
  const { fileList } = uploadProps
  return (
    <Upload
      {...uploadProps}
      listType="picture-card"
      className="logoUpload"
      showUploadList={false}
    >
      {uploadProps.fileList.length < 1 ? (
        showButtons.uploadButton || <Button type="primary">上传</Button>
      ) : (
        <div
          onMouseMove={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        >
          <div>
            <img src={uploadProps.fileList[0].url} className="img" />
          </div>
          <div className="uploadCover" style={{ display: hover ? '' : 'none' }}>
            {showButtons.replaceButton || <Button type="primary">替换</Button>}
          </div>
        </div>
      )}
    </Upload>
  )
}
export default CoverUpload
