/*
 * @Author: melon
 * @Date: 2020-06-29 11:22:13
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-17 17:32:28
 */
import { Spin } from 'antd'
import { PAGE_PATH } from 'apis/Router'
import classNames from 'classnames'
import commonComponent from 'components/index'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
// import { getShowcaseSetting as _getInfo } from 'reduxModules/showcaseSetting'
import { getEnterpriseInfo as _getInfo } from 'reduxModules/enterprise'
import { getEnterpriseList as _getList } from 'reduxModules/enterpriseService'
import ContactForm from '../Components/ContactForm'
import styles from './Contact.module.less'
const { MyMessage, HoverOpt } = commonComponent

const WebContact = (props, ref) => {
  const formRef = useRef()
  const [formData, setFormData] = useState({})
  const [requesting, setRequesting] = useState(false)

  const [servicelist, setServiceList] = useState([])
  let history = useHistory()
  const getInfo = async () => {
    try {
      setRequesting(true)
      const res = await _getInfo()
      if ([0].includes(res.code)) {
        const {
          data: { formattedData },
        } = res
        setFormData(formattedData)
      }
      return res
    } catch (error) {
      console.log('========>error=====>')
      console.log(error)
    } finally {
      setRequesting(false)
    }
  }
  useEffect(() => {
    getInfo()
    getServiceList()
  }, [])
  const getServiceList = async () => {
    try {
      const res = await _getList()
      if ([0].includes(res.code)) {
        const {
          data: {
            formattedData: { formattedList },
          },
        } = res
        setServiceList(formattedList)
      }
      return res
    } catch (error) {
    } finally {
      setRequesting(false)
    }
  }
  useImperativeHandle(ref, () => ({
    // onSaveForm 就是暴露给父组件的方法
    onSaveForm: () => {
      // formRef.current
      //   .validateFields()
      //   .then(async (formValues) => {
      //     // formRef.current.resetFields()
      //   })
      //   .catch((info) => {
      //     console.log('Validate Failed:', info)
      //   })
    },
  }))
  const goToBusiness = () => {
    history.push(PAGE_PATH.settings.children.business)
  }
  const ContactInfo = ({ data }) => (
    <div className={styles.web_contact}>
      <div className={styles.title}>联系信息</div>
      {data.areaString && (
        <div className={styles.contact_text}>{data.areaString}</div>
      )}
      {data.email && <div className={styles.contact_text}>{data.email}</div>}
      {data.contactNumber && (
        <div className={styles.contact_text}>{data.contactNumber}</div>
      )}
      {data.website && (
        <div className={styles.contact_text}>{data.website}</div>
      )}
      {data.wechatImagePhotoInfo && data.wechatImagePhotoInfo.photoUrl ? (
        <div className={styles.contact_wx_code}>
          <img
            src={
              data.wechatImagePhotoInfo
                ? data.wechatImagePhotoInfo.photoUrl
                : ''
            }
            className={styles.image}
          />
          <div className="Inf_page_text">扫一扫添加微信好友</div>
        </div>
      ) : (
        ''
      )}
    </div>
  )

  return (
    <Spin tip="数据加载中" spinning={requesting}>
      <div className={classNames('Inf_page', styles.contact_wrapper)}>
        {/* 联系信息 */}
        <div className={styles.web_contact_wrapper}>
          <HoverOpt
            title={'联系信息'}
            content={
              <span onClick={goToBusiness} style={{ cursor: 'pointer' }}>
                信息设置
              </span>
            }
          >
            <ContactInfo data={formData} />
          </HoverOpt>
        </div>

        {/* 联系表单 */}
        <div className={styles.web_contact_wrapper}>
          <HoverOpt
            title={'联系信息'}
            content={<span style={{ cursor: 'pointer' }}>表单设置</span>}
          >
            <ContactForm
              formRef={formRef}
              formData={formData}
              servicelist={servicelist}
            />
          </HoverOpt>
        </div>
      </div>
    </Spin>
  )
}

export default forwardRef(WebContact)
