import {Button, Input, Layout, Menu, Col, Row, Modal, Popover, Spin, Tooltip, Typography, PageHeader, Card} from 'antd'
import { PAGE_PATH } from 'apis/Router'
import components from 'components'
import { ProjectStatus, YesOrNoTypes } from 'constants/Options'
import { findIndex, isEmpty } from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom'
import {
  addPhotos as _addPhotos,
  addProjectCategory as _addProjectCategory,
  changeSort as _changeSort,
  checkPhotos as _checkPhotos,
  editProject as _editProject,
  getBookmarkList as _getBookmarkList,
  getPhotoCategory as _getPhotoCategory,
  getPhotoCategoryForMove as _getPhotoCategoryForMove,
  getPhotosByBookmark as _getPhotosByBookmark,
  getPhotosByCategory as _getPhotosByCategory,
  getPreviewUrlByProject as _getPreviewUrlByProject,
  getProjectById as _getProjectById,
  getProjectCategory as _getProjectCategory,
  ignoreExceedTip as _ignoreExceedTip,
  moveOrCopyPhotos as _moveOrCopyPhotos,
  overFavorite as _overFavorite,
  packageBookmarkPhotos as _packageBookmarkPhotos,
  downloadBookmarkExcel as _downloadBookmarkExcel,
  downloadPrivateExcel as _downloadPrivateExcel,
  packagePhotos as _packagePhotos,
  removeBookmark as _removeBookmark,
  removeBookmarkPhotos as _removeBookmarkPhotos,
  removePhotos as _removePhotos,
  removeProject as _removeProject,
  saveBookmark as _saveBookmark,
  savePhotoCategory as _savePhotoCategory,
  setCover as _setCover,
  changeLanguage as _changeLanguage,
  changeProjectStatus as _changeProjectStatus,
  fileNameList as _fileNameList,
  fileNameLists as _fileNameLists,
  customerFileNameLists as _customerFileNameLists,
  getHiddenListForPage as _getHiddenListForPage,
  getMessageListForPage as _getMessageListForPage,
  getProjectStatistics as _getProjectStatistics,
  projectRecord as _projectRecord,
  removeRecord as _removeRecord,
  removeMessage as _removeMessage,
  removeHidden as _removeHidden,
  shareRecord as _shareRecord,
  removeShare as _removeShare,
  projectBookmarkRecord as _projectBookmarkRecord,
  removeBookmarkRecord as _removeBookmarkRecord,
  projectDownloadRecord as _projectDownloadRecord,
  removeDownloadRecord as _removeDownloadRecord,
} from 'reduxModules/customerPhoto'
import {
  addClient as _addClient,
  editClient as _editClient,
} from 'reduxModules/client'
import {
  getFullRoute,
  getOption,
  getOptionBykeyName,
  getQueryVariableObj,
} from 'utils/Tools'
import {
  editEnterpriseLevel as _editEnterpriseLevel,
} from 'reduxModules/subscribe'
import {
  getPricesheet as _getPricesheet,
} from 'reduxModules/store'
import { getEnterpriseInfo as _getInfo } from 'reduxModules/enterprise'
import BookmarkForm from '../Components/BookmarkForm'
import BookmarkPage from '../Components/BookmarkPage'
import PrivatePage from '../Components/PrivatePage'
import CommentPage from '../Components/CommentPage'
import PhotoPage from '../Components/PhotoPage'
import ActivitiesPage from '../Components/ActivitiesPage'
import RemoveProjectForm from '../Components/RemoveProjectForm'
import SettingsPage from '../Components/SettingsPage'
import ShareProjectForm from '../Components/ShareProjectForm'
import LinkClient from '../Components/LinkClient'
import AddClientForm from '../../Client/Components/AddClientForm'
import EditClientForm from '../../Client/Components/EditClientForm'
import PermissionsForm from '../Components/PermissionsForm'
import styles from './projectDetails.module.less'

const { Header, Footer, Sider, Content } = Layout
const { message, CategoryModal, UploadModal } = components

const { Paragraph } = Typography
const ProjectDetails = ({ routes }) => {
  document.title = `项目 - Infotos`
  let { search } = useLocation()
  let { projectId, showKey } = useParams()
  let history = useHistory()
  const [infoDetails, setInfoDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const [reloading, setReloading] = useState(false)
  const [hasMore, setHasMore] = useState(false)

  const [photoCategorys, setPhotoCategorys] = useState([])
  const [projectCategorys, setProjectCategorys] = useState([])
  const [selectedCategory, setSelectedCategory] = useState({})
  const [defaultKey, setDefaultKey] = useState(
    showKey === 'photos'
      ? search
        ? getQueryVariableObj(search).categoryId
        : 'all'
      : showKey
  )

  const [projectDetail, setProjectDetail] = useState({})
  const [photosData, setPhotosData] = useState({ list: [] })
  const [photosDatas, setPhotosDatas] = useState({ list: [] })
  const [privatephotosDatas, setprivatePhotosDatas] = useState({ list: [] })
  const [commentphotosDatas, setcommentPhotosDatas] = useState({ list: [] })
  const [editBookmark, setEditBookmark] = useState({})
  const [categoryModalVisible, setCategoryModalVisible] = useState(false)
  const [loadingCategory, setLoadingCategory] = useState(false)
  const [loadingProjectCategory, setLoadingProjectCategory] = useState(false)
  const [loadingBookmarks, setLoadingBookmarks] = useState(false)
  const [removeFormVisible, setRemoveFormVisible] = useState(false)
  const [bookmarkVisible, setBookmarkVisible] = useState(false)
  const [uploadModalVisible, setUploadModalVisible] = useState(false)
  const [linkClientVisible, setLinkClientVisible] = useState(false)
  const [shareFormVisible, setShareFormVisible] = useState(false)
  const [bookmarkData, setBookmarkData] = useState([])
  const [selectedBookmark, setSelectedBookmark] = useState({})
  const [childRef, setChildRef] = useState()
  //新增客户
  const [addClientVisible, setAddClientVisible] = useState(false)
  const [browse, setBrowse] = useState([100,0,0,0])
  const [browseVisible, setBrowseVisible] = useState(false)
  const [infoName, setInfoName] = useState('')
  //获取商品价格表
  const [pricesheetList, setPricesheetList] = useState([])

  const [isAdd, setIsAdd] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [bg, setBg] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const searchInput = useRef()
  useEffect(() => {
    getInfo()
    getProjectById() //获取项目详情
    getProjectCategory()
    getBookmarkList()
    getPhotoCategory()
    getWebDetails()
    getProjectStatistics()
    getListByPricesheet()
// setTimeout(()=>{saveLinkClient('1641392181000')},5000)
  },[])
  // 获取价格表
  const getListByPricesheet = async (data) => {
    setLoading(true)
    const res = await _getPricesheet({
      storeId:sessionStorage.getItem('user')
        ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
        :null,
      pageNum:1,
      pageSize:10000,
    })
    setLoading(false)
    if ( res.data.code === '000000') {
      setPricesheetList(res.data.list)
    } else {
      // if(res.data.mesg!=="免费版无该权限") {
      if(res.data.code!= "020002") {
        message.error(res.data.msg)
      }
    }
  }
  const getInfo = async() => {
    const res = await _getInfo()
    if ([0].includes(res.code)) {
      setInfoName(res.data.name)
    }
  }
  useEffect(() => {
    if (
      !isEmpty(photoCategorys) &&
      isEmpty(selectedCategory) &&
      showKey === 'photos'
    ) {
      getPhotosByCategory({ categoryId: photoCategorys[0].id })
      setSelectedCategory({
        key: photoCategorys[0].id,
        name: photoCategorys[0].name,
      })
    }
  }, [photoCategorys, selectedCategory])

  useEffect(() => {
    if (!isEmpty(selectedCategory) && showKey === 'photos') {
      getPhotosByCategory({
        categoryId: selectedCategory.key,
      })
    }
  }, [selectedCategory])

  useEffect(() => {
    const urlBookmarkId = getQueryVariableObj(search).bookmarkId
    if (
      !isEmpty(bookmarkData) &&
      isEmpty(selectedBookmark) &&
      showKey === 'fav'
    ) {
      const index = findIndex(bookmarkData, ['id', urlBookmarkId])
      const obj =
        index === -1
          ? {
              ...bookmarkData[0],
              key: bookmarkData[0].id,
            }
          : {
              ...bookmarkData[index],
              key: bookmarkData[index].id,
            }
      setSelectedBookmark(obj)
    }
  }, [bookmarkData, selectedBookmark])

  useEffect(() => {
    if (!isEmpty(selectedBookmark) && showKey === 'fav') {
      getPhotosByBookmark({ bookmarkId: selectedBookmark.key })
    }
  }, [selectedBookmark])
  useEffect(() => {
    // setTimeout(()=>{
      if(selectedCategory.key) {
        setPhotosData({ list: [] })
        getPhotosByCategory({
          categoryId: selectedCategory.key,
          pagination: { pageNo: 1 },
        })
      }
    // },500)
  }, [searchText])
  const getProjectStatistics=async ()=>{
    const res = await _getProjectStatistics({ projectId: projectId })
    if ([0].includes(res.code)) {
      let arr=JSON.parse(JSON.stringify(browse))
      arr[1]=res.data.collectionQuantity
      arr[2]=res.data.hiddenQuantity
      arr[3]=res.data.messageQuantity
      setBrowse(arr)
    }
  }
  //权限
  const getWebDetails = async () => {
    const res = await _editEnterpriseLevel()
    if ([0].includes(res.code)) {
      setInfoDetails(res.data)
    }
  }
  //获取项目类别
  const getProjectCategory = async () => {
    setLoadingProjectCategory(true)
    const res = await _getProjectCategory()
    setLoadingProjectCategory(false)
    if (res.code === 0) {
      setProjectCategorys(res.data)
    } else {
      message.error(res.msg)
    }
  }

  // 下拉框添加项目类别
  const onAddProjectCategory = async (name) => {
    const body = {
      name: name,
      // sort: projectCategorys.length
    }
    const res = await _addProjectCategory(body)
    if (res.data.code === 0) {
      getProjectCategory()
    }
  }
  // 获取图片类别
  const getPhotoCategory = async () => {
    setLoadingCategory(true)
    const res = await _getPhotoCategory({ projectId: projectId })
    if (res.code === 0) {
      setPhotoCategorys(res.data)
      setLoadingCategory(false)
    } else {
      message.error(res.msg)
    }
  }

  // 新增或修改，排序项目类
  const doSaveCategory = async (values) => {
    const res = await _savePhotoCategory({
      projectId: projectId,
      categoryList: values,
    })
    setCategoryModalVisible(false)
    if (res.data.code === 0) {
      message.success('提交成功')
      getPhotoCategory()
    } else {
      message.error('请求失败')
    }
  }
  // 获取客片
  const getPhotosByCategory = async ({ categoryId, pagination }) => {
    let res = null
    setLoading(true)
    // console.log(categoryId, pagination)
    res = await _getPhotosByCategory({
      categoryId: categoryId,
      projectId,
      ...pagination,
      search:searchText,
    })

    setLoading(false)
    setHasMore(false)
    if (res.code === 0) {
      const { pagination, list } = res.data
      const newPhotosData = {
        pagination,
        list: pagination.current === '1' ? list : photosData.list.concat(list),
      }
      setPhotosData(newPhotosData)
      setHasMore(pagination.current * pagination.pageSize < pagination.total)
    } else {
      message.error(res.msg)
    }
  }

  const getProjectById = async () => {
    setLoading(true)
    const res = await _getProjectById({ id: projectId })
    setLoading(false)
    if (res.code === 0) {
      //debugger
      if (res.data.projectCategoryId === 0) {
        res.data.projectCategoryId = null
      }
      res.data.priceId=Number(res.data.priceId)
      setProjectDetail(res.data)
    } else {
      message.error(res.msg)
    }
  }

  const showRemove = () => {
    setRemoveFormVisible(true)
  }
  // 删除项目
  const doRemoveProject = async () => {
    const res = await _removeProject({ id: projectId })
    if (res.data.code === 0) {
      message.success('删除成功')
      setRemoveFormVisible(false)
      history.push(PAGE_PATH.customerPhoto.index)
    } else {
      message.error('删除失败')
    }
  }
  const toShowCategoryModal = () => {
    setCategoryModalVisible(true)
  }

  // 获取收藏夹列表
  const getBookmarkList = async () => {
    setLoadingBookmarks(true)
    const res = await _getBookmarkList({ projectId: projectId })
    if (res.code === 0) {
      // if(!urlBookmarkId) {
        // 替换默认选中收藏夹的名称
        if ( !isEmpty(res.data)) {
          setBookmarkData(res.data)
          // if(bookmarkData.length>0) {
          //   const urlBookmarkId = getQueryVariableObj(search).favId
          //   const index = findIndex(bookmarkData, ['id', urlBookmarkId])
          //   const obj =
          //       index === -1
          //           ? {
          //             ...bookmarkData[0],
          //             key: bookmarkData[0].id,
          //           }
          //           : {
          //             ...bookmarkData[index],
          //             key: bookmarkData[index].id,
          //           }
          //   setSelectedBookmark(obj)
          // }
      }

      setLoadingBookmarks(false)
    } else {
      message.error(res.msg)
    }
  }
//切换收藏夹
  const clickNav=(key)=>{
    // const urlBookmarkId = getQueryVariableObj(search).bookmarkId
    // console.log(!urlBookmarkId,'urlBookmarkId')
    const urlBookmarkId = getQueryVariableObj(search).favId
    // console.log(key,urlBookmarkId)
    const index = findIndex(bookmarkData, ['id', key])
    const obj =
        index === -1
            ? {
              ...bookmarkData[0],
              key: bookmarkData[0].id,
            }
            : {
              ...bookmarkData[index],
              key: bookmarkData[index].id,
            }
    setSelectedBookmark(obj)
  }
  // 新增/修改收藏夹
  const saveBookmark = async (values) => {
    const res = await _saveBookmark({
      ...values,
      projectId,
      id: editBookmark.id,
    })
    if (res.data.code === 0) {
      message.success('创建成功')
      setBookmarkVisible(false)
      setEditBookmark({})
      getBookmarkList()
    } else {
      message.error('请求失败')
    }
  }
  const showEditBookmark = (editRow) => {
    setEditBookmark(editRow)
    setBookmarkVisible(true)
  }
  const deleteBookmarkById = async (id) => {
    const res = await _removeBookmark({ id })
    if (res.data.code === 0) {
      message.success('删除成功')
      getBookmarkList()
    } else {
      message.error('删除失败')
    }
  }
  // 获取收藏夹内照片
  const getPhotosByBookmark = async ({ bookmarkId, pagination }) => {
    let res = null
    setLoadingBookmarks(true)
    res = await _getPhotosByBookmark({
      bookmarkId: bookmarkId,
      projectId,
      ...pagination,
    })
    setLoadingBookmarks(false)
    setHasMore(false)
    if (res.code === 0) {
      const { pagination, list } = res.data
      const newPhotosData = {
        pagination,
        list: pagination.current === '1' ? list : photosData.list.concat(list),
      }
      setPhotosDatas(newPhotosData)
      setHasMore(pagination.current * pagination.pageSize < pagination.total)
    } else {
      message.error(res.msg)
    }
  }
  const loadBookmarkData = (page) => {
    if (hasMore) {
      getPhotosByBookmark({
        bookmarkId: selectedBookmark.key,
        pagination: { pageNo: page },
      })
    }
  }

  const reloadBookmarkData = () => {
    setPhotosData({ list: [] })
    setReloading(true)
    getPhotosByBookmark({
      bookmarkId: selectedBookmark.key,
      pagination: { pageNo: 1 },
    })
    setReloading(false)
  }
  // 获取私有内照片
  const getHiddenListForPage = async ({ pagination }) => {
    let res = null
    setLoadingBookmarks(true)
    res = await _getHiddenListForPage({
      categoryId:'',
      projectId,
      ...pagination,
    })
    setLoadingBookmarks(false)
    setHasMore(false)
    if (res.code === 0) {
      const { pagination, list } = res.data
      const newPhotosData = {
        pagination,
        list: pagination.current === '1' ? list : photosData.list.concat(list),
      }
      setprivatePhotosDatas(newPhotosData)
      setHasMore(pagination.current * pagination.pageSize < pagination.total)
    } else {
      message.error(res.msg)
    }
  }
  const loadHiddenData = (page) => {
    if (hasMore) {
      getHiddenListForPage({
        pagination: { pageNo: page },
      })
    }
  }
  const reloadHiddenListData = () => {
    setPhotosData({ list: [] })
    setReloading(true)
    getHiddenListForPage({
      pagination: { pageNo: 1 },
    })
    setReloading(false)
  }

  // 获取留言反馈内照片
  const getMessageListForPage = async ({ pagination }) => {
    let res = null
    setLoadingBookmarks(true)
    res = await _getMessageListForPage({
      categoryId:'',
      projectId,
      ...pagination,
    })
    setLoadingBookmarks(false)
    setHasMore(false)
    if (res.code === 0) {
      const { pagination, list } = res.data
      const newPhotosData = {
        pagination,
        list: pagination.current === '1' ? list : photosData.list.concat(list),
      }
      setcommentPhotosDatas(newPhotosData)
      setHasMore(pagination.current * pagination.pageSize < pagination.total)
    } else {
      message.error(res.msg)
    }
  }
  const loadMessageData = (page) => {
    if (hasMore) {
      getMessageListForPage({
        pagination: { pageNo: page },
      })
    }
  }
  const reloadMessageListData = () => {
    setPhotosData({ list: [] })
    setReloading(true)
    getMessageListForPage({
      pagination: { pageNo: 1 },
    })
    setReloading(false)
  }
  // 展示文件夹
  const showBookmarkForm = () => {
    setBookmarkVisible(true)
  }

  // 切换按钮
  const changeMenu = ({ key }) => {
    let selectKey = ''
    if (key && key !== 'settings') {
      setPhotosData({ list: [] })
      setPhotosDatas({ list: [] })
      setprivatePhotosDatas({ list: [] })
      setcommentPhotosDatas({ list: [] })
      const menuParam = key.split('_')
      if (menuParam[0] === 'photos') {
        selectKey = menuParam[1]
        setDefaultKey(selectKey)
        const SelectedItem = {
          key: selectKey,
          name: getOptionBykeyName('id', selectKey, 'name', photoCategorys),
        }
        setSelectedCategory(SelectedItem)
      } else if (menuParam[0] === 'fav') {
        selectKey = menuParam[1]
        const bookmarkIndex = findIndex(bookmarkData, ['id', selectKey])
        const SelectedBookmark = {
          ...bookmarkData[bookmarkIndex],
          key: selectKey,
        }
        setSelectedBookmark(SelectedBookmark)
      }else if(menuParam[0] === 'private'){
        reloadHiddenListData()
      }else if(menuParam[0] === 'comment'){
        reloadMessageListData()
      }
    }
  }
  // 返回
  const goBack = () => {
    history.goBack()
  }

  const loadData = (page) => {
    if (hasMore) {
      getPhotosByCategory({
        categoryId: selectedCategory.key,
        pagination: { pageNo: page },
      })
    }
  }
  const reloadData = () => {
    setPhotosData({ list: [] })
    setReloading(true)
    getPhotosByCategory({
      categoryId: selectedCategory.key,
      pagination: { pageNo: 1 },
    })
    setReloading(false)
  }
  // 修改項目信息
  const saveSettings = async (values) => {
    // return
    console.log(projectDetail,infoDetails)
    const res = await _editProject({ ...projectDetail, projectId, ...values,type:infoDetails.type })
    if (res.data.code === 0) {
      message.success('提交成功')
      getProjectById()
    } else {
      message.error('提交失败! ' + res.data.msg)
    }
    const langRes = await _changeLanguage({
      projectId,
      language: values.language,
    });
  }
  //修改联系人
  const saveLinkClient=async(val)=>{
    let obj=JSON.parse(JSON.stringify(projectDetail))
    obj.customerId=val
    obj.bookmarkIsCollect=obj.bookmarkIsCollect?true:false
    obj.bookmarkIsMessage=obj.bookmarkIsMessage?true:false
    obj.isVisitor=obj.isVisitor?true:false
    const res = await _editProject({ ...obj, projectId })
    if (res.data.code === 0) {
      message.success(!val?'移除成功':'绑定成功')
      setLinkClientVisible(false)
      setBrowseVisible(false)
      if(!!val){
        setBrowseVisible(true)
      }
      getProjectById()
    } else {
      message.error('绑定失败! ' + res.data.msg)
    }
  }
  const AddClient=()=>{
    setIsAdd(true)
    setLinkClientVisible(false)
    setAddClientVisible(true)
  }
  const hideClient=()=>{
    setLinkClientVisible(true)
    setAddClientVisible(false)
  }
  //添加客户
  const onAddClient = async (values) => {
    values.customerProvinceGeoId=values.areaArray[0]
    values.customerCityGeoId=values.areaArray[1]
    values.customerAreaGeoId=values.areaArray[2]

    if(projectDetail&&projectDetail.customerResultVO&&projectDetail.customerResultVO.id) {
      values.id=projectDetail&&projectDetail.customerResultVO&&projectDetail.customerResultVO.id
      const res = await _editClient(values)
      if (res.data.code === 0) {
        getProjectById()
        message.success('修改成功')
        setAddClientVisible(false)
        // history.push(`/collection/${res.data.data.id}/photos`)
      }
    }else{
      const res = await _addClient(values)
      if (res.data.code === 0) {
        setAddClientVisible(false)
        saveLinkClient(res.data.data.id)
        message.success('添加成功')
        // saveLinkClient()
        // history.push(`/collection/${res.data.data.id}/photos`)
      }
    }
  }
  //移除联系人
  const removeClient=()=>{
    saveLinkClient('')
  }
  //编辑客户
  const editClient=()=>{
    // console.log('++++')
  }
  //添加权限
  const onVisitor = async (values) => {
    let obj=JSON.parse(JSON.stringify(projectDetail))
    obj.isVisitor=values.isVisitor?true:false
    obj.bookmarkIsCollect=values.bookmarkIsCollect?true:false
    obj.bookmarkIsMessage=values.bookmarkIsMessage?true:false
    obj.isSetPhotoHidden=values.isSetPhotoHidden
    obj.isMessage=values.isMessage
    //当允许客户留言时，收藏夹也为true
    // obj.isShowBookmark=values.isMessage
    //当仅客户访问时隐私中的分享关闭
    // obj.isAllowShare=values.isVisitor?false:obj.isAllowShare
    // return
    setBrowseVisible(false)
    const res = await _editProject({ ...obj, projectId })
    if (res.data.code === 0) {
      getProjectById()
      message.success('设置成功')
      // saveLinkClient()
      // history.push(`/collection/${res.data.data.id}/photos`)
    }

  }
  // 新增照片
  const savePhotos = async (res) => {
    const addRes = await _addPhotos({
      projectId,
      categoryId: selectedCategory.key,
      photos: [
        {
          imageKey: res.key,
          imageName: res.file.name,
          imageTime:res.file.imageTime
        },
      ],
      isWaterMark: res.waterMark ? 'Y' : 'N',
      isCover: getOption(res.cover, 'value', YesOrNoTypes),
    })
    //上传一张图片后刷新
    if (addRes.data.code === 0) {
      // reloadData()
    }
  }

  const Contract = () => {
    let copyText = `项目地址\n${projectDetail.projectFullUrl}`
	  if(projectDetail.visitType === 'PASSWORD') {
	    copyText += `；\n账号：${projectDetail.customerPhone}；\n密码：${projectDetail.visitPassword}；`
	  }
	  //绑定联系人
	  const linkClient=()=>{

    }
    return (
      <div className={styles.feedback} >
        <div className={styles.feedbackTitle}>客户与反馈</div>
        {(projectDetail.customerId ? (
          <Popover
            placement="right"
            trigger={['hover']}
            overlayClassName="copyPopover noArrow"
            content={
              <div>
                <Row className={styles.browse}>
                  {/* <Col className={styles.col}>
                    <p className={styles.font}>{browse[0]}</p>
                    <span className={styles.text}>浏览</span>
                  </Col> */}
                  <Col className={styles.col}>
                    <p className={styles.font}>{browse[1]}</p>
                    <span className={styles.text}>收藏</span>
                  </Col>
                  <Col className={styles.col}>
                    <p className={styles.font}>{browse[2]}</p>
                    <span className={styles.text}>私有</span>
                  </Col>
                  <Col className={styles.col}>
                    <p className={styles.font}>{browse[3]}</p>
                    <span className={styles.text}>留言</span>
                  </Col>
                  <Col className={styles.col}>
                    <span onClick={()=>setBrowseVisible(true)} className={styles.save}>编辑</span>
                  </Col>
                </Row>
                {/*<div>项目地址</div>
                {projectDetail.status === 'ONLINE' ? <>
                 <div>{projectDetail.projectFullUrl}</div>
	                {projectDetail.visitType === 'PASSWORD' && (
		              <div>
			              账号: {projectDetail.customerPhone}，密码:{' '}
			              {projectDetail.visitPassword}{' '}
		              </div>
	                )}
                <div className="copyBtn">
                  <CopyToClipboard text={copyText}>
                    <Tooltip title="已复制" trigger={['click']}>
                      <Button type="primary" size="small" className="black-btn">
                        复制
                      </Button>
                    </Tooltip>
                  </CopyToClipboard>
                </div>
                </> : <div style={{color: '#9b9b9b',fontStyle: 'italic'}}>未上线</div>}*/}
              </div>
            }
          >
            <Button className={styles.feedbackBtn}>
              <div className={styles.serviceInfo}>
                <div className={styles.name}>
                  <Paragraph ellipsis>{projectDetail.customerResultVO.customerName}</Paragraph>
                </div>
                <div className={styles.phone}>
                  {projectDetail.customerResultVO.customerPhone?projectDetail.customerResultVO.customerPhone:projectDetail.customerResultVO.customerEmail}
                </div>
              </div>
              <span
                className="iconfont iconicon_nextmenu"
                style={{ fontSize: '12px' }}
              ></span>
            </Button>
          </Popover>
        ) : (<Button onClick={()=>setLinkClientVisible(true)} style={{color:'#1AA77D',borderColor:'#1AA77D'}}>添加客户</Button>))}
      </div>
    )
  }
  const onRef = (ref) => {
    setChildRef(ref)
  }
  const doShowModalVisible = () => {
    setUploadModalVisible(true)
    childRef.setShowUploadBtn(true)
    childRef.setMinStatus(false)
  }
  const doHiddenModalVisible = () => {
    setUploadModalVisible(false)
    childRef.setShowUploadBtn(false)
    childRef.setMinStatus(false)
    // 关闭弹窗后刷新界面
    reloadData()
  }

  // 预览
  const toPreview = async () => {
    const res = await _getPreviewUrlByProject({ projectId: projectDetail.id })
    if (res.data.code == 0) {
      window.open(res.data.data.url)
    }
  }
  // 分享项目

  const showShareVisible = async () => {

    if (projectDetail.status === 'HIDDEN') {
      let res = await _changeProjectStatus({
        id: projectDetail.id,
        status: 'ONLINE',
      })
      if (res.data.code === 0) {
        // getProjectById()
        let obj=JSON.parse(JSON.stringify(projectDetail))
        obj.status='ONLINE'
        setProjectDetail(obj)
        setShareFormVisible(true)
      } else {
        message.error('修改失败:' + res.data.msg)
      }
    }
    if (projectDetail.status === 'ONLINE') {
      setShareFormVisible(true)
    }
  }

  // 设为封面
  const setCover = async (body) => {
    const resData = await _setCover(body)
    if (resData.data && [0].includes(resData.data.code)) {
      message.success('设置成功')
      getProjectById() //获取项目详情
    }
  }
  return (
    <Layout>
      <Sider
        style={{
          overflowX: 'hidden',
          height: '100vh',
          position: 'fixed',
        }}
        theme="light"
        className="sub-sidebar"
      >
        <div className="sub-title">
          <Button
            icon={
              <span
                className={`iconfont iconbtn_back ${styles.goBackIcon}`}
              ></span>
            }
            onClick={goBack}
            className={styles.goBackBtn}
            style={{
              width: '28px',
              height: '22px',
              lineHeight: '20px',
              padding: '0 0',
            }}
          />
          {projectDetail.name}
        </div>

        <Spin spinning={loadingProjectCategory}>
          <div className={styles.title}>图片</div>
          <Menu
            mode="inline"
            theme="light"
            style={{ border: 'none' }}
            onClick={changeMenu}
            defaultSelectedKeys={[defaultKey.toString()]}
            inlineIndent={30}
            className="sub-sider-menu"
          >
            {photoCategorys.map((category) => (
              <Menu.Item key={`photos_${category.id}`}>
                <Link
                  replace
                  to={{
                    pathname: getFullRoute(PAGE_PATH.customerPhoto.details, {
                      projectId: projectId,
                      showKey: 'photos',
                    }),
                    search: `?categoryId=${category.id}`,
                  }}
                >
                  {category.name}
                </Link>
              </Menu.Item>
            ))}
            <Menu.Item disabled>
              <Button
                type="link"
                onClick={toShowCategoryModal}
                className="link-btn"
              >
                添加/编辑文件夹
              </Button>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key={'settings'}>
              <Link
                replace
                to={getFullRoute(PAGE_PATH.customerPhoto.details, {
                  projectId: projectId,
                  showKey: 'settings',
                })}
              >
                设置
              </Link>
            </Menu.Item>
            <Menu.Divider />
              <Button
                  type="primary"
                  style={{ display: 'block' }}
                  onClick={showShareVisible}
              >
                  {projectDetail.status === ProjectStatus[1].key
                      ? '分享项目'
                      : '上线并分享'}
              </Button>
              <Menu.Divider />
            <Contract />
            {/*{projectDetail.customerId?bookmarkData.map((bookmark) => (
              <Menu.Item key={`fav_${bookmark.id}`}>
                <Link
                  replace
                  to={{
                    pathname: getFullRoute(PAGE_PATH.customerPhoto.details, {
                      projectId: projectId,
                      showKey: 'fav',
                    }),
                    search: `?favId=${bookmark.id}&text=${bookmark.name}`,
                  }}
                >
                  {bookmark.name}
                </Link>
              </Menu.Item>
            )):null}*/}
            {projectDetail.customerId? (
              <Menu.Item key={`fav_${bookmarkData[0]?bookmarkData[0].id:''}`}>
                <Link
                  replace
                  to={{
                    pathname: getFullRoute(PAGE_PATH.customerPhoto.details, {
                      projectId: projectId,
                      showKey: 'fav',
                    }),
                    search: `?favId=${bookmarkData[0]?bookmarkData[0].id:''}&text=${bookmarkData[0]?bookmarkData[0].name:''}`,
                  }}
                >
                  收藏夹
                </Link>
              </Menu.Item>
            ):null}
            {projectDetail.customerId?(
                <Menu.Item key={`private`}>
                  <Link
                      replace
                      to={{
                        pathname: getFullRoute(PAGE_PATH.customerPhoto.details, {
                          projectId: projectId,
                          showKey: 'private',
                        }),
                        search: `?text=私有`,
                      }}
                  >
                    私有
                  </Link>
                </Menu.Item>
            ):''}
            {projectDetail.customerId?(
                <Menu.Item key={`comment`}>
                  <Link
                      replace
                      to={{
                        pathname: getFullRoute(PAGE_PATH.customerPhoto.details, {
                          projectId: projectId,
                          showKey: 'comment',
                        }),
                        search: `?text=留言反馈`,
                      }}
                  >
                    留言反馈
                  </Link>
                </Menu.Item>
            ):''}
            <Menu.Divider />
            {/*<div>动态记录</div>*/}
            <Menu.Item key={'activities'}>
              <Link
                  replace
                  to={getFullRoute(PAGE_PATH.customerPhoto.details, {
                    projectId: projectId,
                    showKey: 'activities',
                  })}
              >
                动态记录
              </Link>
            </Menu.Item>
            {/*<div style={{color:'#9B9B9B',fontSize:'14px',cursor:'pointer'}}>动态记录</div>*/}
          </Menu>
        </Spin>

        {/* 底部操作 */}
        <div className="sub-sider-footer">
          {/*<Button
            className="Inf_default_btn"
            style={{ display: 'block' }}
            onClick={showShareVisible}
          >
            {projectDetail.status === ProjectStatus[1].key
              ? '分享项目'
              : '上线并分享'}
          </Button>*/}
          <Button
            className="Inf_default_btn"
            style={{ display: 'block' }}
            onClick={toPreview}
          >
            预览
          </Button>
        </div>
      </Sider>

      <Layout
        style={{ marginLeft: 200, backgroundColor: '#fff', overflow: 'hidden' }}
      >
        <Switch>
          {routes.map((route, i) => {
            let component = null
            switch (showKey) {
              case 'photos':
                component = (
                  <PhotoPage
                    projectId={projectId}
                    selectedCategory={selectedCategory}
                    projectDetail={projectDetail}
                    photosData={photosData}
                    photoCategorys={photoCategorys}
                    key={selectedCategory.key + reloading}
                    loadData={loadData}
                    reloadData={reloadData}
                    loading={loading}
                    hasMore={hasMore}
                    _getCategoryForMove={_getPhotoCategoryForMove}
                    _moveOrCopyPhotos={_moveOrCopyPhotos}
                    _removePhotos={_removePhotos}
                    _setCover={setCover}
                    _packagePhotos={_packagePhotos}
                    _changeSort={_changeSort}
                    onShow={doShowModalVisible}
                    setPhotosData={setPhotosData}
                    _setBg={setBg}
                    bg={bg}
                    _setIsSearch={setIsSearch}
                    isSearch={isSearch}
                    searchInput={searchInput}
                    searchText={searchText}
                    _setSearchText={setSearchText}
                  />
                )
                break
              case 'settings':
                component = (
                  <SettingsPage
                    key={projectDetail.id}
                    projectDetail={projectDetail}
                    projectCategorys={projectCategorys}
                    photoCategorys={photoCategorys}
                    pricesheetList={pricesheetList}
                    addCategory={onAddProjectCategory}
                    showRemove={showRemove}
                    showBookmarkForm={showBookmarkForm}
                    bookmarks={bookmarkData}
                    editBookmark={showEditBookmark}
                    deleteBookmark={deleteBookmarkById}
                    onSave={saveSettings}
                    infoDetails={infoDetails}
                  />
                )
                break
              case 'fav':
                component = (
                  <BookmarkPage
                    projectId={projectId}
                    selectedBookmark={selectedBookmark}
                    projectDetail={projectDetail}
                    photosData={photosDatas}
                    key={selectedBookmark.key + reloading}
                    loadData={loadBookmarkData}
                    reloadData={reloadBookmarkData}
                    loading={loadingBookmarks}
                    hasMore={hasMore}
                    _removePhotos={_removeBookmarkPhotos}
                    _packageBookmarkPhotos={_packageBookmarkPhotos}
                    _downloadBookmarkExcel={_downloadBookmarkExcel}
                    _ignoreExceedTip={_ignoreExceedTip}
                    _overFavorite={_overFavorite}
                    _fileNameList={_fileNameList}
                    _fileNameLists={_fileNameLists}
                    bookmarkData={bookmarkData}
                    search={search}
                    clickNav={clickNav}
                  />
                )
                break
              case 'private':
                component = (
                    <PrivatePage
                        projectId={projectId}
                        selectedBookmark={selectedBookmark}
                        projectDetail={projectDetail}
                        photosData={privatephotosDatas}
                        key={selectedBookmark.key + reloading}
                        loadData={loadHiddenData}
                        reloadData={reloadHiddenListData}
                        loading={loadingBookmarks}
                        hasMore={hasMore}
                        _removePhotos={_removeHidden}
                        _packageBookmarkPhotos={_packageBookmarkPhotos}
                        _downloadBookmarkExcel={_downloadPrivateExcel}
                        _ignoreExceedTip={_ignoreExceedTip}
                        _overFavorite={_overFavorite}
                        _fileNameList={_fileNameList}
                        _fileNameLists={_customerFileNameLists}
                        bookmarkData={bookmarkData}
                        search={search}
                        clickNav={clickNav}
                    />
                )
                break
              case 'comment':
                component = (
                    <CommentPage
                        projectId={projectId}
                        selectedBookmark={selectedBookmark}
                        projectDetail={projectDetail}
                        photosData={commentphotosDatas}
                        key={selectedBookmark.key + reloading}
                        loadData={loadMessageData}
                        reloadData={reloadMessageListData}
                        loading={loadingBookmarks}
                        hasMore={hasMore}
                        _removePhotos={_removeMessage}
                        _packageBookmarkPhotos={_packageBookmarkPhotos}
                        _downloadBookmarkExcel={_downloadPrivateExcel}
                        _ignoreExceedTip={_ignoreExceedTip}
                        _overFavorite={_overFavorite}
                        _fileNameList={_fileNameList}
                        _fileNameLists={_customerFileNameLists}
                        bookmarkData={bookmarkData}
                        search={search}
                        clickNav={clickNav}
                    />
                )
                break
              case 'activities':
                component = (
                  <div>
                    {infoDetails.type&&infoDetails.type !== 'Trail' && (
                      <ActivitiesPage
                          key={projectDetail.id}
                          projectDetail={projectDetail}
                          projectCategorys={projectCategorys}
                          photoCategorys={photoCategorys}
                          addCategory={onAddProjectCategory}
                          showRemove={showRemove}
                          showBookmarkForm={showBookmarkForm}
                          bookmarks={bookmarkData}
                          editBookmark={showEditBookmark}
                          deleteBookmark={deleteBookmarkById}
                          onSave={saveSettings}
                          infoDetails={infoDetails}
                          _projectRecord={_projectRecord}
                          _removeRecord={_removeRecord}
                          _shareRecord={_shareRecord}
                          _removeShare={_removeShare}
                          _projectBookmarkRecord={_projectBookmarkRecord}
                          _removeBookmarkRecord={_removeBookmarkRecord}
                          _projectDownloadRecord={_projectDownloadRecord}
                          _removeDownloadRecord={_removeDownloadRecord}
                      />
                    )}
                    {infoDetails.type&&infoDetails.type == 'Trail' && (
                      <Layout>
                        <PageHeader
                          ghost={false}
                          style={{borderBottom: '1px solid #e6e6e6'}}
                          title={
                            <div>动态记录</div>
                          }
                        ></PageHeader>
                        <Card bordered={false} className={styles.Trailtext}>
                          <div className={styles.dialogTitle}>您当前的账户类型不支持</div>
                          <div className={styles.dialogDes}>动态记录允许您查看当前项目的所有浏览与操作记录，实时跟进客户的行为。若需更多功能请升级到更高版本。</div>
                          <Button
                            key='ok'
                            type="primary">
                            <Link
                              to={PAGE_PATH.settings.children.subscribe}
                            >
                              升级订阅
                            </Link>
                          </Button>
                        </Card>
                      </Layout>
                    )}
                  </div>
                )
                break
              default:
                break
            }
            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                children={component}
              />
            )
          })}
        </Switch>
      </Layout>

      <CategoryModal
        visible={categoryModalVisible}
        onCreate={doSaveCategory}
        onCancel={() => {
          setCategoryModalVisible(false)
        }}
        key={loadingCategory}
        list={photoCategorys}
        title={'文件夹管理'}
        tip={'该文件夹所有图片与相关记录将会被移除，20天内可在回收站恢复，确定继续?'}
      />

      <RemoveProjectForm
        visible={removeFormVisible}
        onCreate={doRemoveProject}
        onCancel={() => {
          setRemoveFormVisible(false)
        }}
        project={projectDetail}
      />
      <BookmarkForm
        visible={bookmarkVisible}
        onCreate={saveBookmark}
        onCancel={() => {
          setBookmarkVisible(false)
          setEditBookmark({})
        }}
        editData={editBookmark}
        key={editBookmark.id}
      />

      <UploadModal
        onRef={onRef}
        visible={uploadModalVisible}
        addPhotos={savePhotos}
        onCancel={doHiddenModalVisible}
        options={selectedCategory}
        _checkPhotos={_checkPhotos}
        setLoading={setLoading}
        setLoadingUpload={setLoadingUpload}
        loadingUpload={loadingUpload}
      />

      <ShareProjectForm
        visible={shareFormVisible}
        onCancel={() => setShareFormVisible(false)}
        project={projectDetail}
        infoName={infoName}
        key={projectDetail.id}
      />

      <LinkClient
          visible={linkClientVisible}
          onCancel={() => setLinkClientVisible(false)}
          setLinkClientVisible={setLinkClientVisible}
          setAddClientVisible={setAddClientVisible}
          AddClient={AddClient}
          saveLinkClient={saveLinkClient}
      />
      {/*<AddClientForm
          visible={addClientVisible}
          isAdd={true}
          clientInfo={{}}
          editClient={editClient}
          onCreate={onAddClient}
          onCancel={hideClient}
      />*/}
      {projectDetail&&projectDetail.customerResultVO&&projectDetail.customerResultVO.id?<AddClientForm
          visible={addClientVisible}
          isAdd={isAdd}
          clientInfo={projectDetail&&projectDetail.customerResultVO?projectDetail.customerResultVO:{}}
          editClient={editClient}
          onCreate={onAddClient}
          onCancel={()=>{
            setLinkClientVisible(false)
            setAddClientVisible(false)
            setBrowseVisible(true)
          }}
      />:<EditClientForm
          visible={addClientVisible}
          clientInfo={projectDetail&&projectDetail.customerResultVO?projectDetail.customerResultVO:{}}
          isAdd={isAdd}
          onCreate={onAddClient}
          onCancel={() => {
            setLinkClientVisible(true)
            setAddClientVisible(false)
            setBrowseVisible(false)
          }}
      />}
      <PermissionsForm
          visible={browseVisible}
          projectDetail={projectDetail}
          removeClient={removeClient}
          onCreate={onVisitor}
          editClient={()=>{
            setAddClientVisible(true)
            setBrowseVisible(false)
            setIsAdd(false)
          }}
          onCancel={()=>setBrowseVisible(false)}
      />
    </Layout>
  )
}

export default ProjectDetails
