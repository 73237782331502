/*
 * @Author: melon
 * @Date: 2020-07-06 12:31:51
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-11 22:15:19
 */

import { isEmpty } from 'lodash/fp'
import { getAddressString } from 'utils/Help'

export const EnterpriseInfoSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    ...data,
    formattedData: {
      ...data,
      name: data.name, // 企业名称
      remark: data.remark, // 企业简介
      contactNumber: data.contactNumber, // 联系电话
      email: data.email, // 联系email
      areaArray: !isEmpty(data.provinceGeoId)
        ? [data.provinceGeoId, data.cityGeoId, data.areaGeoId]
        : [], // 省市区

      address: data.address, // 详细街道地址
      areaString:
        !isEmpty(data.provinceGeoId)?getAddressString([data.provinceGeoId, data.cityGeoId, data.areaGeoId]) +
        data.address:"",
      website: data.website, // 企业网站
      wechatImageKey: data.wechatImageKey, // 联系微信图片key
      wechatImagePhotoInfo: data.wechatImagePhotoInfo,
      weiboUrl: data.weiboUrl, // 微博地址
      wechatNo: data.wechatNo, // 微信号/微信公众号
      instagram: data.instagram, // instagram
      fileList: !isEmpty(data.wechatImagePhotoInfo)
        ? [
            {
              url: data.wechatImagePhotoInfo.photoUrl,
            },
          ]
        : [],
    },
  }
  return newData
}

// 修改
export const EnterpriseAddSelector = (data) => {
  return {
    name: data.name, // 企业名称
    remark: data.remark, // 企业简介
    contactNumber: data.contactNumber, // 联系电话
    email: data.email, // 联系email
    provinceGeoId: data.provinceGeoId, // 省id
    cityGeoId: data.cityGeoId, // 市id
    areaGeoId: data.areaGeoId, // 区id
    address: data.address, // 详细街道地址
    website: data.website, // 企业网站
    wechatImageKey: data.wechatImageKey, // 联系微信图片key
    wechatImagePhotoInfo: data.wechatImagePhotoInfo,
    weiboUrl: data.weiboUrl, // 微博地址
    wechatNo: data.wechatNo, // 微信号/微信公众号
    instagram: data.instagram, // instagram
  }
}
