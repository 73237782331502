import { Button, Divider, Form, Input, Radio, Upload, Switch, Modal ,Tooltip} from 'antd'
import Components from 'components'
import CoverUpload from 'components/CoverUpload'
import { WebLayout } from 'constants/Options'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { uploadImage } from 'utils/Uploader'
import { domainPattern } from 'utils/ValidateRulesAndMsg'
import styles from './brandForm.module.less'
import style from "../../Account/Components/InviteMember.module.less"
import AccessControl from './AccessControl'
import AddProjectForm from "../../CustomerPhoto/Components/AddProjectForm";

const { message } = Components
const BrandForm = ({ formRef, webData, setWebData,infoDetails }) => {
  const [form] = Form.useForm()
  const [initialValues] = useState(webData)
  const [webLayoutCheck, setWebLayoutCheck] = useState(
    webData.websiteLayoutType
  )
  const changePoweredBy = (checked) => {
    setPoweredBy(checked)
  }
  const [poweredBy, setPoweredBy] = useState(webData.poweredBy)
  const [webIcon, setWebIcon] = useState(
    isEmpty(webData.websiteIcon) ? '' : webData.websiteIconPhotoInfo.photoUrl
  )
  const [address, setAddress] = useState(webData.accessSuffix || 'name')
  const [bfileList, setBFileList] = useState(
    isEmpty(webData.brandLogo)
      ? []
      : [
          {
            url: webData.brandLogoPhotoInfo.photoUrl,
          },
        ]
  )
  const [cfileList, setCFileList] = useState(
    isEmpty(webData.customerLogo)
      ? []
      : [
          {
            url: webData.customerLogoPhotoInfo.photoUrl,
          },
        ]
  )
  // const [webIconList, setWebIconList] = useState(
  //   isEmpty(webData.customerLogo)
  //     ? []
  //     : [
  //         {
  //           url: webData.websiteIconPhotoInfo.photoUrl,
  //         },
  //       ]
  // )
    const [modalVisible, setModalVisible] = useState(false)
    const [modalType, setModalType] = useState('')
    const [addressInp, setAddressInp] = useState(
        webData.accessSuffix
    )
    const [addressName, setAddressName] = useState(
        addressInp
    )
    const [viewAddress, setViewAddress] = useState(webData.domain2c)
    const [alarmVisible, setAlarmVisible] = useState(false);
    const [JurisdictionVisible, setJurisdictionVisible] = useState(false);
  const customRequest = (info) => {
    const { file, onSuccess } = info
    // // 派发请求
    uploadImage({
      file: file,
      errorCallback: async () => {},
      completeCallback: async (res) => {
        const { hash, urlPrefix } = res

        form.setFieldsValue({
          brandLogo: hash,
        })
        setBFileList([
          {
            url: urlPrefix + hash,
          },
        ])
        onSuccess(res)
      },
    })
  }

  const customRequestC = (info) => {
    const { file, onSuccess } = info

    // // 派发请求
    uploadImage({
      file: file,
      errorCallback: async () => {},
      completeCallback: async (res) => {
        const { hash, urlPrefix } = res

        form.setFieldsValue({
          customerLogo: hash,
        })
        setCFileList([
          {
            url: urlPrefix + hash,
          },
        ])
        onSuccess(res)
      },
    })
  }

  const beforeUpload = (file) => {
    const isPng = file.type === 'image/png'
    if (!isPng) {
      message.error('请上传PNG格式得图片!')
    }
    const isLt1M = file.size / 1024 / 1024 < 1
    if (!isLt1M) {
      message.error('图片大小请控制在1MB以内!')
    }

    return isPng && isLt1M
  }
  const brandLogoProps = {
    customRequest: customRequest,
    fileList: bfileList,
    accept: 'image/png',
    beforeUpload,
  }
  const customerLogoProps = {
    customRequest: customRequestC,
    fileList: cfileList,
    accept: 'image/png',
    beforeUpload,
  }
  const onChangeLayout = (e) => {
    setWebLayoutCheck(e.target.value)
  }
  const customRequestWebIcon = (info) => {
    const { onProgress, file, onSuccess, onError } = info

    // // 派发请求
    uploadImage({
      file: file,
      errorCallback: async () => {},
      completeCallback: async (res) => {
        const { hash, urlPrefix } = res

        form.setFieldsValue({
          websiteIcon: hash,
        })
        setWebIcon(urlPrefix + hash)
      },
    })
  }
  const handleOk = () => {
    if (modalType === 'rename') {
      setViewAddress(webData.domain2c.replace(addressName, addressInp))
      setModalVisible(false)
      return
    }
    if (!domainPattern.test(form.getFieldsValue().domain)) {
      form.validateFields()
      return;
    }
    const domain = form.getFieldsValue().domain
    setWebData({
      ...webData,
      domain,
    })
    setModalVisible(false)
    return
  }
  const handleCancel = () => {
      setAddressInp(addressName)
      setModalVisible(false)
  }

  const handleCancelDomain = () => {
    setAlarmVisible(true);
  }

  const checkCancelDomain = () => {
    setWebData({
      ...webData,
      domain: '',
    })
  }

  const cancelAlarmVisible = () => {
    setAlarmVisible(false);
  }

  const handleShowModal = type => {
      setModalVisible(true)
      setModalType(type)
  }
  const isJurisdiction=()=>{
    setJurisdictionVisible(true);
  }
  return (
    <div className={styles.formContrl}>
      <Form
        form={form}
        ref={formRef}
        layout="vertical"
        name="brandForm"
        initialValues={initialValues}
      >
        <Form.Item
          label="品牌Logo"
          name="brandLogo"
          className={styles.brandLogoUpload}
        >
          <CoverUpload
            uploadProps={brandLogoProps}
            showButtons={{
              uploadButton: (
                <Button type="primary" style={{ padding: '4px 40px' }}>
                  上传
                </Button>
              ),
              replaceButton: (
                <Button type="primary" style={{ padding: '4px 40px' }}>
                  替换
                </Button>
              ),
            }}
          />
          <div className={styles.description}>
            说明：该Logo将会作为主品牌图片显示在全站、邮件通知、交付过程，甚至销售的商品中。仅支持背景透明的PNG图片，最小边长不小于300像素，最长边不大于1000像素。
          </div>
        </Form.Item>
        <Divider />
        <Form.Item
          label="项目影集模块Logo"
          name="customerLogo"
          className={styles.slicesLogoUpload}
        >
          <CoverUpload uploadProps={customerLogoProps} />
          <div className={styles.description}>
          说明：该Logo将会出现在项目交付过程页面中。仅支持背景透明的正方形PNG图片，最小边长不小于300像素，最长边不大于1000像素。
          </div>
        </Form.Item>
        <Divider />
        <Form.Item label="网站图标">
          <div className={styles.webIcon}>
            {!isEmpty(webIcon) ? <img src={webIcon} /> : null}
          </div>
          <Form.Item name="websiteIcon" noStyle>
            <Upload
              customRequest={customRequestWebIcon}
              showUploadList={false}
              accept="image/png"
              beforeUpload={beforeUpload}
            >
              {isEmpty(webIcon) ? (
                <Button type="primary" style={{ marginBottom: '0.75rem' }}>
                  上传
                </Button>
              ) : (
                <Button type="primary" style={{ marginBottom: '0.75rem' }}>
                  替换
                </Button>
              )}
            </Upload>
          </Form.Item>
          <div className={styles.description}>
          说明：该图标将会出现在浏览器中。
          </div>
        </Form.Item>
        <Divider />
        {/* 判断用户基本，若允许则取消disabaled状态 */}
        <Form.Item label="显示Infotos品牌信息" style={{display:'relative'}}>
        <Form.Item name="poweredBy" noStyle valuePropName="checked">
                <Switch onChange={changePoweredBy} disabled={infoDetails.poweredBy=='N'}/>
            </Form.Item>
          <div onClick={isJurisdiction} className={styles.absolute} style={{display:infoDetails.poweredBy=='N'?'':'none'}}></div>
            <span className={styles.switchLabel}>
              {poweredBy ? '是' : '否'}
            </span>
          <div className={styles.description}>
          说明：关闭则会隐藏在网站、项目等页面底部的“Powered by Infotos”信息。
          </div>
        </Form.Item>
        <Divider />
        <Form.Item label="网站布局" className={styles.webLayout}>
          <Form.Item
            name="websiteLayoutType"
            noStyle
            className={styles.webLayout}
          >
            <Radio.Group buttonStyle="solid" onChange={onChangeLayout}>
              {WebLayout.map((item) => (
                <Radio.Button value={item.value} key={item.value}>
                  {item.label}
                  <span className={styles.buttonCheckIcon}>
                    <span
                      className="iconfont iconicon_btn_selected primary"
                      style={{
                        display: webLayoutCheck === item.value ? '' : 'none',
                      }}
                    ></span>
                  </span>
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <div className={styles.description}>
            {/* 说明：建议Logo为横向样式的使用左右排列形式。 */}
          </div>
        </Form.Item>
        <Divider />
        <Form.Item label="网站地址" className={styles.address}>
          {!webData.domain && <>
           <span
            className={styles.addressLabel}
          >
            {viewAddress}
          </span>
          <span className={styles.rename} onClick={() => handleShowModal('rename')}>
              重命名
          </span>
          </>}
          {webData.domain && <>
            <span className={styles.ownDomain}>
                绑定域名{webData.domain}，待验证
            </span>
            <span className={styles.rename} onClick={handleCancelDomain}>
                解除绑定
            </span>
          </>}
          {!webData.domain && <p style={{position:'relative'}}>
            <Tooltip placement="rightBottom" title={infoDetails.domain=='N'?'当前账户类型不支持':''}>
              <Button
                  onClick={() => handleShowModal('own')}
                  type="primary"
                  style={{ marginTop: '20px'}} disabled={infoDetails.domain=='N'?true:''}>
                  绑定自有域名
              </Button>
            </Tooltip>
            {/*<span onClick={isJurisdiction} style={{display:infoDetails.domain=='N'?'block':'',position: 'absolute',*/}
            {/*  bottom: '0',*/}
            {/*  left:'0',*/}
            {/*  width: '116px',*/}
            {/*  height: '32px',*/}
            {/*  background: 'rgba(255, 255, 255, 0.5)',*/}
            {/*  cursor: 'no-drop'}}></span>*/}
          </p>}
        </Form.Item>
        <Modal
            visible={alarmVisible}
            maskClosable={false}
            centered
            title={<span>是否解除绑定</span>}
            onOk={checkCancelDomain}
            onCancel={cancelAlarmVisible}
            width={600}
          ></Modal>
        <Modal
            visible={modalVisible}
            maskClosable={false}
            centered
            title={<span>{modalType === 'rename' ? '重命名网站地址' : '绑定自有域名'}</span>}
            onOk={handleOk}
            onCancel={handleCancel}
            width={600}
            footer={[
                <Button
                    key='cancel'
                    className={styles.secondBtn}
                    type='text'
                    onClick={handleCancel}>取消</Button>,
                <Button
                    key='ok'
                    type="primary"
                    onClick={handleOk}>
                    {modalType === 'rename' ? '保存 ' : '提交申请'}
                </Button>
            ]}
        >
            {
                modalType === 'rename' ? <div>
                    <p className={styles.addressP}>
                        <span className={styles.webAdd}>网站地址</span>
                        <span className={styles.webAdd2}>
                            {webData.domain2c && webData.domain2c.replace(addressName, addressInp)}
                        </span>
                    </p>
                    <Form.Item
                      name="accessSuffix"
                      rules={[
                        {
                          required: true,
                          message: '网址不能为空!'
                        }
                      ]}
                    >
                      <Input
                          style={{marginTop: '8px'}}
                          onChange={e => setAddressInp(e.target.value)}
                      />
                    </Form.Item>
                </div> : <div>
                    <p className={styles.addressP}>
                        <span className={styles.webAdd}>域名</span>
                    </p>
                    <Form.Item
                      name="domain"
                      rules={[
                        {
                          required: true,
                          message: '请输入绑定的域名地址'
                        },
                        {
                          pattern: domainPattern,
                          message: '请输入正确的域名!'
                        }
                      ]}
                    >
                      <Input
                          style={{marginTop: '8px'}}
                          placeholder="请输入域名地址，例如abc.com"
                      />
                    </Form.Item>
                </div>
            }
        </Modal>
      </Form>
      <AccessControl
          visible={JurisdictionVisible}
          htmlTexts='您当前的账户类型不支持该功能，若需使用请升级到更高版本。'
          onCancel={() => {
            setJurisdictionVisible(false)
          }}
      />
    </div>
  )
}
export default BrandForm
