import { Avatar, Card } from 'antd'
import Imgs from 'imgs'
import React from 'react'
import styles from './index.module.less'

const { photoSample } = Imgs
const { Meta } = Card

const ImageCardCell = ({
  imgSize = 30,
  imgSrc = photoSample,
  title = '这是标题',
  description = '这是描述',
  imgStyle = {},
}) => {
  return (
    <Card bordered={false} className={styles.Inf_Image_Card}>
      <Meta
        avatar={
          <Avatar
            shape="square"
            style={{ ...imgStyle }}
            size={imgSize}
            src={imgSrc}
          />
        }
        title={<div className={styles.title}>{title}</div>}
        description={<div className={styles.description}>{description}</div>}
      />
    </Card>
  )
}

export default ImageCardCell
