/*
 * @Author: melon
 * @Date: 2020-07-04 23:21:17
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-19 18:25:36
 */
import { isEmpty } from 'lodash/fp'

export const AccountInfoSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    ...data,
    formattedData: {
      ...data,
      nickname: data.nickname, // 用户名
      account: data.mobile || data.email, // 账号
      avatarPhoto: !isEmpty(data.avatarPhotoInfo)
        ? data.avatarPhotoInfo.photoUrl
        : '', // 头像url
      sidebarColorType: isEmpty(data.sidebarColorType)
        ? undefined
        : data.sidebarColorType, // 侧边
    },
  }

  return newData
}

// 修改密码
export const ChangePasswordSelector = (data) => {
  return {
    ...data,
    oldPassword: data.oldPassword, // 旧密码
    nowPassword: data.nowPassword, // 新密码
    confirmPassword: data.confirmPassword, // 确认新密码
  }
}

// 修改头像
export const ChangeAvatarSelector = (data) => {
  return {
    ...data,
    avatar: data.avatar, // 头像key
  }
}

// 修改账号信息
export const ChangeAccountInfoSelector = (data) => {
  return {
    ...data,
    nickname: data.nickname, // 用户名(昵称)
    sidebarColorType: data.sidebarColorType, // 侧边栏色值类型
  }
}
