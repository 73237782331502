import {Col, DatePicker, Form, Input, Modal, Row, Select} from 'antd'
import AddSelect from 'components/AddSelect'
import React, {useEffect, useState} from 'react'
import styles from './selectProduct.module.less'
import classNames from "classnames";
const { Option,OptGroup  } = Select

const SelectProduct = ({
  visible,
  onCreate,
  onCancel,
  productList,
  eiditPrice,
}) => {
  const [form] = Form.useForm()
  const [showMore, setShowMore] = useState(false)
  const [list, setList] = useState([
      {value:'框画',list:[
              {code:1,value:'普通框画'},
              {code:2,value:'实木框画'},
          ]},
      {value:'相册',list:[
              {code:3,value:'精装'},
              {code:4,value:'普通'},
          ]},
  ])
  const [size, setSize] = useState([
      {code:1,value:'12*12'}
  ])
  const [texture, setTexture] = useState([
      {code:1,value:'实木框画'}
  ])
  const [pages, setPages] = useState([
      {code:1,value:'实木框画'}
  ])
  const [productOptionVos,setProductOptionVos]=useState([])
  useEffect(() => {
    //修改赋值
    if(Object.keys(eiditPrice).length !== 0) {
      productList.forEach(val=>{
        val.productBaseInfoList.forEach(va=>{
          if(va.id==eiditPrice.childProductId&&!!va.productOptionVos) {
            setProductOptionVos(va.productOptionVos)
            va.productOptionVos.forEach((v,i)=>{
              form.setFieldsValue({
                [v.optionName]:eiditPrice.productSpecificationsName.split(',')[i]
              })
            })
          }
        })
      })
      form.setFieldsValue({
        childProductId: eiditPrice.childProductId,
      })
    }
  },)
  //表单值变化
  const valuesChange =  ( size ) => {
    console.log(size)
    // 选择商品后展示商品选项
    if(!!size.childProductId){
      console.log(size.childProductId,productList)
      // form.setFieldsValue({
      //   childProductId: size.childProductId,
      // })
      productList.forEach(val=>{
        val.productBaseInfoList.forEach(v=>{
          if(v.id==size.childProductId) {
            setProductOptionVos(!!v.productOptionVos?v.productOptionVos:[])
          }
        })
      })
    }
    //照片数量限定
    // !!size.photoNumType&&setPhotoNum(size.photoNumType=='anyQuantity'?0:size.photoNumType=='specificQuantity'?1:2)

  }
  return (
    <Modal
      visible={visible}
      title="选择商品到组合"
      okText="添加"
      cancelText="取消"
      centered
      cancelButtonProps={{className: 'footerCanceltext'}}
      onCancel={onCancel}
      maskClosable={false}
      width={600}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            onCreate(values)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_project_form"
        requiredMark={false}
        className={styles.createProjectDialog}
        onValuesChange={valuesChange}
        initialValues={{}}
      >

            <Form.Item
              name="childProductId"
              label="选择商品"
              rules={[
                {
                  required: true,
                  message: '选择商品',
                },
              ]}
            >
                <Select
                    placeholder="请选择"
                    suffixIcon={
                        <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                    }
                    // defaultValue={eiditPrice.childProductId}
                >
                    {productList.length>0&&productList.map((v,i)=>(
                        <OptGroup label={v.productTypeName} key={i}>
                            {v.productBaseInfoList.map((val,ind)=>(
                                <Option value={val.id} key={ind}>{val.name}</Option>
                            ))}
                        </OptGroup>
                    ))}
                </Select>
            </Form.Item>
        {productOptionVos.length>0&&(
            <Form.Item
              name="values"
              label="设置选项"
            >
                <Row gutter={24}>
                  {
                    productOptionVos.map(v=>(
                      <Col span={12}>
                        <Form.Item
                          name={v.optionName}
                          label={v.optionName=='albumPages'?'页数':v.optionName}
                          rules={[
                            {
                              required: true,
                              message: '请选择',
                            },
                          ]}
                        >
                          <Select
                            placeholder="请选择"
                            suffixIcon={
                              <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                            }
                          >
                            {v.optionValue.split(',').map((v,i)=>(
                              <Option value={v}>{v}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    ))
                  }
                </Row>
            </Form.Item>
        )}
        {/*<div className={styles.more} onClick={()=>setShowMore(!showMore)}>更多选项/客户信息</div>*/}
      </Form>
    </Modal>
  )
}

export default SelectProduct
