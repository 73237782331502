import React, {useState, useEffect} from 'react'
import {Button, Input, message, Modal, Select} from "antd"
import style from "./InviteMember.module.less"
const { Search } = Input
const InviteMember = (props) => {

    const handleOk = () => {
        // console.log(12)
    }
    const onSearch = (value) => {
        let transfer = document.createElement('input')
        document.body.appendChild(transfer)
        transfer.value = value
        transfer.focus()
        transfer.select()
        if (document.execCommand('copy')) {
            document.execCommand('copy')
        }
        transfer.blur()
        message.success('已复制到剪切板')
        document.body.removeChild(transfer)
    }

    return (
        <Modal
            visible
            centered
            maskClosable={false}
            title={<span className={style.addTitle}>邀请成员</span>}
            onOk={handleOk}
            width={600}
            onCancel={() => props.changeShowInvite(false)}
            footer={null}
        >
            <p className={style.inviteFooterP}>
                <span className={style.span1}>邀请链接</span>
                <span className={style.span2}>链接5日内有效</span>
            </p>
            <Search
                style={{marginTop: '8px'}}
                value='https://ifto.link/89t40ur8'
                placeholder="input search text"
                enterButton="复制链接"
                // size="large"
                onSearch={onSearch}
            />
            <p
                onClick={() => props.changeShowInvite(false)}
                className={style.return}
            >
                直接添加成员
            </p>
        </Modal>

    )
}
export default InviteMember
