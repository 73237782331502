import {isEmpty, map} from 'lodash/fp'
import moment from "moment";
import {couponStatus, discountType, productStatus, orderSource, orderType} from 'constants/Options'
import {getOption, getOptionBykeyName} from 'utils/Tools'

export const newDatas = (res) => {
  let newData = {
    ...res.data,
  }
  const {data} = res.data
  newData.data = {
    pagination: {
      current: data.current,
      pageSize: data.size,
      total: data.total,
    },
    list: data.records.map((img) => ({
      ...img,
      createdTime: !!img.createdTime
        ? moment(img.createdTime).format('YYYY/MM/DD HH:mm')
        : '',
      endTime: !!img.endTime
        ? moment(img.endTime).format('YYYY/MM/DD')
        : '',
    })),
  }
  return newData
}

export const pricesheetInfoSelect = (res) => {
  let newData = {
    ...res.data,
  }
  const {data} = res.data
  newData.data.map(val => {
    val.productBaseInfoList.map(v => {
      v.statusText = getOptionBykeyName(
        'value',
        v.status,
        'label',
        productStatus
      )
    })
  })
  return newData
}
export const getOrderSelect = (res) => {
  let newData = {
    ...res.data,
  }
  const {data} = res.data
  if(!newData.data){
    return newData
  }
  newData.data.records.map(v => {
    v.orderSource = getOptionBykeyName(
      'value',
      v.sourceItem,
      'label',
      orderSource
    )
    v.statusText = getOptionBykeyName(
      'value',
      v.status,
      'label', orderType)
  })
  return newData
}
export const getOrderDetailsSelect = (res) => {
  let newData = {
    ...res.data,
  }
  newData.data.orderSource=getOptionBykeyName(
    'value',
    newData.data.sourceItem,
    'label',
    orderSource
  )
  newData.data.statusText=getOptionBykeyName(
    'value',
    newData.data.status,
    'label', orderType
  )
  // newData.data.createTime=newData.data.orderDate
  return newData
}
export const getOrderAddressSelect = (res) => {
  let newData = {
    ...res.data,
  }
  return newData
}
export const getPricesheetSelect = (res) => {
  let newData = {
    ...res.data,
  }
  const {data} = res.data
  if(!newData.data){
    return res
  }
  newData.data = {
    ...res.data,
    list: data.records.map((img) => ({
      ...img,
      createdTime: !!img.createdTime
        ? moment(img.createdTime).format('YYYY/MM/DD HH:mm')
        : '',
    })),
  }

  return newData
}




