/*
 * @Author: melon
 * @Date: 2020-07-02 11:27:55
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-18 18:05:10
 */
/* ------- 我的账户 start------- */
import AccountBusinessIndex from 'pages/Account/Containers/Business'
import AccountIndex from 'pages/Account/Containers/Index'
import AccountInfoIndex from 'pages/Account/Containers/Info'
import AccountServiceIndex from 'pages/Account/Containers/Service'
import AccountSubscribeIndex from 'pages/Account/Containers/Subscribe'
import AccountMemberIndex from 'pages/Account/Containers/Member'
/* ------- 我的账户 end------- */
/* ------- 客片 start------- */
import CustomerPhotoIndex from 'pages/CustomerPhoto/Components/ProjectIndex'
import CustomerPresetIndex from 'pages/CustomerPhoto/Components/PresetList'
import CustomerPhotoProjectDetails from 'pages/CustomerPhoto/Containers/ProjectDetails'
import ProjectRecycleIndex from 'pages/CustomerPhoto/Components/Recycle'
import MyCache from 'pages/My/Containers/Cache'
/* ------- 客片 end------- */
/* ---------------- 我的 start----------------*/
import MyIndex from 'pages/My/Containers/Index'
import MyMsg from 'pages/My/Containers/Msg'
/* ---------------- 我的 end----------------*/
/* ------- 网站设置 start------- */
import SettingsBrandIndex from 'pages/Setting/Containers/Brand'
import SettingsIndex from 'pages/Setting/Containers/Index'
import SettingsMarkstaIndex from 'pages/Setting/Containers/Marksta'
import SettingsSEOIndex from 'pages/Setting/Containers/SEO'
import PresetIndex from 'pages/CustomerPhoto/Components/PresetList'
/* ------- 网站设置 end------- */
/* ---------------- 购物车 start----------------*/
import ShopCartIndex from 'pages/ShoppingCart/Containers/Index'
import WebAboutPage from 'pages/Web/Containers/About'
import WebContactPage from 'pages/Web/Containers/Contact'

/* ------- 网站 start------- */
import WebIndex from 'pages/Web/Containers/Index'
import WebSetting from 'pages/Web/Containers/Setting'
import WebIndexPage from 'pages/Web/Containers/WebIndex'
import WebWorksPage from 'pages/Web/Containers/Works'

/* ------- 网站 end------- */
/* ------- 订阅计划 start------- */
/* ------- 订阅计划 end------- */
/*客户 start*/
import ClientIndex from 'pages/Client/Containers/Index'
import ClientInfo from 'pages/Client/Containers/ClientInfo'
/*客户 end*/

/* ------- 订单 start------- */
import StoreIndex from 'pages/Store/Containers/Index'
import StoreOrderPage from 'pages/Store/Components/Order'
import StoreOrderDetailsPage from 'pages/Store/Components/OrderDetails'
import StorePricesheetPage from 'pages/Store/Components/Pricesheet'
import StoreProductsPage from 'pages/Store/Components/Products'
import StoreNewProductPage from 'pages/Store/Components/NewProduct'
import StoreNewProductDownloadPage from 'pages/Store/Components/NewProductDownload'
import StoreNewProductPackagePage from 'pages/Store/Components/NewProductPackage'
import StoreCouponPage from 'pages/Store/Components/Coupon'
import StoreSettingPage from 'pages/Store/Components/Settings'
import StoreEarningsPage from 'pages/Store/Components/Earnings'
import StoreEarningsRecordPage from 'pages/Store/Components/EarningsRecord'
import WithdrawRecordPage from 'pages/Store/Components/WithdrawRecord'
/* ------- 订单 end------- */
// 项目预设


export const PAGE_PATH = {
  home: '/',
  login: '/account/login',
  register: '/account/registration',
  forgetPsw: '/account/reset',
  settings: {
    index: '/settings',
    children: {
      brand: '/settings/brand',
      member: '/settings/member',
      marksta: '/settings/watermark',
      business: '/settings/profile',
      service: '/settings/service',
      seo: '/settings/seo',
      subscribe: '/settings/subscription',
      preset: '/settings/preset',
    },
  },
  customerPhoto: {
    index: '/collection',
    details: '/collection/:projectId/:showKey',
    preset:'/collection/preset',
    children: {
      index: '/collection/index',
      preset: '/collection/preset',
      recycle: '/collection/recycle',
    }
  },
  my: {
    index: '/my',
    children: {
      info: '/my/info',
      msg: '/my/notification',
      cache: '/my/temp',
      // subscribe: '/my/subscribe',
      member: '/my/member',
      cart: '/my/cart',
    },
  },
  web: {
    index: '/website',
    children: {
      index: '/website/index',
      works: '/website/portfolio',
      about: '/website/about',
      contact: '/website/contact',
      setting: '/website/setting',
    },
  },
  client:{
    index:'/client',
    children:{
      list:'/client',
      clientInfo:'/client/ClientInfo',
    }
  },
  clientInfo:{
    index:'/clientInfo',
    children:{
      index:'/client/ClientInfo',
    }
  },
  store:{
    index:'/store',
    products:'/store/products/:pricesheetId',
    newProduct:'/store/newProduct/:pricesheetId',
    newProductDownload:'/store/newProductDownload/:pricesheetId',
    newProductPackage:'/store/newProductPackage/:pricesheetId',
    storeEarningsRecord:'/store/earningsrecord',
    WithdrawRecord:'/store/withdraw',
    children:{
      earnings:'/store/earnings',
      order:'/store/order',
      orderDetails:'/store/orderDetails/:orderId',
      pricesheet:'/store/pricesheet',
      coupon:'/store/coupon',
      setting:'/store/setting',
    },
  }
}

const Routes = [
  {
    path: PAGE_PATH.client.index,
    component: ClientIndex,
    name: '客户',
    page: 'client',
    routes: [
      {
        path: PAGE_PATH.client.children.list,
        component: ClientIndex,
        name: '客户列表',
        page: 'list',
        // exact: true,
      },{
        path: PAGE_PATH.clientInfo.children.clientInfo,
        component: ClientInfo,
        name: '客户详情',
        page: 'ClientInfo',
        // exact: true,
      },
    ]
  },
  {
    path: PAGE_PATH.clientInfo.index,
    component: ClientInfo,
    name: '客户详情',
    page: 'ClientInfo',
  },
  {
    path: PAGE_PATH.settings.index,
    component: SettingsIndex,
    name: '设置',
    page: 'settings',
    routes: [
      {
        path: PAGE_PATH.settings.children.brand,
        component: SettingsBrandIndex,
        name: '品牌设置',
        page: 'brand',
        // exact: true,
      },
      {
        path: PAGE_PATH.settings.children.member,
        component: AccountMemberIndex,
        name: '团队成员',
        page: 'member',
      },
      {
        path: PAGE_PATH.settings.children.marksta,
        component: SettingsMarkstaIndex,
        name: '照片水印',
        page: 'marksta',
        // exact: true,
      },
      {
        path: PAGE_PATH.settings.children.business,
        component: AccountBusinessIndex,
        name: '商业信息',
        page: 'business',
      },
      {
        path: PAGE_PATH.settings.children.service,
        component: AccountServiceIndex,
        name: '服务信息',
        page: 'service',
      },
      {
        path: PAGE_PATH.settings.children.seo,
        component: SettingsSEOIndex,
        name: '网站SEO',
        page: 'seo',
        // exact: true,
      },
      {
        path: PAGE_PATH.settings.children.subscribe,
        component: AccountSubscribeIndex,
        name: '订阅计划',
        page: 'subscribe',
      },
    ],
  },
  {
    path: PAGE_PATH.my.index,
    component: MyIndex,
    name: '我的',
    page: 'my',
    routes: [
      {
        path: PAGE_PATH.my.children.info,
        component: AccountInfoIndex,
        name: '我的账户',
        page: 'info',
      },
      {
        path: PAGE_PATH.my.children.msg,
        component: MyMsg,
        name: '我的消息',
        page: 'msg',
      },
      // {
      //   path: PAGE_PATH.my.children.cache,
      //   component: MyCache,
      //   name: '我的暂存',
      //   page: 'cache',
      // },
      // {
      //   path: PAGE_PATH.my.children.shoppingcart,
      //   component: ShopCartIndex,
      //   name: '订单记录',
      //   page: 'cart',
      // },
      // {
      //   path: PAGE_PATH.my.children.member,
      //   component: AccountMemberIndex,
      //   name: '团队成员',
      //   page: 'member',
      // },

    ],
  },
  {
    path: PAGE_PATH.web.index,
    component: WebIndex,
    name: '网站',
    page: 'web',
    routes: [
      {
        path: PAGE_PATH.web.children.index,
        component: WebIndexPage,
        name: '首页',
        page: 'home',
      },
      {
        path: PAGE_PATH.web.children.works,
        component: WebWorksPage,
        name: '作品',
        page: 'works',
      },
      {
        path: PAGE_PATH.web.children.about,
        component: WebAboutPage,
        name: '关于',
        page: 'about',
      },
      {
        path: PAGE_PATH.web.children.contact,
        component: WebContactPage,
        name: '联系',
        page: 'contact',
      },
      {
        path: PAGE_PATH.web.children.setting,
        component: WebSetting,
        name: '设置',
        page: 'webSetting',
      },
    ],
  },
  {
    path: PAGE_PATH.customerPhoto.index,
    component: CustomerPhotoIndex,
    // component: CustomerPhoto,
    name: '项目',
    page: 'CustomerPhotoIndex',
    exact: true,
    routes: [
      {
        path: PAGE_PATH.customerPhoto.children.index,
        component: CustomerPhotoIndex,
        name: '项目',
        page: 'CustomerPhotoIndex',
        exact: true,
      },

    ]
  },
  {
    path: PAGE_PATH.customerPhoto.children.recycle,
    component: ProjectRecycleIndex,
    name: '回收站',
    page: 'recycle',
    exact: true,
  },
  {
    path: PAGE_PATH.customerPhoto.children.preset,
    component: CustomerPresetIndex,
    name: '项目预设',
    page: 'preset',
    exact: true,
  },
  {
    path: PAGE_PATH.customerPhoto.details,
    component: CustomerPhotoProjectDetails,
    page: 'CustomerPhotoDetail',
    // strict: true,
    routes: [
      {
        path: PAGE_PATH.customerPhoto.details,
        page: 'photos',
      },
      {
        path: PAGE_PATH.customerPhoto.details,
        page: 'settings',
        name: '设置',
      },
      {
        path: PAGE_PATH.customerPhoto.details,
        page: 'fav',
        name: '收藏夹',
      },
      {
        path: PAGE_PATH.customerPhoto.details,
        page: 'activities',
        name: '动态记录',
      },
    ],
  },
  {
    path: PAGE_PATH.store.index,
    name: '销售',
    component: StoreIndex,
    page: 'Store',
    routes: [
      {
        path: PAGE_PATH.store.children.earnings,
        component: StoreEarningsPage,
        name: '首页',
        page: 'earnings',
      },
      {
        path: PAGE_PATH.store.children.order,
        component: StoreOrderPage,
        name: '订单',
        page: 'storeOrder',
      },
      {
        path: PAGE_PATH.store.children.pricesheet,
        component: StorePricesheetPage,
        name: '价格与商品',
        page: 'storePricesheet',
      },
      {
        path: PAGE_PATH.store.children.coupon,
        component: StoreCouponPage,
        name: '优惠券',
        page: 'storeCoupon',
      },
      {
        path: PAGE_PATH.store.children.setting,
        component: StoreSettingPage,
        name: '设置',
        page: 'storeSetting',
      },
      {
        path: PAGE_PATH.store.children.orderDetails,
        component: StoreOrderDetailsPage,
        name: '订单详情',
        page: 'StoreOrderDetails',
      },
      {
        path: PAGE_PATH.store.products,
        component: StoreProductsPage,
        name: '定价单',
        page: 'StoreProductsPage',
      },
      {
        path: PAGE_PATH.store.newProduct,
        component: StoreNewProductPage,
        name: '自定义商品',
        page: 'StoreNewProductPage',
      },
      {
        path: PAGE_PATH.store.newProductDownload,
        component: StoreNewProductDownloadPage,
        name: '数字下载',
        page: 'StoreNewProductDownloadPage',
      },
      {
        path: PAGE_PATH.store.newProductPackage,
        component: StoreNewProductPackagePage,
        name: '商品组合',
        page: 'StoreNewProductPackagePage',
      },
      {
        path: PAGE_PATH.store.storeEarningsRecord,
        name: '收益记录',
        component: StoreEarningsRecordPage,
        page: 'StoreEarningsRecordPage',
      },
      {
        path: PAGE_PATH.store.WithdrawRecord,
        name: '提现记录',
        component: WithdrawRecordPage,
        page: 'WithdrawRecordPage',
      }
    ]
  },
]

export default Routes
