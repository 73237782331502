/*
 * @Author: melon
 * @Date: 2020-07-04 23:16:26
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-04 23:18:07
 */
import request from 'utils/Request'

import Api from 'apis/Api.js'

// 获取 帐户信息

export const _getAccountInfo = async () => {
  return await request({
    method: 'get',
    url: Api.accountSetting.info,
    params: {},
  })
}

// 修改密码
/**
 *
 * @param {oldPassword, nowPassword, confirmPassword } param0
 * oldPassword 旧密码
 * nowPassword 新密码
 * confirmPassword 确认密码
 */
export const _changePassword = async ({
  oldPassword,
  nowPassword,
  confirmPassword,
}) => {
  return await request({
    method: 'post',
    url: Api.accountSetting.changePassword,
    data: {
      oldPassword,
      nowPassword,
      confirmPassword,
    },
  })
}

// 修改头像
export const _changeAvatar = async (data) => {
  return await request({
    method: 'post',
    url: Api.accountSetting.changeAvatar,
    data: data,
  })
}

// 修改账号信息
export const _changeAccountInfo = async (data) => {
  return await request({
    method: 'post',
    url: Api.accountSetting.changeAccountInfo,
    data: data,
  })
}

//获取角色信息
export const _roles = async data => {
    return await request({
        method: 'get',
        url: Api.accountSetting.roles,
        data: data,
    })
}

//批量添加员工账号
export const _addAccount = async ({memberAccountDTOS}) => {
    return await request({
        method: 'post',
        url: Api.accountSetting.addAccount,
        data: memberAccountDTOS,
    })
}
//员工账号列表
export const _accountList = async (data) => {
    return await request({
        method: 'get',
        url: Api.accountSetting.accountList,
        params: data,
    })
}
//更新用户角色
export const _changeRole = async ({memberAccountDTO}) => {
    return await request({
        method: 'post',
        url: Api.accountSetting.changeRole,
        data: memberAccountDTO,
    })
}
//移除账号
export const _remove = async (data) => {
    return await request({
        method: 'post',
        url: Api.accountSetting.remove,
        data,
    })
}
