/*
 * @Author: melon
 * @Date: 2020-07-10 11:25:31
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-10 15:33:58
 */
/*
 * @Author: melon
 * @Date: 2020-07-10 11:15:04
 * @Last Modified by:   melon
 * @Last Modified time: 2020-07-10 11:15:04
 */

import { MessageTypes, YesOrNoOptions } from 'constants/Options'
import { map } from 'lodash/fp'
import Mock from 'mockjs'
const { Random } = Mock

const YesOrNoOptionsValues = map(({ value }) => value)(YesOrNoOptions)
const MessageTypeValues = map(({ value }) => value)(MessageTypes)

// 消息动态列表
export const getMessageList = ({ pageSize = 10 }) => {
  return Array.from({ length: pageSize }, (v) => ({
    id: Random.id(), // id
    enterpriseId: Random.id(), // 企业id
    type: MessageTypeValues[Random.natural(0, 4)], // 消息类型
    content:
      '客户' +
      Random.cname() +
      [
        '下载了24张照片',
        '提交了收藏夹',
        '提交了选片收藏夹',
        '注册并查看了王杰的客片项目',
      ][Random.natural(0, 3)], // 内容
    isRead: YesOrNoOptionsValues[Random.natural(0, 1)], // 是否已读
    createTime: Random.datetime(), // 创建时间
  }))
}

// 未读消息列表
export const getNotReadMessageList = ({ pageSize = 10 }) => {
  return Array.from({ length: pageSize }, (v) => ({
    id: Random.id(), // id
    enterpriseId: Random.id(), // 企业id
    type: MessageTypeValues[Random.natural(0, 4)], // 消息类型
    content:
      '客户' +
      Random.cname() +
      [
        '下载了24张照片',
        '提交了收藏夹',
        '提交了选片收藏夹',
        '注册并查看了王杰的客片项目',
      ][Random.natural(0, 3)], // 内容
    isRead: YesOrNoOptionsValues[Random.natural(0, 1)], // 是否已读
    createTime: Random.datetime(), // 创建时间
  }))
}
