/*
 * @Author: melon
 * @Date: 2020-07-05 22:48:54
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-05 22:52:07
 */
import request from 'utils/Request'

import Api from 'apis/Api.js'

// 获取商业信息

export const _getEnterpriseInfo = async () => {
  return await request({
    method: 'get',
    url: Api.enterprise.enterpriseInfo,
    params: {},
  })
}

// 修改商业信息
export const _editEnterpriseInfo = async (data) => {
  return await request({
    method: 'post',
    url: Api.enterprise.enterpriseEdit,
    data: data,
  })
}

// 查询当前账号级别
export const _editEnterpriseLevel = async () => {
  return await request({
    method: 'get',
    url: Api.enterprise.enterpriseLevel,
    params: {},
  })
}
