/*
 * @Author: melon
 * @Date: 2020-06-29 11:23:54
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-22 11:13:31
 */
import { PAGE_PATH } from 'apis/Router'
import { Spin } from 'antd'

import classNames from 'classnames'
import Imgs from 'imgs'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
// 登录表单
import ForgetPswForm from '../Components/Form'
import styles from './index.module.less'
import { forgetPsw as _forgetPsw } from 'reduxModules/member'
import myMessage from 'components/Message/index'
const { LogoWT, LogoBK, User } = Imgs
const ForgetPsw = () => {
  let history = useHistory()
  const [sendRequesting, setSendRequesting] = useState(false)

  const doForgetPsw = async (values) => {
    // 派发登录请求
    try {
      setSendRequesting(true)
      const res = await _forgetPsw(values)
      if (res.data && [0].includes(res.data.code)) {
        myMessage.success('修改密码成功')
      } else {
        myMessage.error(res.data.msg || '修改密码失败')
      }
      return res
    } catch (error) {
      myMessage.error('系统繁忙')
    } finally {
      setSendRequesting(false)
    }
  }

  return (
    <div className={styles.forget_psw_page}>
      <div className={classNames(styles.forget_psw_show_info_container)}>
        <div className={classNames(styles.welcome_text)}>
          <p className={classNames(styles.welcome)}>Welcome to</p>
          <div className={styles.brands}>
            <img src={LogoWT} alt=""></img>
          </div>
          <div className={classNames(styles.register_wrapper)}>
            <p>开始建立并管理你的业务，为客户提供专业优雅的体验。</p>
          </div>
        </div>
      </div>
      <div className={classNames(styles.forget_psw_form_container)}>
        <Spin
          tip="修改密码中..."
          spinning={sendRequesting}
          size="large"
          style={{ width: '100%', height: '100%' }}
        >
          <ForgetPswForm doSubmit={doForgetPsw} history={history} />
        </Spin>
      </div>
    </div>
  )
}

export default ForgetPsw
