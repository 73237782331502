import {Button, Col, DatePicker, Form, Input, Modal, Row, Select} from 'antd'
import React, {useRef, useState,useEffect} from 'react'
import styles from './linkClient.module.less'
import myMessage from 'components/Message'
import {
    getInfoClient as _getInfoClient,
} from 'reduxModules/client'
import {color} from "echarts/src/export";
const { Option } = Select;
const LinkClient = ({
                            visible,
                            onCreate,
                            onCancel,
                          setLinkClientVisible,
                          saveLinkClient,
                          setAddClientVisible,
                          AddClient
                        }) => {
    const [form] = Form.useForm()
    // const [linkClientVisible, setLinkClientVisible] = useState(visible)
    const [showMore, setShowMore] = useState(false)
    const [ClientList, setClientList] = useState([])
    const [selects, setSelect] = useState('')


    // 获取客户列表
    const getListByClient = async (data) => {
        const res = await _getInfoClient({
            searchValue:data,//邮件/客户名称/客户电话
            pageNo:1,
            pageSize:10,
        })
        console.log(res)
        if (res.data.code === 0) {
            setClientList(res.data.data.list)
        } else {
            myMessage.error(res.data.msg)
        }
    }
    const searchInput = useRef()
    const addLinkClient=()=>{
        saveLinkClient(selects)
        // setLinkClientVisible(false)
    }
    // 搜索名称
    const handleSearch = (event) => {
        // e.persist()
        // console.log(searchInput.current.state.value)
        console.log(event)
        getListByClient(event)
    }
    const handleChange = (event) => {
        setSelect(event)

    }
    const FooterComponent = () => {
        return (
            <div className={styles.footer}>
                <div className={styles.btns}>
                    {/* <div className={styles.more} onClick={AddClient}>添加新客户</div> */}
                    <Button type="link" className="link-btn" onClick={AddClient} style={{width: 'auto'}}>
                        添加新客户
                    </Button>
                </div>
                <div>
                    <Button
                        style={{ border: 'none', boxShadow: 'none' }}
                        onClick={()=>setLinkClientVisible(false)}
                    >
                        取消
                    </Button>
                    <Button
                        type="primary"
                        onClick={addLinkClient}
                    >
                        确定
                    </Button>
                </div>
            </div>
        )
    }
    const options = ClientList.map(d => <Option key={d.id}>
        <p className={styles.resultMain}>{d.customerName}</p>
        <span className={styles.resultExtra}>{d.customerPhone}{d.customerPhone&&d.customerEmail?', ':''}{d.customerEmail}</span>
    </Option>);
    return (
        <Modal
            visible={visible}
            title="添加客户"
            okText="创建"
            cancelText="取消"
            centered
            cancelButtonProps={{className: 'footerCanceltext'}}
            onCancel={onCancel}
            maskClosable={false}
            width={600}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields()
                        onCreate(values)
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info)
                    })
            }}
            footer={<FooterComponent />}
        >
            <Form
                form={form}
                layout="vertical"
                name="add_project_form"
                requiredMark={false}
                className={styles.createProjectDialog}
            >
                <Form.Item
                    name="name"
                    label="搜索并选择"
                >
                    {/*<Input onKeyUp={changeName} ref={addInput} placeholder="输入客户名、电话或Email搜索…"/>*/}
                    <Select
                        showSearch
                        placeholder="输入客户、手机号或Email搜索…"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearch}
                        onChange={handleChange}
                        notFoundContent={null}
                    >
                        {options}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default LinkClient
