import { Table } from 'antd'
import CommonComponent from 'components/index'
import Imgs from 'imgs'
import React from 'react'
import styles from './MsgList.module.less'

const { ImageCardCell } = CommonComponent

const { MsgInfo } = Imgs

class MsgList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  handleTableChange = (pagination) => {
    this.props.getList(pagination)
  }
  render() {
    const { list, pagination } = this.props
    const columns = [
      {
        title: '',
        dataIndex: 'opt',
        key: 'opt',
        width: 100,
        render: (_, i) => (
          <ImageCardCell
            imgSize={36}
            title={<div>{i.content}</div>}
            imgSrc={MsgInfo}
            description={<div className={styles.msg_time}>{i.timeText}</div>}
          />
        ),
      },
    ]
    return (
      <div className={styles.Inf_msg_list}>
        <Table
          showHeader={false}
          rowKey="id"
          columns={columns}
          dataSource={list}
          pagination={{
            ...pagination,
            showSizeChanger: false,
          }}
          onChange={this.handleTableChange}
        />
      </div>
    )
  }
}
export default MsgList
