import {
    Button,
    Layout,
    Menu,
    Divider,
    Dropdown,
    PageHeader,
    Spin,
    Select,
    Col,
    Row,
    Input,
    Tooltip,
    Card,
    Table,
    Tag,
    Space,
    Modal,
    Popconfirm
} from 'antd'
import {InfoCircleOutlined, UserOutlined, SearchOutlined,CloseCircleFilled} from '@ant-design/icons';

import components from 'components'
import {ProjectStatus, ArrangementTypes} from 'constants/Options'
import React, {useEffect, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import { PAGE_PATH } from 'apis/Router'
import {
    getInfoClient as _getInfoClient,
    editClient as _editClient,
    addClient as _addClient,
    removeClient as _removeClient,
    clientInfos as _clientInfos,
    customerRecord as _customerRecord,
} from 'reduxModules/client'
import {
    editProject as _editProject,
    getProjectById as _getProjectById,
} from 'reduxModules/customerPhoto'
import CommonComponent from 'components/index'
import classNames from "classnames";
import myMessage from 'components/Message'
import styles from './ClientInfo.module.less'
import Imgs from 'imgs'
import AddClientForm from '../Components/AddClientForm'
import EditClientForm from '../Components/EditClientForm'
import {isEmpty} from "lodash";
import { CustomerTypes } from 'constants/Options'
import moment from "moment";
import ProjectPage from "../../CustomerPhoto/Components/ProjectPage";
const {Header, Footer, Sider, Content} = Layout
const { MyMessage, InfoEmptyData } = CommonComponent
const {ClientProfoto,DefaultCoverPNG} = Imgs

const ClientInfo = ({name}) => {
    let history = useHistory()
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    // 选中的行keys
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // 选中的行
    const [selectedRows, setSelectedRows] = useState([])
    // 结算总金额
    const [total, setTotal] = useState(0)
    const [bg, setBg] = useState(false)
    //客片项目
    const [customerList, setcustomerList] = useState([])
    const [orderList, setOrderList] = useState([])
    //历史动态
    const [dynamic, setDynamic] = useState([{name:'暂无记录',price:'89',time:'2021-10-10'}])
    const [selectedClient, setSelectedClient] = useState({
        source: '推荐', //客户来源
        grouping: '一组', //客户分组
        customerEmail: '', //邮件
        customerName: '', //客户名称
        customerPhone: '', //客户电话
        pageNo:1,
        pageSize:10,
        total:20
    })
    const [ClientList, setClientList] = useState([])
    const [addVisible, setAddVisible] = useState(false)
    const [isAdd, setIsAdd] = useState(false)
    const [tagVisible, setTagVisible] = useState(false)
    const [clientInfo, setClientInfo] = useState({})
    const [isRefresh, setIsRefresh] = useState(true)
    const [checkedTag, setCheckedTag] = useState([])
    const [checkedTags, setCheckedTags] = useState('')
    const [unbindVisible, setUnbindVisible] = useState(false)
    const [project, setProject] = useState({})
    // 添加
    const showAdd = (item,key) => {
        // setClientInfo(JSON.parse(sessionStorage.getItem('pricesheet')));
        // console.log(clientInfos,'clientInfos')
        setAddVisible(true)
        setIsAdd(false)
    }
    // 客户来源
    const onClick = ({key}) => {
        switch (key) {
            case "1":
                setSelectedClient({
                    source: '推荐', //客户来源
                    grouping: '一组', //客户分组
                    customerEmail: '', //邮件
                    customerName: '', //客户名称
                    customerPhone: '', //客户电话
                    pageNo:1,
                    pageSize:10,
                    total:20
                })
                break;
        }
    };

    // 客户分组
    const onClicks = ({key}) => {
        switch (key) {
            case "1":
                setSelectedClient({
                    source: '推荐', //客户来源
                    grouping: '一组', //客户分组
                    customerEmail: '', //邮件
                    customerName: '', //客户名称
                    customerPhone: '', //客户电话
                    pageNo:1,
                    pageSize:10,
                    total:20
                })
                break;
        }
    };
    // 搜索名称
    const changeName = (event) => {
        // e.persist()
        setSelectedClient({
            source: '推荐', //客户来源
            grouping: '一组', //客户分组
            customerEmail: '', //邮件
            customerName: '', //客户名称
            customerPhone: '', //客户电话
            pageNo:1,
            pageSize:10,
            total:20
        })
    }
    const searchInput = useRef()
    const clearAll = () => {
        searchInput.current.state.value = '';
        setSelectedClient({
            source: '推荐', //客户来源
            grouping: '一组', //客户分组
            customerEmail: '', //邮件
            customerName: '', //客户名称
            customerPhone: '', //客户电话
            pageNo:1,
            pageSize:10,
            total:20
        })
    }
    const menu = (
        <div className={styles.sortMenu}>
            <Menu onClick={onClick}>
                {/*SHOOTING_DAY_DESC, CREATE_TIME_DESC, PROJECT_NAME_DESC*/}
                <Menu.Item key="0" disabled className={styles.titleName}>客户来源</Menu.Item>
                <Menu.Item key="1">推荐</Menu.Item>
            </Menu>
        </div>
    );
    const menus = (
        <div className={styles.sortMenu}>
            <Menu onClick={onClicks}>
                {/*SHOOTING_DAY_DESC, CREATE_TIME_DESC, PROJECT_NAME_DESC*/}
                <Menu.Item key="0" disabled className={styles.titleName}>客户分组</Menu.Item>
                <Menu.Item key="1">一组</Menu.Item>
            </Menu>
        </div>
    );
    // 获取客户列表
    const getListByClient = async (data) => {
        setClientInfo(JSON.parse(sessionStorage.getItem('pricesheet')))
    }
    useEffect(() => {
        // getListByClient()
        // setClientInfo(JSON.parse(sessionStorage.getItem('pricesheet')));
        // if(isRefresh){
        //     history.go()
        //     setIsRefresh(false)
        // }
        // history.go()
        getClient()
        getCustomerRecord()
    }, [])
    //历史动态
    const getCustomerRecord=async()=>{
        let data={customerId:JSON.parse(sessionStorage.getItem('client')).id,pageNo:1,pageSize:100}
        const res = await _customerRecord(data)
        if (res.code === 0) {
            console.log(res.data.commentData.commentList[0])
            setDynamic(res.data.commentData.commentList)
            setLoading(false)
        }
    }
    const getClient=async()=>{
        setLoading(true)
        let data= {id:JSON.parse(sessionStorage.getItem('client')).id}
        const res = await _clientInfos(data)
        if (res.data.code === 0) {
            setClientInfo(res.data.data)
            res.data.data.resultVOS.map(v=>{
                v.photoUrl=v.coverPhotoInfo.photoUrl
                v.shootingDay=v.shootingDay?moment(v.shootingDay).format('YYYY/MM/DD'):''
            })
            setcustomerList(res.data.data.resultVOS)
            // customerLabel
            // res.data.data.
            // setCheckedTag()
            // setCheckedTags

            setLoading(false)
        }
    }
    const accountCell = ({customerPortrait, customerName ,item}) => (<div onClick={clickRow(item)} className={styles.account_cell}>
        <img
            src={customerPortrait || ClientProfoto}/>
        <div>
            <p className={styles.nickname}>{customerName}</p>
        </div>
    </div>)
    const handleProject = async (type, item) => {
        if(type === '编辑'){
            console.log(item)
        }else if(type === '解除'){
            const res = await _getProjectById({ id: item.id })
            if (res.code === 0) {
                setProject(res.data)
                setUnbindVisible(true)
            } else {
                myMessage.error(res.msg)
            }
        }
    }
    //确认解绑
    const forunbind =async()=>{
        setLoading(true)
        let obj=JSON.parse(JSON.stringify(project))
        obj.customerId=''
        let projectId=project.id
        const res = await _editProject({...obj,projectId})
        if (res.data.code === 0) {
            myMessage.success('解除成功')
            setUnbindVisible(false)
            getClient()
        } else {
            myMessage.error('解除失败! ' + res.data.msg)
        }
    }
    const handleItem = async (type, item) => {
        if(type === '编辑'){
            setClientInfo(item);
            setAddVisible(true)
            setIsAdd(false)
        }
    }
    //修改用户
    const onAddClient = async (values) => {
        setLoading(true)
        setAddVisible(false)
        values.customerProvinceGeoId=values.areaArray[0]
        values.customerCityGeoId=values.areaArray[1]
        values.customerAreaGeoId=values.areaArray[2]
        values.id=clientInfo.id
        const res = await _editClient(values)
        if (res.data.code === 0) {
            myMessage.success('修改成功')
            // setClientInfo({
            //
            // });
            getClient()
            // history.push(PAGE_PATH.client.index)
        }
    }
    const clickRow=(row)=>{
        sessionStorage.setItem('client',JSON.stringify(row))
    }
    const goBack = () => {
        history.goBack()
    }
    //添加标签
    const addInput = useRef()
    const addTags= async(val)=>{
        let arr=checkedTag
        if(arr.indexOf(val)==-1) {
            setCheckedTag([...arr,val])
        }else{
            let n=arr.indexOf(val)
            setCheckedTag(arr.splice(n,1))
        }
    }
    const addTag= async(val)=>{
        setLoading(true)
        let arr=[]
        clientInfo.customerLabels.map(v=>{
            arr.push(v.customerLabel)
        })
        let obj=JSON.parse(JSON.stringify(clientInfo))
        obj.customerLabels=unique([...checkedTag,...checkedTags,...arr])
        const res = await _editClient(obj)
        if (res.data.code === 0) {
            myMessage.success('添加成功')
            // setClientInfo(obj)
            getClient()
            setTagVisible(false)
        }
        // console.log(addInput.current.state.value)
    }
    const removeTabs=async(i)=>{
        setLoading(true)
        let arr=[]
        clientInfo.customerLabels.map(v=>{
            arr.push(v.customerLabel)
        })
        arr.splice(i,1)
        let obj=JSON.parse(JSON.stringify(clientInfo))
        obj.customerLabels=arr
        const res = await _editClient(obj)
        if (res.data.code === 0) {
            myMessage.success('删除成功')
            getClient()
        }
    }
    const customerSource=(val)=>{
        if(val) {
            let name=''
            CustomerTypes.map(v => {
                if (v.value == val) {
                    name=v.label
                }
            })
            return name
        }else {
            return '-'
        }
    }
    const FooterComponent = () => {
        return (
            <div className={styles.footer}>
                <div>
                    <Button
                        style={{ border: 'none', boxShadow: 'none' }}
                        onClick={()=>setTagVisible(false)}
                    >
                        取消
                    </Button>
                    <Button
                        type="primary"
                        onClick={()=>addTag('')}
                    >
                        确定
                    </Button>
                </div>
            </div>
        )
    }
    function handleChange(value) {
        setCheckedTags(value)
    }
    //去重
    function unique (arr) {
        return Array.from(new Set(arr))
    }
    return (
        <div className={styles.Inf_page}>
            {/*< Header className="sub-header" >*/}
            <PageHeader
                style={{
                    padding: '24px 50px 23px',
                }}
                className={styles.btnBack}
                onBack={() => window.history.back()}
                ghost={false}
                title="客户详情"
                extra={[
                    // <Button type="primary" onClick={showAdd.bind('','add')} key={'add'}>发送消息</Button>,
                    <Button type="primary" onClick={showAdd.bind('','add')} key={'add'}>编辑</Button>,
                    // <Button onClick={showAdd.bind('','add')} key={'add'}>更多</Button>,
                    <Button style={{width: '28px',
                        height: '22px',
                        lineHeight: '20px',
                        padding: '0',
                        borderRadius: '2px',position:'fixed',top:'33px',left:'185px'}}
                            icon={
                                <span
                                    className="iconfont iconbtn_back"
                                ></span>
                            }
                            onClick={goBack}
                    />
                ]}
            >
            </PageHeader>

            <Spin tip="数据加载中" spinning={loading}>
            <Row className={styles.controlBar}>
                <Col span={15}>
                    <Row  className={styles.info}>
                        <Col span={12} className={styles.names}>
                            <img src={clientInfo.customerPortrait||ClientProfoto} alt=""/>
                            <div>
                                <label htmlFor="">客户名称</label>
                                <div>{clientInfo.customerName}</div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <label htmlFor="">电话</label>
                            <div>{clientInfo.customerPhone?clientInfo.customerPhone:'-'}</div>
                        </Col>
                        <Col span={12}>
                            <label htmlFor="">客户来源</label>
                            <div>{customerSource(clientInfo.customerSource)}</div>
                        </Col>
                        <Col span={12}>
                            <label htmlFor="">公司名称</label>
                            <div>{clientInfo.customerCompany?clientInfo.customerCompany:'-'}</div>
                        </Col>
                        <Col span={12}>
                            <label htmlFor="">微信</label>
                            <div>{clientInfo.customerWechat?clientInfo.customerWechat:'-'}</div>
                        </Col>
                        <Col span={12}>
                            <label htmlFor="">Email</label>
                            <div>{clientInfo.customerEmail?clientInfo.customerEmail:'-'}</div>
                        </Col>
                        <Col span={24}>
                            <label htmlFor="">地址</label>
                            <div>{clientInfo.customerFullAddress?clientInfo.customerProvinceGeo+' '+clientInfo.customerCityGeo+' '+clientInfo.customerAreaGeo+' '+clientInfo.customerAddress:'-'}</div>
                        </Col>
                        <Col span={24}>
                            <label htmlFor="">备注</label>
                            <div>{clientInfo.customerRemark?clientInfo.customerRemark:'-'}</div>
                        </Col>
                    </Row>
                    <h3>历史动态</h3>
                    <Divider  style={{ margin: '10px 0' }}/>
                    <div className={styles.activity}>
                        {dynamic.length>0?dynamic.map(item=>
                            <div>{item.nickname} {item.recordMessage}, {item.timeText}</div>
                        ):''}
                    </div>
                </Col>
                <Col span={8} offset={1}>
                    <h3>项目</h3>
                    <div className={styles.list}>
                    {customerList.length>0?(
                    customerList.map(project=>
                        <div  className={styles.custProject}>
                            <Link to={'/collection/'+project.id+'/photos'}>
                                <div className={styles.custProjects}>
                                        <img src={project.photoUrl||DefaultCoverPNG}/>
                                        <div>
                                            <p>{project.name}</p>
                                            <span>{project.shootingDay?project.shootingDay:'未定义日期'}</span>
                                        </div>
                                </div>
                            </Link>
                            <div className={styles.menuBtn}>
                                <Dropdown
                                    overlay={
                                        <div className={styles.settingsMenu}>
                                            <Menu>
                                                {/* <Menu.Item key='0' onClick={() => handleProject('编辑', project)}>编辑</Menu.Item> */}
                                                <Menu.Item><a key='1' onClick={() => handleProject('解除', project)}>解除关联</a></Menu.Item>
                                                {/*<Divider  style={{ margin: '10px 0' }}/>*/}
                                            </Menu>
                                        </div>
                                    }
                                    placement="bottomRight"
                                    trigger={['click']}>
                                    <span className="iconfont iconbtn_list_set"></span>
                                </Dropdown>
                            </div>
                        </div>
                    )):(
                        <Row className={styles.nocontent}>暂无项目</Row>
                        )
                    }
                    </div>
                    <h3>订单</h3>
                    <div className={styles.list}>
                    {orderList.length>0?(
                    orderList.map(project=>
                        <div className={styles.orders}>
                            <div>
                            <span>{project.name}</span> · <span>{project.price}</span>
                            <div className={styles.times}>{project.time}</div>
                            </div>
                            {/*<div className={styles.menuBtn}>
                                <Dropdown
                                    overlay={
                                        <div className={styles.settingsMenu}>
                                            <Menu>
                                                 <Menu.Item key='0' onClick={() => handleItem('停用', item)}>停用</Menu.Item>
                                                <Divider  style={{ margin: '10px 0' }}/>
                                            </Menu>
                                        </div>
                                    }
                                    trigger={['click']}>
                                    <span className="iconfont iconbtn_list_set"></span>
                                </Dropdown>
                            </div>*/}
                        </div>
                    )):(
                        <Row className={styles.nocontent}>暂无记录</Row>
                        )
                    }</div>
                    <h3>标签</h3>
                    {clientInfo.customerLabels&&clientInfo.customerLabels.length>0?(
                        <div>
                        {clientInfo.customerLabels.map((tab,i) => (
                        <Button className={styles.tabs}>{tab.customerLabel}
                            <Popconfirm
                                title="是否删除该标签?"
                                onConfirm={()=>removeTabs(i)}
                                okText="是"
                                cancelText="否"
                            ><i className="iconfont iconbtn_dialog_close"></i></Popconfirm>
                        </Button>
                            ))}
                            <Button style={{color:'#1AA77D',borderColor:'#1AA77D'}} onClick={()=>setTagVisible(true)}>添加</Button>
                        </div>
                    ):(<Button style={{color:'#1AA77D',borderColor:'#1AA77D'}} onClick={()=>setTagVisible(true)}>添加</Button>)}
                </Col>
            </Row>
            </Spin>
            {clientInfo.id?<AddClientForm
                visible={addVisible}
                clientInfo={clientInfo}
                isAdd={isAdd}
                onCreate={onAddClient}
                onCancel={() => {
                    setAddVisible(false)
                }}
            />:<EditClientForm
                visible={addVisible}
                clientInfo={clientInfo}
                isAdd={isAdd}
                onCreate={onAddClient}
                onCancel={() => {
                    setAddVisible(false)
                }}
            />}
            <Modal
                title="添加客户标签"
                visible={tagVisible}
                onOk={addTag}
                centered
                onCancel={()=>setTagVisible(false)}
                okText="确认"
                cancelText="取消"
                width={600}
                maskClosable={false}
                footer={<FooterComponent />}
            >
                <Select
                    mode="tags"
                    tokenSeparators={[",","，","\t","\n"]}
                    removeIcon={
                        <span className={classNames('iconfont iconbtn_dialog_close', styles.clearButton)}></span>
                    }
                    style={{ width: '100%' }}
                    placeholder="输入标签并用逗号间隔..."
                    onChange={handleChange}
                    open={false}>
                </Select>
                <div className={styles.btns}>
                    <Button type="link" className={styles.btn} onClick={()=>addTags('目标客户')} style={{width: 'auto',background:checkedTag.includes('目标客户')?'rgba(10, 134, 255, 0.3)':''}}>目标客户</Button>
                    <Button type="link" className={styles.btn} onClick={()=>addTags('潜在客户')} style={{width: 'auto',background:checkedTag.includes('潜在客户')?'rgba(10, 134, 255, 0.3)':''}}>潜在客户</Button>
                    <Button type="link" className={styles.btn} onClick={()=>addTags('价值客户')} style={{width: 'auto',background:checkedTag.includes('价值客户')?'rgba(10, 134, 255, 0.3)':''}}>价值客户</Button>
                    <Button type="link" className={styles.btn} onClick={()=>addTags('重点客户')} style={{width: 'auto',background:checkedTag.includes('重点客户')?'rgba(10, 134, 255, 0.3)':''}}>重点客户</Button>
                </div>
                {/*<Input ref={addInput} placeholder='请输入标签描述'/>*/}
            </Modal>
            <Modal
                title="是否解除绑定"
                visible={unbindVisible}
                onOk={forunbind}
                onCancel={()=>setUnbindVisible(false)}
                okText="确认"
                cancelText="取消"
                width={600}
                // maskClosable={false}
                // closable={false}
                centered
                cancelButtonProps={{className: 'footerCanceltext'}}
            >
                <div>将解除该客户与项目的关联，是否继续？</div>
            </Modal>
        </div>
    )
}

export default ClientInfo
