import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Switch, Divider, Dropdown, Menu} from 'antd'
import React, {useRef, useState,useEffect} from 'react'
import styles from './presetForm.module.less'
import myMessage from 'components/Message'
import {
  getInfoClient as _getInfoClient,
} from 'reduxModules/client'
import {getSelectOptions} from 'utils/Help'
import {DownloadImgSize } from 'constants/Options'
import Mock from 'mockjs'
import {color} from "echarts/src/export";
const { Option } = Select;
const { Random } = Mock
const codes = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
const PresetForm  = ({
                            visible,
                            onCancel,
                            onCreate,
                            presetDetail,
                            editClient,
                            removeClient,
                            names,
                            pricesheetList,
                            pricesheetId,
                            infoDetails
                          }) => {
  const formRef = useRef()
  const [form] = Form.useForm()
  console.log(infoDetails)
  const [showModal, setShowModal] = useState(false)
  const [showModals, setShowModals] = useState(true)
  const [accountData, setAccountList] = useState({list: [], pagination: []})
  const [currentChange, setCurrentChange] = useState()
  const [inputValue, setInputValue] = useState('')
  const [isPassword, setPassword] = useState(false)
  const [isVisitorMobile, setMobile] = useState(false)
  const [printingWorkshop, setPrintingWorkshop] = useState(false)
  const [isAllowShare, setShare] = useState('')
  const [isGuestPieceShow, setGuestPiece] = useState('')
  const [download, setDownload] = useState(presetDetail.canDownload)
  const [showPin, setShowPin] = useState('')
  const [visitorDownload, setVisitorDownload] = useState('')
  const [isShowBookmark, setShow] = useState('')
  // constructor(props){
  //     super(props);
  //     this.state = { set:[this.props.states.set[0]]};
  //     this.handleBtnStatusChange = this.handleBtnStatusChange.bind(this);
  // }

  useEffect(() => {
    // setisVisitor(projectDetail.isVisitor)
    // setisSetPhotoHidden(projectDetail.isSetPhotoHidden)
    // setisMessage(projectDetail.isMessage)
    formRef.current.setFieldsValue({
      isPassword:presetDetail.visitPassword?true:false,
      isVisitorMobile:presetDetail.isVisitorMobile,
      isAllowShare:presetDetail.isAllowShare,
      isGuestPieceShow:presetDetail.isGuestPieceShow,
      canDownload:presetDetail.canDownload,
      downloadSize:presetDetail.downloadSize,
      isDownloadCode:presetDetail.isDownloadCode,
      isVisitorDownload:presetDetail.isVisitorDownload,
      language:presetDetail.language,
      websiteColor:presetDetail.websiteColor,
      isShowBookmark:presetDetail.isShowBookmark,
      printingWorkshop:presetDetail.printingWorkshop,
      salesPriceId:!presetDetail.salesPriceId?pricesheetId:Number(presetDetail.salesPriceId),
      visitType:presetDetail.visitType,
      isAllowVisitorMeetingConditions:presetDetail.isAllowVisitorMeetingConditions,
    //   isMessage:projectDetail.isMessage,
    //   isSetPhotoHidden:projectDetail.isSetPhotoHidden,
    //   isVisitor:projectDetail.isVisitor,
    });
    setPrintingWorkshop(presetDetail.printingWorkshop)
    // console.log(formRef)
  }, [presetDetail])

  const changePassword = (checked) => {
    setPassword(checked)
  }
  const changeMobile = (checked) => {
    setMobile(checked)
  }
  const changeShare = (checked) => {
    setShare(checked)
  }
  const changeGuestPiece = (checked) => {
    setGuestPiece(checked)
  }
  const changePrintingWorkshop = (checked) => {
    setPrintingWorkshop(checked)
  }
  const changeDownload = (checked) => {
    setDownload(checked)
    if(checked) {
      formRef.current.setFieldsValue({
        isDownloadCode: true,
        downloadSize:'LOW',
        isVisitorDownload: false,
      })
    }else{
      formRef.current.setFieldsValue({
        isDownloadCode: false,
        isVisitorDownload: false,
      })
    }
  }
  const changeShowPin = (checked) => {
    setShowPin(checked)
  }
  const changeVisitorDownlaod = (checked) => {
    setVisitorDownload(checked)
  }
  const changeShowBookmark = (checked) => {
    setShow(checked)
  }
  const colorOptions = [{
    value: 'light',
    text: '浅色',
  },{
    value: 'dark',
    text: '深色',
  }];
  const langOptions = [
    {
      key: 'EN',
      label: 'English',
    },
    {
      key: 'JP',
      label: '日本語'
    },
    {
      key: 'KR',
      label: '한국어'
    },
    {
      key: 'CN',
      label: '简体中文'
    },
    {
      key: 'HK',
      label: '繁體中文（中国香港）'
    },
    {
      key: 'TW',
      label: '繁體中文（中国台湾）'
    }
  ];
  return (
      <Modal
          wrapClassName={styles.setModel}
          visible={visible}
          title={'设置预设：'+names}
          okText="保存"
          cancelText="取消"
          centered
          cancelButtonProps={{className: 'footerCanceltext'}}
          onCancel={onCancel}
          // closable={false}
          maskClosable={false}
          width={600}
          forceRender={true}
          onOk={() => {
            form
                .validateFields()
                .then((values) => {
                  // form.resetFields()
                  // values.isShowBookmark=true
                  values.isMessage=true
                  values.isSetPhontoHidden=true
                  values.isVisitor=false
                  // values.isAllowShare=true
                  if(values.isPassword){
                    values.visitType='PASSWORD'
                    values.visitPassword=Random.string(codes, 8)
                    onCreate(values)
                  }else{
                    values.visitType='OPEN'
                    values.visitPassword=''
                    onCreate(values)
                  }
                  // formRef.current.setFieldsValue({
                  //   values
                  // })
                })
                .catch((info) => {
                  console.log('Validate Failed:', info)
                })
          }}
      >
        <Form
            ref={formRef}
            form={form}
            layout="vertical"
            name="add_project_form"
            requiredMark={false}
            className={styles.createPreset}
            initialValues={{
              // isPassword:presetDetail.visitPassword?true:false,
              // isVisitor:isVisitor,
              // isSetPhotoHidden:isSetPhotoHidden,
              // isMessage:isMessage,
            }}
        >
          <p className={styles.blockTitle}>隐私设置</p>
          <div className={styles.list}>
            <Form.Item label="">
              <span>密码访问</span>
              <Form.Item name="isPassword" noStyle valuePropName="checked">
                <Switch onChange={changePassword} />
              </Form.Item>
            </Form.Item>
          </div>
          <div className={styles.list}>
            <Form.Item label="" >
              <span>请求访客的手机号码</span>
              <Form.Item name="isVisitorMobile" noStyle valuePropName="checked" >
                <Switch disabled onChange={changeMobile} />
              </Form.Item>
            </Form.Item>
          </div>
          <div className={styles.list}>
            <Form.Item label="">
              <span>允许分享</span>
              <Form.Item name="isAllowShare" noStyle valuePropName="checked">
                <Switch onChange={changeShare} />
              </Form.Item>
            </Form.Item>
          </div>
          <div className={styles.list}>
            <Form.Item label="">
              <span>网站显示该项目</span>
              <Form.Item name="isGuestPieceShow" noStyle valuePropName="checked">
                <Switch onChange={changeGuestPiece} />
              </Form.Item>
            </Form.Item>
          </div>
          <p className={styles.blockTitle} style={{paddingTop: "25px"}}>下载设置</p>
          <div className={styles.list}>
            <Form.Item label="">
              <span>允许下载</span>
              <Form.Item name="canDownload" noStyle valuePropName="checked">
                <Switch onChange={changeDownload} />
              </Form.Item>
            </Form.Item>
          </div>
          {download?(
              <div>
              <div className={styles.list}>
                <Form.Item label="">
                  <span>下载尺寸</span>
                  <Form.Item name="downloadSize">
                    <Select
                        suffixIcon={
                          <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                        }
                    >
                      {getSelectOptions(DownloadImgSize)}
                    </Select>
                  </Form.Item>
                </Form.Item>
              </div>
              <div className={styles.list}>
                <Form.Item label="">
                  <span>需要验证码</span>
                  <Form.Item name="isDownloadCode" noStyle valuePropName="checked">
                    <Switch  onChange={changeShowPin} />
                  </Form.Item>
                </Form.Item>
              </div>
              <div className={styles.list}>
                <Form.Item label="">
                  <span>允许访客下载</span>
                  <Form.Item name="isVisitorDownload" noStyle valuePropName="checked">
                    <Switch onChange={changeVisitorDownlaod} />
                  </Form.Item>
                </Form.Item>
              </div>
              </div>
          ):''}
          <p className={styles.blockTitle} style={{paddingTop: "25px"}}>界面设置（暂仅支持网页端）</p>
          <div className={styles.list}>
            <Form.Item label="">
              <span>界面语言</span>
              <Form.Item name="language">
                <Select
                    suffixIcon={
                      <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                    }
                    // onChange={(value) => form.current.setFieldsValue({
                    //     language: value,
                    // })}
                >
                  {getSelectOptions(langOptions)}
                </Select>
              </Form.Item>
            </Form.Item>
          </div>
          {/*<div className={styles.list}>
            <Form.Item label="">
              <span>颜色</span>
              <Form.Item name="websiteLayoutType">
                <Select
                    suffixIcon={
                      <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                    }
                    // onChange={(value) => form.current.setFieldsValue({
                    //     language: value,
                    // })}
                >
                  {getSelectOptions(colorOptions)}
                </Select>
              </Form.Item>
            </Form.Item>
          </div>*/}
          <p className={styles.blockTitle} style={{paddingTop: "25px"}}>收藏设置</p>
          <div className={styles.list}>
            <Form.Item label="">
              <span>收藏夹及相关功能</span>
              <Form.Item name="isShowBookmark" noStyle valuePropName="checked">
                <Switch onChange={changeShowBookmark} />
              </Form.Item>
            </Form.Item>
          </div>
          {/*免费版不显示*/}
          {infoDetails?.type !== 'Trail' && (
            <div>
              <p className={styles.blockTitle} style={{paddingTop: "25px"}}>销售设置</p>
              <div className={styles.list}>
                <Form.Item label="">
                  <span>允许印坊与购买</span>
                  <Form.Item name="printingWorkshop" noStyle valuePropName="checked">
                    <Switch onChange={changePrintingWorkshop} />
                  </Form.Item>
                </Form.Item>
              </div>
              {printingWorkshop &&
              <div className={styles.list}>
                <Form.Item label="">
                  <span>销售应用价格单</span>
                  <Form.Item name="salesPriceId">
                    <Select
                      suffixIcon={
                        <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                      }
                    >
                      {pricesheetList.map(v => (
                        <Option value={v.id}>{v.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form.Item>
              </div>
              }
            </div>
            )}
        </Form>
      </Modal>
  )
}

export default PresetForm
