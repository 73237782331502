/*
 * @Author: melon
 * @Date: 2020-07-04 23:16:56
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-21 19:55:18
 */

export const proHost = 'https://bsi.infotos.co' //生产环境
export const devHost = 'https://bsi.infotos.co' // 开发-带网关
export const userDevHost = 'https://bsi.infotos.co' // 用户系统api-带网关

export const toBService = '/2b-service';

let host = ''
let userHost = ''
if (process.env.NODE_ENV !== 'production') {
  host = devHost
  userHost = userDevHost
} else {
  host = proHost
  userHost = proHost
}

const systemName = '/api/2b' // 开发-无网关
const userSystem = '/api/2b' // 开发-无网关


export const BACKEND_API_URL = host + toBService + systemName

export const CENTER_API_URL = userHost + toBService + userSystem
//销售重定义
export const BACKEND_STOREAPI_URL = host

const Api = {
  common: {
    getQiniuToken: BACKEND_API_URL + '/common/getQiniuToken', //
    getQrcode: BACKEND_API_URL + '/common/qrcode.jpg', // 获取分享二维码
  },
  dashboard: {
    projectInfo: BACKEND_API_URL + '/index/getProjectInfo', // get 查询项目统计信息
    customerInfo: BACKEND_API_URL + '/index/getCustomerInfo', // get 查询客户统计信息
  },
  settings: {
    // 设置
    webInof: BACKEND_API_URL + '/websiteSetting/getAll', // get 获取网站信息
    webEdit: BACKEND_API_URL + '/websiteSetting/edit', // post 编辑网站信息
  },
  showcasePhoto: {
    // 展示图片
    add: BACKEND_API_URL + '/showcasePhoto/add', // post 添加展示图片
    addDescription: BACKEND_API_URL + '/showcasePhoto/addDescription', // post 添加描述
    changeSort: BACKEND_API_URL + '/showcasePhoto/changeSort', // post 调整展示图片排序
    copyToIndex: BACKEND_API_URL + '/showcasePhoto/copyToIndex', // post 显示到首页
    getListForPage: BACKEND_API_URL + '/showcasePhoto/getListForPage', // get 分页查询展示图片
    previewUrl: BACKEND_API_URL + '/showcasePhoto/previewUrl', // get 获取展示预览地址
    remove: BACKEND_API_URL + '/showcasePhoto/remove', // post 删除展示图片
    moveOrCopy: BACKEND_API_URL + '/showcasePhoto/moveOrCopy', // post 移动、复制客片到其他分类
    getDownloadUrl: BACKEND_API_URL + '/showcasePhoto/getDownloadUrl', // get 获取展示图片打包下载地址
    checkImage: BACKEND_API_URL + '/showcasePhoto/checkImageExist', // post 校验图片名称是否存在
  },
  showcaseSetting: {
    // 展示设置
    getAll: BACKEND_API_URL + '/showcaseSetting/getAll', // get 查询当前企业展示设置
    edit: BACKEND_API_URL + '/showcaseSetting/edit', // edit 修改当前企业展示设置
    aboutUrl: BACKEND_API_URL + '/showcaseSetting/aboutUrl', // get 获取关于预览地址
    contactUrl: BACKEND_API_URL + '/showcaseSetting/contactUrl', // get 获取联系预览地址
  },
  showcaseCategory: {
    // 展示分类
    edit: BACKEND_API_URL + '/showcaseCategory/edit', // post 编辑/新增展示分类
    getAll: BACKEND_API_URL + '/showcaseCategory/getAll', // get 查询展示分类
    remove: BACKEND_API_URL + '/showcaseCategory/remove', // post 删除展示分类
    batchEdit: BACKEND_API_URL + '/showcaseCategory/batchEdit', // post 批量编辑展示分类
    getAllMoveOrCopyCategory:
      BACKEND_API_URL + '/showcaseCategory/getAllMoveOrCopyCategory', // get 查询复制移动展示分类 - 用于[作品复制/移动]业务
  },
  order: {
    getMyStorage: BACKEND_API_URL + '/order/getMyStorage', // get 分页查询我的暂存[我的暂存]
    addCart: BACKEND_API_URL + '/order/addCart', // post 加入购物车[我的暂存]
    removeMyStorage: BACKEND_API_URL + '/order/removeMyStorage', // post 删除[我的暂存]
    myCart: BACKEND_API_URL + '/order/myCart', // get 我的购物车[我的购物车]
    removeCart: BACKEND_API_URL + '/order/removeCart', // post 删除购物车[购物车]
  },
  member: {
    // 会员相关
    login: CENTER_API_URL + '/member/enterpriseLogin', // post 企业登录
    register: CENTER_API_URL + '/member/enterpriseRegister', // post 企业注册
    forgetPsw: CENTER_API_URL + '/member/forget', // post 忘记密码
    checkPhone: CENTER_API_URL + '/member/getMobileHasRegistered', // get 获取手机号是否已注册
    verifyCode: CENTER_API_URL + '/member/sendVerifyCode', // post 发送短信验证码
  },
  accountSetting: {
    // 账号设置相关
    info: BACKEND_API_URL + '/accountSetting/getAccountInfo', // get 获取账号信息,
    changePassword: BACKEND_API_URL + '/accountSetting/changePassword', // post 更新账户密码
    changeAvatar: BACKEND_API_URL + '/accountSetting/changeAvatar', // post 更新账户头像
    changeAccountInfo: BACKEND_API_URL + '/accountSetting/changeAccountInfo', // post 更新账户信息
    roles: BACKEND_API_URL + '/accountSetting/account/roles/get', // get 获取角色信息
    addAccount: BACKEND_API_URL + '/accountSetting/batch/member/account', // post 批量添加员工账号
    accountList: BACKEND_API_URL + '/accountSetting/account/page', // get 批量添加员工账号
    changeRole: BACKEND_API_URL + '/accountSetting/changeRole', // post 更新用户角色
    remove: BACKEND_API_URL + '/accountSetting/remove', // post 更新用户角色
  },
  enterprise: {
    // 商业信息
    enterpriseInfo: BACKEND_API_URL + '/enterprise/getEnterpriseInfo', // get 查询商业信息设置
    enterpriseEdit: BACKEND_API_URL + '/enterprise/editEnterpriseInfo', // post 更新商业信息设置
    enterpriseLevel: BACKEND_API_URL + '/enterprise/getEnterpriseLevel', // 企业账号级别信息
  },
  enterpriseService: {
    // 服务信息
    enterpriseServiceList: BACKEND_API_URL + '/enterpriseService/getAll', // get 查询服务信息列表
    getCategorySelector:
      BACKEND_API_URL + '/enterpriseService/getCategorySelector', // get 查询分类选择列表
    getById: BACKEND_API_URL + '/enterpriseService/getById', // get 根据id查询服务信息
    enterpriseServiceEdit: BACKEND_API_URL + '/enterpriseService/edit', // post 新增、编辑服务信息
    remove: BACKEND_API_URL + '/enterpriseService/remove', // post 删除服务信息
  },
  message: {
    // 消息
    notRead: BACKEND_API_URL + '/message/getNotReadForPage', // get 分页查询未读消息
    list: BACKEND_API_URL + '/message/getListForPage', // 获取
  },
  subscribe: {
    // 订阅计划
    subscribe: BACKEND_API_URL + '/subscribe/getSubscribe', // get 查询订阅信息,
    getSubscribeDetail: BACKEND_API_URL + '/subscribe/getSubscribeDetail', // get 查询订阅详情
  },
  recycle : {
    // 回收站
    clearRecycle: BACKEND_API_URL + '/recycle/clear', // post 回收站单个文件或文件夹删除操作,
    clearRecycleAll: BACKEND_API_URL + '/recycle/clear/all', // post 回收站清空操作,
    getRecycleList: BACKEND_API_URL + '/recycle/list', // get 查询订阅详情
    recycleReduction: BACKEND_API_URL + '/recycle/reduction', // post 恢复
  },
  customerPhoto: {
    // 客片
    category: BACKEND_API_URL + '/projectCategory/list', // get 查询客片项目分类
    addCategory: BACKEND_API_URL + '/projectCategory/edit', // get 查询客片项目分类
    saveProjectCategory: BACKEND_API_URL + '/projectCategory/batchEdit', // post 批量新增或编辑客片项目分类
    projects: BACKEND_API_URL + '/project/list', // get 获取客片项目
    project: BACKEND_API_URL + '/project/getById', // get 获取客片项目详情
    addProject: BACKEND_API_URL + '/project/add', // post 新增客片项目
    editProject: BACKEND_API_URL + '/project/modify', // post 新增客片项目
    removeProject: BACKEND_API_URL + '/project/remove', // post 删除客片项目
    changeLang: BACKEND_API_URL + '/project/changeLanguage', // post 改变语言
    changeProjectStatus: BACKEND_API_URL + '/project/changeStatus', // post 修改客片项目状态
    photoCategory: BACKEND_API_URL + '/customerPhotoCategory/getAll', // get 查询客片照片分类
    photoCategoryForMove:
      BACKEND_API_URL + '/customerPhotoCategory/getAllAndShowcaseCategory', // get 查询客片照片分类(移动、复制)
    moveOrCopyPhotos: BACKEND_API_URL + '/customerPhoto/moveOrCopy', // post 移动、复制客片
    removePhotos: BACKEND_API_URL + '/customerPhoto/remove', //  post删除类别中图片
    savePhotoCategory: BACKEND_API_URL + '/customerPhotoCategory/batchEdit', // post 批量新增或编辑客片项目分类
    photos: BACKEND_API_URL + '/customerPhoto/getListForPage', // get 查询客片照片
    addPhotos: BACKEND_API_URL + '/customerPhoto/add', // post 新增客片照片
    packagePhotos: BACKEND_API_URL + '/customerPhoto/getDownloadUrl', // post 打包客片照片
    checkImage: BACKEND_API_URL + '/customerPhoto/checkImageExist', // post 检查客片存在
    setCover: BACKEND_API_URL + '/customerPhoto/setCover', // post 设置为封面
    previewUrl: BACKEND_API_URL + '/customerPhoto/previewUrl', // get 客片预览地址
    changeSort: BACKEND_API_URL + '/customerPhoto/changeSort', // post 调整展示图片排序
    bookmark: BACKEND_API_URL + '/bookmark/getAll', // get 查询收藏夹列表
    bookmarkCategoryForMove:
      BACKEND_API_URL + '/bookmark/getAllAndShowcaseCategory', // get 查询客片照片分类(移动、复制)
    bookmarkPhoto: BACKEND_API_URL + '/bookmarkPhoto/getListForPage', // get 查询收藏夹照片
    moveOrCopyBookmarkPhotos: BACKEND_API_URL + '/bookmarkPhoto/moveOrCopy', //post 移动、复制文件夹照片
    removeBookmarkPhotos: BACKEND_API_URL + '/bookmarkPhoto/remove', //  post删除文件夹图片
    saveBookmark: BACKEND_API_URL + '/bookmark/edit', // post 新增/修改收藏夹
    downloadBookmarkExcel: BACKEND_API_URL + '/bookmark/excelBookmark.xlsx', // get 下载Excel
    downloadPrivateExcel: BACKEND_API_URL + '/customerPhoto/excelBookmark.xlsx', // post 下载Excel
    removeBookmark: BACKEND_API_URL + '/bookmark/remove', // post 删除收藏夹
    packageBookmarkPhotos: BACKEND_API_URL + '/bookmarkPhoto/getDownloadUrl', // post 打包收藏夹
    overFavorite: BACKEND_API_URL + '/bookmark/overFavorite', // get b端显示c端超量提示
    ignoreExceedTip: BACKEND_API_URL + '/bookmark/ignoreExceedTip', // post 忽略超量提示
    fileNameList: BACKEND_API_URL + '/bookmark/file/name/list', // get 获取收藏夹文件名
    fileNameLists: BACKEND_API_URL + '/bookmark/file/name/select', // get 获取收藏夹选中文件名
    customerFileNameLists: BACKEND_API_URL + '/customerPhoto/file/name/select', // get 获取收藏夹选中文件名
    getHiddenListForPage: BACKEND_API_URL + '/customerPhoto/getHiddenListForPage', // get 获取客片私有图片
    getMessageListForPage: BACKEND_API_URL + '/customerPhoto/getMessageListForPage', // get 获取客片留言图片
    getProjectStatistics: BACKEND_API_URL + '/project/getProjectStatistics', // get 根据项目ID获取收藏图片数量，隐藏图片数量，留言数量
    removeHidden: BACKEND_API_URL + '/customerPhoto/cancel/hide', // post 移除图片隐藏
    projectRecord: BACKEND_API_URL + '/project/record/browse/list', // get 查询项目浏览记录列表
    removeRecord: BACKEND_API_URL + '/project/record/browse/remove', // post 移除浏览记录
    projectBookmarkRecord: BACKEND_API_URL + '/bookmarkCollectionRecord/list', // get 查询项目收藏记录列表
    removeBookmarkRecord: BACKEND_API_URL + '/bookmarkCollectionRecord/remove', // post 移除收藏记录
    projectDownloadRecord: BACKEND_API_URL + '/customerDownloadRecord/getListForPage', // get 查询项目下载记录列表
    removeDownloadRecord: BACKEND_API_URL + '/customerDownloadRecord/remove', // post 移除下载记录
    removeMessage: BACKEND_API_URL + '/customerPhoto/remove/message', // post 移除留言
    shareRecord: BACKEND_API_URL + '/projectUserShare/list', // get 查询项目浏览记录列表
    removeShare: BACKEND_API_URL + '/projectUserShare/remove', // post 移除分享
    addPreset: BACKEND_API_URL + '/project/projectSetPreset/add', // post 添加项目预设
    editPreset: BACKEND_API_URL + '/project/projectSetPreset/edit', // post 添加项目预设
    listPreset: BACKEND_API_URL + '/project/projectSetPreset/list', // get 查询项目预设
    removePreset: BACKEND_API_URL + '/project/projectSetPreset/remove', // post 移除项目预设
    editProjectsPriceId: BACKEND_API_URL + '/project/editProjectsPriceId', // post 项目批量绑定销售价格ID
  },
  client: {
    //客户
    addClient: BACKEND_API_URL + '/customer/add', // post 添加客户信息
    removeClient: BACKEND_API_URL + '/customer/remove', // post 添加客户信息
    uplateClient: BACKEND_API_URL + '/customer/update', // post 修改客户信息
    infoClient: BACKEND_API_URL + '/customer/getListForPage', // get 查询客户列表信息
    clientInfos: BACKEND_API_URL + '/customer/getById', // get 查询客户详细信息
    customerRecord: BACKEND_API_URL + '/customerRecord/getListForPage', // get 分页查询B端客户管理记录信息
  },
  feedback:{
    feedback: BACKEND_API_URL + '/feedback/add', // 添加意见反馈
  },
  share:{
    email: BACKEND_API_URL + '/ProjectShareRecords/add', // 邮件分享
    emailRecords: BACKEND_API_URL + '/ProjectShareRecords/getShareRecords', //邮件已分享人员
  },
  store:{
      //订单
    addOrder: BACKEND_STOREAPI_URL + '/store-service/order/order-info/add', // 添加订单
    deliverGoods: BACKEND_STOREAPI_URL + '/store-service/order/order-info/deliver-goods', //新增发货
    eiditDeliverGoods: BACKEND_STOREAPI_URL + '/store-service/order/order-info/edit-deliver', //修改发货信息
    getOrderAll: BACKEND_STOREAPI_URL + '/store-service/order/order-info/get-sto', //获取所有订单信息
    getOrderDetails: BACKEND_STOREAPI_URL +'/store-service/order/order-info/get', //通过id获取订单信息
    getOrderAddress: BACKEND_STOREAPI_URL +'/store-service/order-address/get', //通过id获取订单地址信息
      //价格表
    addPricesheet: BACKEND_STOREAPI_URL +'/store-service/product/price-list/add', //新增价格表
    copyPricesheet: BACKEND_STOREAPI_URL +'/store-service/product/price-list/copy', //复制价格表
    deletePricesheet: BACKEND_STOREAPI_URL +'/store-service/product/price-list/delete', //删除价格表
    editPricesheet: BACKEND_STOREAPI_URL +'/store-service/product/price-list/edit', //编辑价格表
    getPricesheet: BACKEND_STOREAPI_URL +'/store-service/product/price-list/get', //获取价格表
    getPricesheetId: BACKEND_STOREAPI_URL +'/store-service/product/price-list/get-default', //获取价格表默认id
    getEnterprise: BACKEND_STOREAPI_URL +'/store-service/product/price-list/get-enterprise', //获取供应商表
    pricesheetInfo: BACKEND_STOREAPI_URL +'/store-service/product/product-base-info/get-list', //获取价格表详细信息
    productEditStatus: BACKEND_STOREAPI_URL +'/store-service/product/product-base-info/edit-status', //上下线
    addProduct: BACKEND_STOREAPI_URL +'/store-service/product/product-base-info/add/product', //添加自定义产品
    editProduct: BACKEND_STOREAPI_URL +'/store-service/product/product-base-info/edit', //编辑自定义产品
    editPrice: BACKEND_STOREAPI_URL +'/store-service/product/product-base-info/price', //编辑价格
    addDigital: BACKEND_STOREAPI_URL +'/store-service/product/product-base-info/add/digital', //添加数字下载
    editDigital: BACKEND_STOREAPI_URL +'/store-service/product/product-base-info/edit/digital', //编辑数字下载
    addPackage: BACKEND_STOREAPI_URL +'/store-service/product/product-base-info/add/product/package', //添加产品包
    editPackage: BACKEND_STOREAPI_URL +'/store-service/product/product-base-info/edit/product/package', //编辑产品包
    deleteProduct: BACKEND_STOREAPI_URL +'/store-service/product/product-base-info/delete', //删除产品
    getSingle: BACKEND_STOREAPI_URL +'/store-service/product/product-base-info/get/single', //通过产品id和规格名称获取产品规格
    getPricename: BACKEND_STOREAPI_URL +'/store-service/product/price-list/get/price-name', //通过价格表id获取名称
    setDefault: BACKEND_STOREAPI_URL +'/store-service/product/price-list/set-default', //设置价格表为默认
    //优惠券
    addCoupon: BACKEND_STOREAPI_URL +'/store-service/coupon/add', //新增优惠券
    deleteCoupon: BACKEND_STOREAPI_URL +'/store-service/coupon/delete', //删除优惠券
    endCoupon: BACKEND_STOREAPI_URL +'/store-service/coupon/end', //终止优惠券
    editCoupon: BACKEND_STOREAPI_URL +'/store-service/coupon/edit', //编辑优惠券
    getCoupon: BACKEND_STOREAPI_URL +'/store-service/coupon/get', //获取优惠券
    productType: BACKEND_STOREAPI_URL +'/store-service/set/store-product-type/get', //获取产品类型
    uploadImg: BACKEND_STOREAPI_URL +'/file-service/qi-niu/upload', //文件上传
    addPolicy: BACKEND_STOREAPI_URL +'/store-service/policy/add', //添加政策
    editPolicy: BACKEND_STOREAPI_URL +'/store-service/policy/edit', //修改政策
    getPolicy: BACKEND_STOREAPI_URL +'/store-service/policy/get', //获取政策
    getPolicyDetail: BACKEND_STOREAPI_URL +'/store-service/policy/get-detail', //政策详情
    deletePolicy: BACKEND_STOREAPI_URL +'/store-service/policy/delete', //删除政策
    addFreight: BACKEND_STOREAPI_URL +'/store-service/set/store-freight/add', //添加运费
    deleteFreight: BACKEND_STOREAPI_URL +'/store-service/set/store-freight/delete', //删除运费
    editFreight: BACKEND_STOREAPI_URL +'/store-service/set/store-freight/edit', //修改运费
    getFreight: BACKEND_STOREAPI_URL +'/store-service/set/store-freight/get', //查询运费
    addProductType: BACKEND_STOREAPI_URL +'/store-service/set/store-product-type/add', //添加商品类型
    deleteProductType: BACKEND_STOREAPI_URL +'/store-service/set/store-product-type/delete', //删除商品类型
    editProductType: BACKEND_STOREAPI_URL +'/store-service/set/store-product-type/edit', //修改商品类型
    getProductType: BACKEND_STOREAPI_URL +'/store-service/set/store-product-type/get', //查询商品类型
    logistEnu: BACKEND_STOREAPI_URL +'/supplier-service/dict/logist-enu', //查询快递公司下拉枚举
  },
}

export default Api
