import {Col, DatePicker, Form, Input, Modal, Row, Select} from 'antd'
import AddSelect from 'components/AddSelect'
import React, {useState} from 'react'
import styles from './addProjectForm.module.less'
const { Option } = Select

const AddProjectForm = ({
  visible,
  onCreate,
  onCancel,
  projectCategorys,
  onAdd, preset
}) => {
  const [form] = Form.useForm()
  const [showMore, setShowMore] = useState(false)
  return (
    <Modal
      visible={visible}
      title="添加项目"
      okText="创建"
      cancelText="取消"
      centered
      cancelButtonProps={{className: 'footerCanceltext'}}
      onCancel={onCancel}
      maskClosable={false}
      width={600}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            onCreate(values)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_project_form"
        requiredMark={false}
        className={styles.createProjectDialog}
        initialValues={{
          presetId:'',
        }}
      >
        <Form.Item
          name="name"
          label="项目名称"
          rules={[
            {
              required: true,
              message: '给项目建个名称',
            },
          ]}
        >
          <Input placeholder="项目名称" maxLength={30}/>
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="shootingDay"
              label="拍摄日期"
              // rules={[
              //   {
              //     required: true,
              //     message: '请选择',
              //   },
              // ]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="category" label="分类">
              <AddSelect options={projectCategorys} onAdd={onAdd} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="presetId" label="预设">
              <Select
                  placeholder="请选择"
                  suffixIcon={
                    <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                  }
              >
                <Option value="">默认</Option>
                {preset.map(v=>(
                      <Option value={v.id}>{v.name}</Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {showMore?(<Row gutter={16}>
          <Col span={12}>
            <Form.Item
                name="customerName"
                label="客户名称"
                // rules={[
                //   {
                //     required: true,
                //     message: '请输入',
                //   },
                // ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
                name="customerPhone"
                label="电话"
                // rules={[
                //   {
                //     required: true,
                //     message: '请输入',
                //   },
                // ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>):null}
        {/*<div className={styles.more} onClick={()=>setShowMore(!showMore)}>更多选项/客户信息</div>*/}
      </Form>
    </Modal>
  )
}

export default AddProjectForm
