import Banner from './banner_dashboard.png'
import DefaultCoverPNG from './default_cover.png'
import NoData from './img_nodata.png'
import photoSample1 from './Infotos-sample1.jpg'
import photoSample2 from './Infotos-sample2.jpg'
import LogoBK from './logo_bk.png'
import LogoWT from './logo_wt.png'
import MsgInfo from './msg_info.png'
import photoSample from './photoSample.png'
import User from './user.png'
import ClientProfoto from './client_profoto.png'
import ProfotoDefault from './profoto_default.png'
import Wxcode from './wx_code.jpg'
import QRcode from './qrCode2.jpg'
import memberIcon from './memberIcon.svg'
import close from './close.svg'
import downloadCover from './download_cover.jpg'
import restricted from './restricted.png'
import imgFolderCatagory from './img_folder_catagory.png'
import imgFolderProject from './img_folder_project.png'
const Imgs = {
  LogoWT,
  LogoBK,
  photoSample,
  User,
  ClientProfoto,
  ProfotoDefault,
  Banner,
  Wxcode,
  MsgInfo,
  DefaultCoverPNG,
  NoData,
  photoSample1,
  photoSample2,
  QRcode,
  memberIcon,
  close,
  downloadCover,
  restricted,
  imgFolderCatagory,
  imgFolderProject
}

export default Imgs
