import React, { Component } from 'react'
import { Input, InputNumber } from 'antd'

const withTrim = (WrappedComponent) =>
  class WrapperComponent extends Component {
    // 去除头尾空格
    handleBlur = (e) => {
      e.target.value = e.target.value.trim()
      const { onChange } = this.props
      onChange(e)
    }

    render() {
      return <WrappedComponent onBlur={this.handleBlur} {...this.props} />
    }
  }
export const TrimInput = withTrim(Input)

export const TrimPswInput = withTrim(Input.Password)

export const TrimTextArea = withTrim(Input.TextArea)

export const TrimSearch = withTrim(Input.Search)

export const TrimInputNumber = withTrim(InputNumber)

export default withTrim
