/*
 * @Author: melon
 * @Date: 2020-07-02 16:20:31
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-16 16:44:49
 */

import { Spin } from 'antd'
import React, { forwardRef, useEffect, useState } from 'react'
import { getMessageList as _getMessageList } from 'reduxModules/message'
import CommonComponent from 'components/index'

import MsgList from '../Components/MsgList'
const { InfoEmptyData } = CommonComponent

const MsgPage = (props, ref) => {
  const [list, setList] = useState([])
  const [requesting, setRequesting] = useState(false)

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  })
  const getMessage = async (data = {}) => {
    try {
      setRequesting(true)

      const res = await _getMessageList({
        pageNo: data.current,
        pageSize: data.pageSize,
      })
      if ([0].includes(res.code) && res.data) {
        const {
          data: {
            formattedData: { formattedList, formattedPagination },
          },
        } = res
        setList(formattedList)
        setPagination(formattedPagination)
      }

      return res
    } catch (error) {
      console.log(error)
    } finally {
      setRequesting(false)
    }
  }
  useEffect(() => {
    getMessage()
  }, [])
  return (
    <Spin tip="数据加载中" spinning={requesting}>
      {list.length > 0 && (
        <MsgList list={list} pagination={pagination} getList={getMessage} />
      )}
      {list.length <= 0 && !requesting && (
        <InfoEmptyData description="暂无消息或动态" />
      )}
    </Spin>
  )
}

export default forwardRef(MsgPage)
