import Api from 'apis/Api.js'
import request from 'utils/Request'

// 获取网站设置
export const _getShowcaseSetting = async () => {
  return await request({
    method: 'get',
    url: Api.showcaseSetting.getAll,
  })
}

// 保存网站设置

export const _editShowcaseSetting = async (data) => {
  return await request({
    method: 'post',
    url: Api.showcaseSetting.edit,
    data: data,
  })
}

// 获取关于预览地址
export const _getAboutUrl = async () => {
  return await request({
    method: 'get',
    url: Api.showcaseSetting.aboutUrl,
  })
}

// 获取联系预览地址
export const _getContactUrl = async () => {
  return await request({
    method: 'get',
    url: Api.showcaseSetting.contactUrl,
  })
}
