import { Form, Modal } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { TrimTextArea } from '../WithTrim/index'
import styles from './index.module.less'

const AddDescriptionModal = ({ data, visible, onCreate, onCancel }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true)
        const resData = await onCreate(values)
        setLoading(false)
        if (resData.data && [0].includes(resData.data.code)) {
          form.resetFields()
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }
  useEffect(() => {
    form.setFieldsValue({
      ...data,
    })
  }, [form, data])
  return (
    <Modal
      visible={visible}
      title="添加描述或标题"
      okText="保存"
      cancelText="取消"
      onCancel={onCancel}
      onOk={onOk}
      confirmLoading={loading}
      maskClosable={false}
      width={600}
      centered
      cancelButtonProps={{className: 'footerCanceltext'}}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_description_form"
        className={styles.add_description_form}
      >
        <Form.Item className={styles.Inf_border_img}>
          <img src={data.thumbInfo ? data.thumbInfo['thumb'].photoUrl : ''} alt="" />
        </Form.Item>
        <Form.Item name="description" label="">
          <TrimTextArea
            placeholder="添加图片标题或描述，最大50字。"
            maxLength={50}
            className={classNames(
              'Inf_large_input_420',
              styles.description_textArea
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddDescriptionModal
